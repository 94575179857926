import { Box, Typography } from '@mui/material'
import moment from 'moment'
import formatarNumeroParaMoeda from '../../../../util/util'
import OverflowTooltip from '../../../estilos/OverflowTooltip'

const renderProcessoOriginador = (params) => (
  <Box display="flex" flexDirection="column" sx={{ width: '100%', margin: '12px, 16px' }}>
    <Typography variant="body2">{params?.row?.numeroProcessoOriginadorDivida}</Typography>
  </Box>
)

const renderDecisao = (params) => (
  <Box display="flex" flexDirection="column" sx={{ width: '100%', margin: '12px, 16px' }}>
    <OverflowTooltip title={params.row?.nomeDeliberacao}>
      <span>{params.row?.deliberacaoOriginadoraDivida?.nomeDeliberacao}</span>
    </OverflowTooltip>
    <Typography variant="body2" color="textSecondary">
      {`${params.row.deliberacaoOriginadoraDivida?.itemDeliberacao || ''} ${
        params.row.descricaoTipoDeliberacao
      }`}
    </Typography>
  </Box>
)

const renderResponsavel = (params) => (
  <OverflowTooltip title={params?.row?.responsavel}>{params?.row?.responsavel}</OverflowTooltip>
)

const renderSituacao = (params) => (
  <Box width={1}>
    <Typography variant="body2">{params?.row?.situacao}</Typography>
  </Box>
)

const renderData = (data) => (
  <Box width={1}>
    <Typography variant="body2" align="right">
      {data ? moment(data).format('DD/MM/yyyy') : ''}
    </Typography>
  </Box>
)

const renderDataCriacao = (params) => renderData(params?.row?.dataHoraCriacao)

const renderDataVencimento = (params) => renderData(params?.row?.dataVencimentoParaPagar)

const renderDataPagamento = (params) => renderData(params?.row?.dataHoraPagamento)

const renderValorPagamento = (params) => (
  <Box display="flex" flexDirection="column" sx={{ width: '100%', margin: '12px, 16px' }}>
    <Typography variant="body2" align="right">
      R$ {formatarNumeroParaMoeda(params?.row?.valorPagamento, 2, 2)}
    </Typography>
    <Typography variant="body2" align="right" color="textSecondary">
      {params?.row?.tipoPagamentoEscolhido}
    </Typography>
  </Box>
)

export const COLUNAS_PAGAMENTO = {
  PROCESSO_ORIGINADOR: {
    field: 'numeroProcessoOriginadorDivida',
    headerName: 'Processo Originador',
    width: 150,
    renderCell: renderProcessoOriginador,
  },
  DECISAO: {
    field: 'decisao',
    headerName: 'Decisão',
    width: 280,
    renderCell: renderDecisao,
  },
  RESPONSAVEL: {
    field: 'responsavel',
    headerName: 'Responsável',
    width: 200,
    renderCell: renderResponsavel,
  },
  SITUACAO: {
    field: 'situacao',
    headerName: 'Situação',
    width: 160,
    renderCell: renderSituacao,
  },
  DATA_CRIACAO: {
    field: 'dataHoraCriacao',
    headerName: 'Data de criação',
    width: 120,
    renderCell: renderDataCriacao,
  },
  DATA_VENCIMENTO: {
    field: 'dataVencimentoParaPagar',
    headerName: 'Data de vencimento',
    width: 150,
    renderCell: renderDataVencimento,
  },
  DATA_PAGAMENTO: {
    field: 'dataHoraPagamento',
    headerName: 'Data de pagamento',
    width: 150,
    renderCell: renderDataPagamento,
  },
  VALOR_PAGAMENTO: {
    field: 'valorPagamento',
    headerName: 'Valor',
    width: 180,
    type: 'number',
    renderCell: renderValorPagamento,
  },
}

export const colunasPagamentoPagTesouro = [
  COLUNAS_PAGAMENTO.PROCESSO_ORIGINADOR,
  COLUNAS_PAGAMENTO.DECISAO,
  COLUNAS_PAGAMENTO.RESPONSAVEL,
  COLUNAS_PAGAMENTO.SITUACAO,
  COLUNAS_PAGAMENTO.DATA_CRIACAO,
  COLUNAS_PAGAMENTO.DATA_VENCIMENTO,
  COLUNAS_PAGAMENTO.DATA_PAGAMENTO,
  COLUNAS_PAGAMENTO.VALOR_PAGAMENTO,
]

export const montarFiltrosSelecionados = (filtrosAplicados) => {
  const filtrosSelecionados = []
  filtrosAplicados?.forEach((filtro) => {
    if (filtro.opcoes) {
      const opcoesSelecionadas = filtro.opcoes.filter((opcao) => opcao.selecionada)
      if (opcoesSelecionadas.length > 0) {
        const codigosOpcoesSelecionadas = opcoesSelecionadas
          .map((opcao) => opcao.codigo)
          .filter((it) => it)
        filtrosSelecionados.push({
          nome: filtro.codigo,
          valor: codigosOpcoesSelecionadas.length > 0 ? codigosOpcoesSelecionadas : filtro.valor,
        })
      }
    } else {
      filtrosSelecionados.push({
        nome: filtro.codigo,
        valor: filtro.valor,
      })
    }
  })
  return filtrosSelecionados
}

export const desabilitarOpcaoFiltro = (opcao) => ({
  ...opcao,
  selecionada: false,
  desabilitada: true,
})

export const habilitarOpcaoFiltro = (opcao) => ({ ...opcao, desabilitada: false })

export const limparOpcoesFiltro = (opcoes) =>
  opcoes.map((opcao) => ({ ...opcao, selecionada: false, desabilitada: false }))

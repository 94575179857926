import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const ContentBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    margin: '96px 16px',
    width: '100%',
  },
  [theme.breakpoints.up('lg')]: {
    margin: '96px 80px',
    width: '100%',
  },
  [theme.breakpoints.up('xl')]: {
    margin: '96px auto',
    width: '1440px',
  },
}));

export default ContentBox
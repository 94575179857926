export const safely = (originalSaga) =>
  function* _safely(payload) {
    try {
      yield originalSaga(payload)
    } catch (e) {
      console.error(e)
    }
  }

function isAmbienteProducao() {
  return (
    window.location.href.indexOf('apps') > -1 ||
    window.location.href.indexOf('prod') > -1 ||
    window.location.href.indexOf('producao') > -1
  )
}

export default isAmbienteProducao

import * as React from 'react';
import { Alert, Box, Card, CardContent, Drawer, IconButton, Typography, List, ListItem, ListItemText } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import CloseIcon from '@mui/icons-material/Close';

const MensagemFormatoArquivo = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const styles = {
    drawer: {
      width: 700
    },
    content: {
      padding: 3,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 2,
    },
    enfase: {
      color: 'primary.main',
      fontWeight: 'bold',
    },
    listItem: {
      display: 'list-item',
      marginLeft: 0,
    },
  };

  return (
    <section>
      <IconButton size='small' onClick={handleOpen}>
        <HelpIcon fontSize='small' />
      </IconButton>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
      >
        <Box sx={styles.drawer}>
          <Box sx={styles.content}>
            <Box sx={styles.header}>
              <Typography variant='h5'>Formato do arquivo (.txt)</Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography variant='body1' gutterBottom>
              O arquivo com a extensão (.txt) a ser importado pela calculadora de débito deve conter o formato descrito a seguir:
            </Typography>
            <Typography variant='h6' gutterBottom sx={{ mt: 2 }}>
              Atributos do arquivo
            </Typography>
            <List dense>
              {[
                { key: '[DEBITO]', desc: 'A primeira linha do arquivo deve conter exclusivamente a palavra [DEBITO], servindo como um indicador do tipo de informação que o arquivo contém.' },
                { key: 'SeMaFe', desc: 'Indica se houve má fé. Os valores possíveis são true, false ou não informado.' },
                { key: 'Responsavel', desc: 'Nome do responsável pelo débito.' },
                { key: 'Funcao', desc: 'Função do responsável.' },
                { key: 'Orgao', desc: 'Órgão relacionado ao débito.' },
                { key: 'SiglaOrgao', desc: 'Sigla do órgão relacionado ao débito.' },
                { key: 'OrigemDebito', desc: 'Origem do débito.' },
                { key: 'PrimeiraAssinatura', desc: 'Primeira assinatura no demonstrativo de débito.' },
                { key: 'SegundaAssinatura', desc: 'Segunda assinatura no demonstrativo de débito.' },
                { key: 'TerceiraAssinatura', desc: 'Terceira assinatura no demonstrativo de débito.' },
                { key: 'Data1', desc: 'Data da primeira parcela do débito, no formato DD/MM/YYYY.' },
                { key: 'Valor1', desc: 'Valor da primeira parcela do débito, no formato 999999.99.' },
                { key: 'Tipo1', desc: 'Indica o tipo de débito. Os valores possíveis são D para débito e C para crédito.' },
              ].map((item, index) => (
                <ListItem key={index} sx={styles.listItem}>
                  <ListItemText
                    primary={<><Box component="span" sx={styles.enfase}>{item.key}: </Box>{item.desc}</>}
                  />
                </ListItem>
              ))}
            </List>

            <Alert severity="info" sx={{ mt: 2, mb: 2 }}>
              Se houver mais parcelas a serem informadas, inclua os atributos Data2, Valor2 e Tipo2 para a segunda parcela, e continue com Data3, Valor3 e Tipo3 para a terceira parcela, e assim por diante.
            </Alert>
            <Alert severity="warning" sx={{ mt: 2, mb: 2 }}>
              Os atributos <Box component="span" sx={styles.enfase}>SeMaFe, Responsavel, Funcao, Orgao, SiglaOrgao, OrigemDebito, PrimeiraAssinatura, SegundaAssinatura e TerceiraAssinatura</Box> podem não ser informados.
              No entanto, a linha contendo o nome do atributo seguido de = deve existir. Por exemplo, se não houver responsável, a linha deve ser <strong>Responsavel=</strong>.
            </Alert>
            <Card variant='outlined' sx={{ mt: 2 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>Exemplo do conteúdo do arquivo:</Typography>
                <Typography variant="body2" component="pre" sx={{ fontFamily: 'monospace', mt: 1, whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                  {`[DEBITO]
SeMaFe=false
Responsavel=José da Silva
Funcao=Chefe de departamento
Orgao=
SiglaOrgao=
OrigemDebito=Origem do débito
PrimeiraAssinatura=
SegundaAssinatura=
TerceiraAssinatura=
Data1=01/01/2023
Valor1=3242.34
Tipo1=D
Data2=01/01/2024
Valor2=100
Tipo2=D`}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Drawer>
    </section>
  );
};

export default MensagemFormatoArquivo;
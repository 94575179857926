import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { Box, Divider, FormControl, IconButton, MenuItem, Select, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Close'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import formatarNumeroParaMoeda from '../../../util/util'
import pagamentoService from '../../../services/pagamentoService'
import MoedaV2 from '../../comum/MoedaV2'
import { Actions as AvisosActions } from '../../../ducks/avisos'
import BootstrapDialogTitle from '../../comum/BootstrapDialogTitle'
import BootstrapDialog from '../../comum/BootstrapDialog'

const PagTesouroModal = ({ divida, open, callbackPagamento = () => {}, onClose }) => {
  const dispatch = useDispatch()
  const [urlPagTesouro, setUrlPagTesouro] = useState(null)
  const [valorPagamento, setValorPagamento] = useState(null)
  const [dadosPagamento, setDadosPagamento] = useState(null)
  const [codigoPessoaResponsavelPagamento, setCodigoPessoaResponsavelPagamento] = useState('')
  const sePodeSolicitarPagamentoPagTesouro = divida.cofreCredor.seTesouroNacional === true
  const seDividaPossuiMaisDeUmResponsavel =
    !_.isEmpty(divida?.responsaveis) && divida?.responsaveis?.length > 1
  const opcoesResponsaveis = divida.responsaveis.map((it) => ({
    codigo: it.pessoa.codigo,
    nome: it.pessoa.nome,
  }))

  useEffect(() => {
    if (
      divida?.valoresAtualizados?.valorAtualizadoParaPagamento ===
      divida?.dadosParaPagamento?.valorMinimoAPagar
    ) {
      setValorPagamento(`${divida?.dadosParaPagamento?.valorMinimoAPagar}`)
    }
  }, [divida, open])

  useEffect(() => {
    if (open && !sePodeSolicitarPagamentoPagTesouro) {
      dispatch(
        AvisosActions.erro([
          `Esta dívida não é pagável pelo PagTesouro, pois o cofre credor é ${divida.cofreCredor.nome}, diferente do exigido que é o Tesouro Nacional.`,
        ])
      )
    }
  }, [divida, open, dispatch, sePodeSolicitarPagamentoPagTesouro])

  const fecharModal = (event, reason) => {
    // Se remover o event, a modal será fechada sem verificar o reason
    if (reason && reason === 'backdropClick') {
      return
    }
    if (reason && reason === 'escapeKeyDown') {
      return
    }
    setUrlPagTesouro(null)
    setDadosPagamento(null)
    setValorPagamento(null)
    setCodigoPessoaResponsavelPagamento('')
    onClose(true)
  }

  const processarPagamentoEFecharModal = (event, reason) => {
    // Se remover o event, a modal será fechada sem verificar o reason
    if (reason && reason === 'backdropClick') {
      return
    }
    if (reason && reason === 'escapeKeyDown') {
      return
    }

    if (dadosPagamento == null || dadosPagamento.idPagamento == null) {
      fecharModal(event, reason)
      return
    }

    pagamentoService.processarPagamento(dadosPagamento.idPagamento).then(() => {
      fecharModal(event, reason)
      callbackPagamento()
    })
  }

  const solicitarPagamento = () => {
    if (!valorPagamento) {
      dispatch(AvisosActions.erro(['Valor a pagar deve ser informado.']))
      return
    }

    if (seDividaPossuiMaisDeUmResponsavel && !codigoPessoaResponsavelPagamento) {
      dispatch(AvisosActions.erro(['Responsável pelo pagamento deve ser selecionado.']))
      return
    }

    pagamentoService
      .solicitarPagamentoPagTesouro(divida.id, { codigoPessoaResponsavelPagamento, valorPagamento })
      .then((response) => {
        if (response.data) {
          setDadosPagamento(response.data)
          setUrlPagTesouro(response.data?.proximaUrl ? response.data.proximaUrl : null)
        }
      })
  }

  const handleChange = (event) => {
    setCodigoPessoaResponsavelPagamento(event.target.value)
  }

  return (
    <BootstrapDialog maxWidth="xl" open={open} onClose={fecharModal}>
      <Box sx={{ minWidth: urlPagTesouro ? 900 : 700 }}>
        {!urlPagTesouro && (
          <BootstrapDialogTitle
            color="primary"
            sx={{ fontSize: '1.5rem', paddingLeft: '16px', color: 'primary' }}
            onClose={() => {}}>
            <Typography variant="h5" component="span">
              Pagamento utilizando o PagTesouro
            </Typography>
          </BootstrapDialogTitle>
        )}
        <Box
          sx={{
            position: 'absolute',
            padding: '2px',
            top: '8px',
            right: '8px',
            borderRadius: '0 0 0 16px',
            backgroundColor: 'rgba(255, 255,255, 1)',
          }}>
          <IconButton
            aria-label="delete"
            size="small"
            onClick={() => processarPagamentoEFecharModal()}>
            <DeleteIcon color="primary" />
          </IconButton>
        </Box>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {urlPagTesouro && (
            <iframe
              width="900px"
              height="700px"
              title="PagTesouro"
              className="iframe-epag"
              src={`${urlPagTesouro}`}
            />
          )}

          {!urlPagTesouro && (
            <>
              <Box>
                <Typography variant="body2">
                  R${' '}
                  {formatarNumeroParaMoeda(
                    divida?.valoresAtualizados?.valorAtualizadoParaPagamento,
                    2,
                    2
                  )}{' '}
                  ({moment(divida?.valoresAtualizados?.dataAtualizacao).format('DD/MM/yyyy')})
                </Typography>
              </Box>
              <Divider />
              <Box>
                <Typography variant="body2" color="text.secondary">
                  Quantidade total de parcelas autorizadas
                </Typography>
                <Typography>
                  {divida?.dadosParaPagamento?.quantidadeParcelasAutorizadas !== 1
                    ? divida?.dadosParaPagamento?.quantidadeParcelasAutorizadas
                    : 'Não há autorização de parcelamento'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" color="text.secondary">
                  Quantidade total de parcelas restantes
                </Typography>
                <Typography>
                  {divida?.dadosParaPagamento?.quantidadeParcelasAutorizadas !== 1
                    ? divida?.dadosParaPagamento?.quantidadeParcelasRestantes
                    : '--'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" color="text.secondary">
                  Valor mínimo (parcela) a ser pago
                </Typography>
                <Typography>
                  R$ {formatarNumeroParaMoeda(divida?.dadosParaPagamento?.valorMinimoAPagar, 2, 2)}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" color="text.secondary">
                  Valor máximo (saldo) a ser pago
                </Typography>
                <Typography>
                  R${' '}
                  {formatarNumeroParaMoeda(
                    divida?.valoresAtualizados?.valorAtualizadoParaPagamento,
                    2,
                    2
                  )}
                </Typography>
              </Box>
              {sePodeSolicitarPagamentoPagTesouro && (
                <>
                  <Divider />
                  <Box>
                    <MoedaV2
                      name="valorPagamento"
                      value={valorPagamento}
                      label="Informe o valor a pagar"
                      placeholder="R$ 0,00"
                      onChange={(valor) => setValorPagamento(valor)}
                      maxLength={19}
                    />
                  </Box>
                  {seDividaPossuiMaisDeUmResponsavel && (
                    <Box>
                      <Typography variant="body2" color="text.secondary" id="responsaveis">
                        Selecione o responsável pelo pagamento
                      </Typography>
                      <FormControl fullWidth size="small">
                        <Select
                          id="selectResponsaveis"
                          value={codigoPessoaResponsavelPagamento}
                          onChange={handleChange}>
                          {opcoesResponsaveis.map(({ codigo, nome }, index) => (
                            <MenuItem value={codigo} key={index}>
                              <Box>
                                <Typography variant="body2">{nome}</Typography>
                              </Box>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  )}
                </>
              )}
              <Divider />
            </>
          )}
        </DialogContent>

        {!urlPagTesouro && (
          <DialogActions>
            <Button onClick={fecharModal} color="primary">
              Cancelar
            </Button>
            <Button
              disabled={!sePodeSolicitarPagamentoPagTesouro}
              onClick={() => solicitarPagamento()}
              color="primary"
              variant="contained">
              Confirmar
            </Button>
          </DialogActions>
        )}
      </Box>
    </BootstrapDialog>
  )
}

PagTesouroModal.propTypes = {
  divida: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  callbackPagamento: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default PagTesouroModal

import React from 'react'
import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import PaperOutlined from 'components/estilos/PaperOutlinedNoSpacing'
import PropTypes from 'prop-types'
import formatarNumeroParaMoeda from '../../../../util/util'
import classes from './Tabela.style'

const TabelaDebitosEMultasPorAnoETipoProcesso = ({ titulo, dados }) =>
  dados && (
    <TableContainer component={PaperOutlined} sx={{maxWidth: '100%'}}>
      <Box sx={{ py: 1.2, px: 2, position: 'sticky', left: 0, }}>
        <Typography
          variant="subtitle1"
          component="div"
          color="grey.800"
          style={{ height: 'auto', verticalAlign: 'center', position: 'sticky', left: 0}}>
          {titulo}
        </Typography>
      </Box>
      <Divider sx={{ position: 'sticky', left: 0}}/>
      <Table size="small" sx={{maxWidth: '100%'}}>
        <TableHead >
          <TableRow>
            <TableCell key="linhaVazia" colSpan={2} sx={classes.colunaVazia} />
            {dados.map((row, index) => (
              <TableCell
                key={`tituloAno_${index}`}
                align="center"
                colSpan={3}
                sx={classes.debitoMulta}>
                {row.ano}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell sx={classes.colunaFixa} key="linhaTituloTipoProcesso" colSpan={2}>
              Tipo de Processo
            </TableCell>
            {dados.map((row, index) => (
              <React.Fragment key={`tituloFragment_${index}`}>
                <TableCell sx={classes.tableCellLabel} key={`tituloDebito_${index}`} align="right">
                  Débito
                </TableCell>
                <TableCell sx={classes.tableCellLabel} key={`tituloMulta_${index}`} align="right">
                  Multa
                </TableCell>
                <TableCell
                  key={`tituloTotal_${index}`}
                  align="right"
                  sx={classes.colunaCustomizada}>
                  Total
                </TableCell>
              </React.Fragment>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key="linha1" hover>
            <TableCell component="th" scope="row" sx={classes.colunaFixa} colSpan={2}>
              Tomada de Contas Especial
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[0].tomadaContasEspecial.valorTotalDebitos, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[0].tomadaContasEspecial.valorTotalMultas, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right" sx={classes.colunaCustomizadaSemNegrito}>
              <Typography variant="body2" sx={classes.cellContent}>
              {formatarNumeroParaMoeda(
                dados[0].tomadaContasEspecial.valorTotalDebitosEMultas,
                2,
                2
              )}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
              {formatarNumeroParaMoeda(dados[1].tomadaContasEspecial.valorTotalDebitos, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
              {formatarNumeroParaMoeda(dados[1].tomadaContasEspecial.valorTotalMultas, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right" sx={classes.colunaCustomizadaSemNegrito}>
              <Typography variant="body2" sx={classes.cellContent}>
              {formatarNumeroParaMoeda(
                dados[1].tomadaContasEspecial.valorTotalDebitosEMultas,
                2,
                2
              )}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[2].tomadaContasEspecial.valorTotalDebitos, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[2].tomadaContasEspecial.valorTotalMultas, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right" sx={classes.colunaCustomizadaSemNegrito}>
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(
                  dados[2].tomadaContasEspecial.valorTotalDebitosEMultas,
                  2,
                  2
                )}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow key="linha2" hover>
            <TableCell component="th" scope="row" sx={classes.colunaFixa} colSpan={2}>
              Tomada de Contas
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[0].tomadaContas.valorTotalDebitos, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[0].tomadaContas.valorTotalMultas, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right" sx={classes.colunaCustomizadaSemNegrito}>
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[0].tomadaContas.valorTotalDebitosEMultas, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[1].tomadaContas.valorTotalDebitos, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[1].tomadaContas.valorTotalMultas, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right" sx={classes.colunaCustomizadaSemNegrito}>
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[1].tomadaContas.valorTotalDebitosEMultas, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[2].tomadaContas.valorTotalDebitos, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[2].tomadaContas.valorTotalMultas, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right" sx={classes.colunaCustomizadaSemNegrito}>
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[2].tomadaContas.valorTotalDebitosEMultas, 2, 2)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow key="linha3" hover>
            <TableCell component="th" scope="row" sx={classes.colunaFixa} colSpan={2}>
              Prestação de Contas
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[0].prestacaoContas.valorTotalDebitos, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[0].prestacaoContas.valorTotalMultas, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right" sx={classes.colunaCustomizadaSemNegrito}>
              <Typography variant="body2" sx={classes.cellContent}>
              {formatarNumeroParaMoeda(dados[0].prestacaoContas.valorTotalDebitosEMultas, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[1].prestacaoContas.valorTotalDebitos, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[1].prestacaoContas.valorTotalMultas, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right" sx={classes.colunaCustomizadaSemNegrito}>
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[1].prestacaoContas.valorTotalDebitosEMultas, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[2].prestacaoContas.valorTotalDebitos, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right" sx={classes.debitoMulta}>
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[2].prestacaoContas.valorTotalMultas, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right" sx={classes.colunaCustomizadaSemNegrito}>
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[2].prestacaoContas.valorTotalDebitosEMultas, 2, 2)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow key="linha4" hover>
            <TableCell component="th" scope="row" sx={classes.colunaFixa} colSpan={2}>
              Subtotal - Contas com débitos e/ou multas
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[0].subtotalContas.valorTotalDebitos, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[0].subtotalContas.valorTotalMultas, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right" sx={classes.colunaCustomizadaSemNegrito}>
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[0].subtotalContas.valorTotalDebitosEMultas, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[1].subtotalContas.valorTotalDebitos, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[1].subtotalContas.valorTotalMultas, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right" sx={classes.colunaCustomizadaSemNegrito}>
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[1].subtotalContas.valorTotalDebitosEMultas, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right" sx={classes.debitoMulta}>
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[2].subtotalContas.valorTotalDebitos, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right" sx={classes.debitoMulta}>
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[2].subtotalContas.valorTotalMultas, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right" sx={classes.colunaCustomizadaSemNegrito}>
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[2].subtotalContas.valorTotalDebitosEMultas, 2, 2)}
              </Typography>
            </TableCell>
          </TableRow>

          <TableRow key="linha5" hover>
            <TableCell component="th" scope="row" sx={classes.colunaFixa} colSpan={2}>
              Fiscalização, denúncia, representação e outros
            </TableCell>
            <TableCell align="right" sx={classes.debitoMulta}>
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[0].fiscalizacaoReprDenOutros.valorTotalDebitos, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[0].fiscalizacaoReprDenOutros.valorTotalMultas, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right" sx={classes.colunaCustomizadaSemNegrito}>
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(
                  dados[0].fiscalizacaoReprDenOutros.valorTotalDebitosEMultas,
                  2,
                  2
                )}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[1].fiscalizacaoReprDenOutros.valorTotalDebitos, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[1].fiscalizacaoReprDenOutros.valorTotalMultas, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right" sx={classes.colunaCustomizadaSemNegrito}>
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(
                  dados[1].fiscalizacaoReprDenOutros.valorTotalDebitosEMultas,
                  2,
                  2
                )}
              </Typography>
            </TableCell>
            <TableCell align="right" sx={classes.cellContent}>
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[2].fiscalizacaoReprDenOutros.valorTotalDebitos, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(dados[2].fiscalizacaoReprDenOutros.valorTotalMultas, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right" sx={classes.colunaCustomizadaSemNegrito}>
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(
                  dados[2].fiscalizacaoReprDenOutros.valorTotalDebitosEMultas,
                  2,
                  2
                )}
              </Typography>
            </TableCell>
          </TableRow>
            <TableRow key="linha6" hover>
            <TableCell component="th" scope="row" sx={classes.colunaFixa} colSpan={2}>
              Total
            </TableCell>
            <TableCell align="right" sx={classes.total}>
              {formatarNumeroParaMoeda(dados[0].total.valorTotalDebitos, 2, 2)}
            </TableCell>
            <TableCell align="right" sx={classes.total}>
              {formatarNumeroParaMoeda(dados[0].total.valorTotalMultas, 2, 2)}
            </TableCell>
            <TableCell align="right" sx={classes.colunaCustomizada}>
              {formatarNumeroParaMoeda(dados[0].total.valorTotalDebitosEMultas, 2, 2)}
            </TableCell>
            <TableCell align="right" sx={classes.total}>
              {formatarNumeroParaMoeda(dados[1].total.valorTotalDebitos, 2, 2)}
            </TableCell>
            <TableCell align="right" sx={classes.total}>
              {formatarNumeroParaMoeda(dados[1].total.valorTotalMultas, 2, 2)}
            </TableCell>
            <TableCell align="right" sx={classes.colunaCustomizada}>
              {formatarNumeroParaMoeda(dados[1].total.valorTotalDebitosEMultas, 2, 2)}
            </TableCell>
            <TableCell align="right" sx={classes.total}>
              {formatarNumeroParaMoeda(dados[2].total.valorTotalDebitos, 2, 2)}
            </TableCell>
            <TableCell align="right" sx={classes.total}>
              {formatarNumeroParaMoeda(dados[2].total.valorTotalMultas, 2, 2)}
            </TableCell>
            <TableCell align="right" sx={classes.total}>
              {formatarNumeroParaMoeda(dados[2].total.valorTotalDebitosEMultas, 2, 2)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )

TabelaDebitosEMultasPorAnoETipoProcesso.propTypes = {
  titulo: PropTypes.string.isRequired,
  dados: PropTypes.array,
}

export default TabelaDebitosEMultasPorAnoETipoProcesso

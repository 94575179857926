const filtrarSeqItem = (id) => (i) => i.seqItem === id

const calcularSeqItemParcela = (lista) => {
  let seqItem = 1
  let seqItemExistente = lista.findIndex(filtrarSeqItem(seqItem))
  while (seqItemExistente !== -1) {
    seqItem += 1
    seqItemExistente = lista.findIndex(filtrarSeqItem(seqItem))
  }
  return seqItem
}

export default calcularSeqItemParcela

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { Actions as AuthenticationActions } from '../ducks/authentication'

const SSO = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { tkn: token, redirectTo } = queryString.parse(location?.search)

  useEffect(() => {
    dispatch(AuthenticationActions.loginPorTokenLoginIntegrado(token, redirectTo))
  }, [dispatch, token, redirectTo])

  return null
}

export default SSO

import { Button, Typography } from '@mui/material'
import UpdateIcon from '@mui/icons-material/Update'
import moment from 'moment/moment'
import PropTypes from 'prop-types'

const AtualizarDivida = ({ onClick, dataAtualizacao }) => {
  let data = dataAtualizacao
  if (!dataAtualizacao) data = Date.now()

  return (
    <div>
      <Button
        variant="text"
        color="secondary"
        startIcon={<UpdateIcon />}
        onClick={onClick}
        sx={{ padding: '3px', minWidth: 0 }}>
        <Typography variant="caption" color="secondary">
          Atualizado em {moment(data).format('DD/MM/yyyy HH:mm')}
        </Typography>
      </Button>
    </div>
  )
}

AtualizarDivida.propTypes = {
  onClick: PropTypes.func.isRequired,
  dataAtualizacao: PropTypes.string,
}

export default AtualizarDivida

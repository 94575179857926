import { Box, DialogContent } from '@mui/material'
import PropTypes from 'prop-types'
import BootstrapDialogTitle from '../../../comum/BootstrapDialogTitle'
import DividaDetalhada from '../DividaDetalhada'
import BootstrapDialog from '../../../comum/BootstrapDialog'

const ModalDividaDetalhada = ({ id, open, onClose }) => (
  <BootstrapDialog onClose={onClose} open={open} maxWidth="xl" fullWidth>
    <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
      <Box display="flex" flexDirection="column" justifyContent="flex-start" style={{marginTop: '-90px'}} height="100%" className="boxDividaDetalhada">
        <DividaDetalhada idDivida={id} />
      </Box>
    </BootstrapDialogTitle>
    <DialogContent dividers style={{overflowX: 'auto'}}>
      <DividaDetalhada idDivida={id} />
    </DialogContent>
  </BootstrapDialog>
)

ModalDividaDetalhada.propTypes = {
  id: PropTypes.number,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ModalDividaDetalhada

import { Box } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

const IconHoverArrow = () => (
  <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center' ,ml: '2px', visibility: 'hidden'}}>
     <ArrowForwardIcon color='primary' fontSize='small' className='forwardIconHover' />
  </Box>
)

export default IconHoverArrow

import Cookies from 'js-cookie'
import { TOKEN_KEY, USER_FINGER_PRINT_KEY } from '../util/constantes'

const addTokenJwt = (config) => {
  const token = Cookies.get(TOKEN_KEY)
  const userFingerPrint = Cookies.get(USER_FINGER_PRINT_KEY)

  if (token != null) {
    // eslint-disable-next-line no-param-reassign
    config.headers = { Authorization: `Bearer ${token}`, 'X-UFP': `${userFingerPrint}` }
  }
  return Promise.resolve(config)
}

export default addTokenJwt

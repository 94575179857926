import axios from 'axios'
import {
  onFullfilledHttpInProgressRequest,
  onRejectedHttpInProgressRequest,
  onFullfilledHttpInProgressResponse,
  onRejectedHttpInProgressResponse
} from './httpProgressInterceptor'
import { onRejectedHttpError } from './httpErrorInterceptor'
import addTokenJwt from './tokenJwtInterceptor'

axios.interceptors.response.use(
  (config) => Promise.resolve(config),
  (err) => onRejectedHttpError(err, null)
)
axios.interceptors.request.use(
  (config) => addTokenJwt(config),
  (err) => Promise.reject(err)
)

axios.interceptors.request.use(
  (config) => onFullfilledHttpInProgressRequest(config),
  (err) => onRejectedHttpInProgressRequest(err)
)

axios.interceptors.response.use(
  (config) => onFullfilledHttpInProgressResponse(config),
  (err) => onRejectedHttpInProgressResponse(err)
)

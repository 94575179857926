import * as React from 'react'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import PropTypes from 'prop-types'
import HeaderList from '../../estilos/HeaderList'
import { colunasDividasParaPagamento as colunas } from '../Divida'
import MensagemResultadoVazio from '../../comum/MensagemResultadoVazio'

const DividasParaPagamentoPorProcesso = ({
  dividas,
  seDividasUsuarioLogado,
  seCarregouDividas,
  callbackPagamento,
}) => {
  const { state: propsNavegacao } = useLocation()
  const [tamanhoPagina, setTamanhoPagina] = useState(
    propsNavegacao?.tamanhoPagina ? propsNavegacao.tamanhoPagina : 20
  )
  const [paginaAtual, setPaginaAtual] = useState(
    propsNavegacao?.paginaAtual ? propsNavegacao.paginaAtual : 0
  )

  const totalElementos = dividas.length
  const numeroProcessoFormatado = totalElementos > 0 ? dividas[0]?.numeroProcessoFormatado : ''
  const titulo = seDividasUsuarioLogado
    ? 'Dívidas associadas ao meu CPF'
    : `Dívidas associadas ao processo ${numeroProcessoFormatado}`

  return (
    <Box sx={{ backgroundColor: 'background.paper' }}>
      {seCarregouDividas && (
        <>
          <HeaderList>{titulo}</HeaderList>
          <DataGrid
            rows={dividas}
            disableColumnMenu
            columns={colunas(callbackPagamento, seDividasUsuarioLogado)}
            rowCount={totalElementos}
            autoHeight
            pagination
            paginationMode="server"
            page={paginaAtual}
            pageSize={tamanhoPagina}
            disableSelectionOnClick
            headerHeight={42}
            rowsPerPageOptions={[20, 50, 100]}
            onPageChange={(novaPagina) => setPaginaAtual(novaPagina)}
            onPageSizeChange={(newPageSize) => setTamanhoPagina(newPageSize)}
            componentsProps={{
              pagination: { classes: null },
            }}
            components={{
              NoRowsOverlay: MensagemResultadoVazio,
            }}
            sx={{
              borderRadius: '0 0 4px 4px',
              '& .MuiDataGrid-row:hover': {
                cursor: 'pointer',
                '& .forwardIconHover ': {
                  visibility: 'visible',
                },
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                color: 'grey.500',
                fontWeight: 400,
              },
              '& .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '.MuiDataGrid-columnHeader:focus-within': {
                outline: 'none',
              },
            }}
          />
        </>
      )}
    </Box>
  )
}

DividasParaPagamentoPorProcesso.propTypes = {
  dividas: PropTypes.array,
  seDividasUsuarioLogado: PropTypes.bool,
  seCarregouDividas: PropTypes.bool,
  callbackPagamento: PropTypes.func,
}

export default DividasParaPagamentoPorProcesso

const classes = {
  boxTituloDivida: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: { xs: 'flex-start', sm: 'space-between' },
    flexDirection: { xs: 'column', sm: 'row' },
  },
  boxBotoesSuperiores: {
    display: 'flex',
    justifyContent: { xs: 'flex-start', sm: 'flex-end' },
    flexDirection: { xs: 'column', sm: 'row' },
  },
  gridPagina: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '20px',
  },
  gridDetalhes: {
    display: 'grid',
    gridTemplateColumns: { sm: '1fr', md: '150px 150px 1fr' },
    gap: '16px',
    rowGap: '8px',
    gridTemplateRows: 'auto',
    gridTemplateAreas: `"originador originador originador linkDividas"
    "deliberacao deliberacao dataDeliberacao dataDeliberacao"
    "fundamentoMulta fundamentoMulta fundamentoMulta fundamentoMulta"
    "itemDeliberacao itemDeliberacao itemDeliberacao itemDeliberacao"`,
  },
  gridDadosOrgaos: {
    display: 'grid',
    gridTemplateColumns: { sm: '1fr', md: 'repeat(3, 1fr)' },
    gap: '16px',
    rowGap: '16px',
  },
  cabecalhoPagina: {
    display: 'grid',
    gridTemplateColumns: { md: '1fr', lg: '1fr 2fr' },
    gap: '16px',
  },
  chipFase: {
    height: { xs: 'auto', md: '24px' },
    '& > .MuiChip-label': {
      whiteSpace: { xs: 'normal', md: 'nowrap' },
    },
  },
  textoDestaque: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: 'primary.light',
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
    padding: 0,
  },
  gridComposicaoDivida: {
    display: 'grid',
    gridTemplateColumns: { xs: '1fr', sm: '1fr 2fr' },
    gridGap: '20px 20px',
  },
  textoReticencias: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    minWidth: 0
  }
}
export default classes

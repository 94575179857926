import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import {
  Box,
  Button,
  Chip,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import TuneIcon from '@mui/icons-material/Tune'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import ContentBox from 'components/estilos/ContentBox'
import TituloPagina from 'components/estilos/TituloPagina'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { colunasListagem as colunas } from '../Divida'
import { limparOpcoesFiltro, montarFiltrosSelecionados } from './PesquisaAvancada'
import MensagemResultadoVazio from '../../comum/MensagemResultadoVazio'
import dividaService from '../../../services/dividaService'
import DrawerFiltros from './DrawerFiltros'
import {
  Actions as PesquisaAvancadaActions,
  CODIGOS_FILTROS_DISPONIVEIS_BACKEND,
  ESTADO_INICIAL_FILTROS,
} from '../../../ducks/pesquisaAvancada'
import classes from './PesquisaAvancadaDividas.style'
import ProgressBar from '../../comum/ProgressBar'
import formatarNumeroParaMoeda from '../../../util/util'
import useIsFirstRender from '../../comum/useIsFirstRender'
import { PAGINA_ORIGEM_DETALHAMENTO_DIVIDA } from '../ConstantesDivida'

const PesquisaAvancadaDividas = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isFirstRender = useIsFirstRender()

  const { state: propsNavegacao } = useLocation()
  const filtrosAplicadosDividas = useSelector(
    ({ pesquisaAvancada }) => pesquisaAvancada.filtrosAplicadosDividas
  )
  const [exibirDrawerFiltro, setExibirDrawerFiltro] = useState(false)
  const [, setCarregandoDividas] = useState(true)
  const [dividas, setDividas] = useState([])
  const [totalizadores, setTotalizadores] = useState([])
  const [totalElementos, setTotalElementos] = useState(0)
  const [paginaAtual, setPaginaAtual] = useState(propsNavegacao?.paginaAtual || 0)
  const [tamanhoPagina, setTamanhoPagina] = useState(propsNavegacao?.tamanhoPagina || 20)
  const [
    seRealizarPesquisaAoAlterarFiltrosAplicados,
    setSeRealizarPesquisaAoAlterarFiltrosAplicados,
  ] = useState(true)

  const pesquisarDividas = useCallback(
    (filtrosDados) => {
      setCarregandoDividas(true)
      dividaService
        .recuperarDividasPorFiltro(filtrosDados, paginaAtual, tamanhoPagina)
        .then((response) => {
          if (response.data) {
            setDividas(response.data.elementos)
            setTotalElementos(response.data.totalElementos)
            setTotalizadores(response.data.totalizadores || [])
          }
        })
        .finally(() => {
          setCarregandoDividas(false)
        })
    },
    [paginaAtual, tamanhoPagina]
  )

  useEffect(() => {
    if (propsNavegacao?.limparPesquisa) {
      dispatch(PesquisaAvancadaActions.recuperarFiltrosDisponiveis())
      pesquisarDividas(montarFiltrosSelecionados(ESTADO_INICIAL_FILTROS.filtrosAplicadosDividas))
      setSeRealizarPesquisaAoAlterarFiltrosAplicados(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, propsNavegacao])

  useEffect(() => {
    if (!seRealizarPesquisaAoAlterarFiltrosAplicados) {
      setSeRealizarPesquisaAoAlterarFiltrosAplicados(true)
      return
    }

    if (!(propsNavegacao?.limparPesquisa && isFirstRender)) {
      pesquisarDividas(montarFiltrosSelecionados(filtrosAplicadosDividas))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pesquisarDividas, filtrosAplicadosDividas])

  const handleVoltar = () => {
    navigate(-1)
  }

  const handleMudarPagina = (novaPagina) => {
    setPaginaAtual(novaPagina)
  }

  const handleDetalhar = (divida) => {
    navigate(`/dividas/${divida.id}`, {
      state: {
        paginaAtual,
        tamanhoPagina,
        paginaOrigem: PAGINA_ORIGEM_DETALHAMENTO_DIVIDA.PESQUISA_AVANCADA_DIVIDAS,
      },
    })
  }

  const toggleDrawerFiltro = () => {
    setExibirDrawerFiltro((prevExibirDrawer) => !prevExibirDrawer)
  }

  const handleLimparFiltroExcluindoFiltro = (filtroRemovido) => {
    const copiaFiltrosAplicados = filtrosAplicadosDividas.filter(
      (filtro) => filtro.codigo !== filtroRemovido.codigo
    )
    dispatch(
      PesquisaAvancadaActions.aplicarFiltrosDividasSelecionados({ data: copiaFiltrosAplicados })
    )
  }

  const handleLimparFiltroLimpandoOpcoesSelecionadas = (filtroRemovido) => {
    const copiaFiltrosAplicados = filtrosAplicadosDividas.map((filtro) =>
      filtro.codigo === filtroRemovido.codigo
        ? { ...filtro, opcoes: limparOpcoesFiltro(filtro.opcoes) }
        : filtro
    )
    dispatch(
      PesquisaAvancadaActions.aplicarFiltrosDividasSelecionados({ data: copiaFiltrosAplicados })
    )
  }

  const handleLimparFiltro = (filtroRemovido) => {
    if (
      CODIGOS_FILTROS_DISPONIVEIS_BACKEND.some(
        (codigoFiltroDisponivelBackend) => filtroRemovido.codigo === codigoFiltroDisponivelBackend
      )
    ) {
      handleLimparFiltroLimpandoOpcoesSelecionadas(filtroRemovido)
    } else {
      handleLimparFiltroExcluindoFiltro(filtroRemovido)
    }
  }

  const renderChipsFiltrosAplicados = () => {
    const chipsFiltrosAplicados = filtrosAplicadosDividas?.reduce((resultado, filtro) => {
      const opcoesSelecionadas = filtro.opcoes?.filter((opcao) => opcao.selecionada)
      if (opcoesSelecionadas?.length > 0) {
        const valores = opcoesSelecionadas
          .map((opcaoSelecionada) => opcaoSelecionada.descricao)
          .join(', ')
        const labelChip = `${filtro.nome} = ${valores}`
        return resultado.concat(
          <Chip
            size="small"
            key={filtro.codigo}
            label={labelChip}
            sx={classes.chipPesquisaAvancada}
            onDelete={() => handleLimparFiltro(filtro)}
          />
        )
      }
      return resultado
    }, [])

    return (
      <Box display="flex" sx={{ gap: '4px', flexWrap: 'wrap' }}>
        {chipsFiltrosAplicados}
      </Box>
    )
  }

  const handleExportarDividasClick = () => {
    dividaService.exportarPesquisaPorFiltroParaCSV(
      montarFiltrosSelecionados(filtrosAplicadosDividas)
    )
  }

  const celulaDeliberacao = {
    19: 2.5,
    16: 1.9,
    17: 1.9,
    4: 2.5,
  }

  const calcularCelulaDeliberacao = (item) => celulaDeliberacao[`${item.ordem}`] || 1

  const recuperarTotalizadorValorAtualizadoComPgto = () => {
    if (totalizadores.length > 0) {
      const totalizador = totalizadores.filter(
        (it) => it.nome === 'AGG_TERMS_TIPO_DELIBERACAO_VALOR_ATUALIZADO_COM_PGTO'
      )
      return totalizador[0]
    }
    return null
  }

  return (
    <ContentBox>
      <Box>
        <Box sx={classes.boxTituloPesquisa}>
          <TituloPagina onClick={handleVoltar}>Lista de dívidas</TituloPagina>
          <Box display="flex" gap={1} alignContent="flex-start" sx={classes.boxBotoesPesquisa}>
            <Button
              variant="outlined"
              onClick={handleExportarDividasClick}
              size="small"
              startIcon={<FileDownloadIcon />}>
              CSV
            </Button>
            <Button
              startIcon={<TuneIcon />}
              variant="outlined"
              size="small"
              onClick={() => toggleDrawerFiltro()}>
              Filtrar e organizar
            </Button>
          </Box>
        </Box>
        <Box pb={1}>{renderChipsFiltrosAplicados()}</Box>
        <Accordion disableGutters>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box width={1} sx={classes.linhaTotalizadores}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '4px',
                  justifyContent: 'flex-start',
                  alignSelf: 'center',
                }}>
                <Typography variant="h6" color="textSecondary">
                  Total Encontrado
                </Typography>
                <Typography variant="h6" color="primary.light">
                  {recuperarTotalizadorValorAtualizadoComPgto()?.totalGeral?.quantidade}
                </Typography>
              </Box>
              <KeyboardArrowRightIcon color="disabled" />
              {recuperarTotalizadorValorAtualizadoComPgto()?.totais?.map((total, i) => (
                <Box
                  sx={classes.boxDetalhesTotalizadores}
                  xs={calcularCelulaDeliberacao(total)}
                  key={i}>
                  <Typography
                    variant="body2"
                    textAlign="right"
                    color="textSecondary"
                    paddingRight="4px">
                    {total.descricao}
                  </Typography>
                  {total.quantidade === 0 ? '——' : total.quantidade}
                </Box>
              ))}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {totalizadores.map((umTotalizador) => (
              <Box pr="24px" sx={classes.linhaTotalizadoresDetalhes} key={umTotalizador.nome}>
                <Box>
                  <Typography variant="caption" color="textSecondary">
                    {umTotalizador.nome === recuperarTotalizadorValorAtualizadoComPgto().nome
                      ? 'Saldo devedor atualizado '
                      : _.capitalize(`${umTotalizador.totalGeral.descricao} `)}
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    {`R$ ${formatarNumeroParaMoeda(umTotalizador?.totalGeral?.valor, 2, 2)}`}
                  </Typography>
                </Box>
                <KeyboardArrowRightIcon color="disabled" />
                {umTotalizador?.totais?.map((total, i) => (
                  <Box xs={calcularCelulaDeliberacao(total)} key={i}>
                    <Typography variant="body1" textAlign="right" color="textPrimary">
                      {total.valor === 0.0
                        ? '——'
                        : `R$ ${formatarNumeroParaMoeda(total.valor, 2, 2)}`}
                    </Typography>
                  </Box>
                ))}
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>

        <Box minHeight={500} sx={{ marginTop: '10px', backgroundColor: 'background.paper' }}>
          <DataGrid
            rows={dividas}
            disableColumnMenu
            columns={colunas.map((it) => ({ ...it, sortable: false }))}
            rowCount={totalElementos}
            autoHeight
            pagination
            paginationMode="server"
            page={paginaAtual}
            pageSize={tamanhoPagina}
            onRowClick={handleDetalhar}
            headerHeight={42}
            rowsPerPageOptions={[20, 50, 100]}
            onPageChange={(novaPagina) => handleMudarPagina(novaPagina)}
            onPageSizeChange={(newPageSize) => setTamanhoPagina(newPageSize)}
            components={{
              NoRowsOverlay: MensagemResultadoVazio,
            }}
            componentsProps={{
              pagination: { classes: null },
            }}
            sx={{
              borderRadius: '0 0 4px 4px',
              '& .MuiDataGrid-row:hover': {
                cursor: 'pointer',
                '& .forwardIconHover ': {
                  visibility: 'visible',
                },
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                color: 'grey.500',
                fontWeight: 400,
              },
              '& .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '.MuiDataGrid-columnHeader:focus-within': {
                outline: 'none',
              },
            }}
          />
        </Box>
      </Box>
      <DrawerFiltros open={exibirDrawerFiltro} toggleDrawerFiltro={toggleDrawerFiltro} />
      <ProgressBar />
    </ContentBox>
  )
}

export default PesquisaAvancadaDividas

const classes = {
  headerTotalizadorEncerrada: {
    backgroundColor: 'background.paper',
    borderRadius: '4px 4px 0 0',
    borderBottom: '1px solid #E0E0E0',
    padding: '12px',
  },
  tituloFase: {
    color:'secondary.main',
    fontWeight: 600,
    padding: '4px'
  },
  totalizadorFase: {
    padding: '8px 2px',
    marginRight: '12px',
    color: 'primary.light'
  },
  linhaTotalizador: {
    p: '8px',
    gridTemplateColumns: {xs: '1fr', lg:'1fr 32px 3fr'},
    backgroundColor: 'background.paper',
    borderBottom: '1px solid #E0E0E0',
  },
  fase: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'action.hover',
      cursor: 'pointer',
      transition: 'all 0.2s ease 0s',
      '& > h6 , p, h6': {
        color: 'primary.main',
      },
    },
  },
  faseSolo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'action.hover',
      cursor: 'pointer',
      transition: 'all 0.2s ease 0s',
      '& > h6 , p, h6': {
        color: 'primary.dark',
      },
    },
  },
  setaSubFases: {
    visibility: { xs: 'hidden', lg: 'visible' },
    display: { xs: 'none', lg: 'inline-block' },
  },
  desdobramentos:{
    display: 'grid',
    gridTemplateColumns: {xs: '1fr', md:'1fr 1fr 1fr'},
  },
  desdobramento: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'action.hover',
      cursor: 'pointer',
      transition: 'all 0.2s ease 0s',
      '& > h6 , p, h6': {
        color: 'primary.main',
      },
    },
  },
  desdobramentoTitulo: {
    color:'secondary.light',
    padding: '0 4px',
    lineHeight: 1.15,
  },
  desdobramentoTotalizador: {
    color: 'primary.light',
    padding: '8px 0',
    marginRight: '12px',
    display: 'flex',
    alignItems: 'center'
  },
  boxTotalDividas: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  cardTotalDividas: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    justifyContent: 'center',
    minWidth: 135,
  },
  cardTotalAlertas: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    justifyContent: 'center',
    minWidth: 135,
    padding: '0 8px',
    transition: 'all 0.3s',
  },
  paperQuantidadeDividas: {
    backgroundColor: '#ffffff',
    color: 'primary.main',
    p: 1,
  },
  ajusteMobile: {
    display:'flex',
    alignItems:{xs:'flex-start', sm:'flex-end'},
    justifyContent: {xs:'flex-start', sm:'space-between'},
    flexDirection: {
      xs: 'column',
      sm: 'row'
    }
  }
}

export default classes

import React from 'react'
import PropTypes from 'prop-types'
import { Button, DialogActions, DialogContent, Typography } from '@mui/material'
import BootstrapDialog from "../../comum/BootstrapDialog";
import BootstrapDialogTitle from "../../comum/BootstrapDialogTitle";


const DialogoConfirmacaoColarParcelas = (props) => {
  const {
    onSubstituir = () => {},
    onIncluir = () => {},
    onCancelar = () => {},
    exibirSubstiuir = true,
    open = false,
  } = props

  return (
    <BootstrapDialog onClose={onCancelar} open={open} maxWidth="sm">
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onCancelar} style={{ width: 380 }}>
        <Typography variant="h5" component="span">
          Parcelas detectadas
        </Typography>
      </BootstrapDialogTitle>
      <DialogContent dividers>Existem parcelas identificadas para serem usadas na calculadora. Qual ação abaixo deseja realizar?</DialogContent>
      <DialogActions>
          <Button
            name="cancelar"
            id="cancelar"
            data-testid="btn-dialogo-cancelar"
            onClick={onCancelar}
            variant="text">
            Cancelar
          </Button>
        {exibirSubstiuir && <Button
          name="nao"
          id="nao"
          data-testid="btn-dialogo-nao"
          onClick={onSubstituir}
          variant="text">
          Substituir parcelas
        </Button>}
        <Button
          name="incluir"
          id="incluir"
          data-testid="btn-dialogo-sim"
          onClick={onIncluir}
          color="primary"
          variant="contained">
          Incluir parcelas
        </Button>
      </DialogActions>
    </BootstrapDialog>
  )
}
DialogoConfirmacaoColarParcelas.propTypes = {
  onSubstituir: PropTypes.func,
  onIncluir: PropTypes.func,
  onCancelar: PropTypes.func,
  exibirSubstiuir: PropTypes.bool,
  open: PropTypes.bool,
}
export default DialogoConfirmacaoColarParcelas

import React from 'react'
import useRelogio from './Relogio'
import { REFRESH_TOKEN_KEY } from '../../util/constantes'
import { reduxStore } from '../../store/reduxStore'
import { Actions as sigaActions } from '../../ducks/authentication'

const MantemSessao = () => {
  const TEMPO_RENOVACAO_SESSAO = 30 * 60 // 30 minutos
  const TEMPO_SESSAO = 60 * 60 * 4 // 4 horas
  const { contador, tempoExecucao, restart, stop } = useRelogio()

  const refreshToken = () => {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY)
    if (refreshToken !== undefined && refreshToken !== null) {
      reduxStore.dispatch(sigaActions.authenticateSigaWithRefreshToken())
    }
  }

  if (tempoExecucao >= TEMPO_SESSAO) {
    stop()
  }

  if (contador > TEMPO_RENOVACAO_SESSAO) {
    refreshToken()
    restart()
  }

  return <div />
}

export default MantemSessao

import React, { useState } from 'react'
import { Box, Button, TextField, Typography, IconButton, CircularProgress } from '@mui/material'
import { NavLink } from 'react-router-dom'

import CloseIcon from '@mui/icons-material/Close'
import PaperOutlined from 'components/estilos/PaperOutlined'
import PropTypes from 'prop-types'
import { startCase, toLower } from 'lodash'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import service from '../../../services/comentarioDividaService'
import { Actions as AvisosActions } from '../../../ducks/avisos'
import DialogoConfirmacao from '../../comum/DialogoConfirmacao'
import classes from './ComentariosStyles'

const Comentarios = ({ lista = [], emLista = false, codigoDivida, setComentarios = () => {} }) => {
  const [confirmCodigoComentatio, setConfirmCodigoComentatio] = useState(undefined)
  const [texto, setTexto] = useState('')
  const [processandoIncluir, setProcessandoIncluir] = useState(false)
  const [processandoExcluir, setProcessandoExcluir] = useState(false)
  const dispatch = useDispatch()
  const [listaComentarios, setListaComentarios] = useState(lista || [])

  const processando = processandoIncluir || processandoExcluir

  const handleIncluirComentario = async () => {
    if (texto && texto.trim().length > 0) {
      setProcessandoIncluir(true)
      try {
        const resp = await service.incluirComentario(codigoDivida, texto)
        const comentarios = resp.data || []
        setTexto('')
        setListaComentarios(comentarios)
        setComentarios(comentarios)
        dispatch(AvisosActions.sucesso(['Comentário incluído com sucesso!']))
      } finally {
        setProcessandoIncluir(false)
      }
    }
  }
  const handleExcluirComentario = async () => {
    setProcessandoExcluir(true)
    try {
      const resp = await service.excluirComentario(confirmCodigoComentatio)
      const comentarios = resp.data || []
      setConfirmCodigoComentatio(undefined)
      setListaComentarios(comentarios)
      setComentarios(comentarios)
      dispatch(AvisosActions.sucesso(['Comentário excluído com sucesso!']))
    } finally {
      setProcessandoExcluir(false)
    }
  }

  return (
    <Box sx={classes.conteudoDrawerComentario}>
      <Box display="flex" flexDirection="column" sx={classes.colunaCardsComentarios}>
        {listaComentarios.map(
          (
            {
              codigo,
              texto,
              nomeUsuarioCadastrador,
              dataHoraCadastramento,
              usuarioComPermissaoExcluir = false,
            },
            i
          ) => (
            <PaperOutlined key={i}>
              <Box display="flex" mb={0.5} alignItems="center">
                <Box flexGrow={1}>
                  <Typography noWrap color="primary" sx={{ fontSize: '0.9rem', maxWidth: '180px' }}>
                    {startCase(toLower(nomeUsuarioCadastrador))}
                  </Typography>
                </Box>
                <Box>
                  <Typography noWrap color="textSecondary" sx={{ fontSize: '0.8rem' }}>
                    {moment(dataHoraCadastramento).format('DD/MM/yyyy kk:mm')}
                  </Typography>
                </Box>
                <Box>
                  {usuarioComPermissaoExcluir && (
                    <IconButton
                      aria-label="delete"
                      size="small"
                      disabled={processando}
                      onClick={() => {
                        setConfirmCodigoComentatio(codigo)
                      }}>
                      {processandoExcluir && confirmCodigoComentatio === codigo ? (
                        <CircularProgress size={14} />
                      ) : (
                        <CloseIcon sx={{ fontSize: '14px' }} />
                      )}
                    </IconButton>
                  )}
                </Box>
              </Box>
              {texto.split('\n').map((linha, key) => (
                <Typography variant="body1" key={key}>
                  {linha}
                </Typography>
              ))}
            </PaperOutlined>
          )
        )}
      </Box>
      <DialogoConfirmacao
        titulo="Confirmar exclusão"
        conteudo="Deseja excluir o comentário?"
        open={confirmCodigoComentatio !== undefined}
        onClose={() => {
          setConfirmCodigoComentatio(undefined)
        }}
        onConfirmar={handleExcluirComentario}
        labelBotaoNao="Não"
      />
      <TextField
        placeholder="Escreva seu comentário..."
        multiline
        value={texto}
        rows={3}
        inputProps={{ maxLength: 4000 }}
        onChange={({ target = { value: '' } }) => setTexto(target.value)}
      />
      <Box display="flex" justifyContent="space-between">
        {emLista ? (
          <Button
            component={NavLink}
            to={`/dividas/${codigoDivida}`}
            variant="text"
            disabled={processando}>
            Visualizar dívida
          </Button>
        ) : (
          <Box />
        )}
        <Button variant="contained" disabled={processando} onClick={handleIncluirComentario}>
          Incluir comentário {processandoIncluir && <CircularProgress size={25} />}
        </Button>
      </Box>
    </Box>
  )
}

Comentarios.propTypes = {
  lista: PropTypes.array,
  emLista: PropTypes.bool,
  codigoDivida: PropTypes.number,
  setComentarios: PropTypes.func,
}

export default Comentarios

import { Chip, TableCell, TableRow } from '@mui/material'
import moment from 'moment'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import PropTypes from 'prop-types'
import formatarNumeroParaMoeda from '../../../../util/util'

const Composicao = ({ composicao }) => {
  const isComposicaoDebito = composicao.indicativoDebitoCredito === 'D'
  return (
    <TableRow sx={{ 'td,th': { border: 0 } }}>
      <TableCell sx={{ padding: '0px 15px' }}>
        {moment(composicao.dataFato).format('DD/MM/yyyy')}
      </TableCell>
      <TableCell align="right" sx={{ padding: '0px 15px' }}>
        {composicao.simboloMoeda} {formatarNumeroParaMoeda(composicao.valorOriginal, 2, 2)}
      </TableCell>
      <TableCell align="center" sx={{ padding: '0px 15px' }}>
        <Chip
          size="small"
          sx={{
            fontSize: 11,
            height: 20,
            borderRadius: 1,
            margin: '1px 0',
            color: isComposicaoDebito ? '#D25455' : '#2ea572',
            bgcolor: isComposicaoDebito ? 'error.light' : 'success.light',
          }}
          color={isComposicaoDebito ? 'error' : 'success'}
          icon={isComposicaoDebito ? <RemoveIcon style={{fontSize: 11, marginRight: -6}} /> : <AddIcon style={{fontSize: 10, marginRight: -5}}/>}
          label={isComposicaoDebito ? 'débito' : 'crédito'}
        />
      </TableCell>
    </TableRow>
  )
}

Composicao.propTypes = {
  composicao: PropTypes.object,
}

export default Composicao

import React from 'react'
import { DialogContent, CircularProgress, Dialog } from '@mui/material'
import { useSelector } from 'react-redux'

const ProgressBar = () => {
  const exibeProgress = useSelector(
    ({ operationInProgress: { operationInProgress } }) => operationInProgress
  )
  return (
    <Dialog
      open={exibeProgress}
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: 'transparent',
          boxShadow: 0,
        },
      }}>
      <DialogContent>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  )
}

export default ProgressBar

import PropTypes from 'prop-types'
import { Box, Button, DialogContent, Tooltip, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import _ from 'lodash'
import BootstrapDialog from '../../../comum/BootstrapDialog'
import BootstrapDialogTitle from '../../../comum/BootstrapDialogTitle'
import formatarNumeroParaMoeda from '../../../../util/util'
import classes from './ValoresDetalhadosModal.style'
import dividaService from '../../../../services/dividaService'
import ProgressBar from '../../../comum/ProgressBar'
import IconeInformacao from '../../../../img/icone_informacao.svg'

const ValoresDetalhadosModal = ({
  divida,
  valorAtualizadoAExibir,
  dataParaGeracaoDemonstrativo,
  open,
  onClose,
}) => {
  const gerarDemonstrativoDebito = (idDivida, dataAtualizacaoDebito) => {
    dividaService.downloadDocumentoDemonstrativo(idDivida, dataAtualizacaoDebito)
  }

  const handleDownloadDemonstrativoDebitoClick = () => {
    if (_.isNil(dataParaGeracaoDemonstrativo))
      dividaService.downloadDocumentoDemonstrativo(divida.id)
    else gerarDemonstrativoDebito(divida.id, dataParaGeracaoDemonstrativo)
  }

  return (
    <>
      <BootstrapDialog onClose={onClose} open={open} maxWidth="sm" fullWidth>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
          <Box display="flex" flexDirection="column" justifyContent="flex-start" height="100%">
            <Typography variant="body2">
              Saldo devedor atualizado (
              {moment(divida.valoresAtualizados.dataHoraAtualizacaoValores).format('DD/MM/yyyy')})
            </Typography>
            <Box display="flex" sx={{ gap: '12px' }}>
              <Typography variant="h4">
                R$ {formatarNumeroParaMoeda(valorAtualizadoAExibir, 2, 2)}
              </Typography>
              <Button
                onClick={() => handleDownloadDemonstrativoDebitoClick()}
                sx={classes.textoDestaque}>
                <Typography variant="body2">Demonstrativo de débito</Typography>
              </Button>
            </Box>
          </Box>
        </BootstrapDialogTitle>
        <DialogContent dividers sx={{ gap: '16px', display: 'flex', flexDirection: 'column' }}>
          <Box justifyContent="flex-start" flexDirection="column">
            <Typography variant="body2" color="textSecondary">
              Valor inicial atualizado na data da deliberação (
              {moment(divida.valoresAtualizados.dataApreciacao).format('DD/MM/yyyy')})
            </Typography>
            <Typography variant="h6">
              R${' '}
              {formatarNumeroParaMoeda(
                divida.valoresAtualizados.valorAtualizadoDataApreciacao,
                2,
                2
              )}
            </Typography>
          </Box>
          {divida.valoresAtualizados.dataCalculoSaldoUltimoPagamento &&
            !moment(
              divida.valoresAtualizados.dataCalculoSaldoUltimoPagamento,
              'DD/MM/yyyy'
            ).isValid() && (
              <Box justifyContent="flex-start" flexDirection="column">
                <Typography variant="body2" color="textSecondary">
                  Valor atualizado na data do último pagamento (
                  {moment(divida.valoresAtualizados.dataCalculoSaldoUltimoPag).format('DD/MM/yyyy')}
                  )
                </Typography>
                <Box display="flex" sx={{ gap: '12px' }}>
                  <Typography variant="h6">
                    R${' '}
                    {formatarNumeroParaMoeda(
                      divida.valoresAtualizados.valorAtualizadoDataUltimoPag,
                      2,
                      2
                    )}
                  </Typography>
                  <Button
                    sx={classes.textoDestaque}
                    onClick={() =>
                      gerarDemonstrativoDebito(
                        divida.id,
                        moment(divida.valoresAtualizados.dataCalculoSaldoUltimoPag).format(
                          'yyyy-MM-DD'
                        )
                      )
                    }
                    rel="noopener noreferrer">
                    <Typography variant="body2">Demonstrativo de débito</Typography>
                  </Button>
                </Box>
              </Box>
            )}

          {divida.valoresAtualizados.dataEnvioCbex &&
            !moment(divida.valoresAtualizados.dataEnvioCbex, 'DD/MM/yyyy').isValid() && (
              <Box justifyContent="flex-start" flexDirection="column">
                <Typography variant="body2" color="textSecondary">
                  Valor atualizado na data do envio CBEX (
                  {moment(divida.valoresAtualizados.dataEnvioCbex).format('DD/MM/yyyy')})
                </Typography>
                <Typography variant="h6">
                  R${' '}
                  {formatarNumeroParaMoeda(
                    divida.valoresAtualizados.valorAtualizadoDataEnvioCbex,
                    2,
                    2
                  )}
                </Typography>
              </Box>
            )}

          <Box justifyContent="flex-start" flexDirection="column">
            <Typography variant="body2" color="textSecondary">
              Valores nominais recolhidos (
              {moment(divida.valoresAtualizados.dataHoraAtualizacaoValores).format('DD/MM/yyyy')} )
            </Typography>
            <Typography variant="h6">
              R$ {formatarNumeroParaMoeda(divida.valoresAtualizados.valorRecolhidoNominal, 2, 2)}
            </Typography>
          </Box>
          <Box justifyContent="flex-start" flexDirection="column">
            <Box display="flex" gap="8px">
              <Typography variant="body2" color="textSecondary">
                Valores recolhidos atualizados (
                {moment(divida.valoresAtualizados.dataHoraAtualizacaoValores).format('DD/MM/yyyy')}{' '}
                )
              </Typography>
              <Tooltip
                placement="right"
                title="Valor é definido pela diferença (A - B) entre o valor atualizado da dívida desconsiderando os pagamentos (A) e o saldo devedor atualizado considerando os pagamentos registrados (B)."
                sx={{ color: 'grey.500' }}>
                <img
                  src={IconeInformacao}
                  style={{ width: '11px', height: 'auto' }}
                  alt="Ícone de informação"
                />
              </Tooltip>
            </Box>
            <Typography variant="h6">
              R$ {formatarNumeroParaMoeda(divida.valoresAtualizados.valorRecolhidoAtualizado, 2, 2)}
            </Typography>
          </Box>
        </DialogContent>
      </BootstrapDialog>
      <ProgressBar />
    </>
  )
}

ValoresDetalhadosModal.propTypes = {
  divida: PropTypes.object,
  valorAtualizadoAExibir: PropTypes.number,
  dataParaGeracaoDemonstrativo: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ValoresDetalhadosModal

const classes = {
  colunaCardsComentarios: {
    backgroundColor: 'grey.200',
    borderTop: '2px solid',
    borderBottom: '2px solid',
    borderColor:'grey.200',
    p: 1,
    gap: 1,
    overflowY: 'auto',
    overflowX: 'hidden',

    '::-webkit-scrollbar': {
      width: '6px'
    },
    '::-webkit-scrollbar-track': {
      background: 'rgba(0,0,0,0)'
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888'
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555'
    },
  },
  conteudoDrawerComentario: {
    display: 'grid',
    gridTemplateRows: '1fr auto auto',
    gap: 1,
    flexGrow: 1,
    height: 0
  }
}

export default classes

import {
  Box,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useState } from 'react'
import PaperOutlined from '../../../estilos/PaperOutlined'
import ComposicaoModal from './ComposicaoModal'
import Composicao from './Composicao'
import NenhumResultadoEncontrado from '../../../comum/NenhumResultadoEncontrado'

const Composicoes = ({ composicoes }) => {
  const [exibirModalComposicoes, setExibirModalComposicoes] = useState(false)

  return (
    <PaperOutlined sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h5" gutterBottom>
        Composição da dívida
      </Typography>
      <Divider />
      <Box mt={1} sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <Box flexGrow={1}>
          <Table size="small" sx={{ tablelayout: 'fixed' }}>
            <TableHead sx={{ th: { border: 0 } }}>
              <TableRow>
                <TableCell sx={{padding: '0px 15px', width: '120px' }}>
                  <Typography variant="body1" color="textSecondary" gutterBottom>
                    Data evento
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{padding: '0px 15px', width: '150px' }}>
                  <Typography variant="body1" color="textSecondary" gutterBottom>
                    Valor
                  </Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{padding: '0px 15px', width: 'auto' }}>
                  <Typography variant="body1" color="textSecondary" gutterBottom>
                    Evento
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {composicoes.length === 0 && <NenhumResultadoEncontrado numeroColSpan={3} />}
              {composicoes.slice(0, 5).map((composicao, i) => (
                <Composicao key={i} composicao={composicao} />
              ))}
            </TableBody>
          </Table>
        </Box>
        {composicoes.length > 5 ? (
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button variant="text" onClick={() => setExibirModalComposicoes(true)}>
              Exibir todos os {composicoes.length} itens da dívida
            </Button>
          </Box>
        ) : null}
      </Box>
      {exibirModalComposicoes && (
        <ComposicaoModal
          composicoes={composicoes}
          open={exibirModalComposicoes}
          onClose={() => setExibirModalComposicoes(false)}
        />
      )}
    </PaperOutlined>
  )
}

Composicoes.propTypes = {
  composicoes: PropTypes.array,
}

export default Composicoes

import PropTypes from 'prop-types'
import { DialogContent, Table, TableBody, TablePagination, Typography } from '@mui/material'
import { useState } from 'react'
import { paginar } from '../../../../util/util'
import Pagamento from './Pagamento'
import NenhumResultadoEncontrado from '../../../comum/NenhumResultadoEncontrado'
import BootstrapDialog from '../../../comum/BootstrapDialog'
import BootstrapDialogTitle from '../../../comum/BootstrapDialogTitle'
import PagamentoHeader from './PagamentoHeader'

const RegistroPagamentoModal = ({ pagamentos, open, onClose }) => {
  const [pagina, setPagina] = useState(0)
  const [registrosPorPagina, setRegistrosPorPagina] = useState(20)

  return (
    <BootstrapDialog onClose={onClose} open={open} maxWidth="md" fullWidth>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        <Typography variant="h5" component="spam">Registro de pagamento</Typography>
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Table size="small">
          <PagamentoHeader />
          <TableBody>
            {pagamentos.length === 0 && <NenhumResultadoEncontrado />}
            {paginar(pagamentos, pagina, registrosPorPagina).map((pagamento, index) => (
              <Pagamento key={index} pagamento={pagamento} />
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={pagamentos.length}
          page={pagina}
          onPageChange={(event, novaPagina) => setPagina(novaPagina)}
          rowsPerPage={registrosPorPagina}
          onRowsPerPageChange={(e) => setRegistrosPorPagina(e.target.value)}
          rowsPerPageOptions={[]}
        />
      </DialogContent>
    </BootstrapDialog>
  )
}

RegistroPagamentoModal.propTypes = {
  pagamentos: PropTypes.array,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default RegistroPagamentoModal

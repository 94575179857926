import { useEffect, useState } from 'react'
import { Box, Collapse, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import PaperOutlined from 'components/estilos/PaperOutlined'
import ContentBox from 'components/estilos/ContentBox'
import HeaderList from 'components/estilos/HeaderList'
import PropTypes from 'prop-types'
import dividaService from '../../services/dividaService'
import { recuperarTotalizador } from './Divida'
import classes from './PainelDividasPorFase.style'
import DividasPorFaseSkeleton from './skeleton/DividasPorFaseSkeleton'
import TabsNavegacao from './TabsNavegacao'
import { ABA_PAINEL_INICIAL } from './ConstantesDivida'

const PainelDividasPorFase = ({ abaAssociada, agrupadorEmAndamento, agrupadorEncerrado }) => {
  const navigate = useNavigate()
  const [agrupamento, setAgrupamento] = useState({})
  const { operationInProgress } = useSelector((state) => state.operationInProgress)
  const [expandirEncerradas, setExpandirEncerradas] = useState(false)
  const totalizadorAgrupadorEmAndamento = recuperarTotalizador(
    agrupamento?.totalizadores,
    agrupadorEmAndamento
  )
  const totalizadorAgrupadorEncerrado = recuperarTotalizador(
    agrupamento?.totalizadores,
    agrupadorEncerrado
  )

  const recuperarFases = async () => {
    const { data } = await dividaService.recuperarTotalizadores()
    setAgrupamento(data)
  }

  const handleTotalizadorFaseClick = (nivel) => {
    const novoNivel = {
      codigo: nivel.codigo,
      tipo: nivel.tipo,
    }
    navigate('/dividas', { state: { nivelListagem: novoNivel } })
  }

  useEffect(() => {
    recuperarFases()
  }, [])

  const renderSubniveis = (totalizador) => {
    const totalizadorPossuiSubniveis = !_.isEmpty(totalizador.subNiveis)
    const cssLinhaTotalizador = totalizadorPossuiSubniveis
      ? classes.linhaTotalizador
      : { ...classes.linhaTotalizador, ...classes.boxHover }

    return (
      <Box key={`totalizador-${totalizador.nivel.codigo}`} display="grid" sx={cssLinhaTotalizador}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          onClick={() => handleTotalizadorFaseClick(totalizador.nivel)}
          sx={totalizadorPossuiSubniveis ? classes.fase : classes.faseSolo}>
          <Typography variant="subtitle2" sx={classes.tituloFase}>
            {totalizador.nivel.descricao}
          </Typography>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="body2" sx={classes.totalizadorFase}>
              {totalizador.nivel.quantidade}
            </Typography>
          </Box>
        </Box>
        {totalizador.subNiveis.length > 1 && (
          <Box display="flex" alignItems="center" mr={1}>
            <KeyboardArrowRightIcon color="disabled" sx={classes.setaSubFases} />
          </Box>
        )}
        <Box sx={classes.desdobramentos}>
          {totalizador.subNiveis.map((subNivel) => (
            <Box
              key={`subnivel-${subNivel.nivel.codigo}`}
              onClick={() => handleTotalizadorFaseClick(subNivel.nivel)}
              sx={classes.desdobramento}>
              <Typography variant="body1" sx={classes.desdobramentoTitulo}>
                {subNivel.nivel.descricao}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Typography variant="body1" sx={classes.desdobramentoTotalizador}>
                  {subNivel.nivel.quantidade}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    )
  }

  return operationInProgress ? (
    <DividasPorFaseSkeleton />
  ) : (
    <ContentBox>
      <Box sx={classes.ajusteMobile} mb={2}>
        <Box>
          <Typography variant="h2" component="h1" color="primary">
            {abaAssociada === ABA_PAINEL_INICIAL.DEBITOS_MULTAS && 'Fases - Débitos e Multas'}
            {abaAssociada === ABA_PAINEL_INICIAL.CITACOES && 'Fases - Citações'}
          </Typography>
          <Box display="flex" gap="16px" alignItems="center">
            <Box display="flex" gap="4px" alignItems="center">
              <Typography variant="body1" component="h2" color="textSecondary">
                Total de dívidas
              </Typography>
              <Typography variant="body1" sx={{ color: 'primary.light' }}>
                {abaAssociada === ABA_PAINEL_INICIAL.DEBITOS_MULTAS &&
                  agrupamento.totalDebitosEMultas}
                {abaAssociada === ABA_PAINEL_INICIAL.CITACOES && agrupamento.totalCitacoes}
              </Typography>
            </Box>
          </Box>
        </Box>
        <TabsNavegacao tabSelecionada={abaAssociada} />
      </Box>
      <PaperOutlined sx={{ p: 0, mb: 3 }}>
        <Box>
          <HeaderList>
            {`${totalizadorAgrupadorEmAndamento?.nivel.descricao} ${totalizadorAgrupadorEmAndamento?.nivel.quantidade}`}
          </HeaderList>
        </Box>
        {totalizadorAgrupadorEmAndamento?.subNiveis.map((totalizador) =>
          renderSubniveis(totalizador)
        )}
      </PaperOutlined>
      <PaperOutlined sx={{ p: 0, mb: 3 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={classes.headerTotalizadorEncerrada}
          onClick={() => setExpandirEncerradas(!expandirEncerradas)}>
          <Box display="flex">
            <Typography variant="h6" marginRight={1}>
              {totalizadorAgrupadorEncerrado?.nivel.descricao}
            </Typography>
            <Typography variant="h6" sx={{ color: 'primary.light' }}>
              {totalizadorAgrupadorEncerrado?.nivel.quantidade}
            </Typography>
          </Box>
          <ExpandMoreIcon />
        </Box>
        <Collapse in={expandirEncerradas}>
          {totalizadorAgrupadorEncerrado?.subNiveis.map((totalizador) =>
            renderSubniveis(totalizador)
          )}
        </Collapse>
      </PaperOutlined>
    </ContentBox>
  )
}

PainelDividasPorFase.propTypes = {
  abaAssociada: PropTypes.string,
  agrupadorEmAndamento: PropTypes.string,
  agrupadorEncerrado: PropTypes.string,
}

export default PainelDividasPorFase

import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Box, Button, FormControlLabel, Paper, Switch, Tab, Tabs, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import TituloPagina from 'components/estilos/TituloPagina'
import _ from 'lodash'
import LinkExterno from 'components/estilos/LinkExterno'
import HeaderList from '../../estilos/HeaderList'
import { colunasListagem as colunas } from '../Divida'
import MensagemResultadoVazio from '../../comum/MensagemResultadoVazio'
import ContentBox from '../../estilos/ContentBox'
import dividaService from '../../../services/dividaService'
import processoService from '../../../services/processoService'
import CartaoDadosProcessoSkeleton from '../skeleton/CartaoDadosProcessoSkeleton'
import ListagemDividasPorProcessoSkeleton from '../skeleton/ListagemDividasPorProcessoSkeleton'
import AtualizarDivida from '../../comum/AtualizarDivida'
import { Actions as AvisosActions } from '../../../ducks/avisos'
import { ABA_LISTAGEM_DIVIDAS, PAGINA_ORIGEM_DETALHAMENTO_DIVIDA } from '../ConstantesDivida'

const DividasPorProcesso = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { idProcesso } = useParams()
  const { state: propsNavegacao } = useLocation()
  const [dividas, setDividas] = useState({
    dataAtualizacao: null,
    debitosMultas: [],
    canceladas: [],
    citacoes: [],
  })
  const [dadosProcesso, setDadosProcesso] = useState('')
  const [carregandoDividas, setCarregandoDividas] = useState(true)
  const [abaSelecionada, setAbaSelecionada] = useState(
    propsNavegacao?.abaSelecionada
      ? propsNavegacao.abaSelecionada
      : ABA_LISTAGEM_DIVIDAS.DEBITOS_MULTAS
  )
  const [paginaAtual, setPaginaAtual] = useState(
    propsNavegacao?.paginaAtual ? propsNavegacao.paginaAtual : 0
  )
  const [tamanhoPagina, setTamanhoPagina] = useState(
    propsNavegacao?.tamanhoPagina ? propsNavegacao.tamanhoPagina : 20
  )
  const [emAutuacaoProcesso, setEmAutuacaoProcesso] = useState(false)
  const [usrLogadoTemPerfilAutuacao, setUsrLogadoTemPerfilAutuacao] = useState(false)
  const [idDividasSelecionadas, setIdDividasSelecionadas] = useState([])

  const [semComentarios, setSemComentarios] = useState(false)
  const { possuiPerfilFazerComentario } = useSelector((state) => state.authentication)

  const handleDetalhar = (divida) => {
    navigate(`/dividas/${divida.id}`, {
      state: {
        idProcesso,
        paginaAtual,
        tamanhoPagina,
        abaSelecionada,
        paginaOrigem: PAGINA_ORIGEM_DETALHAMENTO_DIVIDA.DIVIDAS_POR_PROCESSO,
      },
    })
  }

  const handleVoltar = () => {
    navigate('/')
  }

  const handleClickLinkProcesso = async (codigoProcesso) => {
    const { data: url } = await processoService.recuperarUrlVisualizacaoProcesso(codigoProcesso)
    window.open(url)
  }

  const handleAbaDividasChange = (event, novaAba) => {
    setAbaSelecionada(novaAba)
    setPaginaAtual(0)
  }

  const recuperarDividasAbaSelecionada = () => {
    switch (abaSelecionada) {
      case ABA_LISTAGEM_DIVIDAS.DEBITOS_MULTAS:
        return dividas.debitosMultas
      case ABA_LISTAGEM_DIVIDAS.CANCELADAS:
        return dividas.canceladas
      case ABA_LISTAGEM_DIVIDAS.CITACOES:
        return dividas.citacoes
      default:
        return []
    }
  }

  useEffect(() => {
    const recuperarProcessoPorCodigo = async () => {
      const { data } = await processoService.recuperarDadosProcessoPorCodigo(idProcesso)
      setDadosProcesso(data)
    }
    if (!_.isNil(idProcesso)) {
      recuperarProcessoPorCodigo()
    }
  }, [idProcesso])

  useEffect(() => {
    const doUsrLogadoTemPerfilAutuacao = async () => {
      const { data: podeAutuar } = await processoService.usrLogadoTemPerfilAutuacaoProcessoRap()
      setUsrLogadoTemPerfilAutuacao(podeAutuar)
      return podeAutuar
    }

    doUsrLogadoTemPerfilAutuacao().then(() => {})
  }, [])

  const recuperarDividasProcesso = useCallback(async () => {
    const { codigo } = dadosProcesso
    try {
      setCarregandoDividas(true)
      const { data } = await dividaService.recuperarDividasPorProcesso(codigo, semComentarios)
      setDividas(data)
    } finally {
      setCarregandoDividas(false)
    }
  }, [dadosProcesso, semComentarios])

  const recuperarDividasProcessoCbex = useCallback(async () => {
    const { codigo } = dadosProcesso
    try {
      setCarregandoDividas(true)
      const { data } = await dividaService.recuperarDividasPorProcessoCbex(codigo)
      setDividas(data)
    } finally {
      setCarregandoDividas(false)
    }
  }, [dadosProcesso])

  useEffect(() => {
    const { codigo, siglaTipoProcesso } = dadosProcesso

    if (!_.isNil(codigo) && siglaTipoProcesso === 'CBEX') {
      recuperarDividasProcessoCbex()
    }

    if (!_.isNil(codigo) && siglaTipoProcesso !== 'CBEX') {
      recuperarDividasProcesso()
    }
  }, [dadosProcesso, recuperarDividasProcesso, recuperarDividasProcessoCbex])

  const tipoSiglaProcesso =
    dadosProcesso?.siglaTipoProcesso === 'CBEX' || dadosProcesso?.siglaTipoProcesso === 'RAP'
      ? dadosProcesso?.siglaTipoProcesso
      : `Originador - ${dadosProcesso?.siglaTipoProcesso}`

  const montarResponsabilidadeAgir = () => {
    const { nomeReduzidoUnidadeResponsavelAgir, nomeReduzidoSubunidadeResponsavelAgir } =
      dadosProcesso
    if (!nomeReduzidoUnidadeResponsavelAgir) return '--'
    const complementoResponsabilidadeAgir = nomeReduzidoSubunidadeResponsavelAgir
      ? `/${nomeReduzidoSubunidadeResponsavelAgir}`
      : ''
    return `${nomeReduzidoUnidadeResponsavelAgir}${complementoResponsabilidadeAgir}`
  }

  const handleAtualizarDividaPorProcesso = async () => {
    setCarregandoDividas(true)
    try {
      const { data } = await dividaService.atualizarDividasPorProcesso(dadosProcesso.codigo)
      setDividas((prevState) => ({ prevState, ...data }))
    } finally {
      setCarregandoDividas(false)
    }
  }

  const recuperarDividas = (idDividasSelecionadas) => {
    const dividasSelecionadas = [
      ...dividas.debitosMultas.filter((it) => idDividasSelecionadas.includes(it.id)),
      ...dividas.citacoes.filter((it) => idDividasSelecionadas.includes(it.id)),
      ...dividas.canceladas.filter((it) => idDividasSelecionadas.includes(it.id)),
    ]

    return dividasSelecionadas.filter((it) => it)
  }

  const iniciarAutuacaoProcesso = () => {
    if (idDividasSelecionadas.length === 0) {
      dispatch(AvisosActions.erro(['Selecione ao menos uma dívida para autuar o processo']))
    } else {
      navigate(`/dividas/autuacao`, {
        state: {
          dadosProcessoOriginador: dadosProcesso,
          dividas: recuperarDividas(idDividasSelecionadas),
          paginaOrigem: PAGINA_ORIGEM_DETALHAMENTO_DIVIDA.DIVIDAS_POR_PROCESSO,
        },
      })
    }
  }

  return (
    <ContentBox>
      <Box>
        {carregandoDividas ? (
          <CartaoDadosProcessoSkeleton />
        ) : (
          <div>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <TituloPagina onClick={handleVoltar}>Processo</TituloPagina>
              <Box display="flex" alignItems="center" sx={{ gap: '8px' }}>
                <AtualizarDivida
                  dataAtualizacao={dividas.dataAtualizacao}
                  onClick={handleAtualizarDividaPorProcesso}
                />
                {usrLogadoTemPerfilAutuacao &&
                  !emAutuacaoProcesso &&
                  abaSelecionada !== ABA_LISTAGEM_DIVIDAS.CANCELADAS && (
                    <Button
                      variant="contained"
                      onClick={() => setEmAutuacaoProcesso(true)}
                      sx={{ fontSize: 12 }}>
                      Iniciar autuação de processo
                    </Button>
                  )}

                {usrLogadoTemPerfilAutuacao &&
                  emAutuacaoProcesso &&
                  abaSelecionada !== ABA_LISTAGEM_DIVIDAS.CANCELADAS && (
                    <Button
                      variant="contained"
                      onClick={() => iniciarAutuacaoProcesso()}
                      style={{ marginLeft: '2em' }}
                      sx={{ fontSize: 12 }}>
                      Continuar autuação de processo
                    </Button>
                  )}
              </Box>
            </Box>
            <Paper
              variant="outlined"
              elevation={0}
              sx={{
                padding: 2,
                marginBottom: '16px',
              }}>
              <Box sx={{ gap: '32px', alignItems: 'flex-end', flexWrap: 'wrap' }} display="flex">
                <Box>
                  <LinkExterno onClick={() => handleClickLinkProcesso(dadosProcesso?.codigo)}>
                    {dadosProcesso?.numeroCompleto}
                  </LinkExterno>
                  <Typography variant="body1">{tipoSiglaProcesso}</Typography>
                </Box>
                <Box>
                  <Typography variant="body2" sx={{ color: 'grey.500' }}>
                    Responsabilidade por agir
                  </Typography>
                  <Typography variant="body1">{montarResponsabilidadeAgir()}</Typography>
                </Box>
                <Box>
                  <Typography variant="body2" sx={{ color: 'grey.500' }}>
                    Situação
                  </Typography>
                  <Typography variant="body1">
                    {dadosProcesso?.descricaoSituacao || '--'}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2" sx={{ color: 'grey.500' }}>
                    Estado
                  </Typography>
                  <Typography variant="body1">{dadosProcesso?.descricaoEstado}</Typography>
                </Box>
              </Box>
            </Paper>
          </div>
        )}
        <Box>
          <HeaderList>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: { xs: 'flex-start', sm: 'space-between' },
                flexDirection: {
                  xs: 'column',
                  sm: 'row',
                },
              }}>
              <Box>
                {emAutuacaoProcesso
                  ? 'Selecione as dívidas que irão compor o processo de acompanhamento'
                  : 'Dívidas associadas ao processo'}
              </Box>
              {possuiPerfilFazerComentario && (
                <FormControlLabel
                  sx={{
                    backgroundColor: 'white',
                    color: 'primary.main',
                    borderRadius: 25,
                    padding: '4px 8px',
                  }}
                  control={
                    <Switch
                      size="small"
                      checked={semComentarios}
                      onChange={({ target }) => setSemComentarios(target.checked)}
                    />
                  }
                  label="Apenas sem comentários"
                />
              )}
            </Box>
          </HeaderList>
        </Box>
        <Box sx={{ backgroundColor: 'background.paper' }}>
          <Tabs value={abaSelecionada} onChange={handleAbaDividasChange}>
            <Tab
              label={`Débitos e Multas ${dividas.debitosMultas.length}`}
              value={ABA_LISTAGEM_DIVIDAS.DEBITOS_MULTAS}
            />
            <Tab
              label={`Canceladas ${dividas.canceladas.length}`}
              value={ABA_LISTAGEM_DIVIDAS.CANCELADAS}
            />
            <Tab
              label={`Citações ${dividas.citacoes.length}`}
              value={ABA_LISTAGEM_DIVIDAS.CITACOES}
            />
          </Tabs>
          <DataGrid
            rows={recuperarDividasAbaSelecionada()}
            disableColumnMenu
            checkboxSelection={
              emAutuacaoProcesso && abaSelecionada !== ABA_LISTAGEM_DIVIDAS.CANCELADAS
            }
            disableSelectionOnClick
            columns={colunas}
            ColumnSortedAscendingIcon={null}
            ColumnSortedDescendingIcon={null}
            autoHeight
            pagination
            page={paginaAtual}
            pageSize={tamanhoPagina}
            onRowClick={handleDetalhar}
            headerHeight={42}
            rowsPerPageOptions={[20, 50, 100]}
            onPageChange={(novaPagina) => setPaginaAtual(novaPagina)}
            onPageSizeChange={(newPageSize) => setTamanhoPagina(newPageSize)}
            loading={carregandoDividas}
            onSelectionModelChange={(idsDividas) => {
              setIdDividasSelecionadas(idsDividas)
            }}
            selectionModel={idDividasSelecionadas}
            components={{
              NoRowsOverlay: MensagemResultadoVazio,
              LoadingOverlay: ListagemDividasPorProcessoSkeleton,
            }}
            componentsProps={{
              pagination: { classes: null },
            }}
            sx={{
              borderRadius: '0 0 4px 4px',
              '& .MuiDataGrid-row:hover': {
                cursor: 'pointer',
                '& .forwardIconHover ': {
                  visibility: 'visible',
                },
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                color: 'grey.500',
                fontWeight: 400,
              },
              '& .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '.MuiDataGrid-columnHeader:focus-within': {
                outline: 'none',
              },
            }}
          />
        </Box>
      </Box>
    </ContentBox>
  )
}

export default DividasPorProcesso

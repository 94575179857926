import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Box, Button, DialogContent, TextField, Typography } from '@mui/material'
import BootstrapDialog from '../../../comum/BootstrapDialog'
import BootstrapDialogTitle from '../../../comum/BootstrapDialogTitle'
import { Actions as AvisosActions } from '../../../../ducks/avisos'

const TAMANHO_MAXIMO_MOTIVO = 1000
const ConfirmacaoAlteracaoFaseModal = ({ faseAtual, novaFase, open, onClose, onConfirmar }) => {
  const dispatch = useDispatch()
  const [motivo, setMotivo] = React.useState('')
  const fasesComMotivoObrigatorio = ['SUSPENSA_DECISAO_JUDICIAL', 'CANCELADA_POR_DECISAO_JUDICIAL']

  const limparMotivo = () => {
    setMotivo('')
  }

  const confirmar = () => {
    if (fasesComMotivoObrigatorio.includes(novaFase?.codigo) && !motivo) {
      dispatch(AvisosActions.erro(['Por favor, informe o motivo.']))
      return
    }

    onConfirmar(novaFase?.codigo, motivo)
  }

  const onChangeMotivo = (novoMotivo) => {
    if (novoMotivo.length > TAMANHO_MAXIMO_MOTIVO) {
      setMotivo(novoMotivo.substring(0, TAMANHO_MAXIMO_MOTIVO))
      dispatch(
        AvisosActions.erro([
          `O campo motivo deve possuir no máximo ${TAMANHO_MAXIMO_MOTIVO} caracteres.`,
        ])
      )
    } else {
      setMotivo(novoMotivo)
    }
  }

  const fechar = () => {
    limparMotivo()
    onClose()
  }

  return (
    <BootstrapDialog onClose={fechar} open={open} maxWidth="md" fullWidth>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={fechar}>
        <Typography variant="h5" component="span">
          Você tem certeza de que deseja alterar a fase da dívida?
        </Typography>
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Box mt={1} mb={3}>
          <Typography variant="body1" gutterBottom>
            <strong>De:</strong> {faseAtual}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Para:</strong> {novaFase?.label}
          </Typography>
        </Box>
        {fasesComMotivoObrigatorio.includes(novaFase?.codigo) && (
          <TextField
            id="motivo"
            label="Motivo"
            placeholder="Informe o motivo para alterar a fase da dívida"
            multiline
            fullWidth
            rows={4}
            value={motivo}
            onChange={(event) => {
              onChangeMotivo(event.target.value)
            }}
          />
        )}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '16px', paddingTop: '16px' }}>
          <Button name="cancelar" id="cancelar" onClick={fechar} color="secondary" variant="text">
            Cancelar
          </Button>
          <Button
            name="confirmar"
            id="confirmar"
            onClick={confirmar}
            color="primary"
            variant="contained">
            Alterar
          </Button>
        </Box>
      </DialogContent>
    </BootstrapDialog>
  )
}

ConfirmacaoAlteracaoFaseModal.propTypes = {
  faseAtual: PropTypes.string,
  novaFase: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirmar: PropTypes.func.isRequired,
}

export default ConfirmacaoAlteracaoFaseModal

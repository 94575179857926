import { call, put, takeEvery } from 'redux-saga/effects'
import {
  Types as PesquisaAvancadaTypes,
  Actions as PesquisaAvancadaActions,
} from '../ducks/pesquisaAvancada'
import { safely } from './util/util'
import dividaService from '../services/dividaService'

export function* recuperarFiltrosDisponiveisPesquisaAvancada() {
  const { data } = yield call(dividaService.recuperarDadosFiltro)
  const retorno = { data }
  yield put(PesquisaAvancadaActions.salvarFiltrosDisponiveis(retorno))
}

export function* watchPesquisaAvancada() {
  yield takeEvery(
    PesquisaAvancadaTypes.RECUPERAR_FILTROS_DISPONIVEIS,
    safely(recuperarFiltrosDisponiveisPesquisaAvancada)
  )
}

import { useCallback, useEffect, useState } from 'react'
import { Accordion, AccordionSummary, Box, Button, Chip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import TuneIcon from '@mui/icons-material/Tune'
import ContentBox from 'components/estilos/ContentBox'
import TituloPagina from 'components/estilos/TituloPagina'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import * as React from 'react'
import { styled } from '@mui/material/styles'
import { colunas } from './ColunasRecolhimento'
import { limparOpcoesFiltro, montarFiltrosSelecionados } from './PesquisaAvancada'
import MensagemResultadoVazio from '../../comum/MensagemResultadoVazio'
import DrawerFiltros from './DrawerFiltros'
import {
  Actions as PesquisaAvancadaActions,
  CODIGOS_FILTROS_DISPONIVEIS_BACKEND,
  ESTADO_INICIAL_FILTROS,
} from '../../../ducks/pesquisaAvancada'

import classes from './PesquisaAvancadaRecolhimentos.style'
import ProgressBar from '../../comum/ProgressBar'
import useIsFirstRender from '../../comum/useIsFirstRender'
import {
  NOME_TOTALIZADOR_GERAL_RECOLHIMENTOS,
  PAGINA_ORIGEM_DETALHAMENTO_DIVIDA,
} from '../ConstantesDivida'
import recolhimentoService from '../../../services/recolhimentoService'
import formatarNumeroParaMoeda from '../../../util/util'
import IconeInformacao from '../../../img/icone_informacao.svg'

const PesquisaAvancadaRecolhimentos = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isFirstRender = useIsFirstRender()

  const { state: propsNavegacao } = useLocation()
  const filtrosAplicadosRecolhimentos = useSelector(
    ({ pesquisaAvancada }) => pesquisaAvancada.filtrosAplicadosRecolhimentos
  )
  const [exibirDrawerFiltro, setExibirDrawerFiltro] = useState(false)
  const [, setCarregandoRecolhimentos] = useState(true)
  const [recolhimentos, setRecolhimentos] = useState([])
  const [totalizadores, setTotalizadores] = useState([])
  const [totalElementos, setTotalElementos] = useState(0)
  const [paginaAtual, setPaginaAtual] = useState(propsNavegacao?.paginaAtual || 0)
  const [tamanhoPagina, setTamanhoPagina] = useState(propsNavegacao?.tamanhoPagina || 20)
  const [
    seRealizarPesquisaAoAlterarFiltrosAplicados,
    setSeRealizarPesquisaAoAlterarFiltrosAplicados,
  ] = useState(true)

  const pesquisarRecolhimentos = useCallback(
    (filtrosDados) => {
      setCarregandoRecolhimentos(true)
      recolhimentoService
        .recuperarRecolhimentosPorFiltro(filtrosDados, paginaAtual, tamanhoPagina)
        .then((response) => {
          if (response.data) {
            setRecolhimentos(response.data.elementos)
            setTotalElementos(response.data.totalElementos)
            setTotalizadores(response.data.totalizadores || [])
          }
        })
        .finally(() => {
          setCarregandoRecolhimentos(false)
        })
    },
    [paginaAtual, tamanhoPagina]
  )

  useEffect(() => {
    if (propsNavegacao?.limparPesquisa) {
      dispatch(PesquisaAvancadaActions.recuperarFiltrosDisponiveis())
      pesquisarRecolhimentos(
        montarFiltrosSelecionados(ESTADO_INICIAL_FILTROS.filtrosAplicadosRecolhimentos)
      )
      setSeRealizarPesquisaAoAlterarFiltrosAplicados(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, propsNavegacao])

  useEffect(() => {
    if (!seRealizarPesquisaAoAlterarFiltrosAplicados) {
      setSeRealizarPesquisaAoAlterarFiltrosAplicados(true)
      return
    }

    if (!(propsNavegacao?.limparPesquisa && isFirstRender)) {
      pesquisarRecolhimentos(montarFiltrosSelecionados(filtrosAplicadosRecolhimentos))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pesquisarRecolhimentos, filtrosAplicadosRecolhimentos])

  const handleVoltar = () => {
    navigate(-1)
  }

  const handleMudarPagina = (novaPagina) => {
    setPaginaAtual(novaPagina)
  }

  const handleDetalhar = (recolhimento) => {
    navigate(`/dividas/${recolhimento?.row?.codigoDivida}`, {
      state: {
        paginaAtual,
        tamanhoPagina,
        paginaOrigem: PAGINA_ORIGEM_DETALHAMENTO_DIVIDA.PESQUISA_AVANCADA_RECOLHIMENTOS,
      },
    })
  }

  const toggleDrawerFiltro = () => {
    setExibirDrawerFiltro((prevExibirDrawer) => !prevExibirDrawer)
  }

  const handleLimparFiltroExcluindoFiltro = (filtroRemovido) => {
    const copiaFiltrosAplicados = filtrosAplicadosRecolhimentos.filter(
      (filtro) => filtro.codigo !== filtroRemovido.codigo
    )
    dispatch(
      PesquisaAvancadaActions.aplicarFiltrosRecolhimentosSelecionados({
        data: copiaFiltrosAplicados,
      })
    )
  }

  const handleLimparFiltroLimpandoOpcoesSelecionadas = (filtroRemovido) => {
    const copiaFiltrosAplicados = filtrosAplicadosRecolhimentos.map((filtro) =>
      filtro.codigo === filtroRemovido.codigo
        ? { ...filtro, opcoes: limparOpcoesFiltro(filtro.opcoes) }
        : filtro
    )
    dispatch(
      PesquisaAvancadaActions.aplicarFiltrosRecolhimentosSelecionados({
        data: copiaFiltrosAplicados,
      })
    )
  }

  const handleLimparFiltro = (filtroRemovido) => {
    if (
      CODIGOS_FILTROS_DISPONIVEIS_BACKEND.some(
        (codigoFiltroDisponivelBackend) => filtroRemovido.codigo === codigoFiltroDisponivelBackend
      )
    ) {
      handleLimparFiltroLimpandoOpcoesSelecionadas(filtroRemovido)
    } else {
      handleLimparFiltroExcluindoFiltro(filtroRemovido)
    }
  }

  const renderChipsFiltrosAplicados = () => {
    const chipsFiltrosAplicados = filtrosAplicadosRecolhimentos?.reduce((resultado, filtro) => {
      const opcoesSelecionadas = filtro.opcoes?.filter((opcao) => opcao.selecionada)
      if (opcoesSelecionadas?.length > 0) {
        const valores = opcoesSelecionadas
          .map((opcaoSelecionada) => opcaoSelecionada.descricao)
          .join(', ')
        const labelChip = `${filtro.nome} = ${valores}`
        return resultado.concat(
          <Chip
            size="small"
            key={filtro.codigo}
            label={labelChip}
            sx={classes.chipPesquisaAvancada}
            onDelete={() => handleLimparFiltro(filtro)}
          />
        )
      }
      return resultado
    }, [])

    return (
      <Box display="flex" sx={{ gap: '4px', flexWrap: 'wrap' }}>
        {chipsFiltrosAplicados}
      </Box>
    )
  }

  const handleExportarRecolhimentosClick = () => {
    recolhimentoService.exportarRecolhimentosPorFiltroParaCSV(
      montarFiltrosSelecionados(filtrosAplicadosRecolhimentos)
    )
  }

  const recuperarTotalizadorGeralRecolhimentos = () => {
    if (totalizadores.length > 0) {
      const totalizador = totalizadores.filter(
        (it) => it.nome === NOME_TOTALIZADOR_GERAL_RECOLHIMENTOS
      )
      return totalizador[0]
    }
    return null
  }

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 550,
      fontSize: '0.9rem',
    },
  })

  return (
    <ContentBox>
      <Box>
        <Box sx={classes.boxTituloPesquisa}>
          <TituloPagina onClick={handleVoltar}>
            Relatório de pagamentos administrativos
          </TituloPagina>
          <Box display="flex" gap={1} alignContent="flex-start" sx={classes.boxBotoesPesquisa}>
            <Button
              variant="outlined"
              onClick={handleExportarRecolhimentosClick}
              size="small"
              startIcon={<FileDownloadIcon />}>
              CSV
            </Button>
            <Button
              startIcon={<TuneIcon />}
              variant="outlined"
              size="small"
              onClick={() => toggleDrawerFiltro()}>
              Filtrar e organizar
            </Button>
          </Box>
        </Box>
        <Box pb={1}>{renderChipsFiltrosAplicados()}</Box>

        <Accordion disableGutters>
          <AccordionSummary>
            <Box width={1} sx={classes.linhaTotalizadores}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '20px',
                  justifyContent: 'flex-start',
                  alignSelf: 'center',
                }}>
                <Typography variant="h6" color="textSecondary">
                  Total encontrado
                </Typography>
                <Typography variant="h6" color="primary.light">
                  {recuperarTotalizadorGeralRecolhimentos()?.totalGeral?.quantidade}
                </Typography>
                <Typography variant="h6" color="textSecondary">
                  Valor total{' '}
                  <CustomWidthTooltip
                    title={
                      <Box>
                        <Typography variant="body2" gutterBottom>
                          Soma dos valores nominais dos pagamentos apenas para fins de conferência.
                        </Typography>
                      </Box>
                    }
                    sx={{ color: 'grey.500' }}>
                    <img
                      src={IconeInformacao}
                      style={{ width: '16px', height: 'auto' }}
                      alt="Ícone de informação"
                    />
                  </CustomWidthTooltip>
                </Typography>

                <Typography variant="h6" color="primary.light">
                  {`R$ ${formatarNumeroParaMoeda(
                    recuperarTotalizadorGeralRecolhimentos()?.totalGeral?.valor,
                    2,
                    2
                  )}`}
                </Typography>
              </Box>
            </Box>
          </AccordionSummary>
        </Accordion>

        <Box minHeight={500} sx={{ marginTop: '10px', backgroundColor: 'background.paper' }}>
          <DataGrid
            rows={recolhimentos}
            disableColumnMenu
            columns={colunas.map((it) => ({ ...it, sortable: false }))}
            rowCount={totalElementos}
            autoHeight
            pagination
            paginationMode="server"
            page={paginaAtual}
            pageSize={tamanhoPagina}
            onRowClick={handleDetalhar}
            headerHeight={42}
            rowsPerPageOptions={[20, 50, 100]}
            onPageChange={(novaPagina) => handleMudarPagina(novaPagina)}
            onPageSizeChange={(newPageSize) => setTamanhoPagina(newPageSize)}
            components={{
              NoRowsOverlay: MensagemResultadoVazio,
            }}
            componentsProps={{
              pagination: { classes: null },
            }}
            sx={{
              borderRadius: '0 0 4px 4px',
              '& .MuiDataGrid-row:hover': {
                cursor: 'pointer',
                '& .forwardIconHover ': {
                  visibility: 'visible',
                },
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                color: 'grey.500',
                fontWeight: 400,
              },
              '& .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '.MuiDataGrid-columnHeader:focus-within': {
                outline: 'none',
              },
            }}
          />
        </Box>
      </Box>
      <DrawerFiltros open={exibirDrawerFiltro} toggleDrawerFiltro={toggleDrawerFiltro} />
      <ProgressBar />
    </ContentBox>
  )
}

export default PesquisaAvancadaRecolhimentos

const classes = {
  cardNavegacao: {
    padding: '5px',
    width: '100%',
    textAlign: 'center',
    cursor: 'pointer',
  },
  textoCardNavegacao: {
    fontFamily: 'Libre Franklin',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '25px',
    color: '#1C519B',
    textAlign: 'center',
  },
  paperOutlinedNavegacao: {
    padding: '5px',
    borderRadius: '5px 5px 0px 0px',
    transition: 'all 0.3s',
    '&:hover': {
      boxShadow: '0px 3px 0px 0px rgba(28, 81, 155, 0.8)',
    },
  },
  boxBotoesNavegacao: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: { xs: '100%', sm: '35%' },
  },
}

export default classes

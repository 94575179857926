const classes = {
  labelCampo: {
    fontSize: 12,
    color: 'grey.500',
    marginBottom: '10px' },

  chipPesquisaAvancada: {
    height:'auto',
    borderRadius:'4px',
    padding:'2px 0',
    fontSize: '12px',
    '& .MuiChip-label':{
      whiteSpace: 'normal'
    }
  },
  linhaTotalizadores: {
    display: 'grid',
    gridTemplateColumns: {xs:'1fr 1fr', sm:'200px 24px 1fr 1fr 1fr 1.5fr'},
    alignItems: 'center',
    gap: '16px'
  },
  linhaTotalizadoresDetalhes: {
    paddingBottom: '8px',
    paddingTop: '8px',
    borderTop: '1px solid rgba(0,0,0,.15)',
    display: 'grid',
    gridTemplateColumns: {xs:'1fr 1fr', sm:'200px 24px 1fr 1fr 1fr 1.5fr'},
    alignItems: 'center',
    gap: '16px'
  },
  boxDetalhesTotalizadores: {
    display: {xs:'none',sm:'flex'},
    gap:'4px',
    justifyContent:'flex-end',
    alignItems:'center'
  },
  boxTituloPesquisa:{
    display:'flex',
    flexWrap:'wrap',
    justifyContent: {xs:'flex-start',sm:'space-between'}
  },
  boxBotoesPesquisa: {
    alignItems: 'flex-start',
    width: {xs:'100%',sm:'auto'}
  }
}

export default classes

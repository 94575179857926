import * as React from 'react'
import { useState } from 'react'
import {
  Box,
  Divider,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import PaperOutlined from 'components/estilos/PaperOutlinedNoSpacing'
import GraficoLinhas from '../graficos/GraficoLinhas'
import formatarNumeroParaMoeda from '../../../../util/util'
import painelRetrospectivaService from '../../../../services/painelRetrospectivaService'
import classes from './Tabela.style'
import CustomWidthTooltip from '../CustomWidthTooltip'
import IconeInformacao from '../../../../img/icone_informacao.svg'

const TabelaRecolhimentosPorAnoETipoDeliberacao = ({ titulo, dados }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [tituloChart, setTituloChart] = useState(null)
  const [chartRecolhimentoMensalPorAno, setChartRecolhimentoMensalPorAno] = useState(null)

  const montarChartRecolhimentoMensalPorAno = (dadosRecolhimento) => {
    if (!dadosRecolhimento) return

    const meses = Object.keys(dadosRecolhimento.totalRecolhidoMensal)
    const linhaDebito = {
      name: 'Débito',
      data: Object.values(dadosRecolhimento.debitosRecolhidosMensais),
    }
    const linhaMulta = {
      name: 'Multa',
      data: Object.values(dadosRecolhimento.multasRecolhidasMensais),
    }
    const linhaCitacao = {
      name: 'Citação',
      data: Object.values(dadosRecolhimento.citacoesRecolhidasMensais),
    }
    const linhaTotal = {
      name: 'Total',
      data: Object.values(dadosRecolhimento.totalRecolhidoMensal),
    }
    const chartRecolhimento = {
      categorias: meses,
      valores: [linhaDebito, linhaMulta, linhaCitacao, linhaTotal],
    }

    setChartRecolhimentoMensalPorAno(chartRecolhimento)
  }

  const obterDadosRecolhimentoMensalPorAno = (ano) => {
    painelRetrospectivaService
      .obterRelatorioRecolhimentosMensaisPorTipoDeliberacao(ano)
      .then((response) => {
        if (response.data) {
          montarChartRecolhimentoMensalPorAno(response.data)
        }
      })
  }

  const handleClick = (event, ano) => {
    setAnchorEl(event.currentTarget)
    setTituloChart(`Histórico detalhado de recolhimentos do ano ${ano}`)
    obterDadosRecolhimentoMensalPorAno(ano)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setTituloChart(null)
    setChartRecolhimentoMensalPorAno(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <TableContainer component={PaperOutlined} sx={classes.tableContainerWidth}>
      <Box sx={{ py: 1.2, px: 2 }}>
        <Typography
          variant="subtitle1"
          component="div"
          color="grey.800"
          style={{ height: 'auto', verticalAlign: 'center' }}>
          {titulo}{' '}
          <CustomWidthTooltip
            title={
              <Box>
                <Typography variant="body2">
                  Os <strong>somatórios de recolhimentos</strong> apresentados correspondem à soma
                  dos valores nominais dos pagamentos, levando-se em conta a data em que o pagamento
                  ocorreu, independentemente do ano em que a dívida foi imputada.
                </Typography>
              </Box>
            }
            sx={{ color: 'grey.500' }}>
            <img
              src={IconeInformacao}
              style={{ width: '15px', height: 'auto', verticalAlign: '-2px' }}
              alt="Ícone de informação"
            />
          </CustomWidthTooltip>
        </Typography>
      </Box>
      <Divider />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={classes.tableCellLabel}>Ano</TableCell>
            <TableCell align="right" sx={classes.tableCellLabel}>Débito</TableCell>
            <TableCell align="right" sx={classes.tableCellLabel}>Multa</TableCell>
            <TableCell align="right" sx={classes.tableCellLabel}>Citação</TableCell>
            <TableCell align="right" sx={classes.tableCellLabel}>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dados?.map((row) => (
            <TableRow key={row.ano} onClick={(event) => handleClick(event, row.ano)} hover>
              <TableCell
                align="center"
                component="th"
                scope="row"
                sx={{ cursor: 'pointer', fontWeight: 'bold', color: 'primary.light' }}>
                <Typography sx={{ cursor: 'pointer', fontWeight: 'bold', color: 'primary.light' }}>
                  {row.ano}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body2" sx={classes.cellContent}>
                  {formatarNumeroParaMoeda(row.valorTotalPagamentosDebitos, 2, 2)}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body2" sx={classes.cellContent}>
                  {formatarNumeroParaMoeda(row.valorTotalPagamentosMultas, 2, 2)}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body2" sx={classes.cellContent}>
                  {formatarNumeroParaMoeda(row.valorTotalPagamentosCitacoes, 2, 2)}
                </Typography>
              </TableCell>
              <TableCell align="right">
                {formatarNumeroParaMoeda(row.valorTotalPagamentos, 2, 2)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {chartRecolhimentoMensalPorAno && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          sx={{
            '& .MuiPaper-root': {
              padding: 1,
              width: 700,
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}>
          <GraficoLinhas titulo={tituloChart} data={chartRecolhimentoMensalPorAno} />
        </Popover>
      )}
    </TableContainer>
  )
}

TabelaRecolhimentosPorAnoETipoDeliberacao.propTypes = {
  titulo: PropTypes.string.isRequired,
  dados: PropTypes.array,
}

export default TabelaRecolhimentosPorAnoETipoDeliberacao

import React from 'react'
import NumberFormat from 'react-number-format'
import _ from 'lodash'
import {TextField} from '@mui/material'
import PropTypes from 'prop-types'

const MoedaV2 = ({
  name,
  value,
  placeholder,
  label,
  onChange = () => {},
  onBlur = () => {},
  setFieldValue = () => {},
  disabled = false,
  maxLength = 19,
  style = {},
}) => {

  const newValue = _.isEmpty(value) && !_.isNumber(value) && value !== 0 ? '' : Number(value) * 100

  const formatCurrencyByEnd = (value) => {
    if (_.isEmpty(value)) return ''

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    })
      .format(parseFloat(value) / 100)
      .replace(' ', ' ')
    return `${amount}`
  }

  const returnInputValue = (values) => {
    const valor = (parseFloat(values.value) / 100).toFixed(2)
    return valor === 'NaN' ? '' : valor
  }

  return (
    <NumberFormat
        id={name}
        name={name}
        data-testid={name}
        label={label}
        format={formatCurrencyByEnd}
        allowNegative={false}
        fixedDecimalScale
        decimalScale={2}
        allowEmptyFormatting={false}
        placeholder={placeholder}
        inputMode="numeric"
        customInput={TextField}
        InputLabelProps={{ shrink: true }}
        size="small"
        value={newValue}
        isAllowed={(values) => {
          const { formattedValue, floatValue } = values
          return formattedValue === '' || floatValue / 100 <= 10 ** maxLength
        }}
        onValueChange={(values) => {
          const valor = returnInputValue(values)
          setFieldValue(name, valor)
          onChange(valor)
        }}
        disabled={disabled}
        onBlur={onBlur}
        style={style}
      />
  )
}

export default MoedaV2

MoedaV2.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  style: PropTypes.object,
}

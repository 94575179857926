const classes = {
  gridAlertasPorTipo: {
    p: '8px',
    gridTemplateColumns: { md: '1fr 1px 1fr' },
    gap: '10px',
    backgroundColor: 'background.paper',
    borderBottom: '1px solid #E0E0E0',
  },
  agrupadorClicavel: {
    display: 'grid',
    gridTemplateColumns: { xs: '1fr 48px' },
    gap: '8px',
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: 'all 0.3s',
    '&:hover': {
      cursor: 'pointer',
      '& > h6 , p, h6': {
        color: 'primary.light',
      },
    },
  },
  descricaoTipoAlerta: {
    color:'secondary.light',
    padding: '0 4px',
    lineHeight: 1.15,
  },
  totalAlertasTipo: {
    color: 'primary.light',
    padding: '8px 0',
},
  iconeAlerta: {
    padding: '3px',
    backgroundColor: 'common.white',
    '&:hover': {
      backgroundColor: 'common.white',
    },
  },
  textoOverflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  boxTitulo: {
    display:'flex',
    alignItems:{xs:'flex-start', sm:'flex-end'},
    justifyContent: {xs:'flex-start', sm:'space-between'},
    flexDirection: {
      xs: 'column',
      sm: 'row'
    }
  }
}

export default classes

import React from 'react'
import { Box, Button, DialogContent, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import PropTypes from 'prop-types'
import MensagemResultadoVazio from '../../comum/MensagemResultadoVazio'
import ListagemDividasPorProcessoSkeleton from '../skeleton/ListagemDividasPorProcessoSkeleton'
import BootstrapDialog from '../../comum/BootstrapDialog'
import BootstrapDialogTitle from '../../comum/BootstrapDialogTitle'

const renderCabecalho = (params) => (
  <Box display="flex" flexDirection="column" sx={{ width: '100%', margin: '12px, 16px' }}>
    <Typography variant="body2">{params.row?.textoCabecalho}</Typography>
  </Box>
)
const renderComplementoAssunto = (params) => (
  <Box display="flex" flexDirection="column" sx={{ width: '100%', margin: '12px, 16px' }}>
    <Typography variant="body2">{params.row?.complementoAssunto}</Typography>
  </Box>
)

export const COLUNAS = {
  DESCRICAO: {
    field: 'textoCabecalho',
    headerName: 'Peça',
    width: 500,
    renderCell: renderCabecalho,
  },
  TIPO: {
    field: 'complementoAssunto',
    headerName: 'Complemento',
    width: 500,
    renderCell: renderComplementoAssunto,
  },
}

export const colunasListagem = [COLUNAS.DESCRICAO, COLUNAS.TIPO]

const ModalSelecionarPecasProcesso = ({
  pecasProcessoOriginador,
  open,
  onClose,
  callbackPecasSelecionadas,
}) => {
  const [idsPecas, setIdsPecas] = React.useState([])
  return (
    <BootstrapDialog onClose={() => onClose()} open={open} maxWidth="lg" fullWidth>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        <Box display="flex" flexDirection="column" justifyContent="flex-start" height="100%">
          <Typography variant="h3">
            Selecionar peças do processo
          </Typography>
        </Box>
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <DataGrid
          rows={pecasProcessoOriginador}
          getRowId={(row) => row.codDocumento}
          disableColumnMenu
          checkboxSelection
          columns={colunasListagem}
          ColumnSortedAscendingIcon={null}
          ColumnSortedDescendingIcon={null}
          autoHeight
          onSelectionModelChange={(idPecas) => {
            setIdsPecas(idPecas)
          }}
          selectionModel={idsPecas}
          components={{
            NoRowsOverlay: MensagemResultadoVazio,
            LoadingOverlay: ListagemDividasPorProcessoSkeleton,
          }}
          componentsProps={{
            pagination: { classes: null },
          }}
          sx={{
            borderRadius: '0 0 4px 4px',
            '& .MuiDataGrid-row:hover': {
              cursor: 'pointer',
              '& .forwardIconHover ': {
                visibility: 'visible',
              },
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              color: 'grey.500',
              fontWeight: 400,
            },
            '& .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '.MuiDataGrid-columnHeader:focus-within': {
              outline: 'none',
            },
          }}
        />
      </DialogContent>
      <Box display="flex" justifyContent="center" p={1}>
        <Button
          variant="contained"
          onClick={() =>
            callbackPecasSelecionadas(
              pecasProcessoOriginador.filter((it) => idsPecas.includes(it.codDocumento))
            )
          }>
          Selecionar
        </Button>
      </Box>
    </BootstrapDialog>
  )
}

ModalSelecionarPecasProcesso.propTypes = {
  pecasProcessoOriginador: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  callbackPecasSelecionadas: PropTypes.func.isRequired,
}

export default ModalSelecionarPecasProcesso

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Drawer,
  FormControlLabel,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import PropTypes from 'prop-types'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import moment from 'moment'
import classes from './DrawerFiltros.style'
import { normalizarString } from '../Divida'
import {
  desabilitarOpcaoFiltro,
  habilitarOpcaoFiltro,
  limparOpcoesFiltro,
} from './PesquisaAvancada'
import {
  Actions as PesquisaAvancadaActions,
  CODIGO_FILTRO_ANO,
  CODIGO_FILTRO_DATA_FIM_ENVIO_CBEX,
  CODIGO_FILTRO_DATA_INICIO_ENVIO_CBEX,
  CODIGO_FILTRO_FASES_CITACAO,
  CODIGO_FILTRO_FASES_DEBITO_MULTA,
  CODIGO_FILTRO_ORGAO_EXECUTOR,
  CODIGO_FILTRO_TIPO_DELIBERACAO,
  CODIGO_FILTRO_TIPO_PROCESSO_ORIGINADOR,
  CODIGO_FILTRO_VALOR_MAXIMO_SALDO_DEVEDOR,
  CODIGO_FILTRO_VALOR_MINIMO_SALDO_DEVEDOR,
  CODIGO_OPCAO_TIPO_DELIBERACAO_CITACAO,
  CODIGOS_FILTROS_PESQUISAVEIS,
  CODIGOS_OPCOES_FASES_CANCELADAS,
  FILTRO_APENAS_DIVIDAS_COM_REGISTRO_PAGAMENTO,
  FILTRO_EXCLUIR_CITACOES,
  FILTRO_EXCLUIR_DIVIDAS_CANCELADAS,
} from '../../../ducks/pesquisaAvancada'
import MoedaV2 from '../../comum/MoedaV2'
import formatarNumeroParaMoeda from '../../../util/util'
import { Actions as AvisosActions } from '../../../ducks/avisos'

const QTD_MAXIMA_OPCOES_EXIBIDAS = 15
const DrawerFiltros = ({ open, toggleDrawerFiltro }) => {
  const dispatch = useDispatch()
  const filtrosAplicadosDividas = useSelector(
    ({ pesquisaAvancada }) => pesquisaAvancada.filtrosAplicadosDividas
  )

  const [campoValorMinimoSaldoDevedor, setCampoValorMinimoSaldoDevedor] = useState(null)
  const [campoValorMaximoSaldoDevedor, setCampoValorMaximoSaldoDevedor] = useState(null)
  const [campoFiltroAno, setCampoFiltroAno] = useState([])
  const [campoFiltroTipoDeliberacao, setCampoFiltroTipoDeliberacao] = useState([])
  const [campoDataInicioEnvioCBEX, setCampoDataInicioEnvioCBEX] = useState(null)
  const [campoDataFimEnvioCBEX, setCampoDataFimEnvioCBEX] = useState(null)
  const [camposFiltrosPesquisaveis, setCamposFiltrosPesquisaveis] = useState([])

  const [excluirCitacao, setExcluirCitacao] = useState(true)
  const [excluirDividaCancelada, setExcluirDividaCancelada] = useState(true)
  const [apenasDividasComRegistroPagamento, setApenasDividasComRegistroPagamento] = useState(false)

  useEffect(() => {
    setCampoFiltroAno(
      filtrosAplicadosDividas?.find((campoFiltro) => campoFiltro.codigo === CODIGO_FILTRO_ANO)
    )
    const campoFiltroTipoDeliberacaoAtualizado = filtrosAplicadosDividas?.find(
      (campoFiltro) => campoFiltro.codigo === CODIGO_FILTRO_TIPO_DELIBERACAO
    )
    setCampoFiltroTipoDeliberacao(campoFiltroTipoDeliberacaoAtualizado)

    const camposFiltrosPesquisaveisAtualizados = filtrosAplicadosDividas?.filter((campoFiltro) =>
      CODIGOS_FILTROS_PESQUISAVEIS.some(
        (codigoFiltroPesquisavel) => campoFiltro.codigo === codigoFiltroPesquisavel
      )
    )
    setCamposFiltrosPesquisaveis(camposFiltrosPesquisaveisAtualizados)

    const filtroExcluirCitacao = filtrosAplicadosDividas.find(
      (campoFiltro) => campoFiltro.codigo === FILTRO_EXCLUIR_CITACOES.codigo
    )
    const excluirCitacao = filtroExcluirCitacao != null
    setExcluirCitacao(excluirCitacao)

    const filtroExcluirDividaCancelada = filtrosAplicadosDividas.find(
      (campoFiltro) => campoFiltro.codigo === FILTRO_EXCLUIR_DIVIDAS_CANCELADAS.codigo
    )
    const excluirDividaCancelada = filtroExcluirDividaCancelada != null
    setExcluirDividaCancelada(excluirDividaCancelada)

    // eslint-disable-next-line no-use-before-define
    tratarExcluirCitacaoEExcluirDividasCanceladas(
      excluirCitacao,
      excluirDividaCancelada,
      campoFiltroTipoDeliberacaoAtualizado,
      camposFiltrosPesquisaveisAtualizados
    )

    const filtroApenasDividasComRegistroPagamento = filtrosAplicadosDividas.find(
      (campoFiltro) => campoFiltro.codigo === FILTRO_APENAS_DIVIDAS_COM_REGISTRO_PAGAMENTO.codigo
    )
    setApenasDividasComRegistroPagamento(filtroApenasDividasComRegistroPagamento != null)

    const filtroValorMinimoSaldoDevedor = filtrosAplicadosDividas.find(
      (campoFiltro) => campoFiltro.codigo === CODIGO_FILTRO_VALOR_MINIMO_SALDO_DEVEDOR
    )
    setCampoValorMinimoSaldoDevedor(filtroValorMinimoSaldoDevedor?.valor[0])

    const filtroValorMaximoSaldoDevedor = filtrosAplicadosDividas.find(
      (campoFiltro) => campoFiltro.codigo === CODIGO_FILTRO_VALOR_MAXIMO_SALDO_DEVEDOR
    )
    setCampoValorMaximoSaldoDevedor(filtroValorMaximoSaldoDevedor?.valor[0])

    const filtroDataInicioEnvioCBEX = filtrosAplicadosDividas.find(
      (campoFiltro) => campoFiltro.codigo === CODIGO_FILTRO_DATA_INICIO_ENVIO_CBEX
    )
    if (filtroDataInicioEnvioCBEX) {
      setCampoDataInicioEnvioCBEX(filtroDataInicioEnvioCBEX?.valor[0])
    } else {
      setCampoDataInicioEnvioCBEX(null)
    }

    const filtroDataFimEnvioCBEX = filtrosAplicadosDividas.find(
      (campoFiltro) => campoFiltro.codigo === CODIGO_FILTRO_DATA_FIM_ENVIO_CBEX
    )
    if (filtroDataFimEnvioCBEX) {
      setCampoDataFimEnvioCBEX(filtroDataFimEnvioCBEX?.valor[0])
    } else {
      setCampoDataFimEnvioCBEX(null)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtrosAplicadosDividas])

  const filtrarOpcoes = (opcoes, filtro) => {
    if (opcoes) {
      const opcoesOcultasSelecionadas = opcoes
        .slice(QTD_MAXIMA_OPCOES_EXIBIDAS)
        .filter((opcao) => opcao.selecionada)
      if (filtro) {
        return opcoes
          .filter((opcao) => opcao.descricao.toLowerCase().includes(filtro.toLowerCase()))
          .slice(0, QTD_MAXIMA_OPCOES_EXIBIDAS)
      }
      return [...opcoesOcultasSelecionadas, ...opcoes.slice(0, QTD_MAXIMA_OPCOES_EXIBIDAS)]
    }
    return []
  }

  const onPesquisaTextualChange = (event, index) => {
    const copiaDadosFiltro = camposFiltrosPesquisaveis.slice()
    const copiaFiltro = { ...copiaDadosFiltro[index], pesquisaTextual: event.target.value }
    copiaDadosFiltro.splice(index, 1, copiaFiltro)
    setCamposFiltrosPesquisaveis(copiaDadosFiltro)
  }

  const handleSelecionarAnoClick = (event, opcao) => {
    const copiaCampoFiltroAno = { ...campoFiltroAno }
    copiaCampoFiltroAno.opcoes = copiaCampoFiltroAno.opcoes.map((it) => {
      if (it.codigo === opcao.codigo) {
        return { ...it, selecionada: !it.selecionada }
      }
      return it
    })
    setCampoFiltroAno(copiaCampoFiltroAno)
  }

  const tratarCampoFiltroTipoDeliberacao = (
    excluirCitacao,
    campoFiltroTipoDeliberacaoAtualizado = null
  ) => {
    const campoFiltro = campoFiltroTipoDeliberacaoAtualizado
      ? campoFiltroTipoDeliberacaoAtualizado
      : campoFiltroTipoDeliberacao
    if (!campoFiltro || campoFiltro.length === 0) return

    const copiaCampoFiltroTipoDeliberacao = { ...campoFiltro }
    copiaCampoFiltroTipoDeliberacao.opcoes = copiaCampoFiltroTipoDeliberacao.opcoes.map((it) => {
      if (it.codigo === CODIGO_OPCAO_TIPO_DELIBERACAO_CITACAO) {
        if (excluirCitacao) {
          return desabilitarOpcaoFiltro(it)
        }
        return habilitarOpcaoFiltro(it)
      }
      return it
    })
    setCampoFiltroTipoDeliberacao(copiaCampoFiltroTipoDeliberacao)
  }

  const tratarCamposFiltrosPesquisaveis = (
    excluirCitacao,
    excluirDividaCancelada,
    camposFiltrosPesquisaveisAtualizados = null
  ) => {
    const camposFiltros = camposFiltrosPesquisaveisAtualizados
      ? camposFiltrosPesquisaveisAtualizados
      : camposFiltrosPesquisaveis
    if (!camposFiltros || camposFiltros.length === 0) return

    const camposFiltrosPesquisaveisTratados = camposFiltros.map((filtro) => {
      if (filtro.codigo === CODIGO_FILTRO_FASES_DEBITO_MULTA) {
        return {
          ...filtro,
          opcoes: filtro.opcoes.map((it) => {
            if (CODIGOS_OPCOES_FASES_CANCELADAS.some((codigoOpcao) => it.codigo === codigoOpcao)) {
              if (excluirDividaCancelada) {
                return desabilitarOpcaoFiltro(it)
              }
              return habilitarOpcaoFiltro(it)
            }
            return it
          }),
        }
      }
      if (filtro.codigo === CODIGO_FILTRO_FASES_CITACAO) {
        return {
          ...filtro,
          opcoes: filtro.opcoes.map((it) => {
            if (
              excluirCitacao ||
              (excluirDividaCancelada &&
                CODIGOS_OPCOES_FASES_CANCELADAS.some((codigoOpcao) => it.codigo === codigoOpcao))
            ) {
              return desabilitarOpcaoFiltro(it)
            }
            return habilitarOpcaoFiltro(it)
          }),
        }
      }
      return filtro
    })
    setCamposFiltrosPesquisaveis(camposFiltrosPesquisaveisTratados)
  }

  const tratarExcluirCitacaoEExcluirDividasCanceladas = (
    excluirCitacao,
    excluirDividaCancelada,
    campoFiltroTipoDeliberacaoAtualizado = null,
    camposFiltrosPesquisaveisAtualizados = null
  ) => {
    tratarCampoFiltroTipoDeliberacao(excluirCitacao, campoFiltroTipoDeliberacaoAtualizado)
    tratarCamposFiltrosPesquisaveis(
      excluirCitacao,
      excluirDividaCancelada,
      camposFiltrosPesquisaveisAtualizados
    )
  }

  const handleExcluirCanceladas = ({ target: { checked } }) => {
    setExcluirDividaCancelada(checked)
    tratarExcluirCitacaoEExcluirDividasCanceladas(excluirCitacao, checked)
  }

  const handleExcluirCitacoes = ({ target: { checked } }) => {
    setExcluirCitacao(checked)
    tratarExcluirCitacaoEExcluirDividasCanceladas(checked, excluirDividaCancelada)
  }

  const handleApenasDividasComRegistroPagamento = ({ target: { checked } }) => {
    if (checked) {
      setApenasDividasComRegistroPagamento(true)
    } else {
      setApenasDividasComRegistroPagamento(false)
    }
  }

  const handleChangeDataInicio = (novaDataInicio) => {
    setCampoDataInicioEnvioCBEX(novaDataInicio)
  }

  const handleChangeDataFim = (novaDataFim) => {
    setCampoDataFimEnvioCBEX(novaDataFim)
  }

  const onOpcaoFiltroCheckChange = (event, dadosFiltroIndex, opcao) => {
    const copiaDadosFiltro = camposFiltrosPesquisaveis.slice()
    const copiaFiltro = { ...copiaDadosFiltro[dadosFiltroIndex] }
    copiaFiltro.opcoes = copiaFiltro.opcoes.map((it) => {
      if (it.codigo === opcao.codigo) {
        return { ...it, selecionada: !it.selecionada }
      }
      return it
    })
    copiaDadosFiltro.splice(dadosFiltroIndex, 1, copiaFiltro)
    setCamposFiltrosPesquisaveis(copiaDadosFiltro)
  }

  const onOpcaoFiltroCheckChangeTipoDeliberacao = (event, opcao) => {
    const copiaFiltroTipoDeliberacao = { ...campoFiltroTipoDeliberacao }
    copiaFiltroTipoDeliberacao.opcoes = copiaFiltroTipoDeliberacao.opcoes.map((it) => {
      if (it.codigo === opcao.codigo) {
        return { ...it, selecionada: !it.selecionada }
      }
      return it
    })
    setCampoFiltroTipoDeliberacao(copiaFiltroTipoDeliberacao)
  }

  const validarData = (data, nome) => {
    if (data && !moment(data, 'DD/MM/yyyy').isValid()) {
      dispatch(AvisosActions.erro([`${nome} deve ser uma data válida.`]))
      return false
    }
    return true
  }

  const validarPeriodo = (dataInicio, dataFim) => {
    const a = moment(dataInicio, 'DD/MM/YYYY')
    const b = moment(dataFim, 'DD/MM/YYYY')
    if (b - a < 0) {
      dispatch(
        AvisosActions.erro([
          `Data início do envio ao CBEX deve ser anterior à data fim do envio ao CBEX.`,
        ])
      )
      return false
    }
    return true
  }

  const validarPeriodoEnvioCBEX = (dataInicio, dataFim) => {
    if (!validarData(dataInicio, 'Data início do envio ao CBEX')) return false
    if (!validarData(dataFim, 'Data fim do envio ao CBEX')) return false
    if (dataInicio && dataFim && !validarPeriodo(dataInicio, dataFim)) return false
    return true
  }

  const aplicarFiltrosSelecionados = () => {
    if (!validarPeriodoEnvioCBEX(campoDataInicioEnvioCBEX, campoDataFimEnvioCBEX)) return

    toggleDrawerFiltro()

    dispatch(
      PesquisaAvancadaActions.aplicarFiltrosDividasSelecionados({
        data: [
          ...camposFiltrosPesquisaveis,
          campoFiltroAno,
          campoFiltroTipoDeliberacao,
          campoValorMinimoSaldoDevedor
            ? {
                codigo: CODIGO_FILTRO_VALOR_MINIMO_SALDO_DEVEDOR,
                valor: [campoValorMinimoSaldoDevedor],
                nome: 'Valor mínimo saldo devedor',
                opcoes: [campoValorMinimoSaldoDevedor].map((v) => ({
                  descricao: `R$ ${formatarNumeroParaMoeda(v, 2, 2)}`,
                  selecionada: true,
                })),
              }
            : null,
          campoValorMaximoSaldoDevedor
            ? {
                codigo: CODIGO_FILTRO_VALOR_MAXIMO_SALDO_DEVEDOR,
                valor: [campoValorMaximoSaldoDevedor],
                nome: 'Valor máximo saldo devedor',
                opcoes: [campoValorMaximoSaldoDevedor].map((v) => ({
                  descricao: `R$ ${formatarNumeroParaMoeda(v, 2, 2)}`,
                  selecionada: true,
                })),
              }
            : null,
          campoDataInicioEnvioCBEX
            ? {
                codigo: CODIGO_FILTRO_DATA_INICIO_ENVIO_CBEX,
                valor: [campoDataInicioEnvioCBEX],
                nome: 'Data início envio CBEX',
                opcoes: [campoDataInicioEnvioCBEX].map((v) => ({
                  descricao: moment(v)?.format('DD/MM/yyyy'),
                  selecionada: true,
                })),
              }
            : null,
          campoDataFimEnvioCBEX
            ? {
                codigo: CODIGO_FILTRO_DATA_FIM_ENVIO_CBEX,
                valor: [campoDataFimEnvioCBEX],
                nome: 'Data fim envio CBEX',
                opcoes: [campoDataFimEnvioCBEX].map((v) => ({
                  descricao: moment(v)?.format('DD/MM/yyyy'),
                  selecionada: true,
                })),
              }
            : null,
          excluirCitacao ? FILTRO_EXCLUIR_CITACOES : null,
          excluirDividaCancelada ? FILTRO_EXCLUIR_DIVIDAS_CANCELADAS : null,
          apenasDividasComRegistroPagamento ? FILTRO_APENAS_DIVIDAS_COM_REGISTRO_PAGAMENTO : null,
        ].filter((it) => it),
      })
    )
  }

  const limparFiltros = () => {
    const filtrosLimpos = camposFiltrosPesquisaveis.map((filtro) => ({
      ...filtro,
      pesquisaTextual: '',
      opcoes: limparOpcoesFiltro(filtro.opcoes),
    }))
    setCamposFiltrosPesquisaveis(filtrosLimpos)
    setCampoFiltroAno({ ...campoFiltroAno, opcoes: limparOpcoesFiltro(campoFiltroAno.opcoes) })
    setCampoFiltroTipoDeliberacao({
      ...campoFiltroTipoDeliberacao,
      opcoes: limparOpcoesFiltro(campoFiltroTipoDeliberacao.opcoes),
    })
    setCampoValorMaximoSaldoDevedor(null)
    setCampoValorMinimoSaldoDevedor(null)
    setCampoDataInicioEnvioCBEX(null)
    setCampoDataFimEnvioCBEX(null)
    setExcluirCitacao(false)
    setExcluirDividaCancelada(false)
    setApenasDividasComRegistroPagamento(false)
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => aplicarFiltrosSelecionados()}
      PaperProps={{
        sx: { width: { md: '90%', lg: '85%', xl: '80%' } },
      }}>
      <Box sx={classes.gridDrawer}>
        <Box display="flex" alignItems="center" py={1} px={2} gap="8px">
          <Typography variant="h6" color="textPrimary">
            Selecione os filtros necessários e clique em aplicar filtros
          </Typography>
        </Box>

        <Divider />

        <Box p={2}>
          <Typography sx={classes.labelCampo}>Ano de deliberação</Typography>
          <Box display="flex" gap={0.5} sx={classes.boxChipAno}>
            {campoFiltroAno?.opcoes?.map((opcao) => (
              <Chip
                key={opcao.codigo}
                size="small"
                label={opcao.descricao}
                sx={{
                  backgroundColor: opcao.selecionada ? 'primary.main' : 'gray.700',
                  color: opcao.selecionada ? 'common.white' : 'common.black',
                  borderRadius: '2px',
                  height: '20px',
                  fontSize: '12px',
                  '&:hover': { backgroundColor: 'primary.light' },
                }}
                onClick={(event) => handleSelecionarAnoClick(event, opcao)}
              />
            ))}
          </Box>
        </Box>

        <Divider />

        <Box display="flex" paddingX={2} alignItems="center" sx={{ gap: '4px' }}>
          <Typography sx={classes.labelCampoNoMargin}>Tipo de deliberação</Typography>
          <Box display="flex" gap={0.5} sx={classes.boxTipoDeliberacao}>
            {campoFiltroTipoDeliberacao?.opcoes?.map((opcao, index) => (
              <Stack key={index} sx={classes.stackCheckBox} direction="row" alignItems="center">
                <Checkbox
                  size="small"
                  sx={{ padding: '4px' }}
                  checked={opcao.selecionada}
                  disabled={opcao.desabilitada}
                  onChange={(event) => onOpcaoFiltroCheckChangeTipoDeliberacao(event, opcao)}
                />
                {opcao.descricao}
              </Stack>
            ))}
          </Box>

          <Divider orientation="vertical" />

          <Box sx={{ display: 'flex', alignItems: 'center', paddingX: '16px', gap: '8px' }}>
            <Typography sx={classes.labelCampoNoMargin}>Data de envio CBEX</Typography>
            <Box display="flex" sx={{ gap: 2 }}>
              <Box sx={{ width: '155px' }}>
                <DesktopDatePicker
                  id="campoDataInicioEnvioCBEX"
                  size="small"
                  label="Data início"
                  inputFormat="dd/MM/yyyy"
                  value={campoDataInicioEnvioCBEX}
                  onChange={(novaDataInicio) => handleChangeDataInicio(novaDataInicio)}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      inputProps={{ ...params.inputProps, placeholder: 'dd/mm/aaaa' }}
                    />
                  )}
                />
              </Box>
              <Box sx={{ width: '155px', marginRight: '10px' }}>
                <DesktopDatePicker
                  id="campoDataFimEnvioCBEX"
                  size="small"
                  label="Data fim"
                  inputFormat="dd/MM/yyyy"
                  value={campoDataFimEnvioCBEX}
                  onChange={(novaDataFim) => handleChangeDataFim(novaDataFim)}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      inputProps={{ ...params.inputProps, placeholder: 'dd/mm/aaaa' }}
                    />
                  )}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Divider />

        <Box display="flex" paddingX={2} alignItems="center" sx={{ gap: '4px' }}>
          <FormControlLabel
            control={<Switch checked={excluirCitacao} onChange={handleExcluirCitacoes} />}
            label="Excluir citações"
          />
          <FormControlLabel
            control={<Switch checked={excluirDividaCancelada} onChange={handleExcluirCanceladas} />}
            label="Excluir dívidas canceladas"
          />
          <Divider orientation="vertical" />
          <FormControlLabel
            control={
              <Switch
                checked={apenasDividasComRegistroPagamento}
                onChange={handleApenasDividasComRegistroPagamento}
              />
            }
            label="Apenas dívidas com registro de pagamento"
            style={{ marginLeft: 2 }}
          />
          <Divider orientation="vertical" />
          <Typography variant="body1" color="textPrimary" ml={1}>
            Valor do saldo devedor:
          </Typography>
          <MoedaV2
            name="campoValorMinimoSaldoDevedor"
            value={campoValorMinimoSaldoDevedor}
            placeholder="Valor mínimo"
            onChange={(valor) => {
              setCampoValorMinimoSaldoDevedor(valor)
            }}
            maxLength={19}
          />
          <MoedaV2
            name="campoValorMaximoSaldoDevedor"
            value={campoValorMaximoSaldoDevedor}
            placeholder="Valor máximo"
            style={{ marginLeft: '1em' }}
            onChange={(valor) => setCampoValorMaximoSaldoDevedor(valor)}
            maxLength={19}
          />
        </Box>
        <Divider />
        <Box padding={2} sx={classes.boxParametros}>
          {camposFiltrosPesquisaveis?.map((filtro, index) => (
            <Box key={index} height="auto" sx={{ position: 'relative' }}>
              <Box>
                <Typography noWrap sx={classes.labelCampo}>
                  {filtro.codigo !== CODIGO_FILTRO_ORGAO_EXECUTOR
                    ? normalizarString(filtro.nome)
                    : filtro.nome}
                </Typography>
                <TextField
                  variant="outlined"
                  placeholder={
                    filtro.codigo !== CODIGO_FILTRO_TIPO_PROCESSO_ORIGINADOR
                      ? `Filtre pelo nome`
                      : `Filtre pela sigla`
                  }
                  size="small"
                  fullWidth
                  value={filtro.pesquisaTextual}
                  onChange={(event) => onPesquisaTextualChange(event, index)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <Box sx={classes.boxCheck}>
                  {filtrarOpcoes(filtro.opcoes, filtro.pesquisaTextual).map((opcao, i) => (
                    <Stack key={i} sx={classes.stackCheckBox} direction="row" alignItems="center">
                      <Checkbox
                        size="small"
                        sx={{ padding: '4px' }}
                        checked={opcao.selecionada}
                        disabled={opcao.desabilitada}
                        onChange={(event) => onOpcaoFiltroCheckChange(event, index, opcao)}
                      />
                      {opcao.descricao}
                    </Stack>
                  ))}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
        <Divider />
        <Box p={1} display="flex" gap={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => aplicarFiltrosSelecionados()}
            startIcon={<SearchIcon />}>
            Aplicar filtros
          </Button>
          <Button
            onClick={() => limparFiltros()}
            color="secondary"
            startIcon={<FilterAltOffIcon />}>
            Limpar filtros
          </Button>
        </Box>
      </Box>
    </Drawer>
  )
}

export default DrawerFiltros

DrawerFiltros.propTypes = {
  open: PropTypes.bool,
  toggleDrawerFiltro: PropTypes.func,
}

const TIPO_CONTEUDO_PDF = 'application/pdf'
export const TIPO_CONTEUDO_XML = 'application/xml'

const convertBase64ToBlob = (base64, contentType) => {
  const byteCharacters = window.atob(base64)
  const sliceSize = base64.length
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)
    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: contentType })
}

export const gerarDocumento = (
  conteudoArquivoBase64,
  id,
  nomeDocumento = 'download-documento',
  contentType = TIPO_CONTEUDO_PDF
) => {
  const navegador = navigator.userAgent
  const blob = convertBase64ToBlob(conteudoArquivoBase64, contentType)

  if (
    (contentType === TIPO_CONTEUDO_PDF || contentType === TIPO_CONTEUDO_XML) &&
    navegador.match(/firefox|fxios/i)
  ) {
    const url = window.URL.createObjectURL(blob)
    window.open(url, '_blank')
    return
  }
  const link = document.createElement('a')
  link.id = id
  link.href = window.URL.createObjectURL(blob)
  link.download = nomeDocumento
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export default gerarDocumento

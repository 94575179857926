import {
  Box,
  Button,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import PaperOutlined from '../../estilos/PaperOutlined'
import PagamentoHeader from '../detalhar/pagamento/PagamentoHeader'

const RegistrosPagamentosSkeleton = () => (
  <PaperOutlined>
    <Typography variant="h5" gutterBottom>
      Registro de pagamento
    </Typography>
    <Divider />
    <Box mt={1} mb={1} gap={1} display="flex" alignItems="center">
      <Skeleton width={300} />
    </Box>
    <Divider />
    <Box mt={1}>
      <Table sx={{ minWidth: 650 }} size="small">
        <PagamentoHeader />
        <TableBody>
          {Array(1)
            .fill('')
            .map((it, index) => (
              <TableRow key={index} sx={{ 'td,th': { border: 0 } }}>
                <TableCell align="center" sx={{ padding: '0px 15px' }}>
                  <Skeleton width={50} />
                </TableCell>
                <TableCell align="left" sx={{ padding: '0px 15px' }}>
                  <Skeleton width={100} />
                </TableCell>
                <TableCell align="left" sx={{ padding: '0px 15px' }}>
                  <Skeleton width={100} />
                </TableCell>
                <TableCell align="left" sx={{ padding: '0px 15px' }}>
                  <Skeleton width={150} />
                </TableCell>
                <TableCell align="left" sx={{ padding: '0px 15px' }}>
                  <Skeleton width={250} />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button variant="text">Exibir todos os 0 pagamentos</Button>
      </Box>
    </Box>
  </PaperOutlined>
)

export default RegistrosPagamentosSkeleton

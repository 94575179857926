import { Box, Divider, Typography } from '@mui/material'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import PaperOutlined from '../../../estilos/PaperOutlined'
import LinhaDoTempoSkeleton from '../../skeleton/LinhaDoTempoSkeleton'

const LinhaDoTempo = ({ eventosLinhaTempo }) =>
  _.isNil(eventosLinhaTempo) ? (
    <LinhaDoTempoSkeleton />
  ) : (
    <PaperOutlined>
      <Typography variant="h5" gutterBottom>
        Linha do tempo
      </Typography>
      <Divider />
      <Box>
        <Timeline
          position="right"
          sx={{
            paddingLeft: 0,
            '& .MuiTimelineItem-root:before': {
              flex: 0,
            },
          }}>
          {eventosLinhaTempo.length === 0 && (
            <Typography variant="body1" color="textSecondary">
              Não foi possível encontrar marcos importantes da linha do tempo para esta dívida.
            </Typography>
          )}
          {eventosLinhaTempo.map((evento, index) => (
            <TimelineItem key={index}>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                {index !== eventosLinhaTempo.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="subtitle2">
                  {moment(evento.data).format('DD/MM/yyyy')}
                </Typography>
                <Typography variant="body2">
                  {evento.tipo} {!_.isNil(evento.descricao) ? ` - ${evento.descricao}` : ''}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Box>
    </PaperOutlined>
  )

LinhaDoTempo.propTypes = {
  eventosLinhaTempo: PropTypes.array.isRequired,
}

export default LinhaDoTempo

import { Provider } from 'react-redux'
import { useState, useEffect } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { CssBaseline } from '@mui/material'
import { PersistGate } from 'redux-persist/integration/react'
import ptBrDateFns from 'date-fns/locale/pt-BR'
import { ptBR } from '@mui/x-date-pickers/locales'

import AppRoutes from './routes/AppRoutes'
import './interceptors/axiosInterceptor'
import { persistor, reduxStore } from './store/reduxStore'
import lightTheme, { configTheme } from './theme/light'
import darkTheme from './theme/dark'
import ErrorBoundary from './components/comum/ErrorBoundary'

const brasilLocale =
  ptBR.components.MuiLocalizationProvider.defaultProps.localeText

const Root = () => {
  const [isDarkTheme, setIsDarkTheme] = useState(false)

  useEffect(() => {
    const existingPreference = localStorage.getItem("darkState");
    if (existingPreference) {
     if(existingPreference === "light") {
      setIsDarkTheme(false)
     } else {
      setIsDarkTheme(true)
     }
    } else {
      setIsDarkTheme(false);
      localStorage.setItem("darkState", "light");
    }
  }, []);

  const toggleTheme = () => {
    if (!isDarkTheme){
      localStorage.setItem("darkState", "dark");
      setIsDarkTheme(!isDarkTheme)
    } else {
      localStorage.setItem("darkState", "light")
      setIsDarkTheme(!isDarkTheme)
    }
  }

  return (
    <ThemeProvider
      theme={isDarkTheme ? createTheme(darkTheme) : createTheme(configTheme(lightTheme))}>
      <LocalizationProvider dateAdapter={AdapterDateFns}
                            adapterLocale={ptBrDateFns}
                            localeText={brasilLocale}
      >
        <CssBaseline />
        <ErrorBoundary>
          <Provider store={reduxStore}>
            <PersistGate loading={null} persistor={persistor}>
              <AppRoutes isDarkTheme={isDarkTheme} toggleTheme={toggleTheme} />
            </PersistGate>
          </Provider>
        </ErrorBoundary>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default Root

import { Box, Button, IconButton, Skeleton, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ContentBox from 'components/estilos/ContentBox'
import PaperOutlined from 'components/estilos/PaperOutlined'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ResponsaveisSkeleton from './ResponsaveisSkeleton'
import ComposicoesSkeleton from './ComposicoesSkeleton'
import RegistrosPagamentosSkeleton from './RegistrosPagamentosSkeleton'
import LinhaDoTempoSkeleton from './LinhaDoTempoSkeleton'

const DividaDetalhadaSkeleton = () => (
  <ContentBox>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          mb: 2,
        }}>
        <IconButton>
          <ArrowBackIcon />
        </IconButton>
        <Box>
          <Typography variant="h3">
            <Skeleton width={500} />
          </Typography>
          <Typography variant="caption">
            <Skeleton width={300} />
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" mb={2}>
        <Box display="flex" alignItems="center" mr={2}>
          <Typography variant="caption">
            <Skeleton width={200} />
          </Typography>
        </Box>
        <Button endIcon={<KeyboardArrowDownIcon />} sx={{ height: 32 }} variant="contained" size="small">
          Ações
        </Button>
      </Box>
    </Box>
    <Box display="grid" gridTemplateColumns="1fr" gridTemplateRows="120px 90px 1fr" rowGap={3}>
      <Box display="grid" gridTemplateColumns="1fr 2fr" gridTemplateRows="1fr" columnGap={3}>
        <PaperOutlined>
          <Box display="grid" gridTemplateColumns="1fr">
            <Typography variant="subtitle2" gutterBottom sx={{ color: 'grey.500' }}>
              Saldo devedor
            </Typography>
            <Typography variant="h6" gutterBottom>
              <Skeleton width={150} />
            </Typography>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle2" sx={{ color: 'grey.500' }}>
                <Skeleton width={200} />
              </Typography>
              <Skeleton width={50} />
            </Box>
          </Box>
        </PaperOutlined>
        <PaperOutlined>
          <Box display="grid" gridTemplateColumns="1fr 1fr 2fr" columnGap={10}>
            <div>
              <Typography variant="subtitle2" gutterBottom sx={{ color: 'grey.500' }}>
                Processo originador
              </Typography>
              <Typography variant="subtitle2">
                <Skeleton width={130} />
              </Typography>
            </div>
            <div>
              <Typography variant="subtitle2" gutterBottom sx={{ color: 'grey.500' }}>
                Acórdão
              </Typography>
              <Typography variant="subtitle2">
                <Skeleton width={130} />
              </Typography>
            </div>
            <div>
              <Typography variant="subtitle2" gutterBottom sx={{ color: 'grey.500' }}>
                Item de deliberação
              </Typography>
              <Typography variant="subtitle2">
                <Skeleton width={250} />
              </Typography>
            </div>
          </Box>
          <Box mt={2}>
            <Skeleton width={300} />
          </Box>
          <Box mt={2}>
            <Skeleton width={300} />
          </Box>
        </PaperOutlined>
      </Box>
      <PaperOutlined sx={{marginTop: 3}}>
        <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" columnGap={20}>
          <div>
            <Typography variant="subtitle2" gutterBottom sx={{ color: 'grey.500' }}>
              Cofre credor
            </Typography>
            <Typography variant="subtitle2">
              <Skeleton width={130} />
            </Typography>
          </div>
          <div>
            <Typography variant="subtitle2" gutterBottom sx={{ color: 'grey.500' }}>
              Órgão executor
            </Typography>
            <Typography variant="subtitle2">
              <Skeleton width={130} />
            </Typography>
          </div>
          <Box display="grid">
            <Typography variant="subtitle2" gutterBottom sx={{ color: 'grey.500' }}>
              Processo cbex
            </Typography>
            <Typography variant="subtitle2">
              <Skeleton width={130} />
            </Typography>
          </Box>
        </Box>
      </PaperOutlined>
      <ResponsaveisSkeleton />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 2fr',
          gridGap: 20,
        }}>
        <ComposicoesSkeleton />
        <RegistrosPagamentosSkeleton />
      </Box>
      <LinhaDoTempoSkeleton />
    </Box>
  </ContentBox>
)

export default DividaDetalhadaSkeleton

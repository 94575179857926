import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, FormHelperText, TextField, Typography } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import PaperOutlined from 'components/estilos/PaperOutlined'
import ContentBox from 'components/estilos/ContentBox'
import SearchIcon from '@mui/icons-material/Search'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { Actions as AvisosActions } from '../../../../ducks/avisos'
import pagamentoService from '../../../../services/pagamentoService'
import PagamentosPagTesouro from './PagamentosPagTesouro'
import formatarNumeroParaMoeda from '../../../../util/util'
import ProgressBar from '../../../comum/ProgressBar'

const FINALIZADO_PAGO = 'Finalizado pago'

const RelatorioPagTesouro = () => {
  const dispatch = useDispatch()
  const [dataFim, setDataFim] = useState(moment())
  const [relatorioPagamentos, setRelatorioPagamentos] = useState(null)
  const [pagamentos, setPagamentos] = useState([])
  const [pagamentosFiltrados, setPagamentosFiltrados] = useState([])
  const [executandoPesquisa, setExecutandoPesquisa] = useState(false)
  const [seCarregouPagamentos, setSeCarregouPagamentos] = useState(false)
  const [msgErroDataInicio, setMsgErroDataInicio] = useState('')
  const [msgErroDataFim, setMsgErroDataFim] = useState('')
  const [apenasDividasPagas, setApenasDividasPagas] = useState(false)

  const primeiroDiaMesAtual = () => {
    const dataAtual = new Date()
    return moment(new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1))
  }

  const [dataInicio, setDataInicio] = useState(primeiroDiaMesAtual)

  const validarDataInicio = (dataInicio) => {
    const a = moment(dataInicio, 'DD/MM/YYYY')
    const b = moment(dataFim, 'DD/MM/YYYY')
    if (b - a < 0) return 'Data inicial deve ser anterior à data final'
    return ''
  }

  const validarDataFim = (dataFim) => {
    const a = moment(dataFim, 'DD/MM/YYYY')
    const b = moment(dataInicio, 'DD/MM/YYYY')
    if (a - b < 0) return 'Data final deve ser posterior à data inicial'
    return ''
  }

  const handlePesquisarPagamentosPagTesouroPorPeriodo = () => {
    if (!dataInicio || !dataFim) {
      dispatch(AvisosActions.erro(['Periodo da pesquisa deve ser informado.']))
    }

    setExecutandoPesquisa(true)
    try {
      const dataInicioFormatada = moment(dataInicio)?.format('DD/MM/yyyy')
      const dataFimFormatada = moment(dataFim)?.format('DD/MM/yyyy')
      pagamentoService
        .recuperarPagamentosPagTesouroPorPeriodo({
          dataInicio: dataInicioFormatada,
          dataFim: dataFimFormatada,
        })
        .then((response) => {
          if (response.data) {
            setRelatorioPagamentos(response.data)
            setPagamentos(response.data.pagamentos)
            setPagamentosFiltrados(response.data.pagamentos)
          } else {
            setRelatorioPagamentos(null)
            setPagamentos([])
            setPagamentosFiltrados([])
          }
        })
    } finally {
      setExecutandoPesquisa(false)
      setSeCarregouPagamentos(true)
    }
  }

  const handleChangeDataInicio = (novaDataInicio) => {
    setDataInicio(novaDataInicio)
    setMsgErroDataInicio(validarDataInicio(novaDataInicio))
    setSeCarregouPagamentos(false)
    setRelatorioPagamentos(null)
  }

  const handleChangeDataFim = (novaDataFim) => {
    setDataFim(novaDataFim)
    setMsgErroDataFim(validarDataFim(novaDataFim))
    setSeCarregouPagamentos(false)
    setRelatorioPagamentos(null)
  }

  useEffect(() => {
    if (apenasDividasPagas) {
      setPagamentosFiltrados(
        pagamentos.filter((pagamento) => pagamento.situacao === FINALIZADO_PAGO)
      )
    } else {
      setPagamentosFiltrados(pagamentos)
    }
  }, [apenasDividasPagas, pagamentos])

  useEffect(() => {
    handlePesquisarPagamentosPagTesouroPorPeriodo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ContentBox>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Typography variant="h2" component="h1" color="primary">
          Pagamentos de dívidas
        </Typography>
        <Box display="flex" sx={{ widht: '100%', gap: 2 }}>
          <PaperOutlined sx={{ flexGrow: 'auto' }}>
            <Typography variant="body2" color="text.secondary" gutterBottom mb={1.5}>
              Período
            </Typography>
            <Box display="flex" sx={{ width: '100%', gap: 2 }}>
              <Box sx={{ width: '155px' }}>
                <DesktopDatePicker
                  id="dataInicio"
                  size="small"
                  label="Data início"
                  inputFormat="dd/MM/yyyy"
                  value={dataInicio}
                  maxDate={dataFim}
                  onChange={(novaDataInicio) => handleChangeDataInicio(novaDataInicio)}
                  renderInput={(params) => <TextField size="small" {...params} inputProps={{ ...params.inputProps, placeholder: "dd/mm/aaaa" }}/>}
                />
                <FormHelperText style={{ color: 'red' }}>{msgErroDataInicio}</FormHelperText>
              </Box>
              <Box sx={{ width: '155px' }}>
                <DesktopDatePicker
                  id="dataFim"
                  size="small"
                  label="Data final"
                  inputFormat="dd/MM/yyyy"
                  value={dataFim}
                  minDate={dataInicio}
                  onChange={(novaDataFim) => handleChangeDataFim(novaDataFim)}
                  renderInput={(params) => <TextField size="small" {...params} inputProps={{ ...params.inputProps, placeholder: "dd/mm/aaaa" }}/>}
                />
                <FormHelperText style={{ color: 'red' }}>{msgErroDataFim}</FormHelperText>
              </Box>
              <Button
                disabled={executandoPesquisa}
                variant="contained"
                onClick={() => handlePesquisarPagamentosPagTesouroPorPeriodo()}>
                {executandoPesquisa ? <CircularProgress size={25} /> : <SearchIcon />}
              </Button>
            </Box>
          </PaperOutlined>
          {!_.isNil(relatorioPagamentos) && (
            <PaperOutlined sx={{ flexGrow: 2, display: 'flex', alignItems: 'center', gap: 3 }}>
              <Box>
                <Typography variant="body2" sx={{ color: 'grey.500' }}>
                  {relatorioPagamentos?.qtdePagamentoConcluido} pagamento(s) concluído(s)
                </Typography>
                <Typography variant="body2" noWrap>
                  R${' '}
                  {formatarNumeroParaMoeda(relatorioPagamentos?.valorTotalPagamentoConcluido, 2, 2)}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" sx={{ color: 'grey.500' }}>
                  {relatorioPagamentos?.qtdePagamentoCartaoCredito} pagamento(s) via cartão de
                  crédito
                </Typography>
                <Typography variant="body2" noWrap>
                  R${' '}
                  {formatarNumeroParaMoeda(
                    relatorioPagamentos?.valorTotalPagamentoCartaoCredito,
                    2,
                    2
                  )}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" sx={{ color: 'grey.500' }}>
                  {relatorioPagamentos?.qtdePagamentoPix} pagamento(s) via pix
                </Typography>
                <Typography variant="body2" noWrap>
                  R$ {formatarNumeroParaMoeda(relatorioPagamentos?.valorTotalPagamentoPix, 2, 2)}
                </Typography>
              </Box>
            </PaperOutlined>
          )}
        </Box>

        {!_.isNil(relatorioPagamentos) && _.isEmpty(pagamentosFiltrados) && (
          <PaperOutlined sx={{ flexGrow: 2, display: 'flex', alignItems: 'center', padding: 5 }}>
            <Box display="flex" alignItems="center" height="100%">
              <Typography variant="h6">Não há pagamentos para o período informado.</Typography>
            </Box>
          </PaperOutlined>
        )}

        {!_.isNil(relatorioPagamentos) && !_.isEmpty(pagamentosFiltrados) && (
          <PagamentosPagTesouro
            pagamentos={pagamentosFiltrados}
            seCarregouPagamentos={seCarregouPagamentos}
            apenasDividasPagas={apenasDividasPagas}
            setApenasDividasPagas={setApenasDividasPagas}
          />
        )}
      </Box>
      <ProgressBar />
    </ContentBox>
  )
}

export default RelatorioPagTesouro

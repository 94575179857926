import * as React from 'react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, FormControlLabel, Switch } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useSelector } from 'react-redux'
import HeaderList from 'components/estilos/HeaderList'
import ContentBox from 'components/estilos/ContentBox'
import TituloPagina from 'components/estilos/TituloPagina'
import dividaService from '../../services/dividaService'
import { colunasListagem as colunas } from './Divida'
import MensagemResultadoVazio from '../comum/MensagemResultadoVazio'
import { PAGINA_ORIGEM_DETALHAMENTO_DIVIDA } from './ConstantesDivida'

const ListagemDividas = () => {
  const { possuiPerfilFazerComentario } = useSelector((state) => state.authentication)
  const navigate = useNavigate()
  const { state: propsNavegacao } = useLocation()
  const [carregandoDividas, setCarregandoDividas] = useState(true)
  const [semComentarios, setSemComentarios] = useState(false)
  const [dividas, setDividas] = useState([])
  const [totalElementos, setTotalElementos] = useState(0)
  const [tituloListagem, setTituloListagem] = useState('')
  const [paginaAtual, setPaginaAtual] = useState(
    propsNavegacao?.paginaAtual ? propsNavegacao.paginaAtual : 0
  )
  const [tamanhoPagina, setTamanhoPagina] = useState(
    propsNavegacao?.tamanhoPagina ? propsNavegacao.tamanhoPagina : 20
  )
  const [ordem, setOrdem] = useState(propsNavegacao?.ordem ? propsNavegacao.ordem : 'id')

  const handleDetalhar = (divida) => {
    navigate(`/dividas/${divida.id}`, {
      state: {
        ...propsNavegacao,
        paginaAtual,
        tamanhoPagina,
        paginaOrigem: PAGINA_ORIGEM_DETALHAMENTO_DIVIDA.LISTAGEM_DIVIDAS,
        ordem,
      },
    })
  }

  const handleVoltar = () => {
    navigate('/')
  }

  const handleMudarPagina = (novaPagina) => {
    setPaginaAtual(novaPagina)
  }
  const handleSortModelChange = React.useCallback((sortOrder) => {
    setOrdem(sortOrder)
    setPaginaAtual(0)
  }, [])

  const montarTituloListagem = (agrupador, fase, desdobramento) => {
    const complementoFase = fase ? ` - ${fase}` : ''
    const complementoDesdobramento = desdobramento ? ` - ${desdobramento}` : ''
    setTituloListagem(`${agrupador}${complementoFase}${complementoDesdobramento}`)
  }

  const colunasSemOrdenacao = (colunas) =>
    colunas.map((col) => ({
      ...col,
      sortable: col.field === 'valoresAtualizados.valorAtualizadoComPagamento',
    }))

  useEffect(() => {
    if (propsNavegacao?.nivelListagem === undefined) {
      navigate('/')
      return
    }

    const { nivelListagem } = propsNavegacao

    setCarregandoDividas(true)
    dividaService
      .recuperarDividasPorNivel(
        nivelListagem.tipo,
        nivelListagem.codigo,
        paginaAtual,
        tamanhoPagina,
        ordem,
        semComentarios
      )
      .then((response) => {
        montarTituloListagem(
          response.data.agrupador,
          response.data.fase,
          response.data.desdobramento
        )
        setDividas(response.data.resultado.elementos)
        setTotalElementos(response.data.resultado.totalElementos)
      })
      .finally(() => {
        setCarregandoDividas(false)
      })
  }, [propsNavegacao, paginaAtual, tamanhoPagina, navigate, ordem, semComentarios])

  return (
    <ContentBox>
      <Box>
        <TituloPagina onClick={handleVoltar}>Painel</TituloPagina>
        <Box>
          <Box>
            <HeaderList>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: { xs: 'flex-start', sm: 'space-between' },
                  flexDirection: {
                    xs: 'column',
                    sm: 'row',
                  },
                }}>
                <Box>{tituloListagem}</Box>
                {possuiPerfilFazerComentario && (
                  <FormControlLabel
                    sx={{
                      backgroundColor: 'white',
                      color: 'primary.main',
                      borderRadius: 25,
                      padding: '4px 8px',
                    }}
                    control={
                      <Switch
                        size='small'
                        checked={semComentarios}
                        onChange={({ target }) => setSemComentarios(target.checked)}
                      />
                    }
                    label="Apenas sem comentários"
                  />
                )}
              </Box>
            </HeaderList>
          </Box>
          <Box minHeight={500} sx={{ backgroundColor: 'background.paper' }}>
            <DataGrid
              rows={dividas}
              sortingMode="server"
              onSortModelChange={handleSortModelChange}
              disableColumnMenu
              columns={colunasSemOrdenacao(colunas)}
              rowCount={totalElementos}
              autoHeight
              pagination
              paginationMode="server"
              page={paginaAtual}
              pageSize={tamanhoPagina}
              onRowClick={handleDetalhar}
              headerHeight={42}
              rowsPerPageOptions={[20, 50, 100]}
              onPageChange={(novaPagina) => handleMudarPagina(novaPagina)}
              onPageSizeChange={(newPageSize) => setTamanhoPagina(newPageSize)}
              loading={carregandoDividas}
              components={{
                NoRowsOverlay: MensagemResultadoVazio,
              }}
              componentsProps={{
                pagination: { classes: null },
              }}
              sx={{
                borderRadius: '0 0 4px 4px',
                '& .MuiDataGrid-row:hover': {
                  cursor: 'pointer',
                  '& .forwardIconHover ': {
                    visibility: 'visible',
                  },
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  color: 'grey.500',
                  fontWeight: 400,
                },
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '.MuiDataGrid-columnHeader:focus-within': {
                  outline: 'none',
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </ContentBox>
  )
}

export default ListagemDividas

import React from 'react'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
// eslint-disable-next-line import/no-extraneous-dependencies
import HighchartsReact from 'highcharts-react-official'

const GraficoLinhas = ({ titulo, data: { categorias, valores } }) => {
  const options = {
    title: {
      text: titulo,
      align: 'left',
      style: {
        color: '#545454',
        fontSize: '14px',
      },
    },
    xAxis: {
      categories: categorias,
    },
    yAxis: {
      title: {
        text: '',
      },
    },
    series: valores,
    tooltip: {
      valueDecimals: 2,
      valuePrefix: 'R$',
      valueSuffix: '',
      formatter() {
        // eslint-disable-next-line react/no-this-in-sfc
        return `R$ ${Highcharts.numberFormat(this.y, 2, ',', '.')}` // Add 'R$ ' prefix to the tooltip value
      },
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: false,
        },
        enableMouseTracking: true,
      },
    },
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

GraficoLinhas.propTypes = {
  titulo: PropTypes.string.isRequired,
  data: PropTypes.shape({
    categorias: PropTypes.array.isRequired,
    valores: PropTypes.array.isRequired,
  }).isRequired,
}

export default GraficoLinhas

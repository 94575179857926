import axios from 'axios'
import { AUTH_CONFIG_HEADERS } from '../util/constantes'

const urlLoginSiga = (urlDestino, redirectTo) =>
  `bff/publico/auth/url-login-siga?urlDestino=${urlDestino}/sso?redirectTo=${redirectTo}`
const urlLoginPorTokenLoginIntegrado = 'bff/auth/login-por-login-integrado'
const urlLogoff = 'bff/auth/token-jwt/logoff'
const urlDadosUsuarioLogado = 'bff/dados-usuario-logado'
const urlLoginPorRefreshToken = '/bff/auth/token-jwt/por-refresh-token'

const authenticateSigaService = (urlDestino, redirectTo) => {
  const redirect = redirectTo ?? '/'
  const url = urlLoginSiga(urlDestino, redirect)
  return axios.get(url, AUTH_CONFIG_HEADERS)
}

const obterDadosUsuarioLogado = () => axios.get(urlDadosUsuarioLogado)

const loginPorRefreshToken = (refreshToken, userFingerPrint) =>
  axios.post(
    urlLoginPorRefreshToken,
    {
      refreshToken,
      userFingerPrint,
    },
    AUTH_CONFIG_HEADERS
  )

const loginPorTokenLoginIntegrado = (tokenLoginIntegrado) =>
  axios.post(
    urlLoginPorTokenLoginIntegrado,
    {
      tokenLoginIntegrado,
    },
    AUTH_CONFIG_HEADERS
  )

const logoffToken = (tokenJwt, userFingerPrint) =>
  axios.post(
    urlLogoff,
    {
      tokenJwt,
      userFingerPrint,
    },
    AUTH_CONFIG_HEADERS
  )

export default {
  authenticateSigaService,
  loginPorTokenLoginIntegrado,
  logoffToken,
  obterDadosUsuarioLogado,
  loginPorRefreshToken,
}

import { Box, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import PaperOutlined from '../../estilos/PaperOutlined'
import LinkExterno from '../../estilos/LinkExterno'
import processoService from '../../../services/processoService'

const Cbex = ({ cobrancaExecutiva: { dadosCobrancaExecutiva, dadosProcessoOriginador } = {} }) => {
  const { seUsuarioInternoTcu } = useSelector((state) => state.authentication)

  const handleClickLinkProcesso = async (codigoProcesso) => {
    const { data: url } = await processoService.recuperarUrlVisualizacaoProcesso(codigoProcesso)
    window.open(url)
  }

  return (
    <>
      {!dadosCobrancaExecutiva && (
        <PaperOutlined>
          <Box display="flex" alignItems="center" height="100%">
            <Typography variant="h6">Busque um processo CBEX no campo ao lado</Typography>
          </Box>
        </PaperOutlined>
      )}
      {dadosCobrancaExecutiva && (
        <PaperOutlined>
          <Box
            sx={{
              height: '100%',
              display: 'grid',
              alignItems: 'center',
              gridTemplateColumns: { sm: '1fr', md: '1fr 1fr 1fr 2fr' },
              gap: '16px',
              rowGap: '16px',
            }}>
            <Box>
              <Typography variant="body2" sx={{ color: 'grey.500' }}>
                Processo Cbex
              </Typography>
              {seUsuarioInternoTcu && (
                <LinkExterno
                  onClick={() => handleClickLinkProcesso(dadosCobrancaExecutiva.processo.codigo)}>
                  {dadosCobrancaExecutiva.processo.numero}
                </LinkExterno>
              )}
              {!seUsuarioInternoTcu && (
                <Typography>{dadosCobrancaExecutiva.processo.numero}</Typography>
              )}
            </Box>
            <Box>
              <Typography variant="body2" sx={{ color: 'grey.500' }}>
                Processo originador
              </Typography>
              {seUsuarioInternoTcu && (
                <LinkExterno
                  onClick={() => handleClickLinkProcesso(dadosProcessoOriginador.codigo)}>
                  {dadosProcessoOriginador.numero} {dadosProcessoOriginador.tipo}
                </LinkExterno>
              )}
              {!seUsuarioInternoTcu && (
                <Typography>
                  {dadosProcessoOriginador.numero} {dadosProcessoOriginador.tipo}
                </Typography>
              )}
            </Box>
            <Box>
              <Typography variant="body2" gutterBottom sx={{ color: 'grey.500' }}>
                Executor Cbex
              </Typography>
              <Typography variant="body1">{dadosCobrancaExecutiva.executor.nome || '-'}</Typography>
            </Box>
            <Box>
              <Typography variant="body2" gutterBottom sx={{ color: 'grey.500' }}>
                Situação
              </Typography>
              <Typography variant="body1">{dadosCobrancaExecutiva.processo.situacao}</Typography>
            </Box>
          </Box>
        </PaperOutlined>
      )}
    </>
  )
}

Cbex.propTypes = {
  cobrancaExecutiva: PropTypes.object,
}
export default Cbex

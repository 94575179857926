import { Box, Alert, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import ContentBox from '../../estilos/ContentBox'
import BarraPesquisaCbex from './BarraPesquisaCbex'
import PaperOutlined from '../../estilos/PaperOutlined'
import DividasCbex from './DividasCbex'
import Cbex from './Cbex'
import ResumoOperacoesCbex from './ResumoOperacoesCbex'
import cbexServico from '../../../services/cbexService'
import { Actions as AvisosActions } from '../../../ducks/avisos'
import { removerNumeroProcesso } from '../../../util/util'
import ProgressBar from '../../comum/ProgressBar'

const NAO_FAZ_NADA = 'S'
const CANCELADA = 'C'
const INCLUIR = 'U'
const EXCLUIR = 'D'

const AGRUPADOR_CANCELADA = 'CANCELADA'

const AlteracaoVinculoCbex = () => {
  const { state: propsNavegacao } = useLocation()

  const { numeroProcessoCbex = undefined, paginaOrigem = undefined } = propsNavegacao || {}

  const [cobrancaExecutiva, setCobrancaExecutiva] = useState()
  const [executandoPesquisa, setExecutandoPesquisa] = useState(false)
  const [resumoOperacoes, setResumoOperacoes] = useState()
  const [aplicandoOperacoes, setAplicandoOperacoes] = useState(false)
  const [selecionados, setSelecionados] = useState([])
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const encerrado =
    cobrancaExecutiva?.dadosCobrancaExecutiva?.processo?.descricaoEstadoProcesso === 'ENCERRADO'

  const handleCancelar = () => {
    if (paginaOrigem) navigate(-1)
    setCobrancaExecutiva(undefined)
    // setNumeroProcesso(undefined)
  }

  const handleSelecionados = (selecionados) => {
    setSelecionados(selecionados)
  }

  const handleBuscarProcessCbex = async (numeroProcesso) => {
    if (isEmpty(numeroProcesso)) return
    setExecutandoPesquisa(true)
    const numeroProcessoSemFormatacao = removerNumeroProcesso(numeroProcesso)

    try {
      const res = await cbexServico.recuperarCbexPorCodigo(numeroProcessoSemFormatacao)
      const cbex = res.data
      if (cbex && cbex.dadosCobrancaExecutiva) {
        setCobrancaExecutiva(cbex)
        setSelecionados(
          (cbex.dividasAssociadasCbex || [])
            .filter((d) => d.codigoAgrupador !== AGRUPADOR_CANCELADA)
            .map((d) => d.id)
        )
        setResumoOperacoes(undefined)
      } else {
        dispatch(
          AvisosActions.info([
            'O processo informado não é CBEX ou não possui nenhuma dívida associada.',
          ])
        )
      }
    } catch (e) {
      console.error(e)
    } finally {
      setExecutandoPesquisa(false)
    }
  }

  useEffect(() => {
    if (numeroProcessoCbex) handleBuscarProcessCbex(numeroProcessoCbex)
    // eslint-disable-next-line
  }, [numeroProcessoCbex])

  const handleConcluirOperacoes = async () => {
    if (isEmpty(resumoOperacoes)) return

    setAplicandoOperacoes(true)

    const numeroProcessoSemFormatacao = removerNumeroProcesso(
      cobrancaExecutiva.dadosCobrancaExecutiva.processo.numero
    )
    const operacoesSobreDividas = resumoOperacoes
      .map(({ operacaoVinculoCbex, id }) => ({
        operacaoVinculoCbex,
        id,
      }))
      .filter(
        ({ operacaoVinculoCbex }) =>
          operacaoVinculoCbex !== NAO_FAZ_NADA && operacaoVinculoCbex !== CANCELADA
      )
      .map(({ operacaoVinculoCbex, id }) => ({
        id,
        operacaoVinculoCbex: operacaoVinculoCbex === EXCLUIR ? 'DELETE' : 'INSERT',
      }))

    try {
      const resp = await cbexServico.vincularDividasCbex(
        numeroProcessoSemFormatacao,
        operacoesSobreDividas
      )
      dispatch(AvisosActions.sucesso(['Alterações aplicadas com sucesso!']))
      const cbex = resp.data
      setCobrancaExecutiva(cbex)
      setSelecionados(
        (cbex.dividasAssociadasCbex || [])
          .filter((d) => d.codigoAgrupador !== AGRUPADOR_CANCELADA)
          .map((d) => d.id)
      )
      setResumoOperacoes(undefined)
    } catch (e) {
      console.log(e)
    } finally {
      setAplicandoOperacoes(false)
    }
  }
  const definirOperacoesAlteracoesCbex = (
    selecionados,
    { dividasAssociadasCbex, dividasAptasAssociacaoProcessoOriginador }
  ) => {
    if (selecionados.length === 0) {
      dispatch(AvisosActions.info(['Não é permitido remover todos os vínculos.']))
      return
    }
    selecionados.map((id) => dividasAssociadasCbex.find((divida) => divida.id === id))
    const inclusoes = dividasAptasAssociacaoProcessoOriginador
      .filter((d) => selecionados.includes(d.id))
      .filter((d) => dividasAssociadasCbex.findIndex((da) => da.id === d.id) < 0)
      .map((d) => ({ operacaoVinculoCbex: INCLUIR, ...d }))

    const exclusoes = dividasAssociadasCbex
      .filter((d) => !selecionados.includes(d.id))
      .map((divida) => ({
        operacaoVinculoCbex: divida.codigoAgrupador !== AGRUPADOR_CANCELADA ? EXCLUIR : CANCELADA,
        ...divida,
      }))

    const manter = dividasAssociadasCbex
      .filter((d) => selecionados.includes(d.id))
      .map((d) => ({ operacaoVinculoCbex: NAO_FAZ_NADA, ...d }))

    setResumoOperacoes([...inclusoes, ...manter, ...exclusoes])
  }

  // cobrancaExecutiva.dividas
  // cobrancaExecutiva.dividasAssociadas

  return (
    <>
      <ContentBox>
        <Box mb={3}>
          <Typography variant="h2" component="h1" color="primary">
            Alteração de Vínculo CBEX
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '20px',
          }}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { md: '1fr', lg: '1fr 4fr' },
              gap: '16px',
            }}>
            <PaperOutlined>
              <BarraPesquisaCbex
                onSearch={handleBuscarProcessCbex}
                executandoPesquisa={executandoPesquisa}
              />
            </PaperOutlined>
            <Cbex cobrancaExecutiva={cobrancaExecutiva} />
          </Box>
          {encerrado && (
            <PaperOutlined>
              <Box alignItems="center" display="flex">
                <Alert severity="warning">A Cbex esta encerrada, não pode ser alterada.</Alert>
              </Box>
            </PaperOutlined>
          )}

          {cobrancaExecutiva && !resumoOperacoes && (
            <DividasCbex
              cobrancaExecutiva={cobrancaExecutiva}
              onCancelar={handleCancelar}
              selecionados={selecionados}
              encerrado={encerrado}
              setSelecionados={handleSelecionados}
              onConcluir={(selecionados) =>
                definirOperacoesAlteracoesCbex(selecionados, cobrancaExecutiva)
              }
            />
          )}
          {cobrancaExecutiva && resumoOperacoes && !encerrado && (
            <ResumoOperacoesCbex
              aplicandoOperacoes={aplicandoOperacoes}
              resumoOperacoes={resumoOperacoes}
              onVoltar={() => setResumoOperacoes(undefined)}
              onConcluir={handleConcluirOperacoes}
            />
          )}
        </Box>
      </ContentBox>
      <ProgressBar />
    </>
  )
}

export default AlteracaoVinculoCbex

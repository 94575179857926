import { Box, Tooltip, Typography, Divider } from '@mui/material'
import PaperOutlined from 'components/estilos/PaperOutlined'
import PropTypes from 'prop-types'
import ReportIcon from '@mui/icons-material/Report'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import FlagIcon from '@mui/icons-material/Flag'
import React from 'react'

export const TIPO_SEVERIDADE_LEVE = 'LEVE'
export const TIPO_SEVERIDADE_MEDIA = 'MEDIA'
export const TIPO_SEVERIDADE_GRAVE = 'GRAVE'
export const TIPO_SEVERIDADE_GRAVISSIMA = 'GRAVISSIMA'

export function renderIconeAlertaPorTipoSeveridade(
  tipoSeveridadeAlerta,
  handlePopoverOpen = () => {},
  handlePopoverClose = () => {}
) {
  switch (tipoSeveridadeAlerta) {
    case TIPO_SEVERIDADE_LEVE:
      return (
        <FlagIcon
          sx={{ color: 'primary.main', fontSize: 20 }}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
      )
    case TIPO_SEVERIDADE_MEDIA:
      return (
        <ReportProblemIcon
          sx={{ color: 'warning.main', fontSize: 20 }}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
      )
    case TIPO_SEVERIDADE_GRAVE:
      return (
        <ReportIcon
          sx={{ color: 'error.dark', fontSize: 20 }}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
      )
    case TIPO_SEVERIDADE_GRAVISSIMA:
      return (
        <ReportIcon
          sx={{ color: 'error.dark', fontSize: 20 }}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
      )
    default:
      return null
  }
}

const Alertas = ({ alertas }) => (
  <PaperOutlined
    sx={{ display: 'flex', flexDirection: 'column', '& hr:last-child': { display: 'none' } }}>
    {alertas?.map((it, i) => (
      <Box key={i}>
        <Box gap={1} py={1} alignItems="center" display="flex">
          {renderIconeAlertaPorTipoSeveridade(it?.tipoSeveridade)}
          <Typography variant="body1" color="textprimary">
            {it.descricaoAlerta} {it.acao}{' '}
          </Typography>
          {it.textoComplementar && (
            <Tooltip title={it.textoComplementar}>
              <Typography variant="body1" color="primary.light">
                Detalhes
              </Typography>
            </Tooltip>
          )}
        </Box>
        <Divider />
      </Box>
    ))}
  </PaperOutlined>
)

Alertas.propTypes = {
  alertas: PropTypes.array.isRequired,
}

export default Alertas

import { ABA_PAINEL_INICIAL, AGRUPADOR_FASES } from './ConstantesDivida'
import PainelDividasPorFase from './PainelDividasPorFase'

const PainelDebitosEMultasPorFase = () => (
  <PainelDividasPorFase
    abaAssociada={ABA_PAINEL_INICIAL.DEBITOS_MULTAS}
    agrupadorEmAndamento={AGRUPADOR_FASES.EM_ANDAMENTO}
    agrupadorEncerrado={AGRUPADOR_FASES.ENCERRADA}
  />
)

export default PainelDebitosEMultasPorFase

import axios from 'axios'

const recuperarCbexPorCodigo = (numeroProcesso) =>
  axios.get(`/bff/dividas-para-ajuste-vinculo-cbex/processos/${numeroProcesso}`)

const vincularDividasCbex = (numeroProcesso, operacoes) =>
  axios.put(`/bff/alterar-dividas-com-vinculo-cbex/processos/${numeroProcesso}`, operacoes)

export default {
  recuperarCbexPorCodigo,
  vincularDividasCbex,
}

import { Box, Paper, Skeleton, Typography } from '@mui/material'
import TituloPagina from '../../estilos/TituloPagina'

const CartaoDadosProcessoSkeleton = () => (
  <div>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <TituloPagina onClick={() => true}>Processo</TituloPagina>
      <Box display="flex" alignItems="center" mr={2}>
        <Skeleton variant="rectangular" width={201} height={26} />
      </Box>
    </Box>
    <Paper
      variant="outlined"
      elevation={0}
      sx={{
        padding: 2,
        height: '5em',
        marginBottom: '16px',
      }}>
      <Box display="flex" sx={{ gap: 3 }}>
        <Box>
          <Typography variant="subtitle2">
            <Skeleton width={130} />
          </Typography>
          <Typography variant="subtitle2" sx={{ color: 'grey.500' }}>
            <Skeleton width={130} />
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2">
            <Skeleton width={170} />
          </Typography>
          <Typography variant="subtitle2" sx={{ color: 'grey.500' }}>
            <Skeleton width={170} />
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2">
            <Skeleton width={80} />
          </Typography>
          <Typography variant="subtitle2" sx={{ color: 'grey.500' }}>
            <Skeleton width={80} />
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2">
            <Skeleton width={80} />
          </Typography>
          <Typography variant="subtitle2" sx={{ color: 'grey.500' }}>
            <Skeleton width={80} />
          </Typography>
        </Box>
      </Box>
    </Paper>
  </div>
)

export default CartaoDadosProcessoSkeleton

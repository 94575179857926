import React from 'react'
import { Box, Paper, LinearProgress, Typography } from '@mui/material'
import UpdateIcon from '@mui/icons-material/Update'

const LoadingComponent = () => (
  <Box
    sx={{
      position: 'fixed',
      bottom: 16,
      left: 0,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 9999, // Defina um valor alto para que o componente fique sempre acima dos outros elementos
    }}>
    <Paper sx={{ padding: 2, minWidth: 400 }} elevation={2}>
      <LinearProgress color="primary" />
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1, mt: 1 }}>
        <UpdateIcon />
        <Typography>Dívida em processo de atualização</Typography>
      </Box>
    </Paper>
  </Box>
)

export default LoadingComponent

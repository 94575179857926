import { Box, IconButton, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import PropTypes from 'prop-types'
import _ from 'lodash'

const TituloPagina = ({ onClick, children, subtitulo }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'flex-start',
      mb: 2,
    }}>
    {  onClick && (
       <IconButton size="small" onClick={onClick}>
          <ArrowBackIcon />
       </IconButton>)
    }
    <Box>
      <Typography variant="h4" component="h1" gutterBottom onClick={onClick} sx={{ cursor: 'pointer' }}>
        {children}
      </Typography>
      {!_.isNil(subtitulo) && (
        <Typography variant="subtitle2">
          {subtitulo}
        </Typography>
      )}
    </Box>
  </Box>
)

TituloPagina.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  subtitulo: PropTypes.string,
}

export default TituloPagina

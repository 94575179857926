const classes = {
  botaoTipoPesquisa: {
    fontSize: {xs:'11px',sm:'12px'},
    padding: '0px 8px',
    height: '40px',
    width: '110px',
    lineHeight: '15px',
    textAlign: 'right',
    color: 'secondary.light',
    borderRight: '1px solid #DEDEDE',
    borderRadius: 0,
    marginRight: '5px'
  },
  campoPesquisa: {
    m: 1,
    width: {xs:'162px',sm:'25ch'},
    margin: 0,
    '& .MuiInput-root:before': { borderBottom: 0 },
    '& .MuiInput-root:hover:not(.Mui-disabled):before': { borderBottom: 0 },
    '& .MuiInput-root:after': { borderBottom: 0 }
  },
  botaoPesquisa: {
    borderLeft: '1px solid #DEDEDE',
    borderRadius: 0,
    height: '100%',
    padding: 0,
    width: '30px'
  },
  boxTodasDividas: {
    color: '#1C519B',
    margin: '0px 7px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  botaoPesquisaDividas: {
    borderColor: '#DEDEDE'
  },
  desativarMobile: {
    display:{xs:'none',sm:'inherit'}
  }
}

export default classes

import * as React from 'react'
import { Box,  Divider, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import PaperOutlined from '../../estilos/PaperOutlined'
import classes from './CalculadoraDebitoStyle'
import formatarNumeroParaMoeda from '../../../util/util'

const CalculoSaldo = ({
  saldosDebito,
}) => <PaperOutlined sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', minHeight:"120px" }}>
      <Box>
        <Typography variant="h5" gutterBottom>
          Cálculo
        </Typography>
        <Divider />
      </Box>
      {!saldosDebito &&
        <Box display="flex" height="100%" width="100%"  sx={{justifyContent:'center', alignItems:'center'}} >
          <Typography
          variant="body1"
          color="textSecondary"
          align="center"
          sx={{ alignSelf: 'center'}}>
          Clique em Calcular Saldo após inclusão de parcelas
        </Typography>
        </Box>
      }
      {saldosDebito &&
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Box sx={classes.linhaCalculo}>
            <Box width="50%">
              <Typography variant="body2" align="right" color="textSecondary">
                Saldo débito:
              </Typography>
            </Box>
            <Box width="50%">
              <Typography variant="body1" pl={2} color="textPrimary">
                R$ {formatarNumeroParaMoeda(saldosDebito?.saldoDebito, 2, 2)}
              </Typography>
            </Box>
          </Box>
          <Box sx={classes.linhaCalculo}>
            <Box width="50%">
              <Typography variant="body2" align="right" color="textSecondary">
                Saldo Variação Selic:
              </Typography>
            </Box>
            <Box width="50%">
              <Typography variant="body1" pl={2}  color="textPrimary">
                R$ {formatarNumeroParaMoeda(saldosDebito?.saldoVariacaoSelic, 2, 2)}
              </Typography>
            </Box>
          </Box>
          <Box sx={classes.linhaCalculo}>
            <Box width="50%">
              <Typography variant="body2" align="right" color="textSecondary">
                Saldo juros:
              </Typography>
            </Box>
            <Box width="50%">
              <Typography variant="body1" pl={2}  color="textPrimary">
                R$ {formatarNumeroParaMoeda(saldosDebito?.saldoJuros, 2, 2)}
              </Typography>
            </Box>
          </Box>
          <Box sx={classes.linhaCalculoFinal}>
            <Box width="50%">
              <Typography variant="subtitle1" align="right" color="textSecondary">
                Saldo Total:
              </Typography>
            </Box>
            <Box width="50%">
              <Typography variant="h6" pl={2} color="textPrimary">
                R$ {formatarNumeroParaMoeda(saldosDebito?.saldoTotal, 2, 2)}
              </Typography>
            </Box>
          </Box>
        </Box>
      }
    </PaperOutlined>


CalculoSaldo.propTypes = {
  saldosDebito: PropTypes.object,
}

export default CalculoSaldo

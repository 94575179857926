import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { Button, Menu, MenuItem } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { PAGINA_ORIGEM_DETALHAMENTO_DIVIDA } from '../../ConstantesDivida'
import ProgressBar from '../../../comum/ProgressBar'
import dividaService from '../../../../services/dividaService'
import ConfirmacaoAlteracaoFaseModal from './ConfirmacaoAlteracaoFaseModal'
import { Actions as AvisosActions } from '../../../../ducks/avisos'
import PagTesouroModal from '../../pagtesouro/PagTesouroModal'

const MenuAcoes = ({
  divida,
  operacoesPermitidas,
  sePodeVisualizarValorAtualizadoEDemonstrativo,
  callback = () => {},
}) => {
  const dispatch = useDispatch()
  const [modalAlteracaoFaseAberta, setModalAlteracaoFaseAberta] = useState(false)
  const [exibeModalPagTesouro, setExibeModalPagTesouro] = useState(false)
  const [novaFase, setNovaFase] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDownloadDemonstrativoDebitoClick = () => {
    handleClose()
    dividaService.downloadDocumentoDemonstrativo(divida.id)
  }

  const autuacaoProcesso = () => {
    navigate(`/dividas/autuacao`, {
      state: {
        dadosProcessoOriginador: divida.processo,
        dividas: [divida],
        paginaOrigem: PAGINA_ORIGEM_DETALHAMENTO_DIVIDA.DIVIDAS_POR_PROCESSO,
      },
    })
  }
  const alteracaoVinculoCbex = () => {
    navigate(`/alteracao-vinculo-cbex`, {
      state: {
        numeroProcessoCbex: divida.cobrancaExecutiva.processo.numero,
        paginaOrigem: PAGINA_ORIGEM_DETALHAMENTO_DIVIDA.DIVIDAS_POR_PROCESSO,
      },
    })
  }
  const abrirModalConfirmacaoAlteracaoFase = (novaFase) => {
    handleClose()
    setModalAlteracaoFaseAberta(true)
    setNovaFase(novaFase)
  }

  const fecharModalConfirmacaoAlteracaoFase = () => {
    setModalAlteracaoFaseAberta(false)
    setNovaFase(null)
  }

  const abrirModalPagTesouro = () => {
    handleClose()
    setExibeModalPagTesouro(true)
  }

  const fecharModalPagTesouro = () => {
    setExibeModalPagTesouro(false)
  }

  const alterarFase = (codigoFase, motivo) => {
    const comando = { codigoDivida: divida.id, novaFase: codigoFase, motivo }

    dividaService.alterarFaseDivida(divida.id, comando).then(() => {
      dispatch(AvisosActions.sucesso(['Fase da dívida alterada com sucesso.']))
      fecharModalConfirmacaoAlteracaoFase()
      callback()
    })
  }

  return !sePodeVisualizarValorAtualizadoEDemonstrativo ? null : (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'detalhar-menu' : undefined}
        aria-haspopup="true"
        variant="contained"
        size="small"
        endIcon={<KeyboardArrowDownIcon />}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        Ações
      </Button>
      <Menu
        id="detalhar-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'detalhar-menu',
        }}>
        <MenuItem onClick={() => handleDownloadDemonstrativoDebitoClick()}>
          Gerar demonstrativo de débito
        </MenuItem>

        {operacoesPermitidas.sePermitidaExibicaoOperacaoPagTesouro && (
          <MenuItem onClick={() => abrirModalPagTesouro()}>
            Realizar pagamento via PagTesouro
          </MenuItem>
        )}

        {operacoesPermitidas.sePermitidaAlteracaoVinculoCbex && (
          <MenuItem onClick={() => alteracaoVinculoCbex()}>Alterar Vínculos Cbex</MenuItem>
        )}

        {operacoesPermitidas.sePermitidaOperacaoAssociarDividaAProcessoRap && (
          <MenuItem onClick={() => autuacaoProcesso()}>
            Autuar processo de Recolhimento Administrativo Parcelado para essa dívida
          </MenuItem>
        )}

        {operacoesPermitidas.sePermitidaOperacaoSuspenderDividaPorDecisaoJudicial && (
          <MenuItem
            onClick={() =>
              abrirModalConfirmacaoAlteracaoFase({
                codigo: 'SUSPENSA_DECISAO_JUDICIAL',
                label: 'Suspensa por decisão judicial',
              })
            }>
            Alterar fase da dívida para Suspensa por decisão judicial
          </MenuItem>
        )}
        {operacoesPermitidas.sePermitidaOperacaoCancelarDividaPorDecisaoJudicial && (
          <MenuItem
            onClick={() =>
              abrirModalConfirmacaoAlteracaoFase({
                codigo: 'CANCELADA_POR_DECISAO_JUDICIAL',
                label: 'Cancelada por decisão judicial',
              })
            }>
            Alterar fase da dívida para Cancelada por decisão judicial
          </MenuItem>
        )}
        {operacoesPermitidas.sePermitidaOperacaoEncerrarAlteracaoManualFaseDivida && (
          <MenuItem
            onClick={() =>
              abrirModalConfirmacaoAlteracaoFase({
                codigo: 'ENCERRAR_ALTERACAO_MANUAL_FASE',
                label: 'Encerrar alteração manual de fase da dívida',
              })
            }>
            Encerrar alteração manual de fase e restaurar o cálculo automatizado
          </MenuItem>
        )}
      </Menu>
      <ProgressBar />
      <ConfirmacaoAlteracaoFaseModal
        novaFase={novaFase}
        faseAtual={divida.fase}
        onClose={fecharModalConfirmacaoAlteracaoFase}
        open={modalAlteracaoFaseAberta}
        onConfirmar={alterarFase}
      />
      <PagTesouroModal
        id="modalPagTesouro"
        divida={divida}
        open={exibeModalPagTesouro}
        callbackPagamento={callback}
        onClose={fecharModalPagTesouro}
      />
    </div>
  )
}

MenuAcoes.propTypes = {
  divida: PropTypes.object,
  operacoesPermitidas: PropTypes.object,
  sePodeVisualizarValorAtualizadoEDemonstrativo: PropTypes.bool,
  callback: PropTypes.func,
}

export default MenuAcoes

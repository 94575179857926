import * as React from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import classes from './CalculadoraDebitoStyle'

const DadosComplementaresDebito = ({
  dadosComplementaresDebito,
  atualizarDadosComplementaresDebito,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = () => {
    setExpanded(!expanded)
  }

  const handleOnChangeResponsavel = (e) => {
    const novoResponsavel = e.target.value
    const dadosComplementares = { ...dadosComplementaresDebito, responsavel: novoResponsavel }
    atualizarDadosComplementaresDebito(dadosComplementares)
  }

  const handleOnChangeFuncao = (e) => {
    const novaFuncao = e.target.value
    const dadosComplementares = { ...dadosComplementaresDebito, funcao: novaFuncao }
    atualizarDadosComplementaresDebito(dadosComplementares)
  }

  const handleOnChangeOrgao = (e) => {
    const novoOrgao = e.target.value
    const dadosComplementares = { ...dadosComplementaresDebito, orgao: novoOrgao }
    atualizarDadosComplementaresDebito(dadosComplementares)
  }

  const handleOnChangeSiglaOrgao = (e) => {
    const novaSiglaOrgao = e.target.value
    const dadosComplementares = { ...dadosComplementaresDebito, siglaOrgao: novaSiglaOrgao }
    atualizarDadosComplementaresDebito(dadosComplementares)
  }

  const handleOnChangeOrigemDebito = (e) => {
    const novaOrigemDebito = e.target.value
    const dadosComplementares = { ...dadosComplementaresDebito, origemDebito: novaOrigemDebito }
    atualizarDadosComplementaresDebito(dadosComplementares)
  }

  const handleOnChangePrimeiraAssinatura = (e) => {
    const novaPrimeiraAssinatura = e.target.value
    const dadosComplementares = {
      ...dadosComplementaresDebito,
      primeiraAssinatura: novaPrimeiraAssinatura,
    }
    atualizarDadosComplementaresDebito(dadosComplementares)
  }

  const handleOnChangeSegundaAssinatura = (e) => {
    const novaSegundaAssinatura = e.target.value
    const dadosComplementares = {
      ...dadosComplementaresDebito,
      segundaAssinatura: novaSegundaAssinatura,
    }
    atualizarDadosComplementaresDebito(dadosComplementares)
  }

  const handleOnChangeTerceiraAssinatura = (e) => {
    const novaTerceiraAssinatura = e.target.value
    const dadosComplementares = {
      ...dadosComplementaresDebito,
      terceiraAssinatura: novaTerceiraAssinatura,
    }
    atualizarDadosComplementaresDebito(dadosComplementares)
  }

  return (
    <Accordion onChange={handleChange} disableGutters sx={{boxShadow:'none', border: '1px solid rgba(0, 0, 0, .12)', '&::before':{display: 'none'}}}>
      <AccordionSummary>
        <Switch checked={expanded} onChange={handleChange}/>
        <Typography sx={{paddingTop:1, paddingRight:0}}>Informações opcionais para geração de demonstrativo</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={classes.boxGridDadosDoisCampos}>
          <TextField
            label="Responsável"
            id="responsavel"
            size="small"
            InputLabelProps={{ shrink: true }}
            value={dadosComplementaresDebito?.responsavel}
            onChange={handleOnChangeResponsavel}
          />
          <TextField
            label="Função"
            id="funcao"
            size="small"
            InputLabelProps={{ shrink: true }}
            value={dadosComplementaresDebito?.funcao}
            onChange={handleOnChangeFuncao}
          />
        </Box>
        <Box sx={classes.boxGridDadosComplementares}>
          <TextField
            label="Órgão"
            id="orgao"
            size="small"
            InputLabelProps={{ shrink: true }}
            value={dadosComplementaresDebito?.orgao}
            onChange={handleOnChangeOrgao}
          />
          <TextField
            label="Sigla do órgão"
            size="small"
            id="siglaOrgao"
            InputLabelProps={{ shrink: true }}
            value={dadosComplementaresDebito?.siglaOrgao}
            onChange={handleOnChangeSiglaOrgao}
          />
          <TextField
            label="Origem do débito"
            id="origemDebito"
            size="small"
            InputLabelProps={{ shrink: true }}
            value={dadosComplementaresDebito?.origemDebito}
            onChange={handleOnChangeOrigemDebito}
          />
        </Box>
        <Box sx={classes.boxGridDadosComplementares}>
          <TextField
            label="Assinatura 1"
            id="primeiraAssinatura"
            size="small"
            InputLabelProps={{ shrink: true }}
            value={dadosComplementaresDebito?.primeiraAssinatura}
            onChange={handleOnChangePrimeiraAssinatura}
          />
          <TextField
            label="Assinatura 2"
            id="segundaAssinatura"
            size="small"
            InputLabelProps={{ shrink: true }}
            value={dadosComplementaresDebito?.segundaAssinatura}
            onChange={handleOnChangeSegundaAssinatura}
          />
          <TextField
            label="Assinatura 3"
            id="terceiraAssinatura"
            size="small"
            InputLabelProps={{ shrink: true }}
            value={dadosComplementaresDebito?.terceiraAssinatura}
            onChange={handleOnChangeTerceiraAssinatura}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

DadosComplementaresDebito.propTypes = {
  dadosComplementaresDebito: PropTypes.object,
  atualizarDadosComplementaresDebito: PropTypes.func,
}

export default DadosComplementaresDebito

const classes = {
  menuItem: {
    paddingLeft: '24px',
    height: '52px',
    borderLeft: '4px solid rgba( 0, 0, 0, 0)'
  },
  menuItemSelected: {
    paddingLeft: '24px',
    height: '52px',
    borderLeft: '4px solid #1C519B',
    color:  '#1C519B'
  }
}

export default classes
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import ContentBox from 'components/estilos/ContentBox'
import TituloPagina from 'components/estilos/TituloPagina'
import TabelaDebitosEMultasPorAno from './tabelas/TabelaDebitosEMultasPorAno'
import TabelaDebitosEMultasPorAnoETipoProcesso from './tabelas/TabelaDebitosEMultasPorAnoETipoProcesso'
import painelRetrospectivaService from '../../../services/painelRetrospectivaService'
import TabelaRecolhimentosPorAnoETipoDeliberacao from './tabelas/TabelaRecolhimentosPorAnoETipoDeliberacao'
import ProgressBar from '../../comum/ProgressBar'

const PainelRetrospectiva = () => {
  const navigate = useNavigate()
  const [ano] = useState(1899 + new Date().getYear())

  const [tipoProcessoOriginador] = useState()

  const [debitosEMultasPorAno, setDebitosEMultasPorAno] = useState(null)
  const [recolhimentosPorAnoETipoDeliberacao, setRecolhimentosPorAnoETipoDeliberacao] =
    useState(null)
  const [debitosEMultasPorAnoETipoProcesso, setDebitosEMultasPorAnoETipoProcesso] = useState(null)

  const handleVoltar = () => {
    navigate('/')
  }

  const obterDadosRelatorios = () => {
    painelRetrospectivaService.obterRelatoriosRetrospectiva().then((response) => {
      if (response.data) {
        setDebitosEMultasPorAno(response.data.debitosEMultasPorAno)
        setRecolhimentosPorAnoETipoDeliberacao(response.data.recolhimentosPorAnoETipoDeliberacao)
        setDebitosEMultasPorAnoETipoProcesso(response.data.debitosEMultasPorAnoETipoProcesso)
      }
    })
  }

  const renderTabelaDebitosEMultasPorAno = () => (
    <TabelaDebitosEMultasPorAno
      titulo="Histórico de condenações em débito e multa (R$)"
      dados={debitosEMultasPorAno}
    />
  )

  const renderTabelaRecolhimentosPorAnoETipoDeliberacao = () => (
    <TabelaRecolhimentosPorAnoETipoDeliberacao
      titulo="Histórico de recolhimentos administrativos por tipo de deliberação (R$)"
      dados={recolhimentosPorAnoETipoDeliberacao}
    />
  )

  const renderTabelaDebitosEMultasPorAnoETipoProcesso = () => (
    <TabelaDebitosEMultasPorAnoETipoProcesso
      titulo="Histórico de condenações em débito e multa por tipo de processo (R$)"
      dados={debitosEMultasPorAnoETipoProcesso}
    />
  )

  useEffect(
    () => {
      obterDadosRelatorios()
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [ano, tipoProcessoOriginador]
  )

  return (
    <ContentBox>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'baseline',
          gap: '12px',
          '& > strong': { fontWeight: 'bold', color: 'primary.main' },
        }}>
        <TituloPagina onClick={handleVoltar}>
          Retrospectiva de dívidas e de recolhimentos
        </TituloPagina>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <Box sx={{ display: 'flex', gap: '24px' }}>
          {renderTabelaDebitosEMultasPorAno()}
          {renderTabelaRecolhimentosPorAnoETipoDeliberacao()}
        </Box>
        {renderTabelaDebitosEMultasPorAnoETipoProcesso()}
        <ProgressBar />
      </Box>
    </ContentBox>
  )
}

export default PainelRetrospectiva

import { combineReducers } from 'redux'
import authentication from './authentication'
import operationInProgress from './operationInProgress'
import avisos from './avisos'
import nivelListagem from './nivelListagem'
import pesquisaAvancada from './pesquisaAvancada'

const rootReducer = () =>
  combineReducers({
    authentication,
    operationInProgress,
    avisos,
    nivelListagem,
    pesquisaAvancada,
  })

export default rootReducer

import * as React from 'react'
import { Avatar, Box, Divider, IconButton, Link, Popover, Stack, Typography } from '@mui/material'
import AppsIcon from '@mui/icons-material/Apps'
import IconCadreg from '../../img/icon_cadreg.png'
import IconEtcu from '../../img/icon_etcu.png'
import IconInabilidoneos from '../../img/icon_inabilidoneos.png'
import IconPesquisa from '../../img/icon_pesquisa.png'

const MenuLinks = () => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'menu-links' : undefined

  return (
    <div>
      <IconButton color="secondary" aria-describedby={id} variant="contained" onClick={handleClick}>
        <AppsIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" align="center">
            Relacionados
          </Typography>
          <Stack direction="row" justifyContent="center" spacing={2} my={2}>
            <Link
              href="https://pesquisa.apps.tcu.gov.br/#/pesquisa/integrada"
              underline="hover"
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Avatar variant="square" alt="Link da pesquisa de integrada" src={IconPesquisa} />
              Pesquisa
            </Link>
            <Link
              href="https://contas.tcu.gov.br/egestao/Web/MesaTrabalho/MesaGenerica.faces?codFiltro=GESTAO_PROCESSUAL"
              underline="hover"
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 100 }}>
              <Avatar variant="square" alt="Link do E-TCU" src={IconEtcu} />
              E-TCU
            </Link>
          </Stack>
          <Stack direction="row" justifyContent="center" spacing={2} mb={2}>
            <Link
              href="https://www.gov.br/pt-br/servicos/consultar-inclusao-no-cadin-sisbacen-pela-receita-federal"
              underline="hover"
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 100 }}>
              <Avatar
                variant="square"
                alt="Link do sistema da Receita Federal Cadin"
                src={IconInabilidoneos}
              />
              Cadin
            </Link>
            <Link
              href="https://contas.tcu.gov.br/cadirreg/SigaMenuSistema?tkn=v1_E14A656CB7B3EAE5F6DE77F657EDEDECE3F3AB475A0E54BADA0F542D911B183F28BD918449B2A85ADD11F3BAB67FB008EBAD141F4E09B8C059139268AFC873AA9F2D96ECA0A88349630E82538A2F11DD35A8805DE8CC5CA8DC446A1449F3F3BA7A2633AB57F8CBA37D7847A1D148F9711A29E309BE480A4F2B6827B374BD828686EDD5D2395AD58870DAC2D4F9412E0A&codItem=400"
              underline="hover"
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 100 }}>
              <Avatar variant="square" alt="Link do sistema Cadirreg" src={IconCadreg} />
              Cadirreg
            </Link>
            <Link
              href="https://contas.tcu.gov.br/ords/f?p=704144:1:104583385343237:::::"
              underline="hover"
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 100 }}>
              <Avatar
                variant="square"
                alt="Link do sistema Inabilidôneos"
                src={IconInabilidoneos}
              />
              Inabilidôneos
            </Link>
          </Stack>
          <Divider />
          <Stack direction="row" justifyContent="center" spacing={2} my={1}>
            <Link href="https://portal.tcu.gov.br/sistemas-catalogo/" underline="hover">
              Outros sistemas
            </Link>
          </Stack>
        </Box>
      </Popover>
    </div>
  )
}

export default MenuLinks

import { Box, Skeleton } from '@mui/material'
import ContentBox from 'components/estilos/ContentBox'
import { useSelector } from 'react-redux'

const DividasPorFaseSkeleton = () => {
  const usuarioLogadoPossuiPerfilOperacaoDivida = useSelector(
    ({ authentication: { possuiPerfilOperacaoDivida } }) => possuiPerfilOperacaoDivida
  )

  return (
    <ContentBox>
      <Box mt={3}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Skeleton variant="rounded" height={60} width={335} sx={{ marginBottom: '16px' }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
            <Skeleton variant="rounded" height={37} width={170} sx={{ marginBottom: '16px' }} />
            <Skeleton variant="rounded" height={37} width={170} sx={{ marginBottom: '16px' }} />
            {usuarioLogadoPossuiPerfilOperacaoDivida && (
              <Skeleton variant="rounded" height={37} width={170} sx={{ marginBottom: '16px' }} />
            )}
          </Box>
        </Box>
        {Array(10)
          .fill(0)
          .map((it) => (
            <Skeleton key={`id-${it} - ${Math.random()}`} height={60} width="100%" />
          ))}
        <Skeleton height={60} width="100%" sx={{ mt: 3 }} />
      </Box>
    </ContentBox>
  )
}

export default DividasPorFaseSkeleton

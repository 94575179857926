import { useEffect, useState } from 'react'
import { CONTADOR, TEMPO_EXECUCAO } from '../../util/constantes'

const useRelogio = () => {
  const [tempoExecucao, setTempoExecucao] = useState(0)
  const [contador, setContador] = useState(0)
  const [cronometro, setCronometro] = useState(null)

  const formataTempo = (tempo, separator) => {
    const pad = (input) => (input < 10 ? `0${input}` : input)
    return [
      pad(Math.floor(tempo / 3600)),
      pad(Math.floor((tempo % 3600) / 60)),
      pad(Math.floor(tempo % 60)),
    ].join(typeof separator !== 'undefined' ? separator : ':')
  }

  const restart = () => {
    setContador(0)
  }
  const stop = () => {
    setContador(0)
    setTempoExecucao(0)
    clearInterval(cronometro)
  }

  useEffect(() => {
    if (tempoExecucao !== 0) {
      sessionStorage.setItem(CONTADOR, contador)
      sessionStorage.setItem(TEMPO_EXECUCAO, tempoExecucao)
    }
  }, [contador, tempoExecucao])

  useEffect(() => {
    if (tempoExecucao === 0) {
      const contadorSalvo = sessionStorage.getItem(CONTADOR)
      const tempoExecucaoSalvo = sessionStorage.getItem(TEMPO_EXECUCAO)
      // eslint-disable-next-line radix
      setContador(contadorSalvo && !Number.isNaN(contadorSalvo) ? parseInt(contadorSalvo, 0) : 0)
      setTempoExecucao(
        tempoExecucaoSalvo && !Number.isNaN(tempoExecucaoSalvo)
          ? // eslint-disable-next-line radix
            parseInt(tempoExecucaoSalvo, 0)
          : 0
      )
    }
    setCronometro(
      setInterval(() => {
        setContador((prevCount) => prevCount + 1)
        setTempoExecucao((prevCount) => prevCount + 1)
      }, 1000)
    )
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return { contador, tempoExecucao, restart, stop, formataTempo }
}

export default useRelogio

import { useState } from 'react'
import { Box, Button, CircularProgress, InputAdornment, TextField, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import InputMask from 'react-input-mask'
import PropTypes from 'prop-types'

import classes from './BarraPesquisaCbexStyles'

const BarraPesquisaCbex = ({ onSearch = () => {}, executandoPesquisa }) => {
  const [numeroProcesso, setNumeroProcesso] = useState()

  return (
    <>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Buscar Processo CBEX
      </Typography>
      <Box
        display="flex"
        sx={classes.BoxPesquisaCebx }>
        <InputMask
          mask="999.999/9999-9"
          onChange={(event) => {
            setNumeroProcesso(event.target.value)
          }}
          value={numeroProcesso}
          disabled={false}
          maskChar="_">
          {() => (
            <TextField
              variant="standard"
              placeholder="___.___/____-_"
              sx={classes.campoPesquisaCbex}
              InputProps={{
                startAdornment: <InputAdornment position="start">PROCESSO:</InputAdornment>,
              }}
            />
          )}
        </InputMask>
        <Button
          sx={classes.botaoPesquisaCbex}
          disabled={executandoPesquisa}
          variant="contained"
          onClick={() => {
            onSearch(numeroProcesso)
          }}>
          {executandoPesquisa ? <CircularProgress size={25} /> : <SearchIcon />}
        </Button>
      </Box>
    </>
  )
}

BarraPesquisaCbex.propTypes = {
  onSearch: PropTypes.func,
  executandoPesquisa: PropTypes.bool,
}

export default BarraPesquisaCbex

import axios from 'axios'

const urlDocumento = '/bff/documentos'

const recuperarTiposDocumentosAplicaveisAutuacao = () =>
  axios.get(`${urlDocumento}/tipos-aplicaveis-autuacao`)

export default {
  recuperarTiposDocumentosAplicaveisAutuacao,
}

import React from 'react'
import { Paper, MenuItem, MenuList, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { NavLink, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import classes from './MenuLateralStyles'

const menuRouters = [
  {
    label: 'Fases',
    path: '/',
  },
  {
    label: 'Alertas',
    path: '/alertas',
  },
  {
    label: 'Gerar PagTesouro',
    path: '/pagtesouro',
  },
  {
    label: 'Vínculo CBEX',
    path: '/alteracao-vinculo-cbex',
    possuiPerfilAlterarVinculoCbex: true,
  },
  {
    label: 'Calculadora de Débito',
    path: '/calculadora-debito',
  },
]

const menuRelatorios = [
  {
    label: 'Todas as dividas',
    path: '/dividas/pesquisa-avancada',
    state: { limparPesquisa: true },
  },
  {
    label: 'Pagamentos administrativos',
    path: '/recolhimentos/pesquisa-avancada',
    state: { limparPesquisa: true },
  },
  {
    label: 'Pagamentos via PagTesouro',
    path: '/relatorio-pagtesouro',
    possuiPerfilOperacaoDivida: true,
  },
  {
    label: 'Relatórios gerenciais',
    path: '/relatorios',
  },
]
const MenuLateral = ({ open = true, onClose = () => {} }) => {
  const { pathname } = useLocation()
  const { possuiPerfilDesenvolvedor, possuiPerfilOperacaoDivida, possuiPerfilAlterarVinculoCbex } =
    useSelector((state) => state.authentication)

  return (
    <Paper
      sx={{
        width: open ? '240px' : '0px',
        position: 'relative',
        minHeight: '100%',
      }}
      open={open}
      onClose={onClose}>
      <MenuList sx={{ width: '240px', position: 'sticky', top: 72 }}>
        {open &&
          menuRouters
            .filter((i) => !i.possuiPerfilDesenvolvedor || possuiPerfilDesenvolvedor)
            .filter((i) => !i.possuiPerfilOperacaoDivida || possuiPerfilOperacaoDivida)
            .filter((i) => !i.possuiPerfilAlterarVinculoCbex || possuiPerfilAlterarVinculoCbex)
            .map((i, key) => (
              <MenuItem
                key={key}
                component={NavLink}
                to={i.path}
                state={i.state}
                selected={pathname === i.path}
                sx={pathname === i.path ? classes.menuItemSelected : classes.menuItem}>
                <Typography variant="button">{i.label}</Typography>
              </MenuItem>
            ))}
        {open &&
        <MenuItem disabled>
          <Typography variant="button">Relatórios</Typography>
        </MenuItem>
        }
        {open &&
          menuRelatorios
            .filter((i) => !i.possuiPerfilDesenvolvedor || possuiPerfilDesenvolvedor)
            .filter((i) => !i.possuiPerfilOperacaoDivida || possuiPerfilOperacaoDivida)
            .map((i, key) => (
              <MenuItem
                key={key}
                component={NavLink}
                to={i.path}
                state={i.state}
                selected={pathname === i.path}
                sx={pathname === i.path ? classes.menuItemSelected : classes.menuItem}>
                <Typography variant="button">{i.label}</Typography>
              </MenuItem>
            ))}
      </MenuList>
    </Paper>
  )
}

MenuLateral.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

export default MenuLateral

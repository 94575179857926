import * as React from 'react'
import { InputLabel, MenuItem, Select } from '@mui/material'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'

const SelecaoAnoPesquisaRelatorio = ({ valor, onChange, opcoes }) => (
  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
    <InputLabel id="labelAnoPesquisado">Ano</InputLabel>
    <Select
      label="Tipo"
      labelId="labelAnoPesquisado"
      id="select-anoPesquisado"
      value={valor}
      onChange={onChange}>
      {opcoes?.map((opcao, index) => (
        <MenuItem key={index} value={opcao}>
          {opcao}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default SelecaoAnoPesquisaRelatorio

SelecaoAnoPesquisaRelatorio.propTypes = {
  valor: PropTypes.number,
  onChange: PropTypes.func,
  opcoes: PropTypes.array,
}

import { ABA_PAINEL_INICIAL, AGRUPADOR_FASES } from './ConstantesDivida'
import PainelDividasPorFase from './PainelDividasPorFase'

const PainelCitacoesPorFase = () => (
  <PainelDividasPorFase
    abaAssociada={ABA_PAINEL_INICIAL.CITACOES}
    agrupadorEmAndamento={AGRUPADOR_FASES.CITACAO_EM_PROCESSO_ABERTO}
    agrupadorEncerrado={AGRUPADOR_FASES.CITACAO_EM_PROCESSO_ENCERRADO}
  />
)

export default PainelCitacoesPorFase

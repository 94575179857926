import * as React from 'react'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, FormControlLabel, Switch } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import HeaderList from '../../../estilos/HeaderList'
import MensagemResultadoVazio from '../../../comum/MensagemResultadoVazio'
import { colunasPagamentoPagTesouro as colunas } from './ColunasPagamentoPagTesouro'

const PagamentosPagTesouro = ({
  pagamentos,
  seCarregouPagamentos,
  apenasDividasPagas,
  setApenasDividasPagas,
}) => {
  const { state: propsNavegacao } = useLocation()
  const usuarioLogadoPossuiPerfilOperacaoDivida = useSelector(
    ({ authentication: { possuiPerfilOperacaoDivida } }) => possuiPerfilOperacaoDivida
  )
  const [tamanhoPagina, setTamanhoPagina] = useState(
    propsNavegacao?.tamanhoPagina ? propsNavegacao.tamanhoPagina : 20
  )
  const [paginaAtual, setPaginaAtual] = useState(
    propsNavegacao?.paginaAtual ? propsNavegacao.paginaAtual : 0
  )

  const totalElementos = pagamentos ? pagamentos.length : 0
  const titulo = `Foram criados ${totalElementos} pagamentos`

  return (
    <Box sx={{ backgroundColor: 'background.paper' }}>
      {seCarregouPagamentos && (
        <>
          <HeaderList>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: { xs: 'flex-start', sm: 'space-between' },
                flexDirection: {
                  xs: 'column',
                  sm: 'row',
                },
              }}>
              <Box>{titulo}</Box>
              {usuarioLogadoPossuiPerfilOperacaoDivida && (
                <FormControlLabel
                  sx={{
                    backgroundColor: 'white',
                    color: 'primary.main',
                    borderRadius: 25,
                    padding: '4px 8px',
                  }}
                  control={
                    <Switch
                      size="small"
                      checked={apenasDividasPagas}
                      onChange={({ target }) => setApenasDividasPagas(target.checked)}
                    />
                  }
                  label="Mostrar apenas dívidas pagas"
                />
              )}
            </Box>
          </HeaderList>
          <Box sx={{ backgroundColor: 'background.paper' }}>
          <DataGrid
            rows={pagamentos}
            disableColumnMenu
            columns={colunas}
            rowCount={totalElementos}
            autoHeight
            pagination
            page={paginaAtual}
            pageSize={tamanhoPagina}
            disableSelectionOnClick
            headerHeight={42}
            rowsPerPageOptions={[20, 50, 100]}
            onPageChange={(novaPagina) => setPaginaAtual(novaPagina)}
            onPageSizeChange={(newPageSize) => setTamanhoPagina(newPageSize)}
            componentsProps={{
              pagination: { classes: null },
            }}
            components={{
              NoRowsOverlay: MensagemResultadoVazio,
            }}
            sx={{
              borderRadius: '0 0 4px 4px',
              '& .MuiDataGrid-row:hover': {
                cursor: 'pointer',
                '& .forwardIconHover ': {
                  visibility: 'visible',
                },
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                color: 'grey.500',
                fontWeight: 400,
              },
              '& .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '.MuiDataGrid-columnHeader:focus-within': {
                outline: 'none',
              },
            }}
          />
          </Box>
        </>
      )}
    </Box>
  )
}

PagamentosPagTesouro.propTypes = {
  pagamentos: PropTypes.array,
  seCarregouPagamentos: PropTypes.bool,
  apenasDividasPagas: PropTypes.bool,
  setApenasDividasPagas: PropTypes.func,
}

export default PagamentosPagTesouro

import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const HeaderList = ({ children }) => (
  <Box
    sx={{
      color: 'background.paper',
      backgroundColor: 'primary.main',
      borderRadius: '4px 4px 0 0',
      padding: '12px',
    }}>
    <Typography variant="h6">{children}</Typography>
  </Box>
)

HeaderList.propTypes = {
  children: PropTypes.node,
}

export default HeaderList

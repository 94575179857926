import { Box, Button, Divider, Table, TableBody, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PaperOutlined from '../../../estilos/PaperOutlined'
import Pagamento from './Pagamento'
import RegistroPagamentoModal from './RegistroPagamentoModal'
import NenhumResultadoEncontrado from '../../../comum/NenhumResultadoEncontrado'
import PagamentoHeader from './PagamentoHeader'
import LinkExterno from '../../../estilos/LinkExterno'
import processoService from '../../../../services/processoService'

const RegistrosPagamentos = ({
  pagamentos,
  parcelamentoMaisRecente,
  codigoProcessoRap,
  numeroProcessoRap,
}) => {
  const [exibirModalPagamentos, setExibirModalPagamentos] = useState(false)
  const { seUsuarioInternoTcu } = useSelector((state) => state.authentication)

  const [refresh] = useState(false)

  useEffect(() => {}, [refresh])

  const navigate = useNavigate()

  const handleClickLinkProcesso = async (codigoProcesso) => {
    const { data: url } = await processoService.recuperarUrlVisualizacaoProcesso(codigoProcesso)
    window.open(url)
  }

  const handleVisualizarDividasProcesso = (codigoProcesso) => {
    navigate(`/dividas/processo/${codigoProcesso}`)
  }

  return (
    <PaperOutlined sx={{ display: 'flex', flexDirection: 'column', overflowX: 'auto' }}>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <Typography variant="h5" gutterBottom sx={{ width: 'auto' }}>
          Registro de pagamento{' '}
        </Typography>
      </Box>
      <Divider />
      {numeroProcessoRap && (
        <Box display="flex" justifyContent="space-between" mt={1} mb={1}>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="body2" color="text.secondary">
              RAP - Recolhimento Administrativo Parcelado:
            </Typography>
            {seUsuarioInternoTcu && (
              <LinkExterno onClick={() => handleClickLinkProcesso(codigoProcessoRap)}>
                {numeroProcessoRap}
              </LinkExterno>
            )}
            {!seUsuarioInternoTcu && <Typography>{numeroProcessoRap}</Typography>}
          </Box>

          {seUsuarioInternoTcu && (
            <Typography
              variant="button"
              color="primary"
              onClick={() => handleVisualizarDividasProcesso(codigoProcessoRap)}
              style={{ cursor: 'pointer', color: 'primary.light', textTransform: 'uppercase' }}>
              Visualizar dívidas deste processo
            </Typography>
          )}
        </Box>
      )}

      <Divider />
      <Box mt={1} mb={1} gap={1} display="flex" alignItems="center">
        {!parcelamentoMaisRecente ? (
          <Typography variant="body1" color="textSecondary">
            Não há autorização de parcelamento.
          </Typography>
        ) : (
          <>
            <Typography variant="body2" sx={{ color: 'grey.500' }}>
              Parcelamento:
            </Typography>
            <Typography variant="body1">
              autorizado em {parcelamentoMaisRecente.quantidadeParcelas}{' '}
              {parcelamentoMaisRecente.quantidadeParcelas > 1 ? 'parcelas' : 'parcela'} pela
              deliberação {parcelamentoMaisRecente.deliberacao.numeroDeliberacaoFormatado}
            </Typography>
          </>
        )}
      </Box>
      <Divider />
      <Box mt={1} sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        {pagamentos.length === 0 ? (
          <Typography variant="body1" color="textSecondary">
            Nenhum registro de pagamento identificado.
          </Typography>
        ) : (
          <Box flexGrow={1}>
            <Table sx={{ minWidth: '100%' }} size="small">
              <PagamentoHeader />
              <TableBody>
                {pagamentos.length === 0 && <NenhumResultadoEncontrado numeroColSpan={5} />}
                {pagamentos.slice(0, 5).map((pagamento, index) => (
                  <Pagamento key={index} pagamento={pagamento} />
                ))}
              </TableBody>
            </Table>
          </Box>
        )}
        {pagamentos.length > 5 ? (
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button variant="text" onClick={() => setExibirModalPagamentos(true)}>
              Exibir todos os {pagamentos.length} pagamentos
            </Button>
          </Box>
        ) : null}
      </Box>
      {exibirModalPagamentos && (
        <RegistroPagamentoModal
          pagamentos={pagamentos}
          open={exibirModalPagamentos}
          onClose={() => setExibirModalPagamentos(false)}
        />
      )}
    </PaperOutlined>
  )
}

RegistrosPagamentos.propTypes = {
  pagamentos: PropTypes.array,
  parcelamentoMaisRecente: PropTypes.object,
  codigoProcessoRap: PropTypes.number,
  numeroProcessoRap: PropTypes.string,
}

export default RegistrosPagamentos

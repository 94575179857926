import { Box, Button, Tooltip, CircularProgress, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import PropTypes from 'prop-types'
import ArrowUpward from '@mui/icons-material/ArrowUpward'
import ArrowDownward from '@mui/icons-material/ArrowDownward'
import DragHandle from '@mui/icons-material/DragHandle'
import PaperOutlined from '../../estilos/PaperOutlined'
import { colunasListagemCbex as colunas } from '../Divida'
import MensagemResultadoVazio from '../../comum/MensagemResultadoVazio'

export const indicadoresOperacaoCbex = {
  U: {
    sinal: <ArrowUpward sx={{ color: '#3f8600' }} />,
    texto:
      'Esta dívida sofrerá alteração e passará a ter vínculo CBEX após a conclusão desta operação.',
  },
  D: {
    sinal: <ArrowDownward sx={{ color: '#cf1322' }} />,
    texto: 'Esta dívida sofrerá alteração e será removida de CBEX após a conclusão desta operação.',
  },
  C: {
    sinal: <ArrowDownward sx={{ color: '#cf1322' }} />,
    texto: 'Esta dívida está cancelada e será removida da CBEX  após a conclusão desta operação.',
  },
  S: {
    sinal: <DragHandle sx={{ color: 'rgba(0,0,0, .51)' }} />,
    texto: 'Esta dívida não sofrerá alteração alguma e permanecerá vinculada à CBEX.',
  },
}

const renderOperacaoVinculoCbex = ({ row = {} }) => {
  const operador =
    indicadoresOperacaoCbex[row.operacaoVinculoCbex || 'S'] || indicadoresOperacaoCbex.S
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Tooltip placement="right" title={operador.texto}>
        {operador.sinal}
      </Tooltip>
    </Box>
  )
}

const OPERACAO_VINCULO_CBEX = {
  field: 'operacaoVinculoCbex',
  headerName: '',
  width: 30,
  renderCell: renderOperacaoVinculoCbex,
}

const ResumoOperacoesCbex = ({
  aplicandoOperacoes = false,
  resumoOperacoes,
  onVoltar,
  onConcluir,
}) => (
  <>
    <Box sx={{ marginTop: '10px', backgroundColor: 'background.paper' }}>
      <PaperOutlined
        sx={{
          borderBottom: '0 solid rgba( 0,0,0, .0)',
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
        }}>
        <Typography variant="h6" color="textPrimary">
          Resumo da operação
        </Typography>
      </PaperOutlined>
      <DataGrid
        rows={resumoOperacoes}
        disableColumnMenu
        columns={[OPERACAO_VINCULO_CBEX, ...colunas].map((it) => ({ ...it, sortable: false }))}
        autoHeight
        rowSelection={false}
        hideFooter
        headerHeight={42}
        components={{
          NoRowsOverlay: MensagemResultadoVazio,
        }}
        componentsProps={{
          pagination: { classes: null },
        }}
        sx={{
          borderRadius: '0 0 4px 4px',
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer',
            '& .forwardIconHover ': {
              visibility: 'visible',
            },
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            color: 'grey.500',
            fontWeight: 400,
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '.MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
          },
        }}
      />
    </Box>
    <PaperOutlined>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-end',
          gap: '16px',
          rowGap: '16px',
        }}>
        <Button disabled={aplicandoOperacoes} color="primary" onClick={onVoltar}>
          Voltar
        </Button>
        <Button
          disabled={aplicandoOperacoes}
          color="primary"
          variant="contained"
          onClick={onConcluir}>
          Concluir alterações do vínculo {aplicandoOperacoes && <CircularProgress size={25} />}
        </Button>
      </Box>
    </PaperOutlined>
  </>
)

ResumoOperacoesCbex.propTypes = {
  resumoOperacoes: PropTypes.arrayOf(PropTypes.object),
  onVoltar: PropTypes.func,
  onConcluir: PropTypes.func,
  aplicandoOperacoes: PropTypes.bool,
}
export default ResumoOperacoesCbex

export const Types = {
  AUTHENTICATE: '@authenticationActions/AUTHENTICATE',
  USER_AUTHENTICATED: '@authenticationActions/USER_AUTHENTICATED',
  AUTHENTICATE_WITH_REFRESH_TOKEN: '@authenticationActions/AUTHENTICATE_WITH_REFRESH_TOKEN',
  LOGOUT: '@authenticationActions/LOGOUT',
  CLEAR_ALL: '@authenticationActions/CLEAR_ALL',
  AUTHENTICATION_FAILED: '@authenticationActions/AUTHENTICATION_FAILED',
  AUTHENTICATION_REDIRECT: '@authenticationActions/AUTHENTICATION_REDIRECT',
  LOGIN_POR_TOKEN_LOGIN_INTEGRADO: '@authenticationActions/LOGIN_POR_TOKEN_LOGIN_INTEGRADO',
}

export const Actions = {
  authenticateSiga: (urlDestino, redirectTo) => ({
    type: Types.AUTHENTICATE,
    urlDestino,
    redirectTo,
  }),
  userAuthenticated: (payload) => ({ type: Types.USER_AUTHENTICATED, payload }),
  authenticateSigaWithRefreshToken: () => ({ type: Types.AUTHENTICATE_WITH_REFRESH_TOKEN }),
  loginPorTokenLoginIntegrado: (token, redirectTo) => ({
    type: Types.LOGIN_POR_TOKEN_LOGIN_INTEGRADO,
    token,
    redirectTo,
  }),
  authenticationRedirect: (redirectTo) => ({
    type: Types.AUTHENTICATION_REDIRECT,
    redirectTo,
  }),
  logoutUser: () => ({ type: Types.LOGOUT, payload: {} }),
  clearAll: () => ({ type: Types.CLEAR_ALL, payload: {} }),
}

const INITIAL_STATE = {
  isUserAuthenticated: false,
  nomeUsuarioLogado: '',
  cpfUsuarioLogado: '',
  possuiPerfilDesenvolvedor: false,
  possuiPerfilOperacaoDivida: false,
  possuiPerfilAlterarVinculoCbex: false,
  seUsuarioInternoTcu: false,
  possuiPerfilFazerComentario: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.USER_AUTHENTICATED: {
      return {
        isUserAuthenticated: true,
        nomeUsuarioLogado: action.payload.nome,
        cpfUsuarioLogado: action.payload.cpf,
        possuiPerfilDesenvolvedor: action.payload.possuiPerfilDesenvolvedor,
        possuiPerfilOperacaoDivida: action.payload.possuiPerfilOperacaoDivida,
        seUsuarioInternoTcu: action.payload.seUsuarioInternoTcu,
        possuiPerfilAlterarVinculoCbex: action.payload.possuiPerfilAlterarVinculoCbex,
        possuiPerfilFazerComentario: action.payload.possuiPerfilDesenvolvedor,
      }
    }
    default:
      return state
  }
}

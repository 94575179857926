
const classes = {
  labelCampo: {
    fontSize: 12,
    color: 'grey.700',
    textWrap: 'nowrap',
    marginRight: '2px',
  },
  boxChipAno: {
    overflowY: 'auto',
    maxHeight: '120px',
    flexWrap: 'wrap',
  },
  boxTipoDeliberacao: {
    overflowY: 'auto',
    maxHeight: '120px',
    flexWrap: 'wrap',
  },
  boxParametros: {
    display: 'grid',
    gridTemplateColumns: { xs: 'repeat(2, 1fr)', sm: '1fr 1fr 1fr 1fr 160px' },
    gridGap: '9px',
    overflowY: 'hidden',
  },
  gridDrawer: {
    height: '100vh',
    display: 'grid',
    gridTemplateRows: {
      xs: '[titulo] 48px [divider1] 1px [chip-ano] auto [divider1] 1px [chip-ano] auto [divider2] 1px [toggles] 42px [divider3] 1px [box-parametros] 1fr [divider3] 1px [botoes] 52px',
      sm: '[titulo] 48px [divider1] 1px [chip-ano] 64px [divider1] 1px [chip-ano] 90px [divider2] 1px [toggles] 42px [divider3] 1px [box-parametros] auto [divider3] 1px [botoes] 52px',
    },
  },
  boxCheck: {
    overflowY: 'auto',
    position: 'absolute',
    top: '75px',
    bottom: 0,
    left: 0,
    right: 0,
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba( 193, 193, 193, 1)',
    },
  },
  stackCheckBox: {
    fontSize: '12.5px',
    lineHeight: '1.2',
    marginBottom: '2px',
  },
}

export default classes

import { call, put, takeEvery } from 'redux-saga/effects'
import Cookies from 'js-cookie'

import {
  Actions as AuthenticationActions,
  Types as AuthenticationTypes,
} from '../ducks/authentication'
import authenticationService from '../services/authenticationService'
import history from '../store/history'
import { safely } from './util/util'
import { REFRESH_TOKEN_KEY, TOKEN_KEY, USER_FINGER_PRINT_KEY } from '../util/constantes'

export function* authenticateSiga(payload) {
  Cookies.remove(TOKEN_KEY)
  Cookies.remove(USER_FINGER_PRINT_KEY)
  const { data } = yield call(
    authenticationService.authenticateSigaService,
    payload.urlDestino,
    payload.redirectTo
  )
  window.location.assign(data)
}

export function authenticateRedirect(data) {
  if (data && data.payload && data.payload.redirectTo && data.payload.redirectTo !== '') {
    history.push(data.payload.redirectTo)
  } else {
    history.push('/')
  }
}

export function* loginPorTokenLoginIntegrado(action) {
  const TELA_INICIAL_USUARIO_EXTERNO = 'pagtesouro'
  try {
    Cookies.remove(TOKEN_KEY)
    Cookies.remove(USER_FINGER_PRINT_KEY)

    const {
      data: { tokenJwt, refreshToken, userFingerPrint },
    } = yield call(authenticationService.loginPorTokenLoginIntegrado, action.token)

    Cookies.set(TOKEN_KEY, tokenJwt)
    Cookies.set(USER_FINGER_PRINT_KEY, userFingerPrint)
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)

    const { data } = yield call(authenticationService.obterDadosUsuarioLogado)
    yield put(AuthenticationActions.userAuthenticated(data))
    yield put({
      type: AuthenticationTypes.AUTHENTICATION_REDIRECT,
      payload: {
        redirectTo: data.seUsuarioInternoTcu ? action.redirectTo : TELA_INICIAL_USUARIO_EXTERNO,
      },
    })
  } catch (e) {
    yield put({ type: AuthenticationTypes.CLEAR_ALL })
    yield put({ type: AuthenticationTypes.AUTHENTICATION_REDIRECT })
  }
}

export function* authenticateSigaWithRefreshToken() {
  try {
    const refreshTokenAtual = localStorage.getItem(REFRESH_TOKEN_KEY)
    const userFingerPrintAtual = Cookies.get(USER_FINGER_PRINT_KEY)

    if (refreshTokenAtual === undefined || refreshTokenAtual === null) {
      throw new Error('refreshToken não encontrado para montar a requisição')
    }
    if (userFingerPrintAtual === undefined || userFingerPrintAtual === null) {
      throw new Error('userfingerprint não encontrado para montar a requisição')
    }

    const {
      data: { tokenJwt, refreshToken, userFingerPrint },
    } = yield call(
      authenticationService.loginPorRefreshToken,
      refreshTokenAtual,
      userFingerPrintAtual
    )

    Cookies.set(TOKEN_KEY, tokenJwt)
    Cookies.set(USER_FINGER_PRINT_KEY, userFingerPrint)
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
  } catch (e) {
    yield put({ type: AuthenticationTypes.CLEAR_ALL })
    yield put({ type: AuthenticationTypes.LOGOUT })
  }
}

export function* logoutUser() {
  const token = Cookies.get(TOKEN_KEY)
  const userFingerPrint = Cookies.get(USER_FINGER_PRINT_KEY)
  Cookies.remove(TOKEN_KEY)
  Cookies.remove(USER_FINGER_PRINT_KEY)
  localStorage.clear()
  sessionStorage.clear()

  yield put({ type: AuthenticationTypes.CLEAR_ALL })
  if (token && userFingerPrint) {
    yield call(authenticationService.logoffToken, token, userFingerPrint)
  }
  history.push('/login')
}

export function* watchAuthentication() {
  yield takeEvery(AuthenticationTypes.AUTHENTICATE, safely(authenticateSiga))
  yield takeEvery(
    AuthenticationTypes.LOGIN_POR_TOKEN_LOGIN_INTEGRADO,
    safely(loginPorTokenLoginIntegrado)
  )
  yield takeEvery(AuthenticationTypes.AUTHENTICATION_REDIRECT, safely(authenticateRedirect))
  yield takeEvery(AuthenticationTypes.LOGOUT, safely(logoutUser))
  yield takeEvery(
    AuthenticationTypes.AUTHENTICATE_WITH_REFRESH_TOKEN,
    safely(authenticateSigaWithRefreshToken)
  )
}

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Box, Button, Tooltip, Typography } from '@mui/material'
import ContentBox from 'components/estilos/ContentBox'
import TituloPagina from 'components/estilos/TituloPagina'
import PaperOutlined from 'components/estilos/PaperOutlined'
import LinkExterno from 'components/estilos/LinkExterno'
import MessageBarLoading from 'components/comum/MessageBarLoading'
import { useSelector } from 'react-redux'
import classes from './DividaDetalhada.style'
import IconeInformacao from '../../../img/icone_informacao.svg'
import dividaService from '../../../services/dividaService'
import processoService from '../../../services/processoService'
import Responsaveis from './responsavel/Responsaveis'
import DividaDetalhadaSkeleton from '../skeleton/DividaDetalhadaSkeleton'
import formatarNumeroParaMoeda from '../../../util/util'
import Composicoes from './composicao/Composicoes'
import RegistrosPagamentos from './pagamento/RegistrosPagamentos'
import { PAGINA_ORIGEM_DETALHAMENTO_DIVIDA } from '../ConstantesDivida'
import Alertas from './alerta/Alertas'
import LinhaDoTempo from './linhadotempo/LinhaDoTempo'
import MenuAcoes from './menuacoes/MenuAcoes'
import AtualizarDivida from '../../comum/AtualizarDivida'
import ValoresDetalhadosModal from './valores/ValoresDetalhadosModal'
import PopoverFundamentosMulta from './PopoverFundamentosMulta'
import Comentario from '../comentario/Comentario'

const TIPO_DELIBERACAO_APLICACAO_MULTA = 16

const DividaDetalhada = ({ idDivida }) => {
  const navigate = useNavigate()
  const { state: propsNavegacao } = useLocation()
  const { seUsuarioInternoTcu, possuiPerfilFazerComentario } = useSelector(
    (state) => state.authentication
  )

  const [divida, setDivida] = useState(null)
  const [operacoesPermitidas, setOperacoesPermitidas] = useState(null)
  const [exibirModalValoresDetalhados, setExibirModalValoresDetalhados] = useState(false)
  const paginaOrigem = propsNavegacao !== null ? propsNavegacao.paginaOrigem : null

  const complementoDesdobramento = divida?.desdobramento ? `- ${divida.desdobramento}` : ''
  const descricaoFaseCorrente = !_.isNil(divida?.fase)
    ? `${divida?.agrupador} - ${divida?.fase} ${complementoDesdobramento}`
    : ''
  const codigoProcessoOriginador = divida?.codigoProcessoOriginador

  const valorAtualizadoAExibir =
    paginaOrigem !== null &&
    paginaOrigem === PAGINA_ORIGEM_DETALHAMENTO_DIVIDA.DIVIDAS_PARA_PAGAMENTO_POR_PROCESSO
      ? divida?.valoresAtualizados?.valorAtualizadoParaPagamento
      : divida?.valoresAtualizados?.valorAtualizadoComPagamento

  const seValorAtualizadoParaPagamentoNegativo =
    divida?.valoresAtualizados?.valorAtualizadoParaPagamento < 0

  const sePodeVisualizarValorAtualizadoEDemonstrativo =
    !seValorAtualizadoParaPagamentoNegativo || seUsuarioInternoTcu

  const dataParaGeracaoDemonstrativo =
    paginaOrigem &&
    paginaOrigem === PAGINA_ORIGEM_DETALHAMENTO_DIVIDA.DIVIDAS_PARA_PAGAMENTO_POR_PROCESSO
      ? divida?.valoresAtualizados?.dataCalculoSaldoParaPagamento
      : null

  let { id } = useParams()
  if (!id && idDivida) {
    id = idDivida
  }

  const handleVoltar = () => {
    if (paginaOrigem === PAGINA_ORIGEM_DETALHAMENTO_DIVIDA.DIVIDAS_POR_PROCESSO) {
      navigate(`/dividas/processo/${propsNavegacao.idProcesso}`, {
        state: { ...propsNavegacao },
      })
    } else if (paginaOrigem === PAGINA_ORIGEM_DETALHAMENTO_DIVIDA.DIVIDAS_POR_RESPONSAVEL) {
      navigate(`/dividas/responsaveis/${propsNavegacao.idsResponsaveis}`, {
        state: { ...propsNavegacao },
      })
    } else if (paginaOrigem === PAGINA_ORIGEM_DETALHAMENTO_DIVIDA.DIVIDAS_COM_ALERTAS) {
      navigate(`/dividas-com-alertas`, {
        state: { ...propsNavegacao },
      })
    } else if (paginaOrigem === PAGINA_ORIGEM_DETALHAMENTO_DIVIDA.LISTAGEM_DIVIDAS) {
      navigate('/dividas', {
        state: { ...propsNavegacao },
      })
    } else if (paginaOrigem === PAGINA_ORIGEM_DETALHAMENTO_DIVIDA.PESQUISA_AVANCADA_DIVIDAS) {
      navigate(`/dividas/pesquisa-avancada`, {
        state: { ...propsNavegacao },
      })
    } else if (paginaOrigem === PAGINA_ORIGEM_DETALHAMENTO_DIVIDA.PESQUISA_AVANCADA_RECOLHIMENTOS) {
      navigate(`/recolhimentos/pesquisa-avancada`, {
        state: { ...propsNavegacao },
      })
    } else if (
      paginaOrigem === PAGINA_ORIGEM_DETALHAMENTO_DIVIDA.DIVIDAS_PARA_PAGAMENTO_POR_PROCESSO
    ) {
      navigate(`/pagtesouro`, {
        state: { ...propsNavegacao },
      })
    } else {
      navigate(-1)
    }
  }

  const recuperarDividaCallback = useCallback(async () => {
    const { data } = await dividaService.recuperarDividaComOperacoesPermitidas(id)
    setDivida(data.divida)
    setOperacoesPermitidas(data.operacoesPermitidas)

    if (data.divida.seAtualizacaoEmAndamento) {
      setTimeout(recuperarDividaCallback, 10000)
    }
  }, [id])

  useEffect(() => {
    recuperarDividaCallback().then(() => {})
  }, [id, recuperarDividaCallback])

  const handleVisualizarDividasProcesso = () => {
    navigate(`/dividas/processo/${codigoProcessoOriginador}`)
  }

  const handleClickLinkProcesso = async (codigoProcesso) => {
    const { data: url } = await processoService.recuperarUrlVisualizacaoProcesso(codigoProcesso)
    window.open(url)
  }

  const handleAtualizarDivida = async () => {
    setDivida(null)
    setOperacoesPermitidas(null)

    const { data } = await dividaService.atualizarDivida(id)
    setDivida(data.divida)
    setOperacoesPermitidas(data.operacoesPermitidas)
  }

  const renderMotivoMulta = (motivo, index, existeOutros, outrosMotivos) => (
    <Box key={`boxMotivoMulta_${index}`}>
      <Typography variant="body2">
        {existeOutros ? (
          <>
            {motivo.descricaoFundamentolegal} - {motivo.descricaoMotivo}
          </>
        ) : (
          <>
            {motivo.descricaoFundamentolegal} - {motivo.descricaoMotivo}
          </>
        )}
      </Typography>
      <span style={{ flexShrink: 0 }} key={`spanMotivoMulta_${index}`}>
        {existeOutros ? (
          <PopoverFundamentosMulta
            textoPopover="Ver mais"
            motivosMulta={outrosMotivos}
            chave={`popoverMotivoMulta_${index}`}
            key={`popoverMotivoMulta_${index}`}
          />
        ) : (
          ''
        )}
      </span>
    </Box>
  )

  const renderFundamentosMulta = (motivosAplicacaoMulta) => {
    if (!_.isEmpty(motivosAplicacaoMulta)) {
      if (motivosAplicacaoMulta.length === 1) {
        return motivosAplicacaoMulta
          .slice(0, 1)
          .map((motivo, i) => renderMotivoMulta(motivo, i, false))
      }
      if (motivosAplicacaoMulta.length > 1) {
        return motivosAplicacaoMulta
          .slice(0, 1)
          .map((motivo, i) => renderMotivoMulta(motivo, i, true, motivosAplicacaoMulta))
      }
    }
    return ''
  }

  return _.isNil(divida) ? (
    <DividaDetalhadaSkeleton />
  ) : (
    <ContentBox>
      <Box sx={classes.boxTituloDivida}>
        <TituloPagina onClick={handleVoltar} subtitulo={descricaoFaseCorrente}>
          {`Detalhes da ${divida.descricaoTipoDeliberacao}`}
        </TituloPagina>
        <Box sx={classes.boxBotoesSuperiores} mb={2}>
          <Box display="flex" alignItems="center" mr={2}>
            {divida.seAtualizacaoEmAndamento && <MessageBarLoading />}

            <AtualizarDivida
              dataAtualizacao={divida.dataHoraUltimaAtualizacao}
              onClick={handleAtualizarDivida}
            />
          </Box>
          {!idDivida && (
            <MenuAcoes
              divida={divida}
              operacoesPermitidas={operacoesPermitidas}
              sePodeVisualizarValorAtualizadoEDemonstrativo={
                sePodeVisualizarValorAtualizadoEDemonstrativo
              }
              callback={() => recuperarDividaCallback()}
            />
          )}
        </Box>
      </Box>

      <Box sx={classes.gridPagina}>
        <Box sx={classes.cabecalhoPagina}>
          <PaperOutlined>
            <Box display="flex" flexDirection="column" justifyContent="flex-start" height="100%">
              <Box display="flex" alignItems="baseline" gap={0.5}>
                {divida.seIncideJuro ? (
                  <Typography variant="body2" sx={{ color: 'grey.500', marginBottom: '15px' }}>
                    Saldo devedor com incidência de juros
                  </Typography>
                ) : (
                  <Typography variant="body2" sx={{ color: 'grey.500', marginBottom: '15px' }}>
                    Saldo devedor sem incidência de juros
                  </Typography>
                )}
                <Tooltip
                  title="Regra geral: há incidência de juros no cálculo do valor atualizado de débitos e não há no cálculo do valor atualizado de multas. A regra geral pode ser alterada para dívidas em específico por decisão do colegiado."
                  sx={{ color: 'grey.500' }}>
                  <img
                    src={IconeInformacao}
                    style={{ width: '11px', height: 'auto' }}
                    alt="Ícone de informação"
                  />
                </Tooltip>
              </Box>

              {!sePodeVisualizarValorAtualizadoEDemonstrativo && (
                <Typography variant="h3" sx={{ marginBottom: '15px' }}>
                  R$ ---
                </Typography>
              )}

              {sePodeVisualizarValorAtualizadoEDemonstrativo && (
                <>
                  <Typography variant="h3" sx={{ marginBottom: '15px' }}>
                    {`R$ ${formatarNumeroParaMoeda(valorAtualizadoAExibir, 2, 2)}`}
                  </Typography>
                  {divida.valoresAtualizados.dataHoraAtualizacaoValores && (
                    <Box display="flex" justifyContent="space-between" alignItems="flex-end">
                      <Typography variant="body2" sx={{ color: 'grey.500' }}>
                        Atualizado em{' '}
                        {moment(divida.valoresAtualizados.dataHoraAtualizacaoValores).format(
                          'DD/MM/yyyy HH:mm'
                        )}
                      </Typography>
                      <Button
                        onClick={() => setExibirModalValoresDetalhados(true)}
                        size="small"
                        rel="noopener noreferrer"
                        color="primary"
                        sx={classes.textoDestaque}>
                        <Typography variant="body2">Detalhes</Typography>
                      </Button>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </PaperOutlined>
          <PaperOutlined>
            <Box sx={classes.gridDetalhes}>
              <Box sx={{ gridArea: 'originador' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="body2" sx={{ color: 'grey.500' }}>
                    Processo originador:
                  </Typography>
                  {seUsuarioInternoTcu && (
                    <LinkExterno onClick={() => handleClickLinkProcesso(divida.processo.codigo)}>
                      {`${divida.processo.numero} ${divida.processo.tipo||''}`}
                    </LinkExterno>
                  )}
                  {!seUsuarioInternoTcu && (
                    <Typography>
                      {divida.processo.numero} {divida.processo.tipo||''}
                    </Typography>
                  )}
                </Box>
              </Box>
              {seUsuarioInternoTcu && (
                <Box sx={{ gridArea: 'linkDividas' }}>
                  {!idDivida && (
                    <Button
                      sx={{ padding: '0', textTransform: 'uppercase' }}
                      color="primary"
                      variant="text"
                      size="small"
                      onClick={() => handleVisualizarDividasProcesso()}>
                      {' '}
                      Dívidas desse processo
                    </Button>
                  )}
                </Box>
              )}
              <Box sx={{ gridArea: 'deliberacao' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="body2" sx={{ color: 'grey.500' }}>
                    Deliberação:
                  </Typography>
                  <Typography variant="body2" noWrap>
                    {divida.deliberacaoOriginadoraDivida?.nomeDeliberacao}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ gridArea: 'dataDeliberacao' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="body2" sx={{ color: 'grey.500' }}>
                    Data da deliberação:
                  </Typography>
                  <Typography variant="body2" noWrap>
                    {moment(divida.deliberacaoOriginadoraDivida?.dataDeliberacao).format(
                      'DD/MM/yyyy'
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ gridArea: 'itemDeliberacao' }}>
                <Box>
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{ color: 'grey.500', marginRight: '8px' }}>
                    Item de deliberação:
                  </Typography>
                  <Typography variant="body2" component="span">
                    {divida.deliberacaoOriginadoraDivida?.itemDeliberacao}{' '}
                    {divida.deliberacaoOriginadoraDivida?.descricaoDeliberacao}
                  </Typography>
                </Box>
              </Box>
              {divida.codigoTipoDeliberacao === TIPO_DELIBERACAO_APLICACAO_MULTA && (
                <Box sx={{ gridArea: 'fundamentoMulta', minWidth: 0 }}>
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{ color: 'grey.500', float: 'left', marginRight: '8px' }}>
                      Fundamento da multa:
                    </Typography>
                    {renderFundamentosMulta(divida.motivosAplicacaoMulta)}
                  </Box>
                </Box>
              )}
            </Box>
          </PaperOutlined>
        </Box>
        {seUsuarioInternoTcu && divida.alertas?.length > 0 && <Alertas alertas={divida.alertas} />}
        {possuiPerfilFazerComentario && (
          <PaperOutlined>
            <Comentario
              comentariosDaDivida={divida.comentarios}
              emDetalheDaDivida
              codigoDivida={divida.id}
              setComentarios={(comentarios) => setDivida({ ...divida, comentarios })}
            />
          </PaperOutlined>
        )}
        <PaperOutlined>
          <Box sx={classes.gridDadosOrgaos}>
            <div>
              <Typography variant="body2" gutterBottom sx={{ color: 'grey.500' }}>
                Cofre credor
              </Typography>
              <Typography variant="body1">{divida.cofreCredor?.nome || '-'}</Typography>
            </div>
            <div>
              <Typography variant="body2" gutterBottom sx={{ color: 'grey.500' }}>
                Órgão executor
              </Typography>
              <Box display="flex" alignItems="baseline" gap="5px">
                <Typography variant="body1">
                  {divida.dadosConsolidados?.executorCbex?.nome || '-'}
                </Typography>
                {divida?.cobrancaExecutiva?.descricaoMotivoIndicacaoExecutor && (
                  <Tooltip
                    title={divida.cobrancaExecutiva?.descricaoMotivoIndicacaoExecutor}
                    color="primary">
                    <img
                      src={IconeInformacao}
                      style={{ width: '11px', height: 'auto' }}
                      alt="Ícone de informação"
                    />
                  </Tooltip>
                )}
              </Box>
            </div>
            <Box>
              <Typography variant="body2" gutterBottom sx={{ color: 'grey.500' }}>
                Processo cbex
              </Typography>
              {_.isNil(divida.cobrancaExecutiva?.processo?.numero) && '-'}
              {!_.isNil(divida.cobrancaExecutiva?.processo?.numero) && seUsuarioInternoTcu && (
                <LinkExterno
                  onClick={() =>
                    handleClickLinkProcesso(divida.cobrancaExecutiva?.processo.codigo)
                  }>
                  {divida.cobrancaExecutiva.processo.numero}
                </LinkExterno>
              )}
              {!_.isNil(divida.cobrancaExecutiva?.processo?.numero) && !seUsuarioInternoTcu && (
                <Typography>{divida.cobrancaExecutiva.processo.numero}</Typography>
              )}
            </Box>
          </Box>
        </PaperOutlined>
        <Responsaveis responsaveis={divida.responsaveis} />
        <Box sx={classes.gridComposicaoDivida}>
          <Composicoes composicoes={divida.composicoes || []} />
          <RegistrosPagamentos
            pagamentos={divida.pagamentos || []}
            parcelamentoMaisRecente={divida.parcelamentoMaisRecente}
            codigoProcessoRap={divida.processoRap?.codigo}
            numeroProcessoRap={divida.processoRap?.numero}
          />
        </Box>
        <LinhaDoTempo eventosLinhaTempo={divida.eventosLinhaTempo} />
      </Box>
      {exibirModalValoresDetalhados && (
        <ValoresDetalhadosModal
          divida={divida}
          valorAtualizadoAExibir={valorAtualizadoAExibir}
          dataParaGeracaoDemonstrativo={dataParaGeracaoDemonstrativo}
          onClose={() => setExibirModalValoresDetalhados(false)}
          open={exibirModalValoresDetalhados}
        />
      )}
    </ContentBox>
  )
}

DividaDetalhada.propTypes = {
  idDivida: PropTypes.number,
}

export default DividaDetalhada

import React from 'react';
import PropTypes from 'prop-types';
import { grey } from '@mui/material/colors'

const Falecido = ({ falecido = false }) => {
  if (!falecido) return undefined
  return <span style={{background:'rgba(0,0,0,.10)', padding:'1px 4px', margin: '0 2px', borderRadius: 2, fontSize: 12.5, fontWeight: 600, color: grey[800], verticalAlign: 'baseline'}}>Falecido(a)</span>
}

Falecido.propTypes = {
  falecido: PropTypes.bool
};

export default Falecido;
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Box, Drawer, Divider, IconButton, Button, Typography, Popover } from '@mui/material'
import CommentIcon from '@mui/icons-material/Comment'
import CloseIcon from '@mui/icons-material/Close'
import { startCase, toLower } from 'lodash'
import moment from 'moment/moment'

import Comentarios from './Comentarios'
import PaperOutlined from '../../estilos/PaperOutlined'

const Comentar = ({ comentario }) => {
  if (!comentario) return undefined

  return (
    <Box>
      {comentario.texto.split('\n').map((linha, key) => (
        <Typography variant="body1" key={key}>
          {linha}
        </Typography>
      ))}
      <Typography variant="body2" gutterBottom sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
        {startCase(toLower(comentario.nomeUsuarioCadastrador))} em{' '}
        {moment(comentario.dataHoraCadastramento).format('DD/MM/yyyy, kk:mm')}
      </Typography>
    </Box>
  )
}

const Comentario = ({ comentariosDaDivida = [], emLista = false, codigoDivida }) => {
  const { possuiPerfilFazerComentario } = useSelector((state) => state.authentication)

  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [comentarios, setComentarios] = React.useState(comentariosDaDivida)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  if (!possuiPerfilFazerComentario) return <Box />

  const comComentario = comentarios.length > 0
  const ultimoComentario = comComentario ? comentarios[0] : undefined
  const popOverOpen = Boolean(ultimoComentario) && Boolean(anchorEl)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const renderIcon = () => {
    if (emLista) {
      return comComentario && <CommentIcon size="small" color="primary" />
    }
    if (comComentario && !emLista) {
      return 'VISUALIZAR OU INCLUIR COMENTÁRIOS'
    }
    return 'INCLUIR COMENTÁRIO'
  }

  return (
    <Box>
      {!emLista && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <CommentIcon color="primary" />
            {!comComentario && (
              <Typography variant="body1">Não há comentários cadastrados ainda</Typography>
            )}
            {comComentario && <Comentar comentario={ultimoComentario} />}
          </Box>
          <Box>
            <Button color="primary" onClick={handleDrawerOpen}>
              <nobr>{renderIcon()}</nobr>
            </Button>
          </Box>
        </Box>
      )}

      {emLista && comComentario && (
        <>
          <IconButton
            color="primary"
            onClick={handleDrawerOpen}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}>
            {renderIcon()}
          </IconButton>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
            }}
            open={popOverOpen}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus>
            <PaperOutlined
              sx={{
                display: 'flex',
                flexDirection: 'column',
                '& hr:last-child': { display: 'none' },
                width: '50vh',
              }}>
              <Comentar comentario={ultimoComentario} />
            </PaperOutlined>
          </Popover>
        </>
      )}
      <Drawer anchor="right" open={open} onClose={handleDrawerClose}>
        <Box
          display="flex"
          flexDirection="column"
          sx={{ width: 400, p: 2, height: '100%', overflowY: 'hidden' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h5">Comentários</Typography>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider sx={{ my: 1 }} />
          <Comentarios
            lista={comentarios}
            codigoDivida={codigoDivida}
            setComentarios={setComentarios}
            emLista={emLista}
          />
        </Box>
      </Drawer>
    </Box>
  )
}

Comentar.propTypes = {
  comentario: PropTypes.object,
}

Comentario.propTypes = {
  comentariosDaDivida: PropTypes.array,
  emLista: PropTypes.bool,
  codigoDivida: PropTypes.number,
}

export default Comentario

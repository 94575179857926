import { createTheme } from '@mui/material/styles'
import { ptBR } from '@mui/x-data-grid'

const fontHX = '"Libre Franklin"';
const branco = '#fff';
export const configTheme = () =>
  createTheme(
    {
      mode: "light",
      palette: {
        primary: {
          main: "#1C519B",
          light: "#5F98FF",
          dark: "#2A4070",
        },
        secondary: {
          main: "#2D2D2E",
          light: "#545454",
          dark: "#060608",
        },
        error: {
          main: "#EF8383",
          light: "#FFE4E4",
          dark: "#AB1111",
        },
        warning: {
          main: "#FFC700",
          light: "#F9ED7F",
          dark: "#814D04",
        },
        info: {
          main: "#1C519B",
          light: "#5F98FF",
          dark: "#2A4070",
        },
        success: {
          main: "#73DEAA",
          light: "#CDFFF0",
          dark: "#005F5B",
        },
        grey: {
          50: "#fafafa",
          100: "#f5f5f5",
          200: "#eeeeee",
          300: "#e0e0e0",
          400: "#bdbdbd",
          500: "#9e9e9e",
          600: "#757575",
          700: "#616161",
          800: "#424242",
          900: "#212121",
          A100: "#f5f5f5",
          A200: "#eeeeee",
          A400: "#bdbdbd",
          A700: "#616161",
        },
        tonalOffset: 0.2,
        ptBR,
        text: {
          primary: "rgba(0, 0, 0, 0.87)",
          secondary: "rgba(0, 0, 0, 0.6)",
          disabled: "rgba(0, 0, 0, 0.38)",
        },
        common: {
          black: "#000",
          white: branco,
        },
        contrastThreshold: 3,
        divider: "rgba(0, 0, 0, 0.12)",
        background: {
          paper: "#fff",
          default: "#F5F5F5",
        },
        action: {
          active: "rgba(0, 0, 0, 0.54)",
          hover: " rgba(124, 179, 255, 0.1)",
          selected: "rgba(0, 0, 0, 0.08)",
          selectedOpacity: 0.08,
          disabled: "rgba(0, 0, 0, 0.26)",
          disabledBackground: "rgba(0, 0, 0, 0.12)",
          disabledOpacity: 0.38,
          focus: "rgba(0, 0, 0, 0.12)",
          focusOpacity: 0.12,
          activatedOpacity: 0.12,
        },
      },
      typography: {
        fontFamily: ['"Roboto"', 'sans-serif'].join(','),
        htmlFontSize: 16,
        fontSize: 14,
        h1: {
          fontFamily: `${fontHX}, sans-serif`,
          fontWeight: 300,
          fontSize: "2rem",
          lineHeight: '3.5rem',
          letterSpacing: "-0.01562em",
        },
        h2: {
          fontFamily: `${fontHX}, sans-serif`,
          fontWeight: 300,
          fontSize: "1.715rem",
          lineHeight: '2.43rem',
          letterSpacing: "-0.00833em",
        },
        h3: {
          fontFamily: `${fontHX}, sans-serif`,
          fontWeight: 400,
          fontSize: "1.43rem",
          lineHeight: '2rem',
          letterSpacing: "0em",
        },

        h4: {
          fontFamily: `${fontHX}, sans-serif`,
          fontWeight: 400,
          fontSize: "1.285rem",
          lineHeight: '1.785',
          letterSpacing: "0.00735em",
        },

        h5: {
          fontFamily: `${fontHX}, sans-serif`,
          fontWeight: 400,
          fontSize: "1.145rem",
          lineHeight: "1.57rem",
          letterSpacing: "0em",
        },

        h6: {
          fontWeight: 500,
          fontSize: "1rem",
          lineHeight: "1.43",
          letterSpacing: "0.0075em",
        },

        body1: {
          fontSize: "0.9rem"
        },

        body2: {
          fontSize: "0.875rem"
        },

        button: {
          textTransform: 'initial'
        },
      },
      components: {
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              fontWeight: 400,
              fontSize: '0.8rem',
              backgroundColor: branco,
              color: '#424242',
              border: '1px solid #bdbdbd'
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            sizeSmall: {
              fontSize: "0.875rem"
            },
          },
        }
      },
    },
    ptBR
  )

const lightTheme = (theme) => ({
  root: theme.typography.body1,
})

export default lightTheme

import * as React from 'react'
import { Box, IconButton, Divider, Popover, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { renderIconeAlertaPorTipoSeveridade } from './Alertas'
import PaperOutlined from '../../../estilos/PaperOutlined'

const IconPopoverAlertas = ({ alertas }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [anchorElDescription, setAnchorDescription] = React.useState(false)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const descriptionPopover = () => {
    setAnchorDescription(!anchorElDescription)
  }

  const open = Boolean(anchorEl)

  return (
    <div>
      <IconButton onClick={descriptionPopover}>
        {renderIconeAlertaPorTipoSeveridade(
          alertas[0].tipoSeveridade,
          handlePopoverOpen,
          handlePopoverClose
        )}
        </IconButton>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        <PaperOutlined
          sx={{ display: 'flex', flexDirection: 'column', '& hr:last-child': { display: 'none' }, width:'50vh'}}>
          {alertas?.map((it, index) => (
            <Box key={`alerta-${index}`}>
              <Box gap={1} py={1} display="flex" flexDirection="column">
                <Box alignItems="center" display="flex" gap={1}>
                  {renderIconeAlertaPorTipoSeveridade(it?.tipoSeveridade)}
                  <Typography variant="subtitle2" color="textprimary">
                    {it.nomeAlerta}
                  </Typography>
                </Box>
                <Typography variant="body2" color="textprimary" sx={{marginLeft: '28px'}}>
                  {it.textoComplementar}
                </Typography>
              </Box>
              <Divider />
            </Box>
          ))}
        </PaperOutlined>
      </Popover>
    </div>
  )
}

IconPopoverAlertas.propTypes = {
  alertas: PropTypes.array.isRequired,
}

export default IconPopoverAlertas

import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

const AuthenticatedRoute = ({ Componente }) => {
  const { isUserAuthenticated } = useSelector((state) => state.authentication)

  if (isUserAuthenticated) {
    return <Componente />
  }
  return <Navigate to="/login" />
}

AuthenticatedRoute.propTypes = {
  Componente: PropTypes.func,
}

export default AuthenticatedRoute

import * as React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Tab, Card } from '@mui/material'
import TituloPagina from 'components/estilos/TituloPagina'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import ContentBox from '../../estilos/ContentBox'
import TabelaDebitosEMultasPorAno from './tabelas/TabelaDebitosEMultasPorAno'
import TabelaDebitosEMultasPorAnoETipoProcesso from './tabelas/TabelaDebitosEMultasPorAnoETipoProcesso'
import painelRetrospectivaService from '../../../services/painelRetrospectivaService'
import TabelaRecolhimentosPorAnoETipoDeliberacao from './tabelas/TabelaRecolhimentosPorAnoETipoDeliberacao'
import ProgressBar from '../../comum/ProgressBar'
import TabelaRecolhimentosTotaisPorAno from './tabelas/TabelaRecolhimentosTotaisPorAno'
import TabelaArrecadacaoPorOrgaoExecutor from './tabelas/TabelaArrecadacaoPorOrgaoExecutor'
import TabelaDividasEnviadasCbexPorOrgaoExecutor from './tabelas/TabelaDividasEnviadasCbexPorOrgaoExecutor'
import TabelaDebitosImputadosPorOrgao from './tabelas/TabelaDebitosImputadosPorOrgao'
import SelecaoAnoPesquisaRelatorio from './SelecaoAnoPesquisaRelatorio'

const Relatorios = () => {
  const navigate = useNavigate()

  const [debitosEMultasPorAno, setDebitosEMultasPorAno] = useState(null)
  const [recolhimentosPorAnoETipoDeliberacao, setRecolhimentosPorAnoETipoDeliberacao] =
    useState(null)
  const [debitosEMultasPorAnoETipoProcesso, setDebitosEMultasPorAnoETipoProcesso] = useState(null)
  const [recolhimentosTotaisPorAno, setRecolhimentosTotaisPorAno] = useState(null)
  const [arrecadacaoPorOrgaoExecutor, setArrecadacaoPorOrgaoExecutor] = useState(null)
  const [dividasEnviadasPorOrgaoExecutor, setDividasEnviadasPorOrgaoExecutor] = useState(null)

  const [debitosImputadosPorOrgaoExecutor, setDebitosImputadosPorOrgaoExecutor] = useState(null)
  const [debitosImputadosPorCofreCredor, setDebitosImputadosPorCofreCredor] = useState(null)

  const [opcoesAnoPesquisa, setOpcoesAnoPesquisa] = useState(null)
  const [anoPesquisado, setAnoPesquisado] = useState(new Date().getFullYear() - 1)

  const handleVoltar = () => {
    navigate('/')
  }

  const obterDadosRelatoriosGerais = () => {
    painelRetrospectivaService.obterOpcoesAnosParaPesquisaRelatorios().then((response) => {
      if (response.data) {
        setOpcoesAnoPesquisa(response.data)
      }
    })

    painelRetrospectivaService.obterRelatoriosRetrospectiva().then((response) => {
      if (response.data) {
        setDebitosEMultasPorAno(response.data.debitosEMultasPorAno)
        setRecolhimentosPorAnoETipoDeliberacao(response.data.recolhimentosPorAnoETipoDeliberacao)
        setDebitosEMultasPorAnoETipoProcesso(response.data.debitosEMultasPorAnoETipoProcesso)
      }
    })

    painelRetrospectivaService.obterRelatorioRecolhimentosTotais().then((response) => {
      if (response.data) {
        setRecolhimentosTotaisPorAno(response.data)
      }
    })
  }

  const obterDadosRelatoriosDeterminadoAno = () => {
    painelRetrospectivaService
      .obterRelatorioArrecadacaoPorOrgaoExecutor(anoPesquisado)
      .then((response) => {
        if (response.data) {
          setArrecadacaoPorOrgaoExecutor(response.data)
        }
      })

    painelRetrospectivaService
      .obterRelatorioDividasEnviadasPorOrgaoExecutor(anoPesquisado)
      .then((response) => {
        if (response.data) {
          setDividasEnviadasPorOrgaoExecutor(response.data)
        }
      })

    painelRetrospectivaService
      .obterRelatorioDebitosImputadosPorOrgaoExecutor(anoPesquisado)
      .then((response) => {
        if (response.data) {
          setDebitosImputadosPorOrgaoExecutor(response.data)
        }
      })

    painelRetrospectivaService
      .obterRelatorioDebitosImputadosPorCofreCredor(anoPesquisado)
      .then((response) => {
        if (response.data) {
          setDebitosImputadosPorCofreCredor(response.data)
        }
      })
  }

  useEffect(() => {
    obterDadosRelatoriosGerais()
  }, [])

  useEffect(
    () => {
      obterDadosRelatoriosDeterminadoAno()
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [anoPesquisado]
  )

  const renderTabelaDebitosEMultasPorAno = () => (
    <TabelaDebitosEMultasPorAno
      titulo="Histórico de condenações em débito e multa (em R$)"
      dados={debitosEMultasPorAno}
    />
  )

  const renderTabelaRecolhimentosPorAnoETipoDeliberacao = () => (
    <TabelaRecolhimentosPorAnoETipoDeliberacao
      titulo="Histórico de recolhimentos administrativos por tipo de deliberação (em R$)"
      dados={recolhimentosPorAnoETipoDeliberacao}
    />
  )

  const renderTabelaDebitosEMultasPorAnoETipoProcesso = () => (
    <TabelaDebitosEMultasPorAnoETipoProcesso
      titulo="Histórico de condenações em débito e multa por tipo de processo (em R$)"
      dados={debitosEMultasPorAnoETipoProcesso}
    />
  )

  const renderTabelaRecolhimentosTotaisPorAno = () => (
    <TabelaRecolhimentosTotaisPorAno
      titulo="Arrecadação administrativa e em órgãos executores por ano (em R$)"
      dados={recolhimentosTotaisPorAno}
    />
  )

  const renderTabelaArrecadacaoPorOrgaoExecutor = () => (
    <TabelaArrecadacaoPorOrgaoExecutor
      titulo={`Arrecadação por órgão executor de cobrança executiva (em R$) - ${anoPesquisado}`}
      dados={arrecadacaoPorOrgaoExecutor}
    />
  )

  const renderTabelaDividasEnviadasPorOrgaoExecutor = () => (
    <TabelaDividasEnviadasCbexPorOrgaoExecutor
      titulo={`Total de dívidas e valores por órgão executor de cobrança executiva (em R$) - ${anoPesquisado}`}
      dados={dividasEnviadasPorOrgaoExecutor}
    />
  )

  const renderTabelaDebitosImputadosPorOrgaoExecutor = () => (
    <TabelaDebitosImputadosPorOrgao
      titulo={`Total de débitos imputados pelo TCU por órgão executor (em R$) - ${anoPesquisado}`}
      dados={debitosImputadosPorOrgaoExecutor}
      tituloColunaOrgao="Órgão executor"
    />
  )

  const renderTabelaDebitosImputadosPorCofreCredor = () => (
    <TabelaDebitosImputadosPorOrgao
      titulo={`Total de débitos imputados pelo TCU por cofre credor (em R$) - ${anoPesquisado}`}
      dados={debitosImputadosPorCofreCredor}
      tituloColunaOrgao="Cofre credor"
    />
  )

  const [value, setValue] = React.useState('1')
  const handleMudarAbaRelatorio = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeAnoPesquisado = (event) => {
    setAnoPesquisado(event.target.value)
  }

  return (
    <ContentBox>
      <Box
        mx='auto'
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          maxWidth: '100%',
          alignItems: 'baseline',
          gap: '12px',
          '& > strong': { fontWeight: 'bold', color: 'primary.main' },
        }}>
        <TituloPagina onClick={handleVoltar}>Relatórios de dívidas e de arrecadação</TituloPagina>
      </Box>
      <TabContext value={value}>
        <Card variant="outlined" sx={{ display: 'flex', justifyContent: 'center', width: '100%'}}>
          <TabList onChange={handleMudarAbaRelatorio}
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              '& .MuiTab-root': {
                maxWidth: '280px',
                '@media (min-width: 1350px)': {
                  maxWidth: 'none',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              },
            }}
          >
            <Tab label="Histórico de condenações" value="1"/>
            <Tab
              label="Débitos imputados por órgão executor e cofre credor"
              value="4"
            />
            <Tab
              label="Arrecadação e dívidas enviadas para CBEX por órgão executor"
              value="3"
            />
            <Tab label="Arrecadação administrativa e total" value="2"/>
          </TabList>
        </Card>
        <TabPanel value="1">
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px', alignItems: 'center'}}>
            {renderTabelaDebitosEMultasPorAno()}
            {renderTabelaDebitosEMultasPorAnoETipoProcesso()}
          </Box>
        </TabPanel>
        <TabPanel value="2">
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px', alignItems: 'center'}}>
            {renderTabelaRecolhimentosPorAnoETipoDeliberacao()}
            {renderTabelaRecolhimentosTotaisPorAno()}
          </Box>
        </TabPanel>
        <TabPanel value="3">
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px', alignItems: 'center'}}>
            <Card variant="outlined" sx={{ maxWidth: '950px',width: '100%' , p: 1,}}>
              <SelecaoAnoPesquisaRelatorio
                valor={anoPesquisado}
                opcoes={opcoesAnoPesquisa}
                onChange={handleChangeAnoPesquisado}
              />
            </Card>
            {renderTabelaArrecadacaoPorOrgaoExecutor()}
            {renderTabelaDividasEnviadasPorOrgaoExecutor()}
          </Box>
        </TabPanel>
        <TabPanel value="4">
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px', alignItems: 'center'}}>
            <Card variant="outlined" sx={{maxWidth: '950px', width: '100%' , p: 1}}>
              <SelecaoAnoPesquisaRelatorio
                valor={anoPesquisado}
                opcoes={opcoesAnoPesquisa}
                onChange={handleChangeAnoPesquisado}
              />
            </Card>
            {renderTabelaDebitosImputadosPorOrgaoExecutor()}
            {renderTabelaDebitosImputadosPorCofreCredor()}
          </Box>
        </TabPanel>
      </TabContext>
      <ProgressBar />
    </ContentBox>
  )
}

export default Relatorios

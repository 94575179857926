import * as React from 'react'
import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import PaperOutlined from 'components/estilos/PaperOutlinedNoSpacing'
import formatarNumeroParaMoeda from '../../../../util/util'
import classes from './Tabela.style'
import CustomWidthTooltip from '../CustomWidthTooltip'
import IconeInformacao from '../../../../img/icone_informacao.svg'

const TabelaRecolhimentosTotaisPorAno = ({ titulo, dados }) => (
  <TableContainer component={PaperOutlined} sx={classes.tableContainerWidth}>
    <Box sx={{ py: 1.2, px: 2 }}>
      <Typography
        variant="subtitle1"
        component="div"
        color="grey.800"
        style={{ height: 'auto', verticalAlign: 'center' }}>
        {titulo}{' '}
        <CustomWidthTooltip
          title={
            <Box>
              <Typography variant="body2" gutterBottom>
                Considera a arrecadação nos órgãos executores para os quais temos essa informação
                (conforme detalhado em relatório específico).
              </Typography>
            </Box>
          }
          sx={{ color: 'grey.500' }}>
          <img
            src={IconeInformacao}
            style={{ width: '15px', height: 'auto', verticalAlign: '-2px' }}
            alt="Ícone de informação"
          />
        </CustomWidthTooltip>
      </Typography>
    </Box>
    <Divider />
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell align="center" sx={classes.tableCellLabel}>Ano</TableCell>
          <TableCell align="right" sx={classes.tableCellLabel}>Arrecadação administrativa</TableCell>
          <TableCell align="right" sx={classes.tableCellLabel}>Arrecadação executores cbex</TableCell>
          <TableCell align="right" sx={classes.tableCellLabel}>Arrecadação total</TableCell>
          <TableCell align="right" sx={classes.tableCellLabel}>Total débitos e multas imputados</TableCell>
          <TableCell align="right" sx={classes.tableCellLabel}>Percentual arrecadação</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {dados?.map((row) => (
          <TableRow key={row.ano} hover>
            <TableCell align="center" component="th" scope="row">
              <Typography variant="body2" sx={classes.cellContent}>
              {row.ano}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
              {formatarNumeroParaMoeda(row.valorTotalArrecadacaoAdministrativa, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
              {formatarNumeroParaMoeda(row.valorTotalArrecadacaoOrgaosExecutores, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
              {formatarNumeroParaMoeda(row.valorTotalArrecadacao, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
              {formatarNumeroParaMoeda(row.valorTotalDebitosEMultasImputados, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right" sx={classes.total}>
              <Typography variant="body2">
                {row.percentualTotalArrecadacao}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
)

TabelaRecolhimentosTotaisPorAno.propTypes = {
  titulo: PropTypes.string.isRequired,
  dados: PropTypes.array,
}

export default TabelaRecolhimentosTotaisPorAno

export const Types = {
  RECUPERAR_FILTROS_DISPONIVEIS: '@RECUPERAR_FILTROS_DISPONIVEIS',
  SALVAR_FILTROS_DISPONIVEIS: '@SALVAR_FILTROS_DISPONIVEIS',

  APLICAR_FILTROS_DIVIDAS_SELECIONADOS: '@APLICAR_FILTROS_DIVIDAS_SELECIONADOS',
  LIMPAR_FILTROS_DIVIDAS_SELECIONADOS: '@LIMPAR_FILTROS_DIVIDAS_SELECIONADOS',

  APLICAR_FILTROS_RECOLHIMENTOS_SELECIONADOS: '@APLICAR_FILTROS_RECOLHIMENTOS_SELECIONADOS',
  LIMPAR_FILTROS_RECOLHIMENTOS_SELECIONADOS: '@LIMPAR_FILTROS_RECOLHIMENTOS_SELECIONADOS',
}

export const OPCAO_FILTRO_SIM = 'Sim'

export const Actions = {
  recuperarFiltrosDisponiveis: () => ({ type: Types.RECUPERAR_FILTROS_DISPONIVEIS }),
  salvarFiltrosDisponiveis: (filtros) => ({
    type: Types.SALVAR_FILTROS_DISPONIVEIS,
    payload: filtros,
  }),
  aplicarFiltrosDividasSelecionados: (filtros) => ({
    type: Types.APLICAR_FILTROS_DIVIDAS_SELECIONADOS,
    payload: filtros,
  }),
  aplicarFiltrosRecolhimentosSelecionados: (filtros) => ({
    type: Types.APLICAR_FILTROS_RECOLHIMENTOS_SELECIONADOS,
    payload: filtros,
  }),
}

export const CODIGO_OPCAO_TIPO_DELIBERACAO_CITACAO = 'Citação'

export const CODIGOS_OPCOES_FASES_CANCELADAS = [
  'CANCELADA_POR_DELIBERACAO_TCU',
  'CANCELADA_POR_DECISAO_JUDICIAL',
  'CITACAO_CANCELADA_PROCESSO_ABERTO',
  'CITACAO_CANCELADA_PROCESSO_ENCERRADO',
]
export const CODIGO_FILTRO_FASES_DEBITO_MULTA = 'FASES_DEBITO_MULTA'
export const CODIGO_FILTRO_FASES_CITACAO = 'FASES_CITACAO'
export const CODIGO_FILTRO_COFRE_CREDOR = 'COFRE_CREDOR'
export const CODIGO_FILTRO_ORGAO_EXECUTOR = 'ORGAO_EXECUTOR'
export const CODIGO_FILTRO_ANO = 'ANO_DELIBERACAO'
export const CODIGO_FILTRO_TIPO_DELIBERACAO = 'TIPO_DELIBERACAO'
export const CODIGO_FILTRO_VALOR_MINIMO_SALDO_DEVEDOR = 'VALOR_MIN_SALDO_DEVEDOR'
export const CODIGO_FILTRO_VALOR_MAXIMO_SALDO_DEVEDOR = 'VALOR_MAX_SALDO_DEVEDOR'
export const CODIGO_FILTRO_TIPO_PROCESSO_ORIGINADOR = 'TIPO_PROCESSO_ORIGINADOR'
export const CODIGO_FILTRO_DATA_INICIO_PAGAMENTO = 'DATA_INICIO_PAGAMENTO'
export const CODIGO_FILTRO_DATA_FIM_PAGAMENTO = 'DATA_FIM_PAGAMENTO'
export const CODIGO_FILTRO_VALOR_MINIMO_PAGAMENTO = 'VALOR_MIN_PAGAMENTO'
export const CODIGO_FILTRO_VALOR_MAXIMO_PAGAMENTO = 'VALOR_MAX_PAGAMENTO'
export const CODIGO_FILTRO_SOMENTE_PAGTESOURO = 'SOMENTE_PAGTESOURO'
export const CODIGO_FILTRO_DATA_INICIO_ENVIO_CBEX = 'DATA_INICIO_ENVIO_CBEX'
export const CODIGO_FILTRO_DATA_FIM_ENVIO_CBEX = 'DATA_FIM_ENVIO_CBEX'

export const CODIGOS_FILTROS_PESQUISAVEIS = [
  CODIGO_FILTRO_FASES_DEBITO_MULTA,
  CODIGO_FILTRO_FASES_CITACAO,
  CODIGO_FILTRO_COFRE_CREDOR,
  CODIGO_FILTRO_ORGAO_EXECUTOR,
  CODIGO_FILTRO_TIPO_PROCESSO_ORIGINADOR,
]
export const CODIGOS_FILTROS_DISPONIVEIS_BACKEND = [
  ...CODIGOS_FILTROS_PESQUISAVEIS,
  CODIGO_FILTRO_ANO,
  CODIGO_FILTRO_TIPO_DELIBERACAO,
]
export const FILTRO_EXCLUIR_CITACOES = {
  codigo: 'EXCLUIR_CITACOES',
  valor: [OPCAO_FILTRO_SIM],
  nome: 'Excluir citações',
  opcoes: [
    {
      descricao: OPCAO_FILTRO_SIM,
      selecionada: true,
    },
  ],
}

export const FILTRO_EXCLUIR_DIVIDAS_CANCELADAS = {
  codigo: 'EXCLUIR_DIVIDAS_CANCELADAS',
  valor: [OPCAO_FILTRO_SIM],
  nome: 'Excluir dívidas canceladas',
  opcoes: [
    {
      descricao: OPCAO_FILTRO_SIM,
      selecionada: true,
    },
  ],
}

export const FILTRO_APENAS_DIVIDAS_COM_REGISTRO_PAGAMENTO = {
  codigo: 'POSSUI_REGISTRO_PAGAMENTO',
  valor: [OPCAO_FILTRO_SIM],
  nome: 'Possui registro de pagamento',
  opcoes: [
    {
      descricao: OPCAO_FILTRO_SIM,
      selecionada: true,
    },
  ],
}

export const FILTRO_APENAS_PAGAMENTO_PAGTESOURO = {
  codigo: CODIGO_FILTRO_SOMENTE_PAGTESOURO,
  valor: [OPCAO_FILTRO_SIM],
  nome: 'Somente PagTesouro',
  opcoes: [
    {
      descricao: OPCAO_FILTRO_SIM,
      selecionada: true,
    },
  ],
}

export const ESTADO_INICIAL_FILTROS = {
  filtrosDisponiveisBackend: [], // filtros disponiveis e selecionaveis com opcoes vindas do backend
  filtrosAplicadosDividas: [FILTRO_EXCLUIR_CITACOES, FILTRO_EXCLUIR_DIVIDAS_CANCELADAS],
  filtrosAplicadosRecolhimentos: [FILTRO_EXCLUIR_DIVIDAS_CANCELADAS],
}

export default (state = ESTADO_INICIAL_FILTROS, action) => {
  switch (action.type) {
    case Types.SALVAR_FILTROS_DISPONIVEIS: {
      const { data = [] } = action.payload
      const filtrosDisponiveisBackend = data
      return {
        ...state,
        filtrosDisponiveisBackend,
        filtrosAplicadosDividas: [
          ...filtrosDisponiveisBackend,
          ...ESTADO_INICIAL_FILTROS.filtrosAplicadosDividas,
        ],
        filtrosAplicadosRecolhimentos: [
          ...filtrosDisponiveisBackend,
          ...ESTADO_INICIAL_FILTROS.filtrosAplicadosRecolhimentos,
        ],
      }
    }
    case Types.APLICAR_FILTROS_DIVIDAS_SELECIONADOS: {
      const { data = [] } = action.payload
      const filtrosAplicadosDividas = data
      return {
        ...state,
        filtrosAplicadosDividas,
        filtrosAplicadosRecolhimentos: [...state.filtrosAplicadosRecolhimentos],
      }
    }
    case Types.LIMPAR_FILTROS_DIVIDAS_SELECIONADOS: {
      return {
        ...state,
        filtrosAplicadosDividas: [
          ...state.filtrosDisponiveisBackend,
          ...ESTADO_INICIAL_FILTROS.filtrosAplicadosDividas,
        ],
        filtrosAplicadosRecolhimentos: [...state.filtrosAplicadosRecolhimentos],
      }
    }
    case Types.APLICAR_FILTROS_RECOLHIMENTOS_SELECIONADOS: {
      const { data = [] } = action.payload
      const filtrosAplicadosRecolhimentos = data
      return {
        ...state,
        filtrosAplicadosDividas: [...state.filtrosAplicadosDividas],
        filtrosAplicadosRecolhimentos,
      }
    }
    default:
      return state
  }
}

import {
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material'
import classes from '../detalhar/DividaDetalhada.style'

const Responsaveis = () => (
  <Paper elevation={0} sx={classes.paper}>
    <Typography variant="h6">Responsáveis</Typography>
    <Divider />
    <List sx={classes.responsaveis}>
      {Array(1)
        .fill('')
        ?.map((it, index) => (
          <ListItemButton key={index}>
            <ListItemText
              primary={
                <Typography variant="subtitle2" gutterBottom>
                  <Skeleton width={250} />
                </Typography>
              }
              secondary={
                <Typography variant="subtitle2" gutterBottom>
                  <Skeleton width={130} />
                </Typography>
              }
            />
          </ListItemButton>
        ))}
    </List>
  </Paper>
)

export default Responsaveis

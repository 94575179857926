import * as React from 'react'
import { useState } from 'react'

import { Box, Button, Divider, InputLabel, Select, TextField, Typography } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import InserirParcela from '@mui/icons-material/AddCircleOutlined'
import PropTypes from 'prop-types'
import moment from 'moment/moment'
import { useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'
import PaperOutlined from '../../estilos/PaperOutlined'
import MoedaV2 from '../../comum/MoedaV2'
import { Actions as AvisosActions } from '../../../ducks/avisos'

const InclusaoParcela = ({ onIncluirParcela }) => {
  const dispatch = useDispatch()
  const [dataParcela, setDataParcela] = useState(null)
  const [valorParcela, setValorParcela] = useState(null)
  const [tipoParcela, setTipoParcela] = useState('D')
  const dataAtual = new Date()

  const handleChangeTipoParcela = (event) => {
    setTipoParcela(event.target.value)
  }

  const dataParcelaValida = () => {
    if (dataParcela == null) {
      dispatch(AvisosActions.erro(['Data da parcela deve ser preenchida.']))
      return false
    }

    if (dataParcela.toString() === 'Invalid Date') {
      dispatch(AvisosActions.erro(['Data da parcela deve ser uma data válida.']))
      return false
    }

    if (dataParcela > dataAtual) {
      dispatch(AvisosActions.erro(['Data da parcela deve ser anterior ou igual a data atual.']))
      return false
    }
    return true
  }

  const valorParcelaValido = () => {
    if (valorParcela == null || isEmpty(valorParcela) || parseFloat(valorParcela) === 0) {
      dispatch(AvisosActions.erro(['Valor da parcela deve ser preenchido.']))
      return false
    }
    return true
  }

  const limparCamposParcela = () => {
    setDataParcela(null)
    setValorParcela(null)
    setTipoParcela('D')
  }

  const incluirParcela = () => {
    if (dataParcelaValida() && valorParcelaValido()) {
      const novaParcela = {
        dataFato: moment(dataParcela).format('DD/MM/yyyy'),
        valorOriginal: valorParcela,
        indicativoDebitoCredito: tipoParcela,
      }
      onIncluirParcela(novaParcela)
      limparCamposParcela()
    }
  }

  return (
    <PaperOutlined sx={{flexGrow: 1}}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}>
        <Box>
          <Typography variant="h5" gutterBottom>
            Inclusão de parcelas
          </Typography>
          <Divider />
        </Box>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <DesktopDatePicker
            id="dataParcela"
            size="small"
            label="Data"
            inputFormat="dd/MM/yyyy"
            value={dataParcela}
            maxDate={dataAtual}
            onChange={(data) => setDataParcela(data)}
            renderInput={(params) => (
              <TextField
                InputLabelProps={{ shrink: true }}
                size="small"
                {...params}
                inputProps={{ ...params.inputProps, placeholder: 'dd/mm/aaaa' }}
              />
            )}
            InputLabelProps={{ shrink: true }}
          />
          <MoedaV2
            name="valorParcela"
            value={valorParcela}
            label="Valor"
            placeholder="R$ 0,00"
            onChange={(valor) => setValorParcela(valor)}
            maxLength={19}
          />
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="tipoContabil">Tipo</InputLabel>
            <Select
              label="Tipo"
              labelId="tipoContabil"
              id="select-tipoContabil"
              value={tipoParcela}
              onChange={handleChangeTipoParcela}>
              <MenuItem value="D">Débito</MenuItem>
              <MenuItem value="C">Crédito</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
          <Button
            onClick={incluirParcela}
            variant="text"
            color="primary"
            endIcon={<InserirParcela />}>
            Incluir Parcela
          </Button>
        </Box>
      </Box>
    </PaperOutlined>
  )
}

InclusaoParcela.propTypes = {
  onIncluirParcela: PropTypes.func,
}

export default InclusaoParcela

import axios from 'axios'

const configTextBody = {
  headers: {
    'Content-Length': 0,
    'Content-Type': 'text/plain',
  },
}
const incluirComentario = (codigoDivida, textoComentario) =>
  axios.post(`/bff/dividas/${codigoDivida}/comentarios`, textoComentario, configTextBody)

// const recuperarComentariosDaDivida = (codigoDivida) =>
//    axios.get(`/bff/dividas/${codigoDivida}/comentarios`)

const excluirComentario = (numeroComentario) =>
  axios.post(`/bff/comentarios/${numeroComentario}/excluir`)

export default {
  incluirComentario,
  excluirComentario,
}

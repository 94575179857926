import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Actions as sigaActions } from '../ducks/authentication'

const Login = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const isUserAuthenticated = useSelector(
    ({ authentication: { isUserAuthenticated } }) => isUserAuthenticated
  )

  useEffect(() => {
    if (!isUserAuthenticated) {
      dispatch(sigaActions.authenticateSiga(window.location.origin, '/'))
    }
  }, [dispatch, isUserAuthenticated, location])

  return null
}

export default Login

import PropTypes from 'prop-types'
import {
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { paginar } from '../../../../util/util'
import Composicao from './Composicao'
import NenhumResultadoEncontrado from '../../../comum/NenhumResultadoEncontrado'
import BootstrapDialog from '../../../comum/BootstrapDialog'
import BootstrapDialogTitle from '../../../comum/BootstrapDialogTitle'

const ComposicaoModal = ({ composicoes, open, onClose }) => {
  const [pagina, setPagina] = useState(0)
  const [registrosPorPagina, setRegistrosPorPagina] = useState(20)

  return (
    <BootstrapDialog onClose={onClose} open={open} maxWidth="sm">
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose} style={{width: 380}}>
        <Typography variant="h5" component="span">Itens da dívida</Typography>
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Table size="small">
          <TableHead sx={{ th: { border: 0 } }}>
            <TableRow>
              <TableCell sx={{ width: '20%', color: 'grey.500', padding: '0px 15px' }}>
                Data evento
              </TableCell>
              <TableCell align="right" sx={{ width: '20%', color: 'grey.500', padding: '0px 15px' }}>
                Valor
              </TableCell>
              <TableCell align="left" sx={{ width: '5%', color: 'grey.500', padding: '0px 15px' }}>
                Evento
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {composicoes.length === 0 && <NenhumResultadoEncontrado />}
            {paginar(composicoes, pagina, registrosPorPagina).map((composicao) => (
              <Composicao key={composicao.sequenciaItem} composicao={composicao} />
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={composicoes.length}
          page={pagina}
          onPageChange={(event, novaPagina) => setPagina(novaPagina)}
          rowsPerPage={registrosPorPagina}
          onRowsPerPageChange={(e) => setRegistrosPorPagina(e.target.value)}
          rowsPerPageOptions={[]}
        />
      </DialogContent>
    </BootstrapDialog>
  )
}

ComposicaoModal.propTypes = {
  composicoes: PropTypes.array,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ComposicaoModal

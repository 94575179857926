import {
  Box,
  Button,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import PaperOutlined from '../../estilos/PaperOutlined'

const ComposicoesSkeleton = () => (
  <PaperOutlined>
    <Typography variant="h5" gutterBottom>
      Composição da dívida
    </Typography>
    <Divider />
    <Box mt={1}>
      <Table size="small">
        <TableHead sx={{ th: { border: 0 } }}>
          <TableRow>
            <TableCell sx={{ color: 'grey.500', padding: '0px 15px' }}>Data evento</TableCell>
            <TableCell align="left" sx={{ color: 'grey.500', padding: '0px 15px' }}>
              Valor
            </TableCell>
            <TableCell align="left" sx={{ color: 'grey.500', padding: '0px 15px' }}>
              Evento
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array(1)
            .fill('')
            .map((it, index) => (
              <TableRow key={index} sx={{ 'td,th': { border: 0 } }}>
                <TableCell sx={{ padding: '0px 15px' }}>
                  <Skeleton width={100} />
                </TableCell>
                <TableCell align="left" sx={{ padding: '0px 15px' }}>
                  <Skeleton width={100} />
                </TableCell>
                <TableCell align="left" sx={{ padding: '0px 15px' }}>
                  <Skeleton width={50} />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button variant="text">Exibir todos os itens da dívida</Button>
      </Box>
    </Box>
  </PaperOutlined>
)

export default ComposicoesSkeleton

import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import classes from './TabsNavegacao.style'
import PaperOutlined from '../estilos/PaperOutlined'
import { ABA_PAINEL_INICIAL } from './ConstantesDivida'

const TabsNavegacao = ({ tabSelecionada = ABA_PAINEL_INICIAL.DEBITOS_MULTAS }) => {
  const navigate = useNavigate()

  const handleTabFasesDebitosEMultasClick = () => {
    navigate('/')
  }

  const handleTabFasesCitacoesClick = () => {
    navigate('/painel-citacoes')
  }

  const handleTabAlertasClick = () => {
    navigate('/alertas')
  }

  return (
    <Box sx={classes.boxBotoesNavegacao}>
      <Box sx={classes.cardNavegacao} onClick={() => handleTabFasesCitacoesClick()}>
        <PaperOutlined variant="outlined" sx={classes.paperOutlinedNavegacao}>
          <Typography variant="h6" sx={classes.textoCardNavegacao}>
            Citações
          </Typography>
        </PaperOutlined>
        {tabSelecionada === ABA_PAINEL_INICIAL.CITACOES && (
          <Box sx={{ height: '3px', backgroundColor: 'primary.main' }} />
        )}
      </Box>

      <Box sx={classes.cardNavegacao} onClick={() => handleTabFasesDebitosEMultasClick()}>
        <PaperOutlined variant="outlined" sx={classes.paperOutlinedNavegacao}>
          <Typography variant="h6" sx={classes.textoCardNavegacao}>
            Débitos e Multas
          </Typography>
        </PaperOutlined>
        {tabSelecionada === ABA_PAINEL_INICIAL.DEBITOS_MULTAS && (
          <Box sx={{ height: '3px', backgroundColor: 'primary.main' }} />
        )}
      </Box>
      <Box sx={classes.cardNavegacao} onClick={() => handleTabAlertasClick()}>
        <PaperOutlined variant="outlined" sx={classes.paperOutlinedNavegacao}>
          <Typography variant="body1" sx={classes.textoCardNavegacao}>
            Alertas
          </Typography>
        </PaperOutlined>
        {tabSelecionada === ABA_PAINEL_INICIAL.ALERTAS && (
          <Box sx={{ height: '3px', backgroundColor: 'primary.main' }} />
        )}
      </Box>
    </Box>
  )
}

TabsNavegacao.propTypes = {
  tabSelecionada: PropTypes.string,
}

export default TabsNavegacao

const MSG_ARQUIVO_EXTENSAO_INVALIDA =
  'Arquivo {nomeArquivo} com extensão inválida. Somente é permitido upload de arquivo com extensão txt.'
const MSG_ARQUIVO_SEM_CONTEUDO = 'O arquivo {nomeArquivo} está sem conteúdo, portanto não é válido.'
const MSG_ARQUIVO_MAIOR_TAMANHO_MAXIMO_PERMITIDO =
  'Tamanho do arquivo {nomeArquivo} ultrapassou o tamanho máximo de 80MB.'

export const extensaoArquivoValido = (arquivo) => {
  const extensaoArquivo = arquivo.name.split('.').pop()?.toLowerCase()
  return extensaoArquivo === 'txt'
}

export const conteudoArquivoValido = (arquivo) => arquivo.size !== 0

export const tamanhoArquivoValido = (arquivo) => arquivo.size / 1024 / 1024 <= 80

const validarArquivo = (arquivo) => {
  if (!extensaoArquivoValido(arquivo)) {
    return MSG_ARQUIVO_EXTENSAO_INVALIDA.replace('{nomeArquivo}', arquivo.name)
  }
  if (!conteudoArquivoValido(arquivo)) {
    return MSG_ARQUIVO_SEM_CONTEUDO.replace('{nomeArquivo}', arquivo.name)
  }
  if (!tamanhoArquivoValido(arquivo)) {
    return MSG_ARQUIVO_MAIOR_TAMANHO_MAXIMO_PERMITIDO.replace('{nomeArquivo}', arquivo.name)
  }
  return null
}

export default validarArquivo

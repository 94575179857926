import React from 'react'
import { isEmpty } from 'lodash'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Box, FormControl, Paper, Select, TextField, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const grid = 8
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: 1,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'yellow' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightgray' : 'white',
  padding: grid,
  width: '100%',
})

const ListaPecasAutuacao = ({
  pecas = [],
  tiposDocumentos = [],
  callbackPecasReordenadas = () => {},
  callbackNovoAssunto = () => {},
  callbackTipoDocumentoSelecionado = () => {},
}) => {
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  // https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/responders.md
  const isDropInvalido = (evento) =>
    isEmpty(evento) ||
    isEmpty(evento.destination) ||
    isEmpty(evento.source) ||
    evento.reason === 'CANCEL'

  const onDragEnd = (result) => {
    // dropped outside the list
    if (isDropInvalido(result)) {
      return
    }

    const pecasOrdenadas = reorder(pecas, result.source.index, result.destination.index)
    callbackPecasReordenadas(pecasOrdenadas)
  }

  const renderPecaInfo = (peca, index) => (
    <Paper variant="outlined" elevation={0}>
      <Box display="flex" alignItems="center" px={2} sx={{ gap: '8px' }}>
        <Box width="100px">
          <Typography variant="subtitle2" gutterBottom>
            Peça Nº {index}
          </Typography>
        </Box>
        <Box display="flex" width={1} alignItems="flex-end" sx={{ gap: '8px' }}>
          <Box flexGrow={2}>
            <TextField
              id="assunto-peca-input"
              label="Assunto"
              fullWidth
              value={peca.textoCabecalho}
              onChange={(ev) => callbackNovoAssunto(ev, peca)}
              margin="normal"
              variant="outlined"
            />
          </Box>
          <Box mb={1}>
            <FormControl variant="outlined">
              <Select
                fullWidth
                native
                onChange={(ev) => callbackTipoDocumentoSelecionado(ev, peca)}
                value={peca.codTipoDocumento ? peca.codTipoDocumento : ''}
                style={
                  peca.codTipoDocumento ? {} : { border: '2px solid #D32F2F', borderRadius: 4 }
                }>
                <option value="">--Selecione um tipo--</option>
                {tiposDocumentos.map((tipo, index) => (
                  <option key={index} value={tipo.codigo}>
                    {tipo.descricao}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
    </Paper>
  )

  return (
    <DragDropContext onDragEnd={(ev) => onDragEnd(ev)}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}>
            {pecas?.map((item, index) => (
              <Draggable
                key={item.codDocumento}
                draggableId={item.codDocumento.toString()}
                index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                    {renderPecaInfo(item, index + 1)}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

ListaPecasAutuacao.propTypes = {
  pecas: PropTypes.array.isRequired,
  tiposDocumentos: PropTypes.array.isRequired,
  callbackPecasSelecionadas: PropTypes.func.isRequired,
  callbackPecasReordenadas: PropTypes.func.isRequired,
  callbackNovoAssunto: PropTypes.func.isRequired,
  callbackTipoDocumentoSelecionado: PropTypes.func.isRequired,
}

export default ListaPecasAutuacao

import { format as formatCpf, isValid as isValidCpf } from '@fnando/cpf'
import { format as formatCnpj, isValid as isValidCnpj, strip } from '@fnando/cnpj'

const formatarNumeroParaMoeda = (value, minDecimalDigit, maxDecimalDigit) => {
  if (value === null || value === undefined) return ''
  let valueFormat = value
  valueFormat = Number(valueFormat)
  if (!Number.isNaN(valueFormat)) {
    return valueFormat.toLocaleString('pt-BR', {
      minimumFractionDigits: minDecimalDigit,
      maximumFractionDigits: maxDecimalDigit,
    })
  }
  return value
}

export const formataCpf = (cpf) => {
  if (!cpf) return '--'
  return formatCpf(cpf.padStart(11, '0'))
}

export const formataCnpj = (cnpj) => {
  if (!cnpj) return '--'
  return formatCnpj(cnpj.padStart(14, '0'))
}

export const removerMascaraCpf = (cpf) => strip(cpf)

export const removerMascaraCnpj = (cnpj) => strip(cnpj)

export const removerNumeroProcesso = (numeroProcesso) => strip(numeroProcesso)

export const validarCpf = (cpf) => isValidCpf(cpf)

export const validarCnpj = (cnpj) => isValidCnpj(cnpj)

export const paginar = (registros, pagina, registrosPorPagina) =>
  registros.slice(pagina * registrosPorPagina, pagina * registrosPorPagina + registrosPorPagina)
export default formatarNumeroParaMoeda

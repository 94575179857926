import { Box, Skeleton } from '@mui/material'
import ContentBox from 'components/estilos/ContentBox'

const PainelAlertasSkeleton = () => (
  <ContentBox>
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      <Skeleton variant="rounded" height={60} width={240} sx={{ marginBottom: '16px' }} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
        <Skeleton variant="rounded" height={37} width={170} sx={{ marginBottom: '16px' }} />
        <Skeleton variant="rounded" height={37} width={170} sx={{ marginBottom: '16px' }} />
        <Skeleton variant="rounded" height={37} width={170} sx={{ marginBottom: '16px' }} />
      </Box>
    </Box>
    <Box
      sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 2 }}>
      <Skeleton variant="rounded" height={24} width={203} />
    </Box>
    <Box
      sx={{ display: 'flex', flexDirection: 'column', gap: '25px', justifyContent: 'flex-start' }}>
      <Skeleton variant="rounded" height={147} width="100%" />
      <Skeleton variant="rounded" height={99} width="100%" />
      <Skeleton variant="rounded" height={147} width="100%" />
    </Box>
  </ContentBox>
)

export default PainelAlertasSkeleton

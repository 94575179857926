import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { Box, Button, Divider, Paper, TextField, Typography } from '@mui/material'
import TituloPagina from '../../estilos/TituloPagina'
import LinkExterno from '../../estilos/LinkExterno'
import ContentBox from '../../estilos/ContentBox'
import processoService from '../../../services/processoService'
import { Actions as AvisosActions } from '../../../ducks/avisos'
import formatarNumeroParaMoeda from '../../../util/util'
import ModalDividaDetalhada from '../detalhar/alerta/ModalDividaDetalhada'
import ListaPecasAutuacao from './ListaPecasAutuacao'
import ModalSelecionarPecasProcesso from './ModalSelecionarPecasProcesso'
import documentoService from '../../../services/documentoService'
import DialogoConfirmacaoAutuacao from './DialogoConfirmacaoAutuacao'
import ProgressBar from '../../comum/ProgressBar'
import dividaService from '../../../services/dividaService'

const AutuacaoProcesso = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [pecasProcessoOriginador, setPecasProcessoOriginadorSelecionadas] = React.useState([])
  const [tiposDocumentos, setTiposDocumentos] = React.useState([])
  const [pecasSelecionadas, setPecasSelecionadas] = React.useState([])
  const [modalSelecionarPecasAberta, setModalSelecionarPecasAberta] = React.useState(false)
  const [modalDividaDetalhadaAberta, setModalDividaDetalhadaAberta] = React.useState(false)
  const [idDividaSelecionada, setIdDividaSelecionada] = React.useState(null)
  const [dividaJaAutuada, setDividaJaAutuada] = React.useState(false)
  const [abrirModalSenha, setAbrirModalSenha] = React.useState(false)
  const [senha, setSenha] = React.useState(null)
  const { state: propsNavegacao } = useLocation()
  const idProcesso = propsNavegacao?.dadosProcessoOriginador?.codigo

  const siglaExiste = propsNavegacao.dadosProcessoOriginador?.siglaTipoProcesso
    ? `- ${propsNavegacao.dadosProcessoOriginador?.siglaTipoProcesso}`
    : ''

  const tipoSiglaProcesso =
    propsNavegacao.dadosProcessoOriginador?.siglaTipoProcesso === 'CBEX'
      ? propsNavegacao.dadosProcessoOriginador?.descricaoTipoProcesso
      : `Originador ${siglaExiste}`

  useEffect(() => {
    const recuperarTiposDocumentos = async () => {
      const { data } = await documentoService.recuperarTiposDocumentosAplicaveisAutuacao()
      setTiposDocumentos(data)
    }

    recuperarTiposDocumentos().then(() => {})
  }, [])

  useEffect(() => {
    const recuperarPecasProcesso = async () => {
      const { data } = await processoService.recuperarPecasProcessoPorCodigo(idProcesso)
      setPecasProcessoOriginadorSelecionadas(data)
    }
    if (!_.isNil(idProcesso)) {
      recuperarPecasProcesso().then(() => {})
    }
  }, [idProcesso])

  useEffect(() => {
    const validarSeDividaPodeSerVinculadaProcessoRap = async () => {
      await dividaService.validarSeDividaPodeSerVinculadaProcessoRap({
        codigosDividas: propsNavegacao.dividas.map((it) => it.id),
      })
    }
    validarSeDividaPodeSerVinculadaProcessoRap()
      .then(() => {
        setDividaJaAutuada(false)
      })
      .catch(() => {
        setDividaJaAutuada(true)
      })
  }, [propsNavegacao.dividas])

  const pedirSenha = () => {
    if (pecasSelecionadas.length > 0 && !pecasSelecionadas.every((it) => it.codTipoDocumento)) {
      dispatch(AvisosActions.erro(['Informe o tipo do documento para todas as peças selecionadas']))
    } else {
      setAbrirModalSenha(true)
    }
  }

  function handleVoltar() {
    navigate(`/dividas/processo/${idProcesso}`)
  }

  const handleClickLinkProcesso = async (codigoProcesso) => {
    const { data: url } = await processoService.recuperarUrlVisualizacaoProcesso(codigoProcesso)
    window.open(url)
  }

  const visualizarDetalhesDivida = (id) => {
    setModalDividaDetalhadaAberta(true)
    setIdDividaSelecionada(id)
  }

  const adicionarPecas = () => {
    setModalSelecionarPecasAberta(true)
  }

  const callbackPecasSelecionadas = (pecasSelecionadas) => {
    setPecasSelecionadas(pecasSelecionadas)
    setModalSelecionarPecasAberta(false)
  }

  const callbackNovoAssunto = (ev, pecaAlterada) => {
    const copiaPecaAlterada = pecaAlterada
    copiaPecaAlterada.textoCabecalho = ev.target.value
    setPecasSelecionadas(
      pecasSelecionadas.map((it) => (it.id !== copiaPecaAlterada ? it : copiaPecaAlterada))
    )
  }

  const callbackTipoDocumentoSelecionado = (ev, pecaAlterada) => {
    const copiaPecaAlterada = pecaAlterada
    copiaPecaAlterada.codTipoDocumento = ev.target.value
    setPecasSelecionadas(
      pecasSelecionadas.map((it) => (it.id !== copiaPecaAlterada ? it : copiaPecaAlterada))
    )
  }

  const renderConteudoModalConfirmacao = () => (
    <TextField
      error={senha === ''}
      data-testid="senha"
      placeholder="Informe a senha"
      label="Senha"
      type="password"
      variant="outlined"
      helperText={senha === '' ? 'campo obrigatório' : ''}
      onChange={(event) => setSenha(event.target.value)}
    />
  )

  const camposObrigatoriosPreenchidos = () => {
    if (_.isEmpty(senha)) {
      setSenha('')
      return false
    }
    return true
  }

  const confirmarAutuacaoProcesso = async () => {
    if (camposObrigatoriosPreenchidos()) {
      setAbrirModalSenha(false)

      const comando = {
        codigoProcessoOriginador: idProcesso,
        pecas: pecasSelecionadas,
        codigosDividas: propsNavegacao.dividas.map((it) => it.id),
        senhaUsuario: senha,
      }
      try {
        const { data: processoDto } = await processoService.autuarProcesso(comando)
        dispatch(AvisosActions.sucesso(['Processo autuado com sucesso!']))
        await handleClickLinkProcesso(processoDto.codigo)
        handleVoltar()
      } catch (err) {
        console.error('Erro ao autuar processo', err)
      }
    }
  }

  const acaoRecusar = () => {
    setAbrirModalSenha(false)
  }

  return (
    <ContentBox>
      <div>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <TituloPagina onClick={() => handleVoltar()}>
            Autuação de processo de acompanhamento
          </TituloPagina>
          <div style={{ display: 'flex' }}>
            <Button
              variant="contained"
              disabled={dividaJaAutuada}
              onClick={() => pedirSenha()}
              style={{ marginLeft: '2em' }}
              sx={{ fontSize: 12 }}>
              Autuar processo
            </Button>
          </div>
        </Box>
        <Paper
          variant="outlined"
          elevation={0}
          sx={{
            padding: 2,
            marginBottom: '16px',
          }}>
          <Box sx={{ gap: '32px', alignItems: 'flex-end', flexWrap: 'wrap' }} display="flex">
            <Box>
              <LinkExterno
                onClick={() =>
                  handleClickLinkProcesso(propsNavegacao.dadosProcessoOriginador?.codigo)
                }>
                {propsNavegacao.dadosProcessoOriginador?.numeroCompleto}
                {propsNavegacao.dadosProcessoOriginador?.numero}
              </LinkExterno>
              <Typography variant="body1">{tipoSiglaProcesso}</Typography>
            </Box>
          </Box>
        </Paper>
      </div>
      {propsNavegacao.dividas.map((it, index) => (
        <Paper
          key={it.id}
          variant="outlined"
          elevation={0}
          sx={{
            padding: 2,
            marginBottom: '16px',
          }}>
          <Box
            sx={{ gap: '32px', justifyContent: 'space-between', flexWrap: 'wrap' }}
            display="flex">
            <Typography variant="h5" gutterBottom>
              Dívida {index + 1}
            </Typography>
            <Button
              onClick={() => visualizarDetalhesDivida(it.id)}
              size="small"
              rel="noopener noreferrer"
              color="primary"
              sx={{
                textDecoration: 'none',
                cursor: 'pointer',
                color: 'primary.light',
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                padding: 0,
              }}>
              <Typography variant="body1">visualizar</Typography>
            </Button>
          </Box>
          <Divider />
          <Box
            sx={{ gap: '16px', alignItems: 'flex-end', flexWrap: 'wrap', paddingTop: 1 }}
            display="flex">
            <Box>
              <Typography variant="body2" sx={{ color: 'grey.500' }}>
                Saldo devedor
              </Typography>
              <Typography variant="body1">
                R$ {formatarNumeroParaMoeda(it.valoresAtualizados.valorAtualizadoComPagamento)}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" sx={{ color: 'grey.500' }}>
                Deliberação
              </Typography>
              <Typography variant="body1">
                {it.deliberacaoOriginadoraDivida.numeroDeliberacaoFormatado}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" sx={{ color: 'grey.500' }}>
                Cofre credor
              </Typography>
              <Typography variant="body1">{it.cofreCredor.nome}</Typography>
            </Box>
            <Box>
              <Typography variant="body2" sx={{ color: 'grey.500' }}>
                Órgão executor
              </Typography>
              <Typography variant="body1">{it.dadosConsolidados.executorCbex.nome}</Typography>
            </Box>
            <Box>
              <Typography variant="body1">
                <span style={{ color: '#9E9E9E', marginRight: 4 }}>Item de deliberação:</span>
                {it.deliberacaoOriginadoraDivida.itemDeliberacao}:{' '}
                {it.deliberacaoOriginadoraDivida.descricaoDeliberacao}
              </Typography>
            </Box>
          </Box>
        </Paper>
      ))}
      <Paper
        variant="outlined"
        elevation={0}
        sx={{
          padding: 2,
          marginBottom: '16px',
        }}>
        <Box sx={{ gap: '32px', justifyContent: 'space-between', flexWrap: 'wrap' }} display="flex">
          <Typography variant="h5" gutterBottom>
            Peças
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{ gap: '8px', alignItems: 'flex-end', flexWrap: 'wrap', paddingTop: 1 }}
          display="flex">
          <Typography variant="body1">Reclassificar e reordenar as peças</Typography>
          <Button
            onClick={() => adicionarPecas()}
            size="small"
            rel="noopener noreferrer"
            color="primary"
            sx={{
              textDecoration: 'none',
              cursor: 'pointer',
              color: 'primary.light',
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              padding: 0,
            }}>
            <Typography variant="body1">adicionar/remover peças</Typography>
          </Button>
          <ListaPecasAutuacao
            pecas={pecasSelecionadas}
            tiposDocumentos={tiposDocumentos}
            callbackPecasSelecionadas={(pecasSelecionadas) =>
              callbackPecasSelecionadas(pecasSelecionadas)
            }
            callbackPecasReordenadas={(pecasReordenadas) => setPecasSelecionadas(pecasReordenadas)}
            callbackNovoAssunto={(ev, pecaAlterada) => callbackNovoAssunto(ev, pecaAlterada)}
            callbackTipoDocumentoSelecionado={(ev, pecaAlterada) =>
              callbackTipoDocumentoSelecionado(ev, pecaAlterada)
            }
          />
        </Box>
      </Paper>
      <ModalDividaDetalhada
        key={idDividaSelecionada}
        id={idDividaSelecionada}
        open={modalDividaDetalhadaAberta}
        onClose={() => setModalDividaDetalhadaAberta(false)}
      />
      <ModalSelecionarPecasProcesso
        onClose={() => setModalSelecionarPecasAberta(false)}
        callbackPecasSelecionadas={(pecas) => callbackPecasSelecionadas(pecas)}
        open={modalSelecionarPecasAberta}
        pecasProcessoOriginador={pecasProcessoOriginador}
      />
      <DialogoConfirmacaoAutuacao
        titulo=""
        conteudo={renderConteudoModalConfirmacao()}
        open={abrirModalSenha}
        onConfirmar={confirmarAutuacaoProcesso}
        onRecusar={acaoRecusar}
        labelBotaoSim="Confirmar"
        labelBotaoNao="Cancelar"
      />
      <ProgressBar />
    </ContentBox>
  )
}
export default AutuacaoProcesso

import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, CircularProgress, InputAdornment, TextField, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import ContentBox from 'components/estilos/ContentBox'
import PaperOutlined from 'components/estilos/PaperOutlined'
import InputMask from 'react-input-mask'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'
import classes from '../../comum/BarraPesquisaStyles'
import { removerNumeroProcesso } from '../../../util/util'
import { Actions as AvisosActions } from '../../../ducks/avisos'
import DividasParaPagamentoPorProcesso from './DividasParaPagamentoPorProcesso'
import ProgressBar from '../../comum/ProgressBar'
import MessageBarLoading from '../../comum/MessageBarLoading'
import LinkExterno from '../../estilos/LinkExterno'
import pagamentoService from '../../../services/pagamentoService'

const urlPagamentoGRUSimples =
  'https://sso.apps.tcu.gov.br/sso/SvlCentralizador?contexto=ecomWeb&URL=/Web/eCom/GerarGRU.faces?primeiroAcesso=1'

const PagTesouro = () => {
  const dispatch = useDispatch()
  const { state: propsNavegacao } = useLocation()
  const [dividas, setDividas] = useState([])
  const [seDividasUsuarioLogado, setSeDividasUsuarioLogado] = useState(false)
  const [qtdDividasUsuarioLogado, setQtdDividasUsuarioLogado] = useState(0)
  const [numeroProcesso, setNumeroProcesso] = useState('')
  const [executandoPesquisa, setExecutandoPesquisa] = useState(false)
  const [seCarregouDividas, setSeCarregouDividas] = useState(false)
  const [seAtualizacaoEmAndamento, setSeAtualizacaoEmAndamento] = useState(false)

  const handleClickLinkGRUSimples = async () => {
    window.open(urlPagamentoGRUSimples)
  }

  const recuperarDividasPorProcessoCallback = useCallback(
    async (numeroProcesso, seDividasUsuarioLogado) => {
      if (numeroProcesso === '') {
        return
      }
      const numeroProcessoSemFormatacao = removerNumeroProcesso(numeroProcesso)
      if (seDividasUsuarioLogado) {
        setSeCarregouDividas(false)
      }
      setSeDividasUsuarioLogado(false)
      setExecutandoPesquisa(true)
      try {
        const { data } = await pagamentoService.recuperarDividasParaPagamentoPorNumeroProcesso(
          numeroProcessoSemFormatacao
        )
        setDividas(data)

        if (data != null && data.some((divida) => divida.seAtualizacaoEmAndamento === true)) {
          setSeAtualizacaoEmAndamento(true)
          setTimeout(() => recuperarDividasPorProcessoCallback(numeroProcesso), 10000)
        } else {
          setSeAtualizacaoEmAndamento(false)
        }
      } finally {
        setSeCarregouDividas(true)
        setExecutandoPesquisa(false)
      }
    },
    []
  )

  const recuperarDividasUsuarioLogadoCallback = useCallback(async (seDividasUsuarioLogado) => {
    if (!seDividasUsuarioLogado) {
      setSeCarregouDividas(false)
    }
    setSeDividasUsuarioLogado(true)
    setExecutandoPesquisa(true)
    setNumeroProcesso('')

    try {
      const { data } = await pagamentoService.recuperarDividasParaPagamentoUsuarioLogado()
      setDividas(data)
      setQtdDividasUsuarioLogado(data.length)

      if (data.some((divida) => divida.seAtualizacaoEmAndamento === true)) {
        setSeAtualizacaoEmAndamento(true)
        setTimeout(() => recuperarDividasUsuarioLogadoCallback(true), 10000)
      } else {
        setSeAtualizacaoEmAndamento(false)
      }
    } finally {
      setSeCarregouDividas(true)
      setExecutandoPesquisa(false)
    }
  }, [])

  const preencherQtdDividasUsuarioLogado = () => {
    pagamentoService
      .recuperarDividasParaPagamentoUsuarioLogado()
      .then((response) => setQtdDividasUsuarioLogado(response.data.length))
  }

  useEffect(() => {
    if (!_.isNil(propsNavegacao?.numeroProcesso)) {
      preencherQtdDividasUsuarioLogado()
      setNumeroProcesso(propsNavegacao?.numeroProcesso)
      recuperarDividasPorProcessoCallback(propsNavegacao?.numeroProcesso, false).then(() => {})
    } else {
      recuperarDividasUsuarioLogadoCallback(true).then(() => {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propsNavegacao?.numeroProcesso])

  const handlePesquisarDividasPorProcesso = async () => {
    if (!numeroProcesso) {
      dispatch(AvisosActions.erro(['Número de processo não informado.']))
    }
    await recuperarDividasPorProcessoCallback(numeroProcesso, seDividasUsuarioLogado)
  }

  const handlePesquisarDividasPorUsuarioLogado = async () => {
    await recuperarDividasUsuarioLogadoCallback(seDividasUsuarioLogado)
  }

  // const mostrarLista =
  //  (seDividasUsuarioLogado && !_.isEmpty(dividas)) ||
  //  (!seDividasUsuarioLogado && !_.isEmpty(dividas))

  return (
    <>
      <ContentBox>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <>
            {seAtualizacaoEmAndamento && <MessageBarLoading />}
            <Typography variant="h2" component="h1" color="primary">
              Pagamentos de dívidas
            </Typography>
            <Typography color="text.secondary">
              Digite o número do processo abaixo para localizar a dívida para a qual deseja efetuar
              pagamento. Os pagamentos serão realizados por meio do PagTesouro que permite que os
              valores sejam pagos por meio de PIX ou cartão de crédito. Se necessário fazer o
              pagamento por meio de boleto de GRU simples, por favor
              <span style={{ display: 'inline-flex', marginLeft: '4px' }}>
                <LinkExterno onClick={() => handleClickLinkGRUSimples()}>clique aqui</LinkExterno>
              </span>
              .
            </Typography>
            <Typography color="text.secondary">
              Para pagamento que não tenha como cofre credor o Tesouro Nacional, solicitar a guia
              por e-mail: <a href="mailto:parcelamento@tcu.gov.br">parcelamento@tcu.gov.br</a>
            </Typography>
            <Box display="flex" gap="16px">
              <PaperOutlined sx={{ width: '100%' }}>
                <Box>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    Buscar dívidas
                  </Typography>
                  <Box
                    sx={{
                      background: '#EDEBEB',
                      borderRadius: '4px',
                      display: 'flex',
                      height: '36px',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      paddingLeft: 1,
                      width: 300,
                    }}>
                    <InputMask
                      onChange={(event) => setNumeroProcesso(event.target.value)}
                      value={numeroProcesso}
                      mask="999.999/9999-9"
                      disabled={false}
                      maskChar="_">
                      {() => (
                        <TextField
                          variant="standard"
                          placeholder="___.___/____-_"
                          sx={{
                            m: 1,
                            margin: 0,
                            '& .MuiInput-root:before': { borderBottom: 0 },
                            '& .MuiInput-root:hover:not(.Mui-disabled):before': { borderBottom: 0 },
                            '& .MuiInput-root:after': { borderBottom: 0 },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">PROCESSO:</InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </InputMask>
                    <Button
                      disabled={executandoPesquisa}
                      sx={classes.botaoPesquisa}
                      variant="contained"
                      onClick={() => handlePesquisarDividasPorProcesso()}>
                      {executandoPesquisa ? <CircularProgress size={25} /> : <SearchIcon />}
                    </Button>
                  </Box>
                </Box>
              </PaperOutlined>
              {qtdDividasUsuarioLogado > 0 ? (
                <PaperOutlined
                  id="solucao"
                  sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h6">
                    Dívidas associadas ao meu CPF
                    <Button
                      onClick={() => recuperarDividasUsuarioLogadoCallback(seDividasUsuarioLogado)}
                      style={{ paddingLeft: '8px', color: '#5f98ff' }}>
                      {' '}
                      {qtdDividasUsuarioLogado}{' '}
                    </Button>
                  </Typography>
                </PaperOutlined>
              ) : null}
            </Box>
            {!seDividasUsuarioLogado && seCarregouDividas && _.isEmpty(dividas) && (
              <PaperOutlined
                sx={{ flexGrow: 2, display: 'flex', alignItems: 'center', padding: 5 }}>
                <Box display="flex" alignItems="center" height="100%">
                  <Typography variant="h6">
                    Não há dívidas disponíveis para pagamento no processo informado.
                  </Typography>
                </Box>
              </PaperOutlined>
            )}
            {!_.isEmpty(dividas) && (
              <DividasParaPagamentoPorProcesso
                dividas={dividas}
                seDividasUsuarioLogado={seDividasUsuarioLogado}
                seCarregouDividas={seCarregouDividas}
                callbackPagamento={
                  seDividasUsuarioLogado
                    ? handlePesquisarDividasPorUsuarioLogado
                    : handlePesquisarDividasPorProcesso
                }
              />
            )}
          </>
        </Box>
      </ContentBox>
      <ProgressBar />
    </>
  )
}

export default PagTesouro

const classes = {
  fundoDestaque: {
    borderRadius: '4px',
    padding: '5px',
    backgroundColor: 'grey.200',
  },
  textoDestaque: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: 'primary.light',
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
    padding: 0,
  },
}
export default classes

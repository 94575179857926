import { Box, Typography } from '@mui/material'
import moment from 'moment/moment'
import { COLUNAS, renderResponsaveis } from '../Divida'
import formatarNumeroParaMoeda from '../../../util/util'

const renderDataPagamento = (params) => (
  <Box width={1}>
    <Typography variant="body2" align="center">
      {params?.row?.dataPagamento ? moment(params?.row?.dataPagamento).format('DD/MM/yyyy') : ''}
    </Typography>
  </Box>
)

const renderValorPagamento = (params) => (
  <Box width={1}>
    <Typography variant="body2" align="right">
      R$ {formatarNumeroParaMoeda(params?.row?.valorPago, 2, 2)}
    </Typography>
  </Box>
)

const renderSePagTesouro = (params) => (
  <Box width={1}>
    <Typography variant="body2" align="center">
      {params?.row?.seTransacaoPagTesouro ? 'Sim' : 'Não'}
    </Typography>
  </Box>
)

const COLUNA_RESPONSAVEIS_DIVIDA = {
  field: 'responsaveis',
  headerName: 'Responsáveis da dívida',
  width: 400,
  renderCell: renderResponsaveis,
}

const COLUNA_DATA_PAGAMENTO = {
  field: 'dataPagamento',
  headerName: 'Data pagamento',
  width: 130,
  renderCell: renderDataPagamento,
}

const COLUNA_VALOR_PAGAMENTO = {
  field: 'valorPago',
  headerName: 'Valor pagamento',
  type: 'number',
  width: 220,
  renderCell: renderValorPagamento,
}

const COLUNA_SE_PAGTESOURO = {
  field: 'seTransacaoPagTesouro',
  headerName: 'Via PagTesouro',
  width: 120,
  renderCell: renderSePagTesouro,
}

// eslint-disable-next-line import/prefer-default-export
export const colunas = [
  COLUNAS.PROCESSO_ORIGINADOR,
  COLUNAS.DECISAO,
  COLUNA_RESPONSAVEIS_DIVIDA,
  COLUNA_DATA_PAGAMENTO,
  COLUNA_VALOR_PAGAMENTO,
  COLUNA_SE_PAGTESOURO,
]

import { Box, Tooltip, Typography } from '@mui/material'
import _ from 'lodash'
import IconHoverArrow from 'components/estilos/IconHoverArrow'
import OverflowTooltip from 'components/estilos/OverflowTooltip'
import formatarNumeroParaMoeda from 'util/util'
import ReportProblem from '@mui/icons-material/Warning'

import Comentario from './comentario/Comentario'
import IconPopoverAlertas from './detalhar/alerta/IconPopoverAlertas'
import IconButtonVisualizarDivida from './pagtesouro/IconButtonVisualizarDivida'
import IconButtonPagTesouro from './pagtesouro/IconButtonPagTesouro'
import {
  FASES_DIVIDA,
  fasesNaoCompativeisParaPagamento,
  MSG_CITACAO_COM_ACORDAO_POSTERIOR,
  PAGINA_ORIGEM_DETALHAMENTO_DIVIDA,
} from './ConstantesDivida'
import Falecido from '../estilos/Falecido'

const renderNumeroProcessoOriginador = (params) => (
  <Box display="flex" flexDirection="column" sx={{ width: '100%', margin: '12px, 16px' }}>
    <Typography variant="body2">{params.row?.processo?.numero}</Typography>
    <OverflowTooltip title={params.row.deliberacaoOriginadoraDivida?.nomeDeliberacao}>
      <span style={{ color: '#9E9E9E', fontSize: '0.875rem' }}>
        {params.row.deliberacaoOriginadoraDivida?.nomeDeliberacao}
      </span>
    </OverflowTooltip>
  </Box>
)

const renderNumeroProcessoOriginadorParaPagamento = (params) => (
  <Box display="flex" flexDirection="column" sx={{ width: '100%', margin: '12px, 16px' }}>
    <Typography variant="body2">{params.row?.numeroProcessoFormatado}</Typography>
  </Box>
)

const renderUnidadeResponsavelAgirProcesso = (params) => (
  <Box display="flex" flexDirection="column" sx={{ width: '100%', margin: '12px, 16px' }}>
    <Typography variant="body2">
      {params.row?.processo?.nomeReduzidoUnidadeResponsavelAgir}
    </Typography>
    <Typography variant="body2" color="textSecondary">
      {params.row?.processo?.nomeReduzidoSubunidadeResponsavelAgir}
    </Typography>
  </Box>
)

const renderItemDeliberacao = (params) => (
  <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
    <Typography variant="body2">
      {`${params.row.deliberacaoOriginadoraDivida?.itemDeliberacao || ''} ${
        params.row.descricaoTipoDeliberacao
      }`}
    </Typography>
    <Typography sx={{ color: 'grey.500' }} variant="body2">
      {`${params.row.fase || ''} ${
        params.row.desdobramento ? ` - ${params.row.desdobramento}` : ''
      }`}
    </Typography>
  </Box>
)

export const renderResponsaveis = (params) => {
  const nomeResponsaveis = params?.row?.responsaveis
    ?.filter((responsavel) => responsavel?.pessoa.nome)
    .flatMap((r, i) =>
      r.pessoa.falecido
        ? [`${i === 0 ? '' : ', '}${r.pessoa.nome}`, 'F']
        : [`${i === 0 ? '' : ', '}${r.pessoa.nome}`]
    )
    .map((t, i) => (t === 'F' ? <Falecido key={i} falecido /> : <span key={i}>{t}</span>))

  return <OverflowTooltip title={nomeResponsaveis}>{nomeResponsaveis} </OverflowTooltip>

}

const renderSaldoDevedor = (params) => (
  <Box width={1}>
    <Typography variant="body2" align="right">
      R${' '}
      {formatarNumeroParaMoeda(params?.row?.valoresAtualizados.valorAtualizadoComPagamento, 2, 2)}
    </Typography>
  </Box>
)

const renderColunaAlerta = (params) => {
  const alertas = params?.row?.alertas
  if (alertas !== null && alertas.length > 0) {
    return <IconPopoverAlertas alertas={alertas} />
  }
  return null
}

const renderColunaComentarios = (params) => (
  <Comentario comentariosDaDivida={params.row?.comentarios} emLista codigoDivida={params.row?.id} />
)

const renderDecisao = (params) => (
  <Box display="flex" flexDirection="column" sx={{ width: '100%', margin: '12px, 16px' }}>
    <OverflowTooltip title={params.row?.deliberacaoOriginadoraDivida?.nomeDeliberacao}>
      <span>{params.row?.deliberacaoOriginadoraDivida?.nomeDeliberacao}</span>
    </OverflowTooltip>
    <Typography variant="body2" color="textSecondary">
      {params.row.codigoFase === FASES_DIVIDA.CITACAO_SEM_PAGAMENTO_COM_ACORDAO_POSTERIOR && (
        <Tooltip title={MSG_CITACAO_COM_ACORDAO_POSTERIOR}>
          <span>
            <ReportProblem
              style={{ width: '20px', marginLeft: '4px', color: '#FFC700', verticalAlign: '-6px' }}
            />
          </span>
        </Tooltip>
      )}
      {`${params.row.deliberacaoOriginadoraDivida?.itemDeliberacao || ''} ${
        params.row.descricaoTipoDeliberacao
      }`}
    </Typography>
  </Box>
)
const renderCofreCredor = (params) => (
  <Typography variant="body2" noWrap>
    <OverflowTooltip title={params.row?.cofreCredor?.nome}>
      {params.row?.cofreCredor?.nome}
    </OverflowTooltip>
  </Typography>
)

const renderSaldoParaPagamento = (params) => (
  <Box width={1}>
    <Typography variant="body2" align="right">
      {fasesNaoCompativeisParaPagamento.includes(params.row.codigoFase)
        ? '---'
        : `R$${' '}${formatarNumeroParaMoeda(
            params?.row?.valoresAtualizados.valorAtualizadoComPagamento,
            2,
            2
          )}`}
    </Typography>
  </Box>
)

const renderIconePagTesouro = (params, callbackPagamento) => (
  <Tooltip title="Realizar pagamento">
    <span>
      <IconButtonPagTesouro divida={params?.row} callbackPagamento={callbackPagamento} />
    </span>
  </Tooltip>
)

const renderIconeMotivo = (params) => (
  <Box width={64} display="flex" justifyContent="center" alignItems="center">
    <Tooltip title={params.row.motivoNaoExibicaoPagTesouro}>
      <span>
        <Typography variant="button" sx={{ padding: '0', color: 'rgba(0, 0, 0, 0.32)' }}>
          Pagar
        </Typography>
      </span>
    </Tooltip>
  </Box>
)

// eslint-disable-next-line no-undef, react/display-name
const renderColunaAcoesPagTesouro = (callback, seDividasUsuarioLogado) => (params) =>
  (
    <Box sx={{ display: 'flex' }}>
      <IconButtonVisualizarDivida
        divida={params?.row}
        paginaOrigem={PAGINA_ORIGEM_DETALHAMENTO_DIVIDA.DIVIDAS_PARA_PAGAMENTO_POR_PROCESSO}
        seDividasUsuarioLogado={seDividasUsuarioLogado}
      />
      {params.row.seExibePagTesouro === true
        ? renderIconePagTesouro(params, callback)
        : renderIconeMotivo(params)}
    </Box>
  )

const renderIconHoverArrow = () => <IconHoverArrow />

export const COLUNAS = {
  PROCESSO_ORIGINADOR: {
    field: 'numeroProcessoOriginador',
    headerName: 'Processo',
    width: 160,
    renderCell: renderNumeroProcessoOriginador,
  },
  PROCESSO_ORIGINADOR_PARA_PAGAMENTO: {
    field: 'numeroProcessoOriginador',
    headerName: 'Processo',
    width: 160,
    renderCell: renderNumeroProcessoOriginadorParaPagamento,
  },
  UNIDADE_RESP_AGIR_PROCESSO: {
    field: 'unidadeResponsavelAgir',
    headerName: 'Responsável por agir',
    width: 180,
    renderCell: renderUnidadeResponsavelAgirProcesso,
  },
  ITEM_DELIBERACAO: {
    field: 'itemDeliberacao',
    headerName: 'Item de deliberação',
    minWidth: 420,
    renderCell: renderItemDeliberacao,
  },
  RESPONSAVEIS: {
    field: 'responsaveis',
    headerName: 'Responsáveis',
    minWidth: 200,
    flex: 1,
    renderCell: renderResponsaveis,
  },
  SALDO_DEVEDOR: {
    field: 'valoresAtualizados.valorAtualizadoComPagamento',
    headerName: 'Saldo devedor',
    type: 'number',
    width: 150,
    renderCell: renderSaldoDevedor,
  },
  ALERTAS: {
    field: 'alertas',
    type: 'actions',
    width: 64,
    renderCell: renderColunaAlerta,
  },

  COMENTARIOS: {
    field: 'comentarios',
    type: 'actions',
    width: 64,
    renderCell: renderColunaComentarios,
  },
  ACOES: {
    field: 'actions',
    type: 'actions',
    width: 64,
    renderCell: renderIconHoverArrow,
  },
  DECISAO: {
    field: 'decisao',
    headerName: 'Decisão',
    width: 350,
    renderCell: renderDecisao,
  },
  COFRE_CREDOR: {
    field: 'cofreCredor',
    headerName: 'Cofre credor',
    width: 250,
    renderCell: renderCofreCredor,
  },
}

const COLUNA_SALDO_PARA_PAGAMENTO = {
  field: 'valoresAtualizados.valorAtualizadoParaPagamento',
  headerName: 'Saldo devedor atualizado',
  type: 'number',
  width: 220,
  renderCell: renderSaldoParaPagamento,
}

const COLUNA_ACOES_PAGTESOURO = (callback, seDividasUsuarioLogado) => ({
  headerName: 'Ações',
  field: 'actions',
  type: 'actions',
  width: 150,
  renderCell: renderColunaAcoesPagTesouro(callback, seDividasUsuarioLogado),
})

export const colunasListagem = [
  COLUNAS.PROCESSO_ORIGINADOR,
  COLUNAS.UNIDADE_RESP_AGIR_PROCESSO,
  COLUNAS.ITEM_DELIBERACAO,
  COLUNAS.RESPONSAVEIS,
  COLUNAS.SALDO_DEVEDOR,
  COLUNAS.COMENTARIOS,
  COLUNAS.ALERTAS,
]

/* export const colunasDividasComComentarios = (setComentarioNaDivida) => [
  COLUNAS.PROCESSO_ORIGINADOR,
  COLUNAS.UNIDADE_RESP_AGIR_PROCESSO,
  COLUNAS.ITEM_DELIBERACAO,
  COLUNAS.RESPONSAVEIS,
  COLUNAS.SALDO_DEVEDOR,
  COLUNA_COMENTARIOS(setComentarioNaDivida),
  COLUNAS.ALERTAS,
]
*/

export const colunasListagemCbex = [
  COLUNAS.PROCESSO_ORIGINADOR,
  COLUNAS.UNIDADE_RESP_AGIR_PROCESSO,
  COLUNAS.ITEM_DELIBERACAO,
  COLUNAS.RESPONSAVEIS,
  COLUNAS.COFRE_CREDOR,
  COLUNAS.SALDO_DEVEDOR,
  // COLUNAS.COMENTARIOS, // Como tem duas listas, leva a inconsitencias
]

export const colunasDividasParaPagamentoPorProcesso = (callback, seDividasUsuarioLogado) => [
  COLUNAS.DECISAO,
  COLUNAS.RESPONSAVEIS,
  COLUNAS.COFRE_CREDOR,
  COLUNA_SALDO_PARA_PAGAMENTO,
  COLUNA_ACOES_PAGTESOURO(callback, seDividasUsuarioLogado),
]

export const colunasDividasParaPagamentoPorUsuarioLogado = (callback, seDividasUsuarioLogado) => [
  COLUNAS.PROCESSO_ORIGINADOR_PARA_PAGAMENTO,
  ...colunasDividasParaPagamentoPorProcesso(callback, seDividasUsuarioLogado),
]

export const colunasDividasParaPagamento = (callback, seDividasUsuarioLogado) => {
  if (seDividasUsuarioLogado) {
    return colunasDividasParaPagamentoPorUsuarioLogado(callback, seDividasUsuarioLogado)
  }
  return colunasDividasParaPagamentoPorProcesso(callback, seDividasUsuarioLogado)
}

export const colunasDividasPorResponsavel = [
  COLUNAS.PROCESSO_ORIGINADOR,
  COLUNAS.ITEM_DELIBERACAO,
  COLUNAS.RESPONSAVEIS,
  COLUNAS.SALDO_DEVEDOR,
  COLUNAS.COMENTARIOS,
  COLUNAS.ALERTAS,
]

export const recuperarTotalizador = (totalizadores, fase) =>
  totalizadores?.find((totalizador) => totalizador.nivel.codigo === fase)

export const normalizarString = (str) =>
  !_.isNil(str) ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : ''

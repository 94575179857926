
import * as React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Drawer,
  IconButton,
  Table, TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';


const MensagemFormatoCopiaColaExcel = () => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };


  const classes = {
    enfase: {
      color: 'primary.main',
      fontWeight: 'bold',
    }
  }

  return (
    <section>
      <Typography variant="body2" color="primary.light"  onClick={toggleDrawer(true)} sx={{cursor: 'pointer'}}>
        Saiba Mais
      </Typography>
      <Drawer
         anchor="right"
         open={drawerOpen}
         onClose={toggleDrawer(false)}
      >
        <Box sx={{ p: 2, maxWidth: 800 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', pt: 1}}>
            <Box>
              <Typography variant='h5'>
                Copie e cole as parcelas de uma planilha (Ctrl+C/Crtl+V)
              </Typography>
              <Typography>
                A calculadora aceita colar parcelas de uma planilha, desde que siga formato descrito abaixo:
              </Typography>
            </Box>
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography variant='subtitle2' gutterBottom sx={{ mt: 2 }}>
            Opção 01: Com três colunas, podendo ter duas colunas caso seja debito
          </Typography>
          <Box sx={{ pl: 3, pb: 1, '& > ul' : { listStyleType: 'disc'}}}>
            <ul>
              <li><Typography><Box component="span" sx={classes.enfase}>Data: </Box>Data da parcela do débito, no formato DD/MM/YYYY (ex: 31/01/2024)</Typography></li>
              <li><Typography><Box component="span" sx={classes.enfase}>Valor: </Box>Valor da parcela do débito, no formato brasileiro 9.999,99 ou 9999,99</Typography></li>
              <li><Typography><Box component="span" sx={classes.enfase}>Tipo: </Box>Opcional. Indica o tipo de débito. Os valores possíveis são D ou Débito ou vazio e C ou Crédito</Typography></li>
            </ul>
          </Box>
          <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>Exemplo do conteúdo da planilha</Typography>
          </AccordionSummary>
        <AccordionDetails>
        <Table aria-label="simple table"  size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Data</TableCell>
                      <TableCell>Valor</TableCell>
                      <TableCell>Tipo</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>15/03/2015</TableCell>
                      <TableCell>1568</TableCell>
                      <TableCell>Debito</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>15/04/2015</TableCell>
                      <TableCell>1.568</TableCell>
                      <TableCell>D</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>15/06/2015</TableCell>
                      <TableCell>1.568,34</TableCell>
                      <TableCell>Credito</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>15/07/2015</TableCell>
                      <TableCell>2.668,55</TableCell>
                      <TableCell>C</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>15/08/2015</TableCell>
                      <TableCell>674,38</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableBody>
                </Table>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>Exemplo do conteúdo no formato CSV</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Table aria-label="simple table" size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>15/03/2015;1568;Debito</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>15/04/2015;1.568;D</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>15/07/2015;2.668,55;Credito</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>15/08/2015;674,38;C</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>15/08/2015;774,38</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>Exemplo do conteúdo no formato usando espaço em branco</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Table aria-label="simple table" size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>15/03/2015 1568 Debito</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>15/04/2015 1.568 D</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>15/07/2015 2.668,55 Credito</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>15/08/2015 674,38 C</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>15/08/2015 774,38</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
        </AccordionDetails>
      </Accordion>
          <Typography variant='subtitle2' gutterBottom sx={{ mt: 4 }}>
            Opção 02: Com quatro colunas, conforme demonstrativo de débito da Plataforma de Gestão de divida
          </Typography>
          <Box sx={{ pl: 3, pb: 1, '& > ul' : { listStyleType: 'disc'}}}>
            <ul>
              <li><Typography><Box component="span" sx={classes.enfase}>Data: </Box>Data da parcela do débito, no formato
                DD/MM/YYYY(ex: 31/01/2024).</Typography></li>
              <li><Typography><Box component="span" sx={classes.enfase}>Tipo: </Box>Indica o tipo de débito. Os
                valores possíveis são D para Débito e C para Crédito.</Typography></li>
              <li><Typography><Box component="span" sx={classes.enfase}>Moeda: </Box>Valor fixo &quot;R$&quot;</Typography></li>
              <li><Typography><Box component="span" sx={classes.enfase}>Valor: </Box>Valor da parcela do débito, no
                formato brasileiro 9.999,99 ou 9999,99.</Typography></li>
            </ul>
          </Box>
          <Accordion sx={{ mt: 2 }}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>Exemplo do conteúdo no formato usando espaço em branco</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Table aria-label="simple table" size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>15/03/2015 D R$ 1.568</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>15/04/2015 D R$ 1.568</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>15/07/2015 C R$ 2.668,55</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>15/08/2015 C R$ 674,38</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Drawer>
    </section>
  );
};

export default MensagemFormatoCopiaColaExcel;

import { ptBR } from '@mui/material/locale'

const fontHX = '"Libre Franklin"';

export const darkTheme = {
  palette: {
    mode: "dark",
  },
  typography: {
    fontFamily: ['"Roboto"', 'sans-serif'].join(','),
    htmlFontSize: 16,
    fontSize: 14,
    h1: {
      fontFamily: `${fontHX}, sans-serif`,
      fontWeight: 300,
      fontSize: "2rem",
      lineHeight: '3.5rem',
      letterSpacing: "-0.01562em",
    },
    h2: {
      fontFamily: `${fontHX}, sans-serif`,
      fontWeight: 300,
      fontSize: "1.715rem",
      lineHeight: '2.43rem',
      letterSpacing: "-0.00833em",
    },
    h3: {
      fontFamily: `${fontHX}, sans-serif`,
      fontWeight: 400,
      fontSize: "1.43rem",
      lineHeight: '2rem',
      letterSpacing: "0em",
    },

    h4: {
      fontFamily: `${fontHX}, sans-serif`,
      fontWeight: 400,
      fontSize: "1.285rem",
      lineHeight: '1.785',
      letterSpacing: "0.00735em",
    },

    h5: {
      fontFamily: `${fontHX}, sans-serif`,
      fontWeight: 400,
      fontSize: "1.145rem",
      lineHeight: "1.57rem",
      letterSpacing: "0em",
    },

    h6: {
      fontWeight: 500,
      fontSize: "1rem",
      lineHeight: "1.43",
      letterSpacing: "0.0075em",
    },

    body1: {
      fontSize: "0.9rem"
    },

    body2: {
      fontSize: "0.875rem"
    },

    button: {
      textTransform: 'initial'
    },
    components: {
      MuiButton: {
        styleOverrides: {
          sizeSmall: {
            fontSize: "0.875rem"
          },
        },
      }
    },
  },
  ptBR
};

export default darkTheme

import React, { useState, forwardRef } from 'react'
import { Box, Button } from '@mui/material'
import PropTypes from 'prop-types'
import PagTesouroModal from './PagTesouroModal'

const IconButtonPagTesouro = forwardRef(({ divida, callbackPagamento }, ref) => {
  const [exibeModalPagTesouro, setExibeModalPagTesouro] = useState(false)

  const abrirModalPagTesouro = () => {
    setExibeModalPagTesouro(true)
  }

  const fecharModalPagTesouro = () => {
    setExibeModalPagTesouro(false)
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Button component="span" ref={ref} sx={{ padding: '0' }} onClick={abrirModalPagTesouro}>
        Pagar
      </Button>
      <PagTesouroModal
        id="modalPagTesouro"
        divida={divida}
        open={exibeModalPagTesouro}
        callbackPagamento={callbackPagamento}
        onClose={fecharModalPagTesouro}
      />
    </Box>
  )
})

IconButtonPagTesouro.displayName = 'IconButtonPagTesouro';

IconButtonPagTesouro.propTypes = {
  divida: PropTypes.object,
  callbackPagamento: PropTypes.func,
}

export default IconButtonPagTesouro
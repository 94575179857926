import axios from 'axios'

const urlDividas = '/bff/dividas'

const solicitarPagamentoPagTesouro = (codigoDivida, comando) =>
  axios.post(`${urlDividas}/${codigoDivida}/pagtesouro/solicitar-pagamento`, comando, {
    noLoading: false,
  })

const processarPagamento = (idPagamento) =>
  axios.post(`${urlDividas}/pagtesouro/processar-pagamento/${idPagamento}`)

const recuperarDividasParaPagamentoPorNumeroProcesso = (numeroCompletoProcessoSemFormatacao) =>
  axios.get(`${urlDividas}-para-pagamento/processos/${numeroCompletoProcessoSemFormatacao}`)

const recuperarDividasParaPagamentoUsuarioLogado = () =>
  axios.get(`${urlDividas}-para-pagamento/usuario-logado`)

const recuperarPagamentosPagTesouroPorPeriodo = (comando) =>
  axios.post(`${urlDividas}/pagtesouro/pagamentos-por-periodo`, comando, {
    noLoading: false,
  })

export default {
  solicitarPagamentoPagTesouro,
  processarPagamento,
  recuperarDividasParaPagamentoPorNumeroProcesso,
  recuperarDividasParaPagamentoUsuarioLogado,
  recuperarPagamentosPagTesouroPorPeriodo,
}

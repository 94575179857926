import {isEmpty, isString} from "lodash";
import moment from "moment";


export const toNumber = (s) => {
  const locale = 'pt-BR'

  if (s === undefined || s === null) return undefined

  const s0 = s.trim().replace('.', '')
  const s1 = !s0.includes(',') ? `${s0},00` : s0
  const s2 = s1.replace(',', '.')

  const n = Number(s2)
  const numberFormat = new Intl.NumberFormat(locale, {
    useGrouping: false,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  const s3 = numberFormat.format(n)

  return s1 === s3 ? s2 : undefined
}

export const MASCARA = 'DD/MM/yyyy'
export const isDate = (s) => s.length <= MASCARA.length && moment(s, MASCARA).isValid()

const removerLinhasEmBranco = linha=>!isEmpty(linha)
const removerLinhasInvalidas = ([data]) => isString(data) && data.split('/').length === 3

export const conteudoParaParcelas = (conteudo) => {
  if (isEmpty(conteudo)) return []
  if (!isString(conteudo)) return []
  const conteudoTrim = conteudo.trim()
  if (isEmpty(conteudoTrim)) return []

  const linhas = conteudoTrim.split('\n')
  const [primeiraLinha = ''] = linhas
  let separador = '\t'

  let filtroPorNumeroColunas = (linha) => linha.length === 2 || linha.length === 3
  if (primeiraLinha.includes(';')) {
    separador = ';'
  }
  if (primeiraLinha.includes(' ')) {
    separador = ' '
    filtroPorNumeroColunas = (linha) => linha.length >= 2
  }

  const parcelas = linhas
    .filter(removerLinhasEmBranco)
    .map((linha) => linha.split(separador))
    .filter(filtroPorNumeroColunas)
    .filter(removerLinhasInvalidas) // ignora o cabecalho e linhas sujas
    .map((linha) => { // transformar tabela de demonstrativo de divida em colunas normais [data,valor,tipo]
      const [data, tipo, moeda,valor ] = linha
      return moeda==='R$'?[data,valor,tipo]:linha
    })
    .map(([dataFato, valorOriginal, tipo = 'Debito'], i) => ({
      dataFato,
      valorOriginal: toNumber(valorOriginal),
      valor: valorOriginal,
      indicativoDebitoCredito: tipo.toUpperCase().charAt(0) === 'C' ? 'C' : 'D',
      seqItem: i,
    }))

  return parcelas
}

export default conteudoParaParcelas
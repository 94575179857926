const classes = {
  campoPesquisaCbex: {
    m: 0,
    padding: 0,
    width: {xs:'162px',sm:'25ch'},
    margin: '4px',
    '& .MuiInput-root:before': { borderBottom: 0 },
    '& .MuiInput-root:hover:not(.Mui-disabled):before': { borderBottom: 0 },
    '& .MuiInput-root:after': { borderBottom: 0 }
  },
  botaoPesquisaCbex: {
    borderLeft: '1px solid #DEDEDE',
    borderRadius: 0,
    height: '100%',
    padding: 0,
    width: '30px'
  },
  BoxPesquisaCebx: {
    background: '#EDEBEB',
    borderRadius: '4px',
    display: 'flex',
    height: '36px',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 1,
    width: 300,
  }
}

export default classes

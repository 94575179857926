export const FASES = [
  { nome: 'EM ANDAMENTO', cor: 'primary.dark' },
  { nome: 'VENCIDA', cor: '#125586' },
  { nome: 'ENCERRADA', cor: 'success.dark' },
  { nome: 'SUSPENSA', cor: '#0A5972' },
  { nome: 'INDEFINIDA', cor: '#0A5972' },
  { nome: 'INDETERMINADA', cor: '#0A5972' },
]

export const AGRUPADOR_FASES = {
  EM_ANDAMENTO: 'EM_ANDAMENTO',
  ENCERRADA: 'ENCERRADA',
  CITACAO_EM_PROCESSO_ABERTO: 'CITACAO_EM_PROCESSO_ABERTO',
  CITACAO_EM_PROCESSO_ENCERRADO: 'CITACAO_EM_PROCESSO_ENCERRADO',
  INDETERMINADA: 'INDETERMINADA',
}

export const FASES_DIVIDA = {
  CITACAO_SEM_PAGAMENTO_COM_ACORDAO_POSTERIOR: 'CITACAO_SEM_PAGAMENTO_COM_ACORDAO_POSTERIOR',
  ENVIADA_COBRANCA_EXECUTIVA: 'ENVIADA_COBRANCA_EXECUTIVA',
  AGUARDANDO_ANALISE_QUITACAO: 'AGUARDANDO_ANALISE_QUITACAO',
  QUITADA: 'QUITADA',
  CANCELADA_POR_DELIBERACAO_TCU: 'CANCELADA_POR_DELIBERACAO_TCU',
  CANCELADA_POR_DECISAO_JUDICIAL: 'CANCELADA_POR_DECISAO_JUDICIAL',
}

export const fasesNaoCompativeisParaPagamento = [
  FASES_DIVIDA.ENVIADA_COBRANCA_EXECUTIVA,
  FASES_DIVIDA.AGUARDANDO_ANALISE_QUITACAO,
  FASES_DIVIDA.QUITADA,
  FASES_DIVIDA.CANCELADA_POR_DELIBERACAO_TCU,
  FASES_DIVIDA.CANCELADA_POR_DECISAO_JUDICIAL,
]

export const PAGINA_ORIGEM_DETALHAMENTO_DIVIDA = {
  DIVIDAS_POR_PROCESSO: 'DIVIDAS_POR_PROCESSO',
  DIVIDAS_POR_RESPONSAVEL: 'DIVIDAS_POR_RESPONSAVEL',
  DIVIDAS_COM_ALERTAS: 'DIVIDAS_COM_ALERTAS',
  LISTAGEM_DIVIDAS: 'LISTAGEM_DIVIDAS',
  PESQUISA_AVANCADA_DIVIDAS: 'PESQUISA_AVANCADA_DIVIDAS',
  PESQUISA_AVANCADA_RECOLHIMENTOS: 'PESQUISA_AVANCADA_RECOLHIMENTOS',
  DIVIDAS_PARA_PAGAMENTO_POR_PROCESSO: 'DIVIDAS_PARA_PAGAMENTO_POR_PROCESSO',
}

export const RELATORIO_ORIGEM_PESQUISA_DIVIDAS = {
  MENU_TODAS_DIVIDAS: 0,
  ABA_HISTORICO_CONDENACOES: 1,
  ABA_ARRECADACAO_ADMINISTRATIVA_TOTAL: 2,
  ABA_ARRECADACAO_POR_ORGAO_EXECUTOR: 3,
  ABA_DEBITO_IMPUTADO_ORGAO_EXECUTOR: 4,
}

export const ABA_LISTAGEM_DIVIDAS = {
  DEBITOS_MULTAS: 'DEBITOS_MULTAS',
  CANCELADAS: 'CANCELADAS',
  CITACOES: 'CITACOES',
}

export const ABA_PAINEL_INICIAL = {
  DEBITOS_MULTAS: 'DEBITOS_MULTAS',
  CITACOES: 'CITACOES',
  ALERTAS: 'ALERTAS',
}

export const MSG_CITACAO_COM_ACORDAO_POSTERIOR =
  'Existe um acórdão posterior a essa citação no processo, portanto a citação pode não ser mais válida caso alguma deliberação posterior tenha acolhido eventuais alegações de defesa apresentadas.'

export const NOME_TOTALIZADOR_GERAL_RECOLHIMENTOS = 'TOTALIZADOR_GERAL_RECOLHIMENTOS'

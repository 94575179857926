export const TOKEN_KEY = '__TOKEN_KEY__'
export const USER_FINGER_PRINT_KEY = '__USER_FINGER_PRINT_KEY__'
export const REFRESH_TOKEN_KEY = '__REFRESH_TOKEN_KEY__'
export const CONTADOR = '__CONTADOR__'
export const TEMPO_EXECUCAO = '__TEMPO_EXECUCAO__'

export const SLOW_REQUEST_HEADER = 'X-Slow-Request'
export const BYPASS_AUTH_INTERCEPTOR_HEADER = 'X-Bypass-Auth-Interceptor'
export const AUTH_CONFIG_HEADERS = {
  headers: { [SLOW_REQUEST_HEADER]: true, [BYPASS_AUTH_INTERCEPTOR_HEADER]: true },
}

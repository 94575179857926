import axios from 'axios'

const urlPessoas = '/bff/pessoas'

const recuperarPessoaPorCodigo = (codigo) => axios.get(`${urlPessoas}/${codigo}`)

const recuperarPessoasPorCodigos = (codigosPessoas) =>
  axios.get(`${urlPessoas}?codigosPessoas=${codigosPessoas}`)

const recuperarPessoasPorCpf = (cpf) =>
  axios.get(`${urlPessoas}/fisicas/${cpf}`, { noLoading: true })


const recuperarPessoasPorCnpj = (cnpj) =>
  axios.get(`${urlPessoas}/juridicas/${cnpj}`, { noLoading: true })

export default {
  recuperarPessoaPorCodigo,
  recuperarPessoasPorCpf,
  recuperarPessoasPorCnpj,
  recuperarPessoasPorCodigos,
}

import createSagaMiddleware from 'redux-saga'
import { configureStore } from '@reduxjs/toolkit'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'
import rootReducer from '../ducks'
import { Types as AuthenticationTypes } from '../ducks/authentication'
import rootSaga from '../sagas'

const persistConfig = {
  key: 'root',
  storage,
}
const sagaMiddleware = createSagaMiddleware()

const resetEnhancer = (rootReducer) => (state, action) =>
  action.type === AuthenticationTypes.CLEAR_ALL
    ? rootReducer(undefined, {})
    : rootReducer(state, action)

const reduxStore = configureStore({
  reducer: persistReducer(persistConfig, resetEnhancer(rootReducer())),
  middleware: [sagaMiddleware, reduxImmutableStateInvariant()],
  devTools: process.env.NODE_ENV !== 'production',
})

const persistor = persistStore(reduxStore)

sagaMiddleware.run(rootSaga)

export { reduxStore, persistor }

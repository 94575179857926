import * as React from 'react'
import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import PaperOutlined from 'components/estilos/PaperOutlinedNoSpacing'
import formatarNumeroParaMoeda from '../../../../util/util'
import classes from './Tabela.style'
import CustomWidthTooltip from '../CustomWidthTooltip'
import IconeInformacao from '../../../../img/icone_informacao.svg'

const TabelaDividasEnviadasCbexPorOrgaoExecutor = ({ titulo, dados }) => (
  <TableContainer component={PaperOutlined} sx={classes.tableContainerWidth}>
    <Box sx={{ py: 1.2, px: 2 }}>
      <Typography
        variant="subtitle1"
        component="div"
        color="grey.800"
        style={{ height: 'auto', verticalAlign: 'center' }}>
        {titulo}{' '}
        <CustomWidthTooltip
          title={
            <Box>
              <Typography variant="body2" gutterBottom>
                O valor total enviado para cbex no ano considera a soma dos valores atualizados de
                cada dívida na data de envio da cbex.
              </Typography>
            </Box>
          }
          sx={{ color: 'grey.500' }}>
          <img
            src={IconeInformacao}
            style={{ width: '15px', height: 'auto', verticalAlign: '-2px' }}
            alt="Ícone de informação"
          />
        </CustomWidthTooltip>
      </Typography>
    </Box>
    <Divider />
    <Table size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell sx={classes.tableCellLabel}>Órgão executor</TableCell>
          <TableCell align="right" sx={classes.tableCellLabel}>Total enviado Cbex</TableCell>
          <TableCell align="right" sx={classes.tableCellLabel}>Percentual</TableCell>
          <TableCell align="right" sx={classes.tableCellLabel}>Quantidade de dívidas</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {dados?.dadosPorOrgao?.map((row, index) => (
          <TableRow key={`linha_${index}`} hover>
            <TableCell component="th" scope="row">
              <Typography variant="body2" sx={classes.cellContent}>
                {row.nomeOrgao}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
                {formatarNumeroParaMoeda(row.valorTotalDividas, 2, 2)}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" sx={classes.cellContent}>
                {row.percentualValorTotalDividas}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <CustomWidthTooltip
                title={
                  <Box>
                    <Typography variant="body2" gutterBottom>
                      {`${row.percentualQtdDividas} do total de dívidas enviadas`}
                    </Typography>
                  </Box>
                }
                sx={{ color: 'grey.500' }}>
                <Typography variant="body2" sx={classes.cellContent}>
                  {row.qtdDividas}
                </Typography>
              </CustomWidthTooltip>
            </TableCell>
          </TableRow>
        ))}

        <TableRow key="linha6" hover>
          <TableCell component="th" scope="row" sx={classes.total}>
            Total
          </TableCell>
          <TableCell align="right" sx={classes.total}>
            {formatarNumeroParaMoeda(dados?.valorTotalDividas, 2, 2)}
          </TableCell>
          <TableCell align="right" sx={classes.total}>
            {dados?.percentualValorTotalDividas}
          </TableCell>
          <TableCell align="right" sx={classes.total}>
            <CustomWidthTooltip
              title={
                <Box>
                  <Typography variant="body2" gutterBottom>
                    {`${dados?.percentualQtdDividas} do total de dívidas enviadas`}
                  </Typography>
                </Box>
              }
              sx={{ color: 'grey.500' }}>
              {dados?.qtdDividas}
            </CustomWidthTooltip>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
)

TabelaDividasEnviadasCbexPorOrgaoExecutor.propTypes = {
  titulo: PropTypes.string.isRequired,
  dados: PropTypes.object,
}

export default TabelaDividasEnviadasCbexPorOrgaoExecutor

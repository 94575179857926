import { React, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import _ from 'lodash'
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  Menu,
  MenuItem,
  Radio,
  TextField,
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import SearchIcon from '@mui/icons-material/Search'
import InputMask from 'react-input-mask'
import pessoaService from '../../services/pessoaService'
import processoService from '../../services/processoService'
import classes from './BarraPesquisaStyles'
import {
  removerMascaraCnpj,
  removerMascaraCpf,
  removerNumeroProcesso,
  validarCnpj,
  validarCpf,
} from '../../util/util'
import { Actions as AvisosActions } from '../../ducks/avisos'

const TIPO_PESQUISA = { PROCESSO: 'PROCESSO', CPF: 'CPF', CNPJ: 'CNPJ' }

const BarraPesquisa = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [anchorMenu, setAnchorMenu] = useState(null)
  const [anchorMenuRelatorio, setAnchorMenuRelatorio] = useState(null)
  const [tipoPesquisaSelecionada, setTipoPesquisaSelecionada] = useState(TIPO_PESQUISA.CPF)
  const [cpf, setCpf] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [numeroProcesso, setNumeroProcesso] = useState('')
  const [executandoPesquisa, setExecutandoPesquisa] = useState(false)
  const exibirMenu = Boolean(anchorMenu)

  const openMenuRelatorio = Boolean(anchorMenuRelatorio)

  const { possuiPerfilOperacaoDivida } = useSelector((state) => state.authentication)

  const handleClickMenuRelatorio = (event) => {
    setAnchorMenuRelatorio(event.currentTarget)
  }
  const handleCloseMenuRelatorio = () => {
    setAnchorMenuRelatorio(null)
  }

  const handleOpenMenu = (event) => {
    setAnchorMenu(event.currentTarget)
  }

  const handleFecharMenu = () => {
    setAnchorMenu(null)
  }

  const handleLimparCampos = () => {
    setCnpj('')
    setCpf('')
    setNumeroProcesso('')
  }

  const handleTipoPesquisaChange = (tipoPesquisa) => {
    setTipoPesquisaSelecionada(tipoPesquisa)
    handleFecharMenu()
    handleLimparCampos()
  }

  const handleNavegarTodasDividas = () => {
    navigate('dividas/pesquisa-avancada', {
      state: { limparPesquisa: true },
    })
    handleCloseMenuRelatorio()
  }

  const handleNavegarTodosRecolhimentos = () => {
    navigate('recolhimentos/pesquisa-avancada', {
      state: { limparPesquisa: true },
    })
    handleCloseMenuRelatorio()
  }

  const validarValorPesquisa = () => {
    let pesquisaValida = true
    if (tipoPesquisaSelecionada === TIPO_PESQUISA.CPF && !cpf) {
      dispatch(AvisosActions.erro(['CPF não informado.']))
      pesquisaValida = false
    }
    if (tipoPesquisaSelecionada === TIPO_PESQUISA.CNPJ && !cnpj) {
      dispatch(AvisosActions.erro(['CNPJ não informado.']))
      pesquisaValida = false
    }
    if (tipoPesquisaSelecionada === TIPO_PESQUISA.PROCESSO && !numeroProcesso) {
      dispatch(AvisosActions.erro(['Número de processo não informado.']))
      pesquisaValida = false
    }
    return pesquisaValida
  }

  const handleRecuperarPessoaPorCpf = async () => {
    if (!validarValorPesquisa()) return
    if (!validarCpf(cpf)) {
      dispatch(AvisosActions.erro(['CPF informado é inválido']))
      return
    }
    try {
      setExecutandoPesquisa(true)
      const response = await pessoaService.recuperarPessoasPorCpf(removerMascaraCpf(cpf))
      if (_.isEmpty(response.data)) {
        dispatch(AvisosActions.erro(['Nenhuma dívida encontrada para o CPF informado']))
        return
      }
      const idsPessoas = response.data.map((pessoa) => pessoa.id)
      navigate(`/dividas/responsaveis/${idsPessoas.join(',')}`)
    } catch (e) {
      dispatch(AvisosActions.erro([`Erro ao recuperar pessoas por cpf: ${e}`]))
    } finally {
      setExecutandoPesquisa(false)
    }
  }

  const handleRecuperarPessoaPorCnpj = async () => {
    if (!validarValorPesquisa()) return
    if (!validarCnpj(cnpj)) {
      dispatch(AvisosActions.erro([' CNPJ informado é inválido']))
      return
    }
    try {
      setExecutandoPesquisa(true)
      const response = await pessoaService.recuperarPessoasPorCnpj(removerMascaraCnpj(cnpj))
      if (_.isEmpty(response.data)) {
        dispatch(AvisosActions.erro(['Nenhuma dívida encontrada para o CNPJ informado']))
        return
      }
      const idsPessoas = response.data.map((pessoa) => pessoa.id)
      navigate(`/dividas/responsaveis/${idsPessoas.join(',')}`)
    } catch (e) {
      dispatch(AvisosActions.erro([`Erro ao recuperar pessoas por cnpj: ${e}`]))
    } finally {
      setExecutandoPesquisa(false)
    }
  }

  const handleRecuperarProcessoPorNumero = async () => {
    const numeroProcessoSemFormatacao = removerNumeroProcesso(numeroProcesso)
    if (!validarValorPesquisa()) return
    try {
      setExecutandoPesquisa(true)
      const response = await processoService.recuperarDadosProcessoPorNumeroSemFormatacao(
        numeroProcessoSemFormatacao
      )
      if (!response) {
        dispatch(AvisosActions.erro(['Processo não encontrado']))
        return
      }
      const codigoProcesso = response.data?.codigo
      navigate(`/dividas/processo/${codigoProcesso}`)
    } catch (e) {
      // nao precisa fazer nada, erro ja foi tratado no interceptor do axios
    } finally {
      setExecutandoPesquisa(false)
    }
  }

  const handlePesquisar = async () => {
    if (tipoPesquisaSelecionada === TIPO_PESQUISA.CPF) {
      handleRecuperarPessoaPorCpf()
      return
    }
    if (tipoPesquisaSelecionada === TIPO_PESQUISA.CNPJ) {
      handleRecuperarPessoaPorCnpj()
      return
    }
    handleRecuperarProcessoPorNumero()
  }

  return (
    <>
      <Box
        display="flex"
        sx={{ alignItems: 'center', border: '1px solid #DEDEDE', borderRadius: '4px' }}>
        <Button
          id="basic-button"
          aria-controls={exibirMenu ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={exibirMenu ? 'true' : undefined}
          endIcon={<ArrowDropDownIcon sx={{ marginLeft: 0 }} />}
          onClick={handleOpenMenu}
          sx={classes.botaoTipoPesquisa}>
          Acessar dívida por
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorMenu}
          open={exibirMenu}
          onClose={handleFecharMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}>
          <MenuItem onClick={() => handleTipoPesquisaChange(TIPO_PESQUISA.PROCESSO)}>
            <FormControlLabel
              checked={tipoPesquisaSelecionada === TIPO_PESQUISA.PROCESSO}
              value={TIPO_PESQUISA.PROCESSO}
              control={<Radio />}
              label="Processo"
            />
          </MenuItem>
          <MenuItem onClick={() => handleTipoPesquisaChange(TIPO_PESQUISA.CPF)}>
            <FormControlLabel
              checked={tipoPesquisaSelecionada === TIPO_PESQUISA.CPF}
              value={TIPO_PESQUISA.CPF}
              control={<Radio />}
              label="CPF"
            />
          </MenuItem>
          <MenuItem onClick={() => handleTipoPesquisaChange(TIPO_PESQUISA.CNPJ)}>
            <FormControlLabel
              checked={tipoPesquisaSelecionada === TIPO_PESQUISA.CNPJ}
              value={TIPO_PESQUISA.CNPJ}
              control={<Radio />}
              label="CNPJ"
            />
          </MenuItem>
        </Menu>
        {tipoPesquisaSelecionada === TIPO_PESQUISA.CPF && (
          <InputMask
            mask="999.999.999-99"
            onChange={(event) => setCpf(event.target.value)}
            value={cpf}
            disabled={false}
            maskChar="_">
            {() => (
              <TextField
                variant="standard"
                placeholder="___.___.___-__"
                sx={classes.campoPesquisa}
                InputProps={{
                  startAdornment: <InputAdornment position="start">CPF:</InputAdornment>,
                }}
              />
            )}
          </InputMask>
        )}
        {tipoPesquisaSelecionada === TIPO_PESQUISA.CNPJ && (
          <InputMask
            mask="99.999.999/9999-99"
            onChange={(event) => setCnpj(event.target.value)}
            value={cnpj}
            disabled={false}
            maskChar="_">
            {() => (
              <TextField
                variant="standard"
                placeholder="__.___.___/____-__"
                sx={classes.campoPesquisa}
                InputProps={{
                  startAdornment: <InputAdornment position="start">CNPJ:</InputAdornment>,
                }}
              />
            )}
          </InputMask>
        )}
        {tipoPesquisaSelecionada === TIPO_PESQUISA.PROCESSO && (
          <InputMask
            mask="999.999/9999-9"
            onChange={(event) => setNumeroProcesso(event.target.value)}
            value={numeroProcesso}
            disabled={false}
            maskChar="_">
            {() => (
              <TextField
                variant="standard"
                placeholder="___.___/____-_"
                sx={classes.campoPesquisa}
                InputProps={{
                  startAdornment: <InputAdornment position="start">PROCESSO:</InputAdornment>,
                }}
              />
            )}
          </InputMask>
        )}
        <Button
          disabled={executandoPesquisa}
          sx={classes.botaoPesquisa}
          variant="contained"
          onClick={() => handlePesquisar()}>
          {executandoPesquisa ? <CircularProgress size={25} /> : <SearchIcon />}
        </Button>
      </Box>

      <Button
        id="botao-relatorios"
        aria-controls={openMenuRelatorio ? 'relatorios-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openMenuRelatorio ? 'true' : undefined}
        onClick={handleClickMenuRelatorio}
        endIcon={<ArrowDropDownIcon />}
        sx={classes.botaoPesquisaDividas}
        variant="outlined">
        Relatórios
      </Button>
      <Menu
        id="relatorios-menu"
        anchorEl={anchorMenuRelatorio}
        open={openMenuRelatorio}
        onClose={handleCloseMenuRelatorio}
        MenuListProps={{
          'aria-labelledby': 'menu de relatórios',
        }}>
        <MenuItem onClick={handleNavegarTodasDividas}>Todas as dívidas</MenuItem>
        <MenuItem onClick={handleNavegarTodosRecolhimentos}>Pagamentos administrativos</MenuItem>
        {possuiPerfilOperacaoDivida && (
          <MenuItem onClick={handleCloseMenuRelatorio} component={NavLink} to="/relatorio-pagtesouro">
            Pagamentos via PagTesouro
          </MenuItem>
        )}
        <MenuItem onClick={handleCloseMenuRelatorio} component={NavLink} to="/relatorios">
          Relatórios gerenciais
        </MenuItem>
      </Menu>
    </>
  )
}

export default BarraPesquisa

import * as React from 'react'
import { Popover, Typography, Box, Divider } from '@mui/material'
import PropTypes from 'prop-types'

const PopoverFundamentosMulta = ({ textoPopover, motivosMulta = [], chave }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <span key={`span_externo_${chave}`}>
      <span
        key={`span_${chave}`}
        style={{ color: '#5F98FF', paddingLeft: '4px' }}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}>
        {textoPopover}
      </span>
      <Popover
        key={`popover_${chave}`}
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        <Box px={1.5} py={1} sx={{ border: '1px solid #bdbdbd' }} key={`box_${chave}`}>
          {motivosMulta.map((it, index) => (
            <span key={`span_interno_${index}`}>
              {index !== 0 && <Divider key={`divider_${index}_${chave}`} />}
              <Typography variant="body2" pt={1} gutterBottom key={`motivo_${index}_${chave}`}>
                {`${it.descricaoFundamentolegal} - ${it.descricaoMotivo}`}
              </Typography>
            </span>
          ))}
        </Box>
      </Popover>
    </span>
  )
}

PopoverFundamentosMulta.propTypes = {
  textoPopover: PropTypes.string,
  motivosMulta: PropTypes.array.isRequired,
  chave: PropTypes.string,
}

export default PopoverFundamentosMulta

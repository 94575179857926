export const Types = {
  PROGRESS: '@PROGRESS_BAR'
}

export const Actions = {
  progress: (operationInProgress) => ({ type: Types.PROGRESS, operationInProgress })
}

export default (state = { operationInProgress: false }, action) => {
  switch (action.type) {
    case Types.PROGRESS: {
      return { operationInProgress: action.operationInProgress }
    }
    default:
      return state
  }
}

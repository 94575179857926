import { Actions as progressBar } from '../ducks/operationInProgress'
import { reduxStore } from '../store/reduxStore'

export const showProgressBar = () => {
  reduxStore.dispatch(progressBar.progress(true))
}

export const closeProgressBar = () => {
  reduxStore.dispatch(progressBar.progress(false))
}

export const onFullfilledHttpInProgressRequest = (config) => {
  if (!config.noLoading) {
    showProgressBar()
  }

  return Promise.resolve(config)
}

export const onRejectedHttpInProgressRequest = (err) => {
  closeProgressBar()
  return Promise.reject(err)
}

export const onFullfilledHttpInProgressResponse = (config) => {
  closeProgressBar()
  return Promise.resolve(config)
}

export const onRejectedHttpInProgressResponse = (err) => {
  closeProgressBar()
  return Promise.reject(err)
}

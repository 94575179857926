import { Box, Button, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import PropTypes from 'prop-types'
import InfoIcon from '@mui/icons-material/Info'
import PaperOutlined from '../../estilos/PaperOutlined'
import { colunasListagemCbex as colunas } from '../Divida'
import MensagemResultadoVazio from '../../comum/MensagemResultadoVazio'
import HeaderList from '../../estilos/HeaderList'

const DividasCbex = ({
  selecionados = [],
  setSelecionados,
  cobrancaExecutiva = {},
  encerrado = false,
  onCancelar,
  onConcluir,
}) => (
  <>
    <Box sx={{ marginTop: '10px', backgroundColor: 'background.paper' }}>
      <PaperOutlined
        sx={{
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
          borderBottom: '0px solid rgba( 0,0,0, 0)',
        }}>
        <Typography variant="h6" color="textPrimary">
          Dívidas atualmente vinculadas à Cbex
        </Typography>
      </PaperOutlined>
      <DataGrid
        rows={cobrancaExecutiva.dividasAssociadasCbex}
        disableColumnMenu
        columns={colunas.map((it) => ({ ...it, sortable: false }))}
        autoHeight
        hideFooter
        headerHeight={42}
        components={{
          NoRowsOverlay: MensagemResultadoVazio,
        }}
        componentsProps={{
          pagination: { classes: null },
        }}
        sx={{
          borderRadius: '0 0 4px 4px',
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer',
            '& .forwardIconHover ': {
              visibility: 'visible',
            },
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            color: 'grey.500',
            fontWeight: 400,
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '.MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
          },
        }}
      />
    </Box>
    <Box sx={{ marginTop: '10px', backgroundColor: 'background.paper' }}>
      <HeaderList>Selecione as dívidas a serem vinculadas ao processo Cbex</HeaderList>
      <PaperOutlined>
        <Box gap={1} py={1} alignItems="center" display="flex">
          <InfoIcon sx={{ color: 'grey.400', fontSize: 14 }} />
          <Typography variant="body1" color="textprimary">
            As dívidas canceladas não estão disponíveis para seleção e caso já estejam vinculas ao
            processo CBEX serão automaticamente removidas
          </Typography>
        </Box>
      </PaperOutlined>
      <DataGrid
        rows={cobrancaExecutiva.dividasAptasAssociacaoProcessoOriginador}
        disableColumnMenu
        columns={colunas.map((it) => ({ ...it, sortable: false }))}
        checkboxSelection
        selectionModel={selecionados}
        onSelectionModelChange={(rowSelectionModel) => {
          setSelecionados(rowSelectionModel)
        }}
        autoHeight
        hideFooterPagination
        headerHeight={42}
        components={{
          NoRowsOverlay: MensagemResultadoVazio,
        }}
        componentsProps={{
          pagination: { classes: null },
        }}
        sx={{
          borderRadius: '0 0 4px 4px',
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer',
            '& .forwardIconHover ': {
              visibility: 'visible',
            },
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            color: 'grey.500',
            fontWeight: 400,
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '.MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
          },
        }}
      />
    </Box>
    <PaperOutlined>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-end',
          gap: '16px',
          rowGap: '16px',
        }}>
        <Button color="primary" onClick={onCancelar}>
          Cancelar
        </Button>
        {!encerrado && (
          <Button color="primary" variant="contained" onClick={() => onConcluir(selecionados)}>
            Avançar
          </Button>
        )}
      </Box>
    </PaperOutlined>
  </>
)

DividasCbex.propTypes = {
  cobrancaExecutiva: PropTypes.object,
  onCancelar: PropTypes.func,
  onConcluir: PropTypes.func,
  encerrado: PropTypes.bool,
  selecionados: PropTypes.array,
  setSelecionados: PropTypes.func,
}
export default DividasCbex

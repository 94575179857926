import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import ContentBox from '../../estilos/ContentBox'
import PaperOutlined from '../../estilos/PaperOutlined'
import dividaService from '../../../services/dividaService'
import classes from './PainelAlertas.style'
import {
  renderIconeAlertaPorTipoSeveridade,
  TIPO_SEVERIDADE_GRAVE,
  TIPO_SEVERIDADE_GRAVISSIMA,
  TIPO_SEVERIDADE_LEVE,
  TIPO_SEVERIDADE_MEDIA,
} from '../detalhar/alerta/Alertas'
import TabsNavegacao from '../TabsNavegacao'
import PainelAlertasSkeleton from '../skeleton/PainelAlertasSkeleton'
import { ABA_PAINEL_INICIAL } from '../ConstantesDivida'

const PainelAlertas = () => {
  const navigate = useNavigate()
  const { state: propsNavegacao } = useLocation()

  // const usuarioLogadoPossuiPerfilOperacaoDivida = useSelector(
  //  ({ authentication: { possuiPerfilOperacaoDivida } }) => possuiPerfilOperacaoDivida
  // )
  const { possuiPerfilFazerComentario } = useSelector((state) => state.authentication)

  const { operationInProgress } = useSelector((state) => state.operationInProgress)
  const [resumoTotalizadores, setResumoTotalizadores] = useState()
  const [semComentarios, setSemComentarios] = useState(propsNavegacao?.semComentarios || false)
  const [alertasAgrupadosSeveridade, setAlertasAgrupadosSeveridade] = useState([])

  // if (!usuarioLogadoPossuiPerfilOperacaoDivida) {
  //   navigate('/')
  // }

  const handleAgrupadorSeveridadeClick = (severidadeAlerta) => {
    navigate('/dividas-com-alertas', {
      state: { severidadeAlerta, semComentarios },
    })
  }

  const handleTipoAlertaClick = (tipoAlerta) => {
    navigate('/dividas-com-alertas', {
      state: { tipoAlerta, semComentarios },
    })
  }

  const recuperarAlertas = async (semComentarios) => {
    const { data } = await dividaService.recuperarAlertasAgrupadosSeveridade(semComentarios)
    setAlertasAgrupadosSeveridade(data)
  }

  const recuperarResumoTotalizadores = async (semComentarios) => {
    const response = await dividaService.recuperarResumoTotalizadores(semComentarios)
    setResumoTotalizadores(response.data)
  }

  useEffect(() => {
    recuperarAlertas(semComentarios)
    recuperarResumoTotalizadores(semComentarios)
  }, [semComentarios])

  const montarLabelAgrupadorSeveridade = (agrupadorSeveridade) => {
    let descricaoAgrupadorAlertasSeveridade
    switch (agrupadorSeveridade?.tipoSeveridade) {
      case TIPO_SEVERIDADE_LEVE:
        descricaoAgrupadorAlertasSeveridade = 'Alertas leves'
        break
      case TIPO_SEVERIDADE_MEDIA:
        descricaoAgrupadorAlertasSeveridade = 'Alertas médios'
        break
      case TIPO_SEVERIDADE_GRAVE:
        descricaoAgrupadorAlertasSeveridade = 'Alertas graves'
        break
      case TIPO_SEVERIDADE_GRAVISSIMA:
        descricaoAgrupadorAlertasSeveridade = 'Alertas gravíssimos'
        break
      default:
        break
    }
    return (
      <Box
        display="flex"
        gap={1}
        onClick={() => handleAgrupadorSeveridadeClick(agrupadorSeveridade.tipoSeveridade)}>
        <IconButton size="small" sx={classes.iconeAlerta}>
          {renderIconeAlertaPorTipoSeveridade(agrupadorSeveridade?.tipoSeveridade)}
        </IconButton>
        <Box sx={classes.agrupadorClicavel}>
          <Typography variant="h6">{descricaoAgrupadorAlertasSeveridade}</Typography>
          <Typography variant="h6" sx={{ color: 'primary.light' }}>
            {agrupadorSeveridade.totalAlertas}
          </Typography>
        </Box>
      </Box>
    )
  }

  const renderDescricaoTipoAlerta = (tipo) => (
    <Tooltip title={tipo.descricaoTipoAlerta}>
      <Typography variant="body1" sx={classes.descricaoTipoAlerta}>
        {tipo.nome}
      </Typography>
    </Tooltip>
  )

  const renderAlertasAgrupadosTipo = (alertasPorTipo) => (
    <Box display="grid" sx={classes.gridAlertasPorTipo}>
      {alertasPorTipo.map((tipo, index) => (
        <>
          <Box
            key={tipo.tipoAlerta}
            display="flex"
            sx={classes.agrupadorClicavel}
            onClick={() => handleTipoAlertaClick(tipo.tipoAlerta)}>
            <Box sx={classes.textoOverflow}>{renderDescricaoTipoAlerta(tipo)}</Box>
            <Box display="flex" justifyContent="flex-end">
              <Typography variant="body1" align="right" sx={classes.totalAlertasTipo}>
                {tipo.totalAlertas}
              </Typography>
            </Box>
          </Box>
          {index % 2 === 0 && (
            <Box display="flex" alignItems="center">
              {' '}
              <Divider orientation="vertical" flexItem />{' '}
            </Box>
          )}
        </>
      ))}
    </Box>
  )

  return operationInProgress ? (
    <PainelAlertasSkeleton />
  ) : (
    <ContentBox>
      <Box sx={classes.boxTitulo} mb={2}>
        <Box>
          <Typography variant="h2" component="h1" color="primary">
            Alertas em Dívidas
          </Typography>
          <Box sx={{ minHeight: '22px' }}>
            {resumoTotalizadores && (
              <Box display="flex" gap="4px" alignItems="center">
                <Typography variant="body1" component="h2" color="textSecondary">
                  Total de alertas
                </Typography>
                <Typography variant="body1" sx={{ color: 'primary.light' }}>
                  {resumoTotalizadores.totalAlertas}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <TabsNavegacao tabSelecionada={ABA_PAINEL_INICIAL.ALERTAS} />
      </Box>
      <Box display="flex" justifyContent="flex-end">
        {possuiPerfilFazerComentario && (
          <FormControlLabel
            sx={{
              marginBottom: 2,
              color: 'primary.main',
            }}
            control={
              <Switch
                size="small"
                checked={semComentarios}
                onChange={({ target }) => setSemComentarios(target.checked)}
              />
            }
            label="Apenas sem comentários"
          />
        )}
      </Box>
      {alertasAgrupadosSeveridade &&
        alertasAgrupadosSeveridade.map((severidade) => (
          <PaperOutlined key={severidade.tipoSeveridade} sx={{ p: 0, mb: 3 }}>
            <Box
              sx={{
                borderRadius: '4px 4px 0 0',
                padding: '8px',
                backgroundColor:
                  severidade.tipoSeveridade === 'GRAVE' ? 'primary.main' : 'background.paper',
                color: severidade.tipoSeveridade === 'GRAVE' ? '#fff' : 'text.secondary',
              }}>
              {montarLabelAgrupadorSeveridade(severidade)}
            </Box>
            <Divider />
            {renderAlertasAgrupadosTipo(severidade.alertasAgrupadosTipo)}
          </PaperOutlined>
        ))}
    </ContentBox>
  )
}

export default PainelAlertas

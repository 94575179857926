const classes = {
  total: {
    color: '#000'
  },
  totalUltimaColuna: {
    border: '1px solid #e0e0e0',
    fontWeight: '400',
  },
  colunaCustomizada: {
    borderRight: '1px solid #e0e0e0',
    fontWeight: '400',
  },
  colunaFixa: {
    borderRight: '1px solid #e0e0e0',
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
  },
  colunaVazia: {
    minWidth: '330px',
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
  },
  colunaCustomizadaSemNegrito: {
    borderRight: '1px solid #e0e0e0',
    fontWeight: '400',
  },
  tableCellLabel: {
    color: 'grey.700',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '1.15em'
  },
  cellContent:{
    color: 'grey.700'
  },
  tableContainerWidth: {
    flexGrow: 1,
    maxWidth: '950px'
  }
}

export default classes

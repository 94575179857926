import _ from 'lodash'
import { Actions as AvisosActions } from '../ducks/avisos'
import { reduxStore } from '../store/reduxStore'
import { Actions as sigaActions } from '../ducks/authentication'
import history from '../store/history'

const LABEL_NOME_SISTEMA = 'dívida'

function disparaEventosParaRedirecionarParaLogin() {
  reduxStore.dispatch(sigaActions.clearAll())
  reduxStore.dispatch(sigaActions.authenticateSiga(window.location.origin))
}

function semMensagem() {
  return { mensagens: null, detalhe: null, erro: false }
}

function erro(mensagens, detalhe) {
  return { mensagens, detalhe, erro: true }
}

function alerta(mensagens, detalhe) {
  return { mensagens, detalhe, erro: false }
}

export function aviso(err, ignorarErroHttpCodigo) {
  const { status } = err.response
  const { message } = err

  if (ignorarErroHttpCodigo === status) {
    return semMensagem()
  }

  switch (true) {
    case status === 400:
      return erro([`Requisição ao serviço de ${LABEL_NOME_SISTEMA} está inválida`], message)
    case status === 412: {
      const violacoes = _.get(err, 'response.data.violacoes')
      if (!_.isNil(violacoes)) {
        const errosFiltrados = violacoes.filter((v) => v.tipo === 'ERRO').map((v) => v.mensagem)
        if (!_.isEmpty(errosFiltrados)) {
          return erro([errosFiltrados])
        }
        const alertasFiltados = violacoes.filter((v) => v.tipo === 'ALERTA').map((v) => v.mensagem)
        if (!_.isEmpty(alertasFiltados)) {
          return alerta([alertasFiltados])
        }
      }
      return erro(
        [`O serviço do sistema de ${LABEL_NOME_SISTEMA} considerou essa ação inválida`],
        'Apesar disso, não conseguimos identificar qual problema.'
      )
    }
    case status === 404:
      return erro(['Recurso solicitado não foi encontrado'], message)
    case status === 504:
      return erro([
        `Serviço de ${LABEL_NOME_SISTEMA} está indisponível. Tente mais tarde novamente.`,
      ])
    case status >= 500:
      return erro(
        [
          `Ocorreu um erro interno no serviço de ${LABEL_NOME_SISTEMA}.
            Tente mais tarde novamente.`,
        ],
        message
      )
    default:
      return erro([`Ocorreu um erro no serviço. Por favor tente novamente.`])
  }
}
export const onRejectedHttpError = (err, ignorarErroHttpCodigo = null) => {
  if (!err.response || [401].includes(err.response.status)) {
    disparaEventosParaRedirecionarParaLogin()
    return
  }

  const { status } = err.response
  const { mensagens, detalhe, erro } = aviso(err, ignorarErroHttpCodigo)

  if (erro) {
    if (status >= 500) {
      // eslint-disable-next-line no-restricted-globals
      history.push({
        pathname: `/erroNoServidor`,
        search: `?detalhe=${detalhe}&mensagens=${mensagens}&erro=${erro}`,
      })
      throw erro
    }

    if (status === 403) {
      mensagens.length = 0
      mensagens.push('Usuário sem permissão para acessar a funcionalidade')
    }

    reduxStore.dispatch(AvisosActions.erro(mensagens, detalhe))
    throw erro
  } else if (mensagens) {
    reduxStore.dispatch(AvisosActions.alerta(mensagens, detalhe))
    throw erro
  }
}

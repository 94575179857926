const classes = {
  root: {
    flexGrow: 1,
    zIndex: 2000,
  },
  appBar: {
    backgroundColor: 'background.paper',
    boxShadow: 0,
  },
  avatarUser: {
    color: 'text.primary',
    cursor: 'pointer',
  },
  boxSeparator: {
    flexGrow: 1,
  },
  home: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    width: 40,
    height: 40,
    margin: 1,
    '& img': {
      width: 40,
      height: 40,
    },
  },
  tituloSistema: {
    display: { xs: 'none', sm: 'inline' },
  },
  desativarMobile: {
    display: { xs: 'none', sm: 'inherit' },
  },
}

export default classes

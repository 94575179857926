import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useEffect, useState } from 'react'
import { Alert as MuiAlert, Snackbar as MuiSnackbar, Typography } from '@mui/material'
import _ from 'lodash'
import DetailsIcon from '@mui/icons-material/Details'
import CloseIcon from '@mui/icons-material/Close'
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory'
import PropTypes from 'prop-types'
import { Actions as AvisosActions } from '../../ducks/avisos'

// eslint-disable-next-line prefer-arrow-callback
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const CloseButon = ({ close }) => <CloseIcon aria-label="Close" color="inherit" onClick={close} />

CloseButon.propTypes = {
  close: PropTypes.func.isRequired,
}

const DetailButon = ({ open, detail }) =>
  !open ? <DetailsIcon onClick={detail} /> : <ChangeHistoryIcon onClick={detail} />

DetailButon.propTypes = {
  open: PropTypes.func.isRequired,
  detail: PropTypes.func.isRequired,
}

const Snackbar = ({ property, close, severity }) => {
  const [detalhar, setDetalhar] = useState(false)
  const [actions, setActions] = useState([])

  const handleDetalhar = useCallback(() => {
    setDetalhar(!detalhar)
  }, [detalhar])

  useEffect(() => {
    const newActions = []
    if (!_.isNil(property) && !_.isNil(property.detalhe)) {
      newActions.push(
        <DetailButon key={`detail${severity}`} open={detalhar} detail={handleDetalhar} />
      )
    }
    newActions.push(<CloseButon key={`close${severity}`} close={close} />)
    setActions(newActions)
  }, [property, detalhar, close, severity, handleDetalhar])

  return !_.isNil(property) ? (
    <MuiSnackbar key={severity} open autoHideDuration={5000} onClose={close}>
      <Alert onClose={close} severity={severity} action={actions} data-testid="alerta">
        {property.mensagens?.map((mensagem, index) => (
          <Typography key={`severity${index}`}>{mensagem}</Typography>
        ))}
        <Typography>{detalhar ? property.detalhe : null}</Typography>
      </Alert>
    </MuiSnackbar>
  ) : null
}

Snackbar.propTypes = {
  property: PropTypes.object,
  close: PropTypes.func.isRequired,
  severity: PropTypes.string.isRequired,
}

const Alertas = () => {
  const erros = useSelector(({ avisos: { erro } }) => erro)

  const alertas = useSelector(({ avisos: { alerta } }) => alerta)

  const informacoes = useSelector(({ avisos: { info } }) => info)

  const sucessos = useSelector(({ avisos: { sucesso } }) => sucesso)

  // const teste = {
  //   mensagens: ['mensagem de teste'],
  //   detalhe: null,
  // }

  const dispatch = useDispatch()

  const handleFechar = () => {
    dispatch(AvisosActions.limpar())
  }

  return (
    <>
      <Snackbar property={erros} close={handleFechar} severity="error" />
      <Snackbar property={alertas} close={handleFechar} severity="warning" />
      <Snackbar property={informacoes} close={handleFechar} severity="info" />
      <Snackbar property={sucessos} close={handleFechar} severity="success" />
    </>
  )
}

export default Alertas

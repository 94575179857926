import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import {Typography, Tooltip} from '@mui/material';

const OverflowTooltip = ({ title, children }) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef();
  useEffect(() => {
    setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
  }, []);
  return (
    <Tooltip arrow title={title} disableHoverListener={!isOverflowed}>
      <Typography
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        component="span"
      >
        {children}
      </Typography>
    </Tooltip>
  );
};

OverflowTooltip.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node
}

export default OverflowTooltip
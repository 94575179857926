import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const PaperOutlined = styled(Paper)(({ theme }) => ({
  padding: 12,
  boxShadow: 'none',
  border: '1px solid',
  borderColor: theme.palette.divider
}));

export default PaperOutlined
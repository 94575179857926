import axios from 'axios'
import gerarDocumento from '../components/comum/funcoesUtil'

const urlDividas = '/bff/dividas'

const recuperarRecolhimentosPorFiltro = (filtros, paginaAtual, tamanhoPagina) =>
  axios.post(
    `${urlDividas}/pesquisar-recolhimentos-por-filtro?paginaAtual=${paginaAtual}&tamanhoPagina=${tamanhoPagina}`,
    filtros
  )

const exportarRecolhimentosPorFiltroParaCSV = (filtros) => {
  axios.post(`${urlDividas}/exportar-recolhimentos-para-csv`, filtros).then((response) => {
    gerarDocumento(response.data, 0, 'recolhimentos', 'text/csv')
  })
}

export default {
  recuperarRecolhimentosPorFiltro,
  exportarRecolhimentosPorFiltroParaCSV,
}

import { TableCell, TableRow, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const NenhumResultadoEncontrado = ({ numeroColSpan }) => (
  <TableRow>
    <TableCell component="th" scope="row" colSpan={numeroColSpan}>
      <Typography variant="body2">Nenhum resultado encontrado</Typography>
    </TableCell>
  </TableRow>
)

NenhumResultadoEncontrado.propTypes = {
  numeroColSpan: PropTypes.number.isRequired,
}

export default NenhumResultadoEncontrado

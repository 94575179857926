import { Box, Divider, Skeleton, Typography } from '@mui/material'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab'
import PaperOutlined from '../../estilos/PaperOutlined'

const LinhaDoTempo = () => (
  <PaperOutlined>
    <Typography variant="h5" gutterBottom>
      Linha do tempo
    </Typography>
    <Divider />
    <Box>
      <Timeline
        position="right"
        sx={{
          paddingLeft: 0,
          '& .MuiTimelineItem-root:before': {
            flex: 0,
          },
        }}>
        {Array(3)
          .fill('')
          .map((marco, index) => (
            <TimelineItem key={index}>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                {index !== 2 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="subtitle2">
                  <Skeleton width={70} />
                </Typography>
                <Typography variant="body2">
                  <Skeleton width={150} />
                </Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
      </Timeline>
    </Box>
  </PaperOutlined>
)

export default LinhaDoTempo

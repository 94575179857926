import { Box } from '@mui/material'
import BgSkeleton from '../../../img/skeleton-background.svg'

const ListagemDividasPorProcessoSkeleton = () => (
  <Box
    sx={{
      height: '100%',
      width: '100%',
      backgroundColor: '#fff',
      position: 'relative',
      zIndex: 1000,
    }}>
    <Box variant="text"
       sx={{ width: '100%',
         height: '100%',
         backgroundImage: `url(${BgSkeleton})`,
         animation: 'animation-c7515d 1.5s ease-in-out 0.5s infinite',
         backgroundSize: '100% 52px'
        }}
    />
  </Box>
)

export default ListagemDividasPorProcessoSkeleton

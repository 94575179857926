import React, { useState } from 'react'
import { Route, Routes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Box, Slide } from '@mui/material'
import DividasPorProcesso from '../components/divida/processo/DividasPorProcesso'
import PainelDebitosEMultasPorFase from '../components/divida/PainelDebitosEMultasPorFase'
import ListagemDividas from '../components/divida/ListagemDividas'
import AppToolbar from '../components/comum/AppToolbar'
import ErroNoServidor from '../erros/ErroNoServidor'
import Alertas from '../components/comum/Alertas'
import Login from '../security/Login'
import SSO from '../security/SSO'
import AuthenticatedRoute from './AuthenticatedRoute'
import history from '../store/history'
import DividaDetalhada from '../components/divida/detalhar/DividaDetalhada'
import DividasPorResponsavel from '../components/divida/responsavel/DividasPorResponsavel'
import DividasComAlertas from '../components/divida/detalhar/alerta/DividasComAlertas'
import PainelAlertas from '../components/divida/alerta/PainelAlertas'
import PesquisaAvancadaDividas from '../components/divida/pesquisaavancada/PesquisaAvancadaDividas'
import AutuacaoProcesso from '../components/divida/processo/AutuacaoProcesso'
import PainelCitacoesPorFase from '../components/divida/PainelCitacoesPorFase'
import TelaInicialUsuarioExterno from '../components/divida/TelaInicialUsuarioExterno'
import PagTesouro from '../components/divida/pagtesouro/PagTesouro'
import RelatorioPagTesouro from '../components/divida/pagtesouro/relatorio/RelatorioPagTesouro'
import AlteracaoVinculoCbex from '../components/divida/vinculocbex/AlteracaoVinculoCbex'
import MenuLateral from '../components/comum/MenuLateral'
import CalculadoraDebito from '../components/divida/calculadoradebito/CalculadoraDebito'
import PainelRetrospectiva from '../components/divida/painel/PainelRetrospectiva'
import PesquisaAvancadaRecolhimentos from '../components/divida/recolhimento/PesquisaAvancadaRecolhimentos'
import Relatorios from '../components/divida/painel/Relatorios'

const MenuLateralWithRef = React.forwardRef((props, ref) => (
  <div ref={ref}>
    <MenuLateral {...props} />
  </div>
))

MenuLateralWithRef.displayName = 'MenuLateralWithRef'
const AppRoutes = (props) => {
  const { toggleTheme, isDarkTheme } = props

  const [openMenuLateral, setOpenMenuLateral] = useState(false)

  const { seUsuarioInternoTcu, possuiPerfilAlterarVinculoCbex } = useSelector(
    (state) => state.authentication
  )

  return (
    <HistoryRouter history={history}>
      <AppToolbar
        isDarkTheme={isDarkTheme}
        toggleTheme={toggleTheme}
        toggleMenuLateral={() => setOpenMenuLateral(!openMenuLateral)}
        onClick={() => setOpenMenuLateral(false)}
      />
      <Box
        id="main-container"
        sx={{ display: 'flex', flexDirection: 'row', rowGap: '0px' }}
        onClick={() => setOpenMenuLateral(false)}>
        {seUsuarioInternoTcu && (
          <Slide direction="right" in={openMenuLateral} mountOnEnter unmountOnExit>
            <MenuLateralWithRef
              open={openMenuLateral}
              onClose={() => setOpenMenuLateral(false)}
              container={document.getElementById('main-container')}
            />
          </Slide>
        )}
        <Routes>
          <Route
            index
            path="/"
            element={<AuthenticatedRoute Componente={PainelDebitosEMultasPorFase} />}
          />
          <Route
            index
            path="/painel-citacoes"
            element={<AuthenticatedRoute Componente={PainelCitacoesPorFase} />}
          />
          <Route
            index
            path="/pagtesouro"
            element={<AuthenticatedRoute Componente={PagTesouro} />}
          />
          <Route
            index
            path="/relatorio-pagtesouro"
            element={<AuthenticatedRoute Componente={RelatorioPagTesouro} />}
          />

          <Route path="/login" element={<Login />} />
          <Route path="/sso" element={<SSO />} />
          <Route path="/calculadora-debito" element={<CalculadoraDebito />} />
          <Route
            path="/tela-inicial-usuario-externo"
            element={<AuthenticatedRoute Componente={TelaInicialUsuarioExterno} />}
          />
          <Route path="/dividas" element={<AuthenticatedRoute Componente={ListagemDividas} />} />
          <Route
            path="/dividas/pesquisa-avancada"
            element={<AuthenticatedRoute Componente={PesquisaAvancadaDividas} />}
          />
          <Route
            path="/dividas/:id"
            element={<AuthenticatedRoute Componente={DividaDetalhada} />}
          />
          <Route
            path="/dividas/responsaveis/:idsResponsaveis"
            element={<AuthenticatedRoute Componente={DividasPorResponsavel} />}
          />
          <Route
            path="/dividas/processo/:idProcesso"
            element={<AuthenticatedRoute Componente={DividasPorProcesso} />}
          />
          <Route
            path="/dividas/autuacao"
            element={<AuthenticatedRoute Componente={AutuacaoProcesso} />}
          />
          <Route
            path="/dividas-com-alertas"
            element={<AuthenticatedRoute Componente={DividasComAlertas} />}
          />
          {possuiPerfilAlterarVinculoCbex && (
            <Route
              path="/alteracao-vinculo-cbex"
              element={<AuthenticatedRoute Componente={AlteracaoVinculoCbex} />}
            />
          )}
          <Route path="/alertas" element={<AuthenticatedRoute Componente={PainelAlertas} />} />
          <Route
            path="/retrospectiva"
            element={<AuthenticatedRoute Componente={PainelRetrospectiva} />}
          />
          <Route path="/relatorios" element={<AuthenticatedRoute Componente={Relatorios} />} />
          <Route path="/erroNoServidor" element={<ErroNoServidor />} />
          <Route
            path="/recolhimentos/pesquisa-avancada"
            element={<AuthenticatedRoute Componente={PesquisaAvancadaRecolhimentos} />}
          />
        </Routes>
      </Box>

      <Alertas />
    </HistoryRouter>
  )
}

AppRoutes.propTypes = {
  toggleTheme: PropTypes.func,
  isDarkTheme: PropTypes.bool,
}

export default AppRoutes

import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Box, Button, Tooltip, Typography } from '@mui/material'
import GetPDF from '@mui/icons-material/PictureAsPdf'
import { useDispatch } from 'react-redux'
import moment from 'moment/moment'
import { isEmpty } from 'lodash'

import classes from './CalculadoraDebitoStyle'
import ParcelasDebito from './ParcelasDebito'
import { Actions as AvisosActions } from '../../../ducks/avisos'
import calculadoraDebitoService from '../../../services/calculadoraDebitoService'
import validarArquivo from './validacaoArquivo'
import DadosComplementaresDebito from './DadosComplementaresDebito'
import InclusaoParcela from './InclusaoParcela'
import CalculoSaldo from './CalculoSaldo'
import ProgressBar from '../../comum/ProgressBar'
import calcularSeqItemParcela from './funcoesAuxiliares'
import ParametrosGerais from './ParametrosGerais'
import MensagemFormatoArquivo from './MensagemFormatoArquivo'
import DialogoConfirmacaoColarParcelas from './DialogoConfirmacaoColarParcelas'
import conteudoParaParcelas, {isDate, MASCARA} from "./copiarColarParcelas";

const OPERACAO_GERAR_DEMONSTRATIVO_DEBITO = 'gerar demonstrativo do débito'
const INICIAL_APLICAR_JUROS = true
const OPERACAO_CALCULAR_SALDO_DEBITO = 'calcular saldo do débito'

const validarParcela = ({ dataFato, valor, valorOriginal, seqItem }) => {
  const erros = []
  if (!isDate(dataFato)) {
    erros.push(`a data ${dataFato} é invalida`)
  }
  if (!valorOriginal) {
    erros.push(`o valor ${valor} é inválido`)
  }
  if (erros.length > 0) {
    return [`Na linha ${seqItem}`, ...erros].join(', ')
  }
  return undefined
}

const todasParcelasValidas = (parcelasExcel, dispatch) => {
  const erros = parcelasExcel.map((p) => validarParcela(p)).filter((e) => e !== undefined)
  if (erros.length > 0) {
    dispatch(AvisosActions.erro(erros))
    return false
  }
  return true
}

const CalculadoraDebito = () => {
  const dispatch = useDispatch()
  const [dataAtualizacao, setDataAtualizacao] = useState(moment().format('DD/MM/yyyy'))
  const [dataInicioParcelamento, setDataInicioParcelamento] = useState(null)
  const [aplicaJuros, setAplicaJuros] = useState(INICIAL_APLICAR_JUROS)
  const [parcelasDebito, setParcelasDebito] = useState([])
  const [dadosComplementaresDebito, setDadosComplementaresDebito] = useState({})
  const [saldosDebito, setSaldosDebito] = useState(null)
  const [uploadKey, setUploadKey] = useState(0)
  const [parcelasExcel, setParcelasExcel] = useState(undefined)

  useEffect(() => {
    const colarExcel = (event) => {
      const texto = event.clipboardData.getData('Text')
      const parcelas = conteudoParaParcelas(texto)
      setParcelasExcel(parcelas)
    }
    document.addEventListener('paste', colarExcel)
    return () => window.removeEventListener('paste', colarExcel)
  }, [dispatch])

  useEffect(() => {
    if (aplicaJuros) {
      setDataInicioParcelamento(null)
    }
    setSaldosDebito(null)
  }, [aplicaJuros])

  const validarData = (data, nome) => {
    if (dataAtualizacao === 'Invalid date') {
      dispatch(AvisosActions.erro([`${nome} deve ser uma data válida.`]))
      return false
    }
    return true
  }

  const validarArquivos = (arquivos) => {
    if (arquivos && arquivos.length > 0) {
      const erroValidacao = validarArquivo(arquivos[0])
      if (erroValidacao == null) {
        return true
      }
      dispatch(AvisosActions.erro([erroValidacao]))
    }
    return false
  }

  const obterDadosDebitoDoArquivo = useCallback((arquivoSelecionado) => {
    calculadoraDebitoService.obterDadosDebitoDoArquivo(arquivoSelecionado).then((response) => {
      if (response.data) {
        setDataInicioParcelamento(null)
        // setDataInicioParcelamento(response.data?.dadosComplementaresDebito?.dataInicioParcelamento)
        setParcelasDebito(response.data?.itensDebito)
        setDadosComplementaresDebito(response.data?.dadosComplementaresDebito)
        setAplicaJuros(response.data?.dadosComplementaresDebito.aplicaJuros)
      }
    })
  }, [])

  const importarDadosDebitoDoArquivoTexto = (event) => {
    const arquivos = event.target.files
    if (validarArquivos(arquivos)) {
      obterDadosDebitoDoArquivo(arquivos[0])
      setSaldosDebito(null)
      setUploadKey(uploadKey + 1)
    }
  }

  const limparDados = () => {
    setSaldosDebito(null)
    setUploadKey(uploadKey + 1)
    setDataInicioParcelamento(null)
    setParcelasDebito([])
    setDadosComplementaresDebito(null)
    setAplicaJuros(false)
  }

  const preencherDadosComplementares = () => ({
    ...dadosComplementaresDebito,
    dataAtualizacao,
    // dataInicioParcelamento:
    //  dataInicioParcelamento != null
    //    ? moment(dataInicioParcelamento)?.format('DD/MM/yyyy')
    //    : null,
    aplicaJuros,
  })

  const exportarDadosDebitoParaArquivoTexto = () => {
    if (!validarData(dataAtualizacao, 'Data atualização')) return
    const dadosDebito = {
      itensDebito: parcelasDebito,
      dadosComplementaresDebito: preencherDadosComplementares(),
    }
    calculadoraDebitoService.exportarDadosDebitoParaArquivoTexto(dadosDebito)
  }


  const existeParcelasDebito = (parcelasDebito, operacao) => {
    if (parcelasDebito.length === 0) {
      dispatch(AvisosActions.erro([`Para ${operacao}, pelo menos uma parcela deve ser incluída.`]))
      return false
    }
    return true
  }

  const parcelasDebitoOrdenadasPorData = (parcelasDebitoSemOrdenacao) =>
    parcelasDebitoSemOrdenacao.sort((a, b) =>
      moment(a.dataFato, 'DD/MM/yyyy').diff(moment(b.dataFato, 'DD/MM/yyyy'))
    )

  const validarDataInicioParcelamento = (dataInicioParcelamento) => {
    if (!validarData(dataInicioParcelamento, 'Data de início do parcelamento')) return false
    const dataInicioParcelamentoFormatadoEmDate = new Date(
      moment(dataInicioParcelamento).format('DD/MM/yyyy')
    )
    if (parcelasDebito.length > 0 && dataInicioParcelamento !== null) {
      const parcelasOrdenadas = parcelasDebitoOrdenadasPorData(parcelasDebito)
      const primeiraParcela = parcelasOrdenadas[0]
      if (dataInicioParcelamentoFormatadoEmDate < new Date(primeiraParcela.dataFato)) {
        dispatch(
          AvisosActions.erro([
            'Data de início do parcelamento deve ser igual ou posterior à data da primeira parcela do débito.',
          ])
        )
        return false
      }
    }
    return true
  }

  const gerarDemonstrativoDebitoAtualizado = () => {
    if (!validarData(dataAtualizacao, 'Data atualização')) return
    if (!validarDataInicioParcelamento(dataInicioParcelamento)) return
    if (!existeParcelasDebito(parcelasDebito, OPERACAO_GERAR_DEMONSTRATIVO_DEBITO)) return

    const comando = {
      parcelasDebito,
      dadosComplementaresDebito: preencherDadosComplementares(),
    }
    calculadoraDebitoService.gerarDemonstrativoDebitoAtualizado(comando)
  }

  const gerarDemonstrativoDebitoXML = () => {
    if (!validarData(dataAtualizacao, 'Data atualização')) return
    if (!validarDataInicioParcelamento(dataInicioParcelamento)) return
    if (!existeParcelasDebito(parcelasDebito, OPERACAO_GERAR_DEMONSTRATIVO_DEBITO)) return

    const dadosDebito = {
      itensDebito: parcelasDebito,
      dadosComplementaresDebito: preencherDadosComplementares(),
    }
    calculadoraDebitoService.gerarDemonstrativoDebitoXML(dadosDebito)
  }

  const excluirParcela = (parcela) => {
    const copia = parcelasDebito.filter((it) => it.seqItem !== parcela.seqItem)
    setParcelasDebito(copia)
    setSaldosDebito(null)
  }

  const incluirParcela = (novaParcela) => {
    const novoSeqItem = calcularSeqItemParcela(parcelasDebito)
    const parcela = {
      ...novaParcela,
      seqItem: novoSeqItem,
    }
    const novaListaParcelasDebito = [...parcelasDebito, parcela]

    setParcelasDebito(parcelasDebitoOrdenadasPorData(novaListaParcelasDebito))
    setSaldosDebito(null)
  }

  const onChangeDataAtualizacao = (data) => {
    setDataAtualizacao(data)
    setSaldosDebito(null)
  }

  const onChangeDataInicioParcelamento = (data) => {
    setDataInicioParcelamento(data)
    setSaldosDebito(null)
  }

  const calcularSaldo = () => {
    if (!validarData(dataAtualizacao, 'Data atualização')) return
    if (!validarDataInicioParcelamento(dataInicioParcelamento)) return
    if (!existeParcelasDebito(parcelasDebito, OPERACAO_CALCULAR_SALDO_DEBITO)) return

    const comando = {
      dataAtualizacao,
      // dataInicioParcelamento:
      //  dataInicioParcelamento != null
      //    ? moment(dataInicioParcelamento)?.format('DD/MM/yyyy')
      //    : null,
      aplicaJuros,
      parcelasDebito,
    }
    calculadoraDebitoService
      .calcularSaldoDebito(comando)
      .then((response) => {
        if (response.data) {
          setSaldosDebito(response.data)
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {})
  }

  return (
    <Box sx={classes.boxConteudoCalculadora}>
      <Box display="flex" alignItems="flex-end" sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h1" color="primary">
          Calculadora de Débito
        </Typography>
        <Box sx={classes.boxBotoesSuperiores}>
          <Button id="limpardados" color="primary" component="span" onClick={limparDados}>
            Limpar dados
          </Button>
          <Box sx={classes.boxImportar}>
            <input
              style={{ display: 'none' }}
              accept="text/plain"
              onChange={importarDadosDebitoDoArquivoTexto}
              id="contained-button-file"
              key={uploadKey}
              data-testid="contained-button-file"
              multiple={false}
              type="file"
            />
            <label htmlFor="contained-button-file">
              <Tooltip
                title={
                  <span style={{ fontSize: '15px' }}>
                    Somente é permitido importar dados de arquivo com a extensão txt.
                  </span>
                }>
                <Button id="importardados" color="primary" component="span">
                  Importar dados
                  <input type="hidden" id="hidden-contained-button-file" />
                </Button>
              </Tooltip>
            </label>
            <MensagemFormatoArquivo />
          </Box>
        </Box>
      </Box>
      <Box sx={classes.boxGridConteudo}>
        <Box display="flex" flexDirection="column" gap={2}>
          <ParametrosGerais
            dataAtualizacao={dataAtualizacao}
            atualizarDataAtualizacao={onChangeDataAtualizacao}
            aplicaJuros={aplicaJuros}
            atualizarAplicaJuros={setAplicaJuros}
            dataInicioParcelamento={dataInicioParcelamento}
            atualizarDataInicioParcelamento={onChangeDataInicioParcelamento}
          />
          <InclusaoParcela onIncluirParcela={incluirParcela} />
        </Box>
        <ParcelasDebito parcelas={parcelasDebito || []} onExcluirParcela={excluirParcela} />
      </Box>
      <DadosComplementaresDebito
        dadosComplementaresDebito={dadosComplementaresDebito}
        atualizarDadosComplementaresDebito={setDadosComplementaresDebito}
      />
      <Box sx={{ display: 'flex', flexWrap: 'noWrap' }}>
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', flexGrow: 1 }}>
          <Button
            variant="text"
            color="primary"
            disabled={isEmpty(parcelasDebito)}
            onClick={() => exportarDadosDebitoParaArquivoTexto()}>
            Exportar Dados
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={() => gerarDemonstrativoDebitoXML()}
            disabled={isEmpty(parcelasDebito)}
          >
            Gerar Demonstrativo XML
          </Button>
          <Button
            variant="text"
            color="primary"
            endIcon={<GetPDF />}
            disabled={isEmpty(parcelasDebito)}
            onClick={() => gerarDemonstrativoDebitoAtualizado()}>
            Gerar Demonstrativo
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={saldosDebito}
            onClick={calcularSaldo}>
            Calcular Saldo
          </Button>
        </Box>
      </Box>
      <CalculoSaldo saldosDebito={saldosDebito} />
      <ProgressBar />
      <DialogoConfirmacaoColarParcelas
        open={!isEmpty(parcelasExcel)}
        onCancelar={() => setParcelasExcel(undefined)}
        exibirSubstiuir={!isEmpty(parcelasDebito)}
        onIncluir={() => {
          if (todasParcelasValidas(parcelasExcel, dispatch)) {
            const seq = calcularSeqItemParcela(parcelasDebito)
            const parcelas = parcelasExcel.map(
              ({ dataFato, valorOriginal, indicativoDebitoCredito }, i) => ({
                dataFato: moment(dataFato, MASCARA).format(MASCARA),
                valorOriginal,
                indicativoDebitoCredito,
                seqItem: seq + i,
              })
            )
            setParcelasDebito([...parcelasDebito, ...parcelas])
            setSaldosDebito(null)
          }
          setParcelasExcel(undefined)
        }}
        onSubstituir={() => {
          if (todasParcelasValidas(parcelasExcel, dispatch)) {
            setParcelasDebito(
              parcelasExcel.map(
                ({ dataFato, valorOriginal, indicativoDebitoCredito, seqItem }) => ({
                  dataFato: moment(dataFato, MASCARA).format(MASCARA),
                  valorOriginal,
                  indicativoDebitoCredito,
                  seqItem,
                })
              )
            )
            setSaldosDebito(null)
          }
          setParcelasExcel(undefined)
        }}
      />
    </Box>
  )
}

export default CalculadoraDebito

import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'
import {
  Box,
  FormControlLabel,
  Paper,
  Radio,
  Typography,
  Skeleton,
  Tabs,
  Tab,
  Switch,  Stack
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { useSelector } from 'react-redux'
import { DataGrid } from '@mui/x-data-grid'
import TituloPagina from 'components/estilos/TituloPagina'
import HeaderList from '../../estilos/HeaderList'
import { colunasDividasPorResponsavel as colunas } from '../Divida'
import MensagemResultadoVazio from '../../comum/MensagemResultadoVazio'
import dividaService from '../../../services/dividaService'
import pessoaService from '../../../services/pessoaService'
import ContentBox from '../../estilos/ContentBox'
import { formataCpf, formataCnpj } from '../../../util/util'
import AtualizarDivida from '../../comum/AtualizarDivida'
import { ABA_LISTAGEM_DIVIDAS, PAGINA_ORIGEM_DETALHAMENTO_DIVIDA } from '../ConstantesDivida'
import Falecido from "../../estilos/Falecido";

const DividasPorResponsavel = () => {
  const navigate = useNavigate()
  const { possuiPerfilFazerComentario } = useSelector((state) => state.authentication)

  const { idsResponsaveis } = useParams()
  const { state: propsNavegacao } = useLocation()
  const [dataAtualizacao, setDataAtualizacao] = useState(null)
  const [debitosMultas, setDebitosMultas] = useState([])
  const [canceladas, setCanceladas] = useState([])
  const [citacoes, setCitacoes] = useState([])
  const [responsaveis, setResponsaveis] = useState([])
  const [carregandoDividas, setCarregandoDividas] = useState(true)
  const [carregandoResponsaveis, setCarregandoResponsaveis] = useState(true)
  const [codigoResponsavelSelecionado, setCodigoResponsavelSelecionado] = useState(
    propsNavegacao?.codigoResponsavelSelecionado
      ? propsNavegacao.codigoResponsavelSelecionado
      : null
  )
  const [paginaAtual, setPaginaAtual] = useState(
    propsNavegacao?.paginaAtual ? propsNavegacao.paginaAtual : 0
  )
  const [tamanhoPagina, setTamanhoPagina] = useState(
    propsNavegacao?.tamanhoPagina ? propsNavegacao.tamanhoPagina : 20
  )
  const [abaSelecionada, setAbaSelecionada] = useState(
    propsNavegacao?.abaSelecionada
      ? propsNavegacao.abaSelecionada
      : ABA_LISTAGEM_DIVIDAS.DEBITOS_MULTAS
  )
  const [semComentarios, setSemComentarios] = useState(false)

  const multiplosResponsaveis = responsaveis.length > 1

  const handleDetalhar = (divida) => {
    navigate(`/dividas/${divida.id}`, {
      state: {
        idsResponsaveis,
        paginaAtual,
        tamanhoPagina,
        abaSelecionada,
        codigoResponsavelSelecionado,
        paginaOrigem: PAGINA_ORIGEM_DETALHAMENTO_DIVIDA.DIVIDAS_POR_RESPONSAVEL,
      },
    })
  }

  const handleVoltar = () => {
    navigate('/')
  }

  const handleResponsavelSelecionadoChange = (codigoResponsavel) => {
    setCodigoResponsavelSelecionado(codigoResponsavel)
  }

  const handleAbaDividasChange = (event, novaAba) => {
    setAbaSelecionada(novaAba)
    setPaginaAtual(0)
  }

  const recuperarDividasAbaSelecionada = () => {
    switch (abaSelecionada) {
      case ABA_LISTAGEM_DIVIDAS.DEBITOS_MULTAS:
        return debitosMultas
      case ABA_LISTAGEM_DIVIDAS.CANCELADAS:
        return canceladas
      case ABA_LISTAGEM_DIVIDAS.CITACOES:
        return citacoes
      default:
        return []
    }
  }

  useEffect(() => {
    const recuperarDividas = async () => {
      try {
        setCarregandoDividas(true)
        const { data } = await dividaService.recuperarDividasPorResponsavel(
          codigoResponsavelSelecionado,
          semComentarios
        )
        setDataAtualizacao(data.dataAtualizacao)
        setDebitosMultas(data.debitosMultas)
        setCanceladas(data.canceladas)
        setCitacoes(data.citacoes)
      } finally {
        setCarregandoDividas(false)
      }
    }

    if (!_.isNil(codigoResponsavelSelecionado)) {
      recuperarDividas()
    }
  }, [codigoResponsavelSelecionado, semComentarios])

  useEffect(() => {
    const recuperarDadosPessoa = async () => {
      try {
        setCarregandoResponsaveis(true)
        const { data } = await pessoaService.recuperarPessoasPorCodigos(
          idsResponsaveis,
          semComentarios
        )
        const responsaveisRecuperados = data.map((responsavel) => ({
          id: responsavel.id,
          nome: responsavel.nome,
          cpfCnpj: responsavel.cpf ? responsavel.cpf : responsavel.cnpj,
          tipo: responsavel.tipo,
          falecido: responsavel.falecido
        }))
        setResponsaveis(responsaveisRecuperados)

        const selecionadoNulo = codigoResponsavelSelecionado === undefined
        const indicarNovoResponsavel =
          selecionadoNulo ||
          responsaveisRecuperados.findIndex((resp) => resp?.id === codigoResponsavelSelecionado) < 0

        if (indicarNovoResponsavel) {
          setCodigoResponsavelSelecionado(responsaveisRecuperados[0].id)
        }
      } finally {
        setCarregandoResponsaveis(false)
      }
    }
    recuperarDadosPessoa()
  }, [idsResponsaveis]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleAtualizarDividaPorResponsavel = async () => {
    setCarregandoDividas(true)
    try {
      const { data } = await dividaService.atualizarDividasPorResponsavel(
        codigoResponsavelSelecionado
      )
      setDataAtualizacao(data.dataAtualizacao)
      setDebitosMultas(data.debitosMultas)
      setCanceladas(data.canceladas)
      setCitacoes(data.citacoes)
    } finally {
      setCarregandoDividas(false)
    }
  }

  const skeletonCartaoResponsavel = (
    <Paper
      variant="outlined"
      elevation={0}
      sx={{
        padding: 2,
        height: '5em',
      }}>
      <Typography variant="subtitle2">
        <Skeleton width={350} />
      </Typography>
      <Typography variant="subtitle2" sx={{ color: 'grey.500' }}>
        <Skeleton width={350} />
      </Typography>
    </Paper>
  )

  return (
    <ContentBox>
      <TituloPagina onClick={handleVoltar}>Detalhamento do responsável</TituloPagina>
      <Box display="flex" style={{ justifyContent: 'flex-end', width: '100%' }} sx={{ gap: '8px' }}>
        <AtualizarDivida
          dataAtualizacao={dataAtualizacao}
          onClick={handleAtualizarDividaPorResponsavel}
        />
      </Box>
      <Box display="grid" gridTemplateColumns="1fr" rowGap={3}>
        {multiplosResponsaveis && (
          <Paper variant="outlined" elevation={0} sx={{ padding: 2 }}>
            <Box display="flex" alignItems="center">
              <InfoIcon sx={{ marginRight: '5px', color: 'primary.main' }} />
              <Typography>
                Sua pesquisa encontrou mais de um resultado. Selecione o resultasdo adequado para
                apresentação das dívidas.
              </Typography>
            </Box>
          </Paper>
        )}
        {carregandoResponsaveis && !multiplosResponsaveis
          ? skeletonCartaoResponsavel
          : responsaveis.map((responsavel) => {
              const selecionado =
                codigoResponsavelSelecionado === responsavel.id && multiplosResponsaveis

              return (
                <Paper
                  key={`responsavel-${responsavel.id}`}
                  variant="outlined"
                  elevation={0}
                  sx={{
                    padding: 2,
                    height: '5em',
                    border: selecionado && '1px solid #1C519B',
                  }}>
                  <Box display="flex">
                    {multiplosResponsaveis && (
                      <FormControlLabel
                        checked={selecionado}
                        value={responsavel.id}
                        control={<Radio />}
                        onChange={() => handleResponsavelSelecionadoChange(responsavel.id)}
                      />
                    )}
                    <Box>
                      <Typography variant="subtitle2">{responsavel.nome}</Typography>
                      {responsavel.tipo === 'PESSOA_FISICA' && (
                        <Stack direction="row" spacing={1}>
                          <Typography variant="subtitle2" sx={{ color: 'grey.500' }}>
                            CPF: {formataCpf(responsavel.cpfCnpj)}
                          </Typography>
                          <Falecido falecido={responsavel.falecido}/>
                        </Stack>
                      )}
                      {responsavel.tipo === 'PESSOA_JURIDICA' && (
                        <Typography variant="subtitle2" sx={{ color: 'grey.500' }}>
                          CNPJ: {formataCnpj(responsavel.cpfCnpj)}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Paper>
              )
            })}
        <Box>
          <Box>
            <HeaderList>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: { xs: 'flex-start', sm: 'space-between' },
                  flexDirection: {
                    xs: 'column',
                    sm: 'row',
                  },
                }}>
                <Box>Dívidas do responsável</Box>
                {possuiPerfilFazerComentario && (
                  <FormControlLabel
                    sx={{
                      backgroundColor: 'white',
                      color: 'primary.main',
                      borderRadius: 25,
                      padding: '4px 8px',
                    }}
                    control={
                      <Switch
                        size="small"
                        checked={semComentarios}
                        onChange={({ target }) => setSemComentarios(target.checked)}
                      />
                    }
                    label="Apenas sem comentários"
                  />
                )}
              </Box>
            </HeaderList>
          </Box>
          <Box sx={{ backgroundColor: 'background.paper' }}>
            <Tabs value={abaSelecionada} onChange={handleAbaDividasChange}>
              <Tab
                label={`Débitos e Multas ${debitosMultas.length}`}
                value={ABA_LISTAGEM_DIVIDAS.DEBITOS_MULTAS}
              />
              <Tab
                label={`Canceladas ${canceladas.length}`}
                value={ABA_LISTAGEM_DIVIDAS.CANCELADAS}
              />
              <Tab label={`Citações ${citacoes.length}`} value={ABA_LISTAGEM_DIVIDAS.CITACOES} />
            </Tabs>
            <DataGrid
              rows={recuperarDividasAbaSelecionada()}
              disableColumnMenu
              columns={colunas}
              ColumnSortedAscendingIcon={null}
              ColumnSortedDescendingIcon={null}
              autoHeight
              pagination
              page={paginaAtual}
              pageSize={tamanhoPagina}
              onRowClick={handleDetalhar}
              headerHeight={42}
              rowsPerPageOptions={[20, 50, 100]}
              onPageChange={(novaPagina) => setPaginaAtual(novaPagina)}
              onPageSizeChange={(newPageSize) => setTamanhoPagina(newPageSize)}
              loading={carregandoDividas}
              components={{
                NoRowsOverlay: MensagemResultadoVazio,
              }}
              componentsProps={{
                pagination: { classes: null },
              }}
              sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                  color: 'grey.500',
                  fontWeight: 400,
                },
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '.MuiDataGrid-columnHeader:focus-within': {
                  outline: 'none',
                },
                borderRadius: '0 0 4px 4px',
                '& .MuiDataGrid-row:hover': {
                  cursor: 'pointer',
                  '& .forwardIconHover ': {
                    visibility: 'visible',
                  },
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </ContentBox>
  )
}

export default DividasPorResponsavel

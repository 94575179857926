import { TableCell, TableRow, Tooltip } from '@mui/material'
import moment from 'moment'
import PropTypes from 'prop-types'
import _ from 'lodash'
import IconeInformacao from '../../../../img/icone_informacao.svg'
import formatarNumeroParaMoeda from '../../../../util/util'



const Pagamento = ({ pagamento }) => (
  <TableRow sx={{ 'td,th': { border: 0 } }}>
    <TableCell align="center" sx={{ padding: '0px 8px' }}>
      {pagamento.numeroParcela}
    </TableCell>
    <TableCell align="left" sx={{ padding: '0px 0px 0px 30px'  }}>
          {`${moment(pagamento.dataPagamento).format('DD/MM/yyyy')} `}
          {pagamento.seDataSaldoEPagamentoDivergente
              && <Tooltip
                   title={`No caso desse pagamento, para fins de cálculo de atualização, considera-se a data de ${moment(pagamento.dataCalculoSaldo).format('DD/MM/yyyy')}, pois o pagamento ocorreu dentro de 15 dias da data de ciência da comunicação.`}
                    sx={{ color: 'grey.500' }}>
                  <img src={IconeInformacao} style={{width: '12px', height:'auto'}} alt="Ícone de informação" />
                 </Tooltip>
            }
    </TableCell>
    <TableCell align="right" sx={{ padding: '0px 8px' }}>
      {pagamento.simboloMoeda} {formatarNumeroParaMoeda(pagamento.valorPago, 2, 2)}
    </TableCell>
    <TableCell align="left" sx={{ padding: '0px 8px' }}>
      {!_.isNil(pagamento.numeroReferenciaGru)
        ? pagamento.numeroReferenciaGru
        : pagamento.numeroReferenciaGruManual}
    </TableCell>
    <TableCell align="left" sx={{ padding: '0px 8px' }}>
      {!_.isNil(pagamento.textoObservacao) ? pagamento.textoObservacao : '-'}
    </TableCell>
  </TableRow>
)

Pagamento.propTypes = {
  pagamento: PropTypes.object,
}

export default Pagamento

import { TableCell, TableHead, TableRow } from '@mui/material'

const PagamentoHeader = () => (
  <TableHead sx={{ th: { border: 0 } }}>
    <TableRow>
      <TableCell sx={{ width: '50px', color: 'grey.500', padding: '0px 8px' }}>Parcela</TableCell>
      <TableCell align="center" sx={{ width: 140, color: 'grey.500', padding: '0px 8px' }}>
        Data pagamento
      </TableCell>
      <TableCell align="right" sx={{ width: 140, color: 'grey.500', padding: '0px 8px' }}>
        Valor pago
      </TableCell>
      <TableCell align="left" sx={{ width: 220, color: 'grey.500', padding: '0px 8px' }}>
        Número referência
      </TableCell>
      <TableCell align="left" sx={{ color: 'grey.500', padding: '0px 8px' }}>
        Observação
      </TableCell>
    </TableRow>
  </TableHead>
)

export default PagamentoHeader

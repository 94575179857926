import { Box, Typography } from '@mui/material'
import ContentBox from '../estilos/ContentBox'

const TelaInicialUsuarioExterno = () => (
  <ContentBox>
    <Box>
      <Box>
        <Typography>
          No momento, não há funcionalidades disponíveis para usuários externos ao TCU.
        </Typography>
      </Box>
    </Box>
  </ContentBox>
)

export default TelaInicialUsuarioExterno

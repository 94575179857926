import * as React from 'react'
import { useState } from 'react'
import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Popover,
} from '@mui/material'
import PropTypes from 'prop-types'
import PaperOutlined from 'components/estilos/PaperOutlinedNoSpacing'
import GraficoLinhas from '../graficos/GraficoLinhas'
import formatarNumeroParaMoeda from '../../../../util/util'
import painelRetrospectivaService from '../../../../services/painelRetrospectivaService'
import classes from './Tabela.style'
import IconeInformacao from '../../../../img/icone_informacao.svg'
import CustomWidthTooltip from '../CustomWidthTooltip'

const TabelaDebitosEMultasPorAno = ({ titulo, dados }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [tituloChart, setTituloChart] = useState(null)
  const [chartDebitoMultaMensalPorAno, setChartDebitoMultaMensalPorAno] = useState(null)

  const montarChartDebitoMultaMensalPorAno = (dadosRecolhimento) => {
    if (!dadosRecolhimento) return

    const meses = Object.keys(dadosRecolhimento.debitosMultasTotaisMensais)
    const linhaDebito = {
      name: 'Débito',
      data: Object.values(dadosRecolhimento.debitosImputadosMensais),
    }
    const linhaMulta = {
      name: 'Multa',
      data: Object.values(dadosRecolhimento.multasAplicadasMensais),
    }
    const linhaTotal = {
      name: 'Total',
      data: Object.values(dadosRecolhimento.debitosMultasTotaisMensais),
    }
    const chartRecolhimento = {
      categorias: meses,
      valores: [linhaDebito, linhaMulta, linhaTotal],
    }

    setChartDebitoMultaMensalPorAno(chartRecolhimento)
  }

  const obterDadosDebitosMultasMensaisPorAno = (ano) => {
    painelRetrospectivaService.obterRelatorioDebitosMultasMensais(ano).then((response) => {
      if (response.data) {
        montarChartDebitoMultaMensalPorAno(response.data)
      }
    })
  }

  const handleClick = (event, ano) => {
    setAnchorEl(event.currentTarget)
    setTituloChart(`Histórico detalhado de débitos e multas do ano ${ano}`)
    obterDadosDebitosMultasMensaisPorAno(ano)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setTituloChart(null)
    setChartDebitoMultaMensalPorAno(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <TableContainer component={PaperOutlined} sx={{maxWidth: '950px', width: '100%'}}>
      <Box sx={{ py: 1.2, px: 2 }}>
        <Typography
          variant="subtitle1"
          component="div"
          color="grey.800"
          style={{ height: 'auto', verticalAlign: 'center' }}>
          {titulo}{' '}
          <CustomWidthTooltip
            title={
              <Box>
                <Typography variant="body2" gutterBottom>
                  Os <strong>somatórios de débitos e multas </strong>apresentados correspondem à
                  soma do valor atualizado de cada dívida na <strong>data da deliberação</strong>.
                </Typography>
              </Box>
            }
            sx={{ color: 'grey.500' }}>
            <img
              src={IconeInformacao}
              style={{ width: '15px', height: 'auto', verticalAlign: '-2px' }}
              alt="Ícone de informação"
            />
          </CustomWidthTooltip>
        </Typography>
      </Box>
      <Divider />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={classes.tableCellLabel} align="center">Ano</TableCell>
            <TableCell sx={classes.tableCellLabel} align="right" >Débito</TableCell>
            <TableCell sx={classes.tableCellLabel} align="right">Multa</TableCell>
            <TableCell sx={classes.tableCellLabel} align="right">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dados?.map((row) => (
            <TableRow key={row.ano} onClick={(event) => handleClick(event, row.ano)} hover>
              <TableCell
                align="center"
                component="th"
                scope="row"
                sx={{ cursor: 'pointer', fontWeight: 'bold', color: 'primary.light' }}>
                {row.ano}
              </TableCell>
              <TableCell align="right">
                <Typography variant="body2" color="grey.700">
                  {formatarNumeroParaMoeda(row.valorTotalDebitos, 2, 2)}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body2" color="grey.700">
                  {formatarNumeroParaMoeda(row.valorTotalMultas, 2, 2)}
                </Typography>
              </TableCell>
              <TableCell align="right" sx={classes.total}>
                <Typography variant="body2" color="grey.900">
                  {formatarNumeroParaMoeda(row.valorTotalDebitosEMultas, 2, 2)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {chartDebitoMultaMensalPorAno && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          sx={{
            '& .MuiPaper-root': {
              padding: 1,
              width: 700,
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}>
          <GraficoLinhas titulo={tituloChart} data={chartDebitoMultaMensalPorAno} />
        </Popover>
      )}
    </TableContainer>
  )
}

TabelaDebitosEMultasPorAno.propTypes = {
  titulo: PropTypes.string.isRequired,
  dados: PropTypes.array,
}

export default TabelaDebitosEMultasPorAno

import React from 'react';
import { Button } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import PropTypes from 'prop-types';

const LinkExterno = ({ children, onClick }) => (
  <Button
    onClick={onClick}
    size="small"
    sx={{
      textDecoration: 'none',
      cursor: 'pointer',
      color: 'primary.light',
      display: 'flex',
      alignItems: 'center',
      gap: 0.5,
      padding: 0,
    }}
  >
    <span style={{ userSelect: 'text' }}>{children}</span>
    <LaunchIcon sx={{ width: '16px', paddingBottom: '2px' }} />
  </Button>
);

LinkExterno.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func,
};

export default LinkExterno;
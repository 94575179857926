import React from 'react'
import PropTypes from 'prop-types'
import { Button, DialogActions, DialogContent, Typography, Box } from '@mui/material'
import BootstrapDialogTitle from './BootstrapDialogTitle'
import BootstrapDialog from './BootstrapDialog'

const DialogoConfirmacao = (props) => {
  const {
    onConfirmar = () => {},
    onClose,
    titulo = 'Confirmar?',
    conteudo = <Box />,
    open = false,
    labelBotaoSim = 'Sim',
    labelBotaoNao = undefined,
  } = props

  return (
    <BootstrapDialog onClose={onClose} open={open} maxWidth="sm">
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose} style={{ width: 380 }}>
        <Typography variant="h5" component="span">
          {titulo}
        </Typography>
      </BootstrapDialogTitle>
      {conteudo && <DialogContent dividers>{conteudo}</DialogContent>}
      <DialogActions>
        {labelBotaoNao && (
          <Button
            name="nao"
            id="nao"
            data-testid="btn-dialogo-nao"
            onClick={() => onClose()}
            variant="text">
            {labelBotaoNao}
          </Button>
        )}
        <Button
          name="sim"
          id="sim"
          data-testid="btn-dialogo-sim"
          onClick={() => onConfirmar()}
          color="primary"
          variant="contained">
          {labelBotaoSim}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  )
}
DialogoConfirmacao.propTypes = {
  labelBotaoSim: PropTypes.string,
  labelBotaoNao: PropTypes.string,
  onConfirmar: PropTypes.func,
  onClose: PropTypes.func,
  conteudo: PropTypes.any.isRequired,
  titulo: PropTypes.string,
  open: PropTypes.bool,
}
export default DialogoConfirmacao

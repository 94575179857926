import React, { useState, useEffect, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, FormControlLabel, Skeleton, Switch } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useSelector } from 'react-redux'
import TituloPagina from 'components/estilos/TituloPagina'
import ContentBox from '../../../estilos/ContentBox'
import dividaService from '../../../../services/dividaService'
import HeaderList from '../../../estilos/HeaderList'
import { colunasListagem as colunas } from '../../Divida'
import MensagemResultadoVazio from '../../../comum/MensagemResultadoVazio'
import { PAGINA_ORIGEM_DETALHAMENTO_DIVIDA } from '../../ConstantesDivida'
import ProgressBar from '../../../comum/ProgressBar'

const DividasComAlertas = () => {
  const navigate = useNavigate()
  const { state: propsNavegacao } = useLocation()
  const [dividas, setDividas] = useState([])
  const [carregandoDividas, setCarregandoDividas] = useState(true)
  const [totalElementos, setTotalElementos] = useState(0)
  const [tipoAlerta, setTipoAlerta] = useState(propsNavegacao?.tipoAlerta || '')
  const [nomeAlerta, setNomeAlerta] = useState(propsNavegacao?.nomeAlerta || '')
  const [severidadeAlerta, setSeveridadeAlerta] = useState(propsNavegacao?.severidadeAlerta || '')
  const [paginaAtual, setPaginaAtual] = useState(propsNavegacao?.paginaAtual || 0)
  const [tamanhoPagina, setTamanhoPagina] = useState(propsNavegacao?.tamanhoPagina || 20)
  const [semComentarios, setSemComentarios] = useState(propsNavegacao?.semComentarios || false)
  const { possuiPerfilFazerComentario } = useSelector((state) => state.authentication)

  const handleDetalhar = (divida) => {
    navigate(`/dividas/${divida.id}`, {
      state: {
        paginaAtual,
        tamanhoPagina,
        tipoAlerta: propsNavegacao?.tipoAlerta,
        semComentarios,
        severidadeAlerta: propsNavegacao?.severidadeAlerta,
        paginaOrigem: PAGINA_ORIGEM_DETALHAMENTO_DIVIDA.DIVIDAS_COM_ALERTAS,
      },
    })
  }

  const handleVoltar = () => {
    navigate('/alertas', {
      state: { semComentarios },
    })
  }

  const montarLabelFiltroAlertas = () => {
    if (tipoAlerta) {
      return `${nomeAlerta}`
    }
    if (severidadeAlerta) {
      return `Severidade do alerta - ${severidadeAlerta}`
    }
    return 'Todas as dívidas com alertas'
  }

  const recuperarDividasComAlertas = useCallback(async () => {
    try {
      setCarregandoDividas(true)
      const { data } = await dividaService.recuperarDividasComAlertasPorFiltro(
        propsNavegacao?.tipoAlerta,
        propsNavegacao?.severidadeAlerta,
        paginaAtual,
        tamanhoPagina,
        semComentarios
      )
      setDividas(data.resultado.elementos)
      setTotalElementos(data.resultado.totalElementos)
      setTipoAlerta(data.tipoAlerta)
      setNomeAlerta(data.nomeAlerta)
      setSeveridadeAlerta(data.severidadeAlerta)
    } finally {
      setCarregandoDividas(false)
    }
  }, [
    paginaAtual,
    tamanhoPagina,
    propsNavegacao?.tipoAlerta,
    propsNavegacao?.severidadeAlerta,
    semComentarios,
  ])

  useEffect(() => {
    recuperarDividasComAlertas()
  }, [recuperarDividasComAlertas, semComentarios])

  return (
    <ContentBox>
      <TituloPagina onClick={handleVoltar}>Lista de dívidas</TituloPagina>
      <Box>
        <Box>
          <HeaderList>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: { xs: 'flex-start', sm: 'space-between' },
                flexDirection: {
                  xs: 'column',
                  sm: 'row',
                },
              }}>
              <Box>
                {dividas?.length > 0 ? (
                  montarLabelFiltroAlertas()
                ) : (
                  <Skeleton sx={{ width: '30%', backgroundColor: '#fffff' }} />
                )}
              </Box>
              {possuiPerfilFazerComentario && (
                <FormControlLabel
                  sx={{
                    backgroundColor: 'white',
                    color: 'primary.main',
                    borderRadius: 25,
                    padding: '4px 8px',
                  }}
                  control={
                    <Switch
                      size="small"
                      checked={semComentarios}
                      onChange={({ target }) => setSemComentarios(target.checked)}
                    />
                  }
                  label="Apenas sem comentários"
                />
              )}
            </Box>
          </HeaderList>
        </Box>
        <Box sx={{ backgroundColor: 'background.paper' }}>
          <DataGrid
            rows={dividas}
            disableColumnMenu
            columns={colunas}
            ColumnSortedAscendingIcon={null}
            ColumnSortedDescendingIcon={null}
            rowCount={totalElementos}
            autoHeight
            pagination
            paginationMode="server"
            page={paginaAtual}
            pageSize={tamanhoPagina}
            onRowClick={handleDetalhar}
            headerHeight={42}
            rowsPerPageOptions={[20, 50, 100]}
            onPageChange={(novaPagina) => setPaginaAtual(novaPagina)}
            onPageSizeChange={(newPageSize) => setTamanhoPagina(newPageSize)}
            loading={carregandoDividas}
            components={{
              NoRowsOverlay: MensagemResultadoVazio,
            }}
            componentsProps={{
              pagination: { classes: null },
            }}
            sx={{
              borderRadius: '0 0 4px 4px',
              '& .MuiDataGrid-row:hover': {
                cursor: 'pointer',
                '& .forwardIconHover ': {
                  visibility: 'visible',
                },
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                color: 'grey.500',
                fontWeight: 400,
              },
              '& .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '.MuiDataGrid-columnHeader:focus-within': {
                outline: 'none',
              },
            }}
          />
        </Box>
      </Box>
      <ProgressBar />
    </ContentBox>
  )
}

export default DividasComAlertas

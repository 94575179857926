import axios from 'axios'

const obterOpcoesAnosParaPesquisaRelatorios = () =>
  axios.get(`/bff/dividas/obter-opcoes-anos-para-pesquisa`)

const obterRelatoriosRetrospectiva = () => axios.get(`/bff/dividas/obter-relatorios-retrospectiva`)

const obterRelatorioRecolhimentosMensaisPorTipoDeliberacao = (ano) =>
  axios.get(`/bff/dividas/obter-relatorio-recolhimentos-mensais-por-tipo-deliberacao/${ano}`, {
    noLoading: false,
  })

const obterRelatorioDebitosMultasMensais = (ano) =>
  axios.get(`/bff/dividas/obter-relatorio-debitos-multas-mensais/${ano}`, { noLoading: false })

const obterRelatorioRecolhimentosTotais = () =>
  axios.get(
    `/bff/dividas/obter-relatorio-recolhimentos-administrativos-e-orgaos-executores-por-ano`
  )

const obterRelatorioArrecadacaoPorOrgaoExecutor = (ano) =>
  axios.get(`/bff/dividas/obter-relatorio-arrecadacao-por-executor/${ano}`, { noLoading: false })

const obterRelatorioDividasEnviadasPorOrgaoExecutor = (ano) =>
  axios.get(`/bff/dividas/obter-relatorio-dividas-enviadas-cbex-por-executor/${ano}`, {
    noLoading: false,
  })

const obterRelatorioDebitosImputadosPorOrgaoExecutor = (ano) =>
  axios.get(`/bff/dividas/obter-relatorio-debitos-imputados-por-executor/${ano}`, {
    noLoading: false,
  })

const obterRelatorioDebitosImputadosPorCofreCredor = (ano) =>
  axios.get(`/bff/dividas/obter-relatorio-debitos-imputados-por-cofre-credor/${ano}`, {
    noLoading: false,
  })

export default {
  obterOpcoesAnosParaPesquisaRelatorios,
  obterRelatoriosRetrospectiva,
  obterRelatorioRecolhimentosMensaisPorTipoDeliberacao,
  obterRelatorioDebitosMultasMensais,
  obterRelatorioRecolhimentosTotais,
  obterRelatorioArrecadacaoPorOrgaoExecutor,
  obterRelatorioDividasEnviadasPorOrgaoExecutor,
  obterRelatorioDebitosImputadosPorOrgaoExecutor,
  obterRelatorioDebitosImputadosPorCofreCredor,
}

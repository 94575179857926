import React from 'react'
import {
  AppBar,
  Avatar,
  Box,
  Button,
  FormControlLabel,
  Divider,
  Popover,
  Stack,
  Toolbar,
  Typography,
  IconButton,
} from '@mui/material'
import LogOutIcon from '@mui/icons-material/Launch'
import MenuIcon from '@mui/icons-material/Menu'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import history from '../../store/history'
import { Actions as AuthenticationActions } from '../../ducks/authentication'
import Logo from '../../img/logo_tcu.svg'
import LogoDark from '../../img/logo_tcu_dark.svg'
import MenuLinks from './MenuLinks'
import classes from './AppToolbarStyles'
import MantemSessao from './MantemSessao'
import MaterialUISwitch from '../estilos/MaterialUISwitch'
import BarraPesquisa from './BarraPesquisa'

const AppToolbar = ({ toggleTheme, isDarkTheme, toggleMenuLateral, onClick }) => {
  const dispatch = useDispatch()
  const { nomeUsuarioLogado, seUsuarioInternoTcu, isUserAuthenticated } = useSelector((state) => state.authentication)

  const [anchorMenu, setAnchorMenu] = React.useState(null)
  const openMenu = Boolean(anchorMenu)

  const handleUserLogout = () => {
    dispatch(AuthenticationActions.logoutUser())
  }

  const handleHomeClick = () => {
    if (seUsuarioInternoTcu) history.push('/')
    // if (isUserAuthenticated) history.push('/pagtesouro')

  }

  const handleClickMenu = (event) => {
    setAnchorMenu(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorMenu(null)
  }



  return (
    <Box sx={classes.root}>
      <MantemSessao />
      <AppBar position="fixed" sx={classes.appBar}>
        <Toolbar sx={classes.toolbar}>
          {seUsuarioInternoTcu && (
            <IconButton
              aria-describedby="menu-lateral"
              variant="contained"
              color="secondary"
              aria-label="Abrir Menu"
              onClick={toggleMenuLateral}
              edge="start"
              sx={{ marginRight: 2 }}>
              <MenuIcon />
            </IconButton>
          )}
          <Box sx={classes.home} onClick={onClick}>
            <Avatar
              alt="TCU"
              src={isDarkTheme ? LogoDark : Logo}
              onClick={handleHomeClick}
              sx={classes.logo}
              style={{ height: '40px', width: '40px' }}
            />
            <Typography
              variant="h6"
              sx={classes.tituloSistema}
              color="text.primary"
              onClick={handleHomeClick}
            >
              Plataforma de Gestão de Dívidas
            </Typography>
          </Box>
          <Box sx={classes.boxSeparator} />
          <Stack direction="row" spacing={2} onClick={onClick}>
            {seUsuarioInternoTcu && <BarraPesquisa />}
            <FormControlLabel
              sx={classes.desativarMobile}
              control={<MaterialUISwitch checked={isDarkTheme} onChange={toggleTheme} />}
            />
            {seUsuarioInternoTcu && <MenuLinks sx={classes.desativarMobile} />}
            {isUserAuthenticated && <Avatar
              sx={classes.avatarUser}
              alt="Sua foto ou sigla do nome"
              onClick={handleClickMenu}>
              {nomeUsuarioLogado.substr(0, 1)}
            </Avatar>}
          </Stack>
          {isUserAuthenticated && <Popover
            anchorEl={anchorMenu}
            open={openMenu}
            onClose={handleCloseMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}>
            <Box p={2} sx={classes.desativarMobile}>
              <Typography variant="body2" gutterBottom color="text.secondary">
                {nomeUsuarioLogado}
              </Typography>
              <Button fullWidth onClick={handleUserLogout}>
                <LogOutIcon fontSize="small" />
                Sair
              </Button>
            </Box>
          </Popover>}
        </Toolbar>
        <Divider />
      </AppBar>
    </Box>
  )
}

AppToolbar.propTypes = {
  toggleTheme: PropTypes.func,
  isDarkTheme: PropTypes.bool,
  toggleMenuLateral: PropTypes.func,
  onClick: PropTypes.func,
}

export default AppToolbar

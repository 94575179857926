import * as React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import PropTypes from 'prop-types'
import { Box, Chip, Card, Divider, IconButton, Typography } from '@mui/material'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import _ from 'lodash'
import formatarNumeroParaMoeda from '../../../util/util'
import MensagemFormatoCopiaColaExcel from './MensagemFormatoCopiaColaExcel'

const columns = (onExcluirParcela) => [
  { field: 'dataFato', headerName: 'Data', width: 120, sortable: false },
  {
    field: 'valorOriginal',
    headerName: 'Valor',
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    minWidth: 150,
    sortable: false,
    valueGetter: ({ row: parcela }) =>
      `${parcela.simboloMoeda ? parcela.simboloMoeda : 'R$'} ${formatarNumeroParaMoeda(
        parcela.valorOriginal,
        2,
        2
      )}`,
  },

  {
    field: 'indicativoDebitoCredito',
    headerName: 'Tipo',
    width: 110,
    sortable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ row: parcela }) => {
      const isParcelaDebito = parcela.indicativoDebitoCredito === 'D'
      return (
        <Chip
          size="small"
          sx={{
            fontSize: 11,
            height: 20,
            borderRadius: 1,
            margin: '1px 0',
            color: isParcelaDebito ? '#D25455' : '#2ea572',
            bgcolor: isParcelaDebito ? 'error.light' : 'success.light',
          }}
          color={isParcelaDebito ? 'error' : 'success'}
          icon={
            isParcelaDebito ? (
              <RemoveIcon style={{ fontSize: 11, marginRight: -6 }} />
            ) : (
              <AddIcon style={{ fontSize: 10, marginRight: -5 }} />
            )
          }
          label={isParcelaDebito ? 'débito' : 'crédito'}
        />
      )
    },
  },

  {
    field: 'seqItem',
    headerName: '',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 38,
    renderCell: ({ row: parcela }) => (
      <IconButton size="small" color="grey.500" onClick={() => onExcluirParcela(parcela)}>
        <CloseIcon />
      </IconButton>
    ),
  },
]

const MensagemResultadoVazio = () => (
  <Box
    px={3}
    display="flex"
    height="100%"
    width="100%"
    sx={{ justifyContent: 'center', flexDirection: 'column'}}>
    <Typography variant="subtitle2" color="textSecondary" pb={2}>
      Nenhuma parcela informada
    </Typography>
    <Typography variant="" color="textSecondary">
      Você também pode importar múltiplas parcelas ao copiar de uma tabela e colar nesta janela usando <span style={{ color: '#000000'}} >CTRL + V</span>.
    </Typography>
    <Box sx={{zIndex: 1, width: '80px'}}>
      <MensagemFormatoCopiaColaExcel  id="solucao"/>
    </Box>
  </Box>
)

const ParcelasDebito = ({ parcelas = [], onExcluirParcela = () => {} }) => (
  <Card variant="outlined" sx={{ height: 349, padding: '12px', paddingBottom: '28px' }}>
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography variant="h5" gutterBottom>
          Parcelas cadastradas
        </Typography>
      </Box>
      <Divider />
    </Box>
    <DataGrid
      getRowId={(row) => row.seqItem}
      rows={_.sortBy(parcelas, [
        (o) => _.reverse(o.dataFato.split('/')).join(''),
        (o) => o.seqItem,
      ])}
      rowHeight={36}
      headerHeight={36}
      components={{
        NoRowsOverlay: MensagemResultadoVazio,
      }}
      hideFooterSelectedRowCount
      columns={columns(onExcluirParcela)}
      disableColumnMenu
      checkboxSelection={false}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 5 },
        },
      }}
      rowsPerPageOptions={[6]}
      pageSize={6}
      sx={{
        borderRadius: '0 0 4px 4px',
        borderColor: 'transparent',
        '& .MuiDataGrid-row:hover': {
          '& .forwardIconHover ': {
            visibility: 'visible',
          },
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          color: 'grey.500',
          fontWeight: 400,
        },
        '& .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '& .MuiDataGrid-cell:focus': {
          outline: 'none',
        },
        '.MuiDataGrid-columnHeader:focus-within': {
          outline: 'none',
        },
      }}
    />
  </Card>
)

ParcelasDebito.propTypes = {
  parcelas: PropTypes.array,
  onExcluirParcela: PropTypes.func,
}

export default ParcelasDebito

const classes = {
  boxGridConteudo: {
    display: 'grid',
    gridTemplateColumns: {lg:'1fr 1fr', sm:'1fr'},
    gap: '16px',
  },
  boxConteudoCalculadora: {
    margin: '96px auto',
    width: '960px',
    gap: '16px',
    display: 'flex',
    flexDirection: 'column',
    padding: {lg: 0, sm: 2}
  },
  boxBotoesSuperiores: {
    display: 'flex',
    gap:'16px',
    alignItems: 'center',
    justifyContent: { xs: 'flex-start', sm: 'flex-end' },
    flexDirection: { xs: 'column', sm: 'row' },
  },
  boxImportar: {
    display: 'flex',
    flexDirection: 'row',
  },
  boxGridDadosComplementares: {
    marginBottom: '24px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '24px 16px',
  },
  boxGridDadosDoisCampos: {
    marginBottom: '24px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '24px 16px',
  },
  linhaCalculo: {
    width: '100%',
    borderBottom: '1px solid',
    borderColor: 'grey.300',
    padding: '12px 8px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  linhaCalculoFinal: {
    width: '100%',
    padding: '12px 8px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  switchIncluirJuros: {
    backgroundColor: 'white',
    color: 'primary.main',
    borderRadius: 25,
    padding: '4px 8px',
  }
}

export default classes

import * as React from 'react'
import { Button, Box } from '@mui/material'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

const IconButtonVisualizarDivida = ({ divida, paginaOrigem, seDividasUsuarioLogado }) => {
  const navigate = useNavigate()

  const handleVisualizarDetalhes = (divida) => {
    navigate(`/dividas/${divida.id}`, {
      state: {
        paginaOrigem,
        numeroProcesso: seDividasUsuarioLogado ? null : divida.numeroProcessoFormatado,
      },
    })
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Button sx={{ padding: '0 8px' }} onClick={() => handleVisualizarDetalhes(divida)}>
        Detalhar
      </Button>
    </Box>
  )
}

IconButtonVisualizarDivida.propTypes = {
  divida: PropTypes.object,
  paginaOrigem: PropTypes.string,
  seDividasUsuarioLogado: PropTypes.bool,
}

export default IconButtonVisualizarDivida

import axios from 'axios'

const urlProcesso = '/bff/processos'

const recuperarDadosProcessoPorCodigo = (codigoProcesso) =>
  axios.get(`${urlProcesso}/${codigoProcesso}`)

const recuperarDadosProcessoPorNumeroSemFormatacao = (numeroProcesso) =>
  axios.get(`${urlProcesso}/${numeroProcesso}/numero-completo-sem-formatacao`, { noLoading: true })

export const recuperarUrlVisualizacaoProcesso = (codigoProcesso) =>
  axios.get(`${urlProcesso}/${codigoProcesso}/url-visualizacao-processo`)

const recuperarPecasProcessoPorCodigo = (codigoProcesso) =>
  axios.get(`${urlProcesso}/${codigoProcesso}/pecas`)

const autuarProcesso = (comando) =>
  axios.post(`${urlProcesso}/autuar-processo-rap`, comando, { noLoading: false })

const usrLogadoTemPerfilAutuacaoProcessoRap = () =>
  axios.get(`${urlProcesso}/usr-logado-tem-perfil-autuar-processo-rap`, { noLoading: true })

export default {
  recuperarDadosProcessoPorCodigo,
  recuperarDadosProcessoPorNumeroSemFormatacao,
  recuperarUrlVisualizacaoProcesso,
  recuperarPecasProcessoPorCodigo,
  autuarProcesso,
  usrLogadoTemPerfilAutuacaoProcessoRap,
}

import axios from 'axios'
import gerarDocumento from '../components/comum/funcoesUtil'

const urlDividas = '/bff/dividas'

const recuperarDividasPorNivel = (
  tipoNivel,
  codigoNivel,
  paginaAtual,
  tamanhoPagina,
  ordem,
  semComentarios
) => {
  const fitroOrdem = ordem[0]?.field ? `&ordem=${ordem[0].field} ${ordem[0].sort}` : '&ordem=id'

  return axios.get(
    `${urlDividas}/recuperar-por-nivel?tipoNivel=${tipoNivel}&codigoNivel=${codigoNivel}&paginaAtual=${paginaAtual}&tamanhoPagina=${tamanhoPagina}${fitroOrdem}&semComentarios=${semComentarios}`
  )
}

const recuperarDividaComOperacoesPermitidas = (id) =>
  axios.get(`${urlDividas}/${id}/divida-com-operacoes-permitidas`)
const recuperarDivida = (id) => axios.get(`${urlDividas}/${id}`)

const atualizarDivida = (id) => axios.put(`${urlDividas}/atualizar/${id}`)

const atualizarDividasPorProcesso = (id) => axios.put(`${urlDividas}/processos/${id}/atualizar`)

const atualizarDividasPorResponsavel = (id) =>
  axios.put(`${urlDividas}/responsavel/${id}/atualizar`)

const recuperarTotalizadores = () => axios.get(`${urlDividas}/totalizadores`)

const recuperarDividasPorResponsavel = (codigoResponsavel, semComentarios = false) =>
  axios.get(`${urlDividas}/responsavel/${codigoResponsavel}?semComentarios=${semComentarios}`, {
    noLoading: true,
  })

const recuperarDividasPorProcesso = (codigoProcesso, semComentarios = false) =>
  axios.get(`${urlDividas}/processos/${codigoProcesso}?semComentarios=${semComentarios}`)

const recuperarDividasPorProcessoOriginador = recuperarDividasPorProcesso

const recuperarDividasPorProcessoCbex = recuperarDividasPorProcesso

const recuperarDividasComAlertas = (paginaAtual, tamanhoPagina) =>
  axios.get(`bff/dividas-com-alertas?paginaAtual=${paginaAtual}&tamanhoPagina=${tamanhoPagina}`)

const recuperarAlertasAgrupadosSeveridade = (semComentarios = false) =>
  axios.get(`bff/dividas/alertas-agrupados?semComentarios=${semComentarios}`)

const recuperarResumoTotalizadores = (semComentarios = false) =>
  axios.get(`bff/dividas/resumo-totalizadores?semComentarios=${semComentarios}`)

const recuperarDividasComAlertasPorFiltro = (
  tipoAlerta,
  severidadeAlerta,
  paginaAtual,
  tamanhoPagina,
  semComentarios
) => {
  const filtroTipoAlerta = tipoAlerta ? `&tipoAlerta=${tipoAlerta}` : ''
  const filtroSeveridadeAlerta = severidadeAlerta ? `&severidadeAlerta=${severidadeAlerta}` : ''
  const fitroOrdem = '&ordem=id'
  return axios.get(
    `bff/dividas-com-alertas-por-filtro?paginaAtual=${paginaAtual}&tamanhoPagina=${tamanhoPagina}${filtroTipoAlerta}${filtroSeveridadeAlerta}${fitroOrdem}&semComentarios=${semComentarios}`
  )
}

const downloadDocumentoDemonstrativo = (idDivida, dataAtualizacaoDebito) => {
  let url = `${urlDividas}/${idDivida}/gerar-demonstrativo-atualizado`
  if (dataAtualizacaoDebito != null) {
    url = `${url}?dataAtualizacao=${dataAtualizacaoDebito}`
  }
  axios.get(`${url}`).then((response) => {
    gerarDocumento(response.data.conteudo, idDivida, response.data.nomeArquivo)
  })
}

const recuperarDividasPorFiltro = (filtros, paginaAtual, tamanhoPagina) =>
  axios.post(
    `${urlDividas}/pesquisar-por-filtro?paginaAtual=${paginaAtual}&tamanhoPagina=${tamanhoPagina}`,
    filtros
  )

const recuperarDadosFiltro = () => axios.get(`${urlDividas}/recuperar-dados-filtro`)

const exportarPesquisaPorFiltroParaCSV = (filtros) => {
  axios.post(`${urlDividas}/exportar-para-csv`, filtros).then((response) => {
    gerarDocumento(response.data, 0, 'dividas', 'text/csv')
  })
}

const validarSeDividaPodeSerVinculadaProcessoRap = (comando) =>
  axios.post(`${urlDividas}/validar-vinculacao-processo-rap`, comando, { noLoading: false })

const alterarFaseDivida = (codigoDivida, comando) =>
  axios.post(`${urlDividas}/${codigoDivida}/alterar-fase`, comando, {
    noLoading: false,
  })

export default {
  recuperarDividasPorNivel,
  recuperarDivida,
  recuperarDividaComOperacoesPermitidas,
  recuperarTotalizadores,
  recuperarDividasPorResponsavel,
  recuperarDividasPorProcessoOriginador,
  recuperarDividasPorProcesso,
  recuperarDividasPorProcessoCbex,
  recuperarDividasComAlertas,
  recuperarAlertasAgrupadosSeveridade,
  recuperarResumoTotalizadores,
  atualizarDivida,
  atualizarDividasPorProcesso,
  atualizarDividasPorResponsavel,
  recuperarDividasComAlertasPorFiltro,
  downloadDocumentoDemonstrativo,
  recuperarDividasPorFiltro,
  recuperarDadosFiltro,
  exportarPesquisaPorFiltroParaCSV,
  validarSeDividaPodeSerVinculadaProcessoRap,
  alterarFaseDivida,
}

import axios from 'axios'
import moment from 'moment'
import gerarDocumento, { TIPO_CONTEUDO_XML } from '../components/comum/funcoesUtil'

const urlCalculadoraDividas = '/bff/publico/dividas/calculadora'

const obterDadosDebitoDoArquivo = (arquivo) => {
  const data = new FormData()
  data.append('arquivo', arquivo)
  return axios.post(`${urlCalculadoraDividas}/obter-dados-debito`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    noLoading: false,
  })
}

const calcularSaldoDebito = (comando) =>
  axios.post(`${urlCalculadoraDividas}/calcular-saldos-debito`, comando)

const gerarDemonstrativoDebitoAtualizado = (comando) => {
  axios
    .post(`${urlCalculadoraDividas}/gerar-demonstrativo-debito-atualizado`, comando)
    .then((response) => {
      gerarDocumento(response.data.conteudo, 1, response.data.nomeArquivo)
    })
}

const exportarDadosDebitoParaArquivoTexto = (dadosDebito) => {
  axios.post(`${urlCalculadoraDividas}/gerar-texto-dados-debito`, dadosDebito).then((response) => {
    gerarDocumento(
      response.data.conteudo,
      1,
      `Dados do débito ${moment().format('YYYYMMDDhhmmss')}.txt`,
      'text/plain'
    )
  })
}

const gerarDemonstrativoDebitoXML = (dadosDebito) => {
  axios.post(`${urlCalculadoraDividas}/gerar-xml-dados-debito`, dadosDebito).then((response) => {
    gerarDocumento(
      response.data.conteudo,
      1,
      `Demonstrativo do débito ${moment().format('YYYYMMDDhhmmss')}.xml`,
      TIPO_CONTEUDO_XML
    )
  })
}

export default {
  obterDadosDebitoDoArquivo,
  calcularSaldoDebito,
  gerarDemonstrativoDebitoAtualizado,
  exportarDadosDebitoParaArquivoTexto,
  gerarDemonstrativoDebitoXML,
}

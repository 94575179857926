import {Box, Divider, Switch, TextField, Tooltip, Typography} from '@mui/material'
import PropTypes from 'prop-types'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import InformacaoParcelamento from '@mui/icons-material/Info'
import FormControlLabel from '@mui/material/FormControlLabel'
import * as React from 'react'
import moment from "moment";
import classes from './CalculadoraDebitoStyle'
import PaperOutlined from '../../estilos/PaperOutlined'


const ParametrosGerais = ({
  dataAtualizacao,
  atualizarDataAtualizacao,
  aplicaJuros,
  atualizarAplicaJuros,
  dataInicioParcelamento,
  atualizarDataInicioParcelamento,
}) => (
  <PaperOutlined sx={{flexGrow:1}}>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}>
      <Box>
        <Typography variant="h5" gutterBottom>
          Parâmetros gerais
        </Typography>
        <Divider />
      </Box>
      <Box my={1} sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <DesktopDatePicker
          id="dataAtualizacao"
          size="small"
          label="Data atualização"
          inputFormat="dd/MM/yyyy"
          value={moment(dataAtualizacao,'DD/MM/YYYY')}
          renderInput={(params) => (
            <TextField
              InputLabelProps={{ shrink: true }}
              size="small"
              {...params}
              inputProps={{ ...params.inputProps, placeholder: 'dd/mm/aaaa' }}
            />
          )}
          onChange={(data) => atualizarDataAtualizacao(moment(data).format('DD/MM/YYYY'))}
        />
        <FormControlLabel
          sx={classes.switchIncluirJuros}
          control={
            <Switch
              size="small"
              checked={aplicaJuros}
              onChange={({ target }) => atualizarAplicaJuros(target.checked)}
            />
          }
          label="Incluir juros"
        />
        {!aplicaJuros && false && (
          <>
            <DesktopDatePicker
              id="dataInicioParcelamento"
              size="small"
              label="Início parcelamento"
              inputFormat="dd/MM/yyyy"
              value={dataInicioParcelamento}
              renderInput={(params) => (
                <TextField
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  {...params}
                  inputProps={{ ...params.inputProps, placeholder: 'dd/mm/aaaa' }}
                />
              )}
              onChange={(data) => atualizarDataInicioParcelamento(data)}
            />
            <Tooltip
              title="A data de início do parcelamento, a partir da qual passará a incidir juros, deve ser igual ou posterior à data da primeira parcela do débito."
              sx={{ color: 'grey.500' }}>
              <InformacaoParcelamento sx={{ color: 'grey.500' }} />
            </Tooltip>
          </>
        )}
      </Box>
    </Box>
  </PaperOutlined>
)

ParametrosGerais.propTypes = {
  dataAtualizacao: PropTypes.string,
  atualizarDataAtualizacao: PropTypes.func,
  aplicaJuros: PropTypes.bool,
  atualizarAplicaJuros: PropTypes.func,
  dataInicioParcelamento: PropTypes.string,
  atualizarDataInicioParcelamento: PropTypes.func,
}

export default ParametrosGerais

export const Types = {
  DEFINIR_NIVEL_LISTAGEM: '@DEFINIR_NIVEL_LISTAGEM',
}

export const Actions = {
  definirNivelListagem: (nivel) => ({ type: Types.DEFINIR_NIVEL_LISTAGEM, payload: nivel }),
}

export default (state = {} , action) => {
  switch (action.type) {
    case Types.DEFINIR_NIVEL_LISTAGEM: {
      return { ...action.payload }
    }
    default:
      return state
  }
}

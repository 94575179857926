import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, Typography, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const DialogoConfirmacaoAutuacao = (props) => {
  const {
    onConfirmar,
    onClose,
    onRecusar,
    // titulo,
    conteudo,
    open,
    id,
    labelBotaoSim = 'Sim',
    // labelBotaoNao = 'Não',
  } = props

  return (
    <Dialog
      id={id}
      data-testid="dialogo-confirmacao"
      open={open}
      maxWidth="sm"
      classes={{ paper: 'resetPaper' }}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title">
        <Box px={1} pt={1} sx={{ gap: '4px', justifyContent: 'space-between'}} display="flex">
          <Typography variant="h5">
            Digite sua senha
          </Typography>
          <CloseIcon
            style={{ cursor: 'pointer' }}
            name="nao"
            id="nao"
            data-testid="btn-dialogo-nao"
            onClick={() => onRecusar()}
            color="primary"
            variant="text">
            {labelBotaoSim}
          </CloseIcon>
        </Box>
      <DialogContent>
        {conteudo}
      </DialogContent>
      <DialogActions>
        <Button
          name="sim"
          id="sim"
          data-testid="btn-dialogo-sim"
          onClick={() => onConfirmar()}
          color="primary"
          variant="contained"
          fullWidth>
          {labelBotaoSim}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
DialogoConfirmacaoAutuacao.defaultProps = {
  onConfirmar: () => {},
  onRecusar: () => {},
  onClose: () => {},
  // titulo: 'Atenção',
  open: false,
}
DialogoConfirmacaoAutuacao.propTypes = {
  id: PropTypes.number,
  labelBotaoSim: PropTypes.string,
  onConfirmar: PropTypes.func,
  onRecusar: PropTypes.func,
  onClose: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  conteudo: PropTypes.any.isRequired,
  // titulo: PropTypes.string,
  open: PropTypes.bool,
}
export default DialogoConfirmacaoAutuacao

import React from 'react'
import { Box, Card, CardContent, Typography } from '@mui/material'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import IconeManutencao from '../img/icone-manutencao.svg'

const ErroNoServidor = () => {
  const location = useLocation()
  const { mensagens, detalhe } = queryString.parse(location?.search)
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '80vh',
        width: '100vw',
        alignItems: 'center',
      }}>
      <Card variant="outlined" square>
        <CardContent>
          <Box display="flex" alignItems="flex-start">
            <Box width={76} pr={2}>
              <img
                src={IconeManutencao}
                style={{ width: '100%', height: 'auto' }}
                alt="Ilustração de manutanção"
              />
            </Box>
            <Box>
              <Typography variant="h5" color="secondary" gutterBottom>
                {mensagens}
              </Typography>
              <Typography variant="body1" color="textPrimary">
                Uma cópia da mensagem deste erro foi encaminhada à equipe de TI.
              </Typography>
              <Box my={1}>
                <Typography variant="body1" color="textSecondary">
                  Detalhe do erro: {detalhe}
                </Typography>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default ErroNoServidor

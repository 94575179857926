import { useNavigate } from 'react-router-dom'
import {
  Divider,
  List,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Typography,
  ListItem, Stack
} from '@mui/material'
import PaperOutlined from 'components/estilos/PaperOutlined'
import IconHoverArrow from 'components/estilos/IconHoverArrow'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { formataCnpj, formataCpf } from '../../../../util/util'
import Falecido from "../../../estilos/Falecido";

const Responsaveis = ({ responsaveis }) => {
  const navigate = useNavigate()
  const { seUsuarioInternoTcu } = useSelector((state) => state.authentication)

  const handleResponsavelClick = (responsavel) => {
    navigate(`/dividas/responsaveis/${responsavel.pessoa.codigo}`)
  }

  const renderResponsavel = ({pessoa={}}) => (
    <ListItemText sx={{ flexGrow: '0'}}
      primary={
      <Typography color='textPrimary'>
        {pessoa.nome}
      </Typography>
      }
      secondary={
      <Stack direction="row" spacing={1}>
        <Typography variant="body2" color="grey.500">
          {_.isNull(pessoa.numeroCpf)
            ? formataCnpj(pessoa.numeroCnpj)
            : formataCpf(pessoa.numeroCpf)}
        </Typography>
        <Falecido falecido={pessoa.falecido}/>
      </Stack>
      }
    />
  )

  return (
    <PaperOutlined>
      <Typography variant="h5" gutterBottom>
        Responsáveis
      </Typography>
      <Divider />
      <List
        dense
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '8px',
          '& .MuiListItemButton-root:hover': {
            backgroundColor: 'action.hover',
            '& .MuiTypography-root': {
              color: 'primary.main',
            },
            '& .forwardIconHover ': {
              visibility: 'visible',
            },
          },
        }}>
        {seUsuarioInternoTcu &&
          responsaveis?.map((it) => (
            <ListItemButton sx={{flexGrow: 0}} key={it.pessoa.codigo} onClick={() => handleResponsavelClick(it)}>
              {renderResponsavel(it)}
              <ListItemAvatar
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}>
                <IconHoverArrow />
              </ListItemAvatar>
            </ListItemButton>
          ))}
        {!seUsuarioInternoTcu &&
          responsaveis?.map((it) => (
            <ListItem key={it.pessoa.codigo}>{renderResponsavel(it)}</ListItem>
          ))}
      </List>
    </PaperOutlined>
  )
}

Responsaveis.propTypes = {
  responsaveis: PropTypes.array.isRequired,
}

export default Responsaveis
